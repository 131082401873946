import React, { useEffect, useState } from "react"
import CustomerInsightRow from "../Components/CustomerInsightRow";
import PreferenceView from "../Components/PreferenceView";
import { Translate } from "../../translate/Translate";

export default function CustomerDetailInsights(props) {

    const { insights, preferences, customer } = props

    const [stats, setStats] = useState({ favorites: {}, page_views: {} })

    useEffect(() => {
        setStats(insights.reduce((obj, item) => {
            if (item.target == "user_favorite") {
                if (!obj.favorites[item.property.property_id]) {
                    obj.favorites[item.property.property_id] = 0
                }
                obj.favorites[item.property.property_id] += 1
            }
            if (item.target == "view_history") {
                if (!obj.page_views[item.property.property_id]) {
                    obj.page_views[item.property.property_id] = 0
                }
                obj.page_views[item.property.property_id] += 1
            }
            return obj
        }, { favorites: {}, page_views: {} }))

    }, [insights])

    return (
        <div className="flex-1 flex relative  h-full overflow-hidden  overflow-y-auto bg-gray-100">

            <div className="mx-auto p-4">
                <p className="font-semibold text-xl mb-6">{Translate("Insights")} ({insights?.length})</p>

                {
                    insights.map((value, index) => (<CustomerInsightRow key={index} value={value} />))
                }


                {/* {Object.keys(insights?.preference || {}) != 0 ? <div className="rounded-lg border shadow-lg flex flex-col bg-white overflow-hidden mb-12">
                            <div className="p-6 flex-auto">
                                <p className="text-3xl font-bold">Preference</p>
                                <p className="text-sm font-medium text-gray-500">{Translate("Area")}:<span>{insights?.preference.areas.map((area) => Translate(area)).join(", ")}</span></p>
                                <p className="text-sm font-medium text-gray-500">{Translate("Budget")}:<span>{insights?.preference.budget}</span></p>
                                <p className="text-sm font-medium text-gray-500">{Translate("I'm looking for")}:<span>{insights?.preference.looking_for_property.map((l) => Translate(l)).join(", ")}</span></p>
                                <p className="text-sm font-medium text-gray-500">{Translate("Purposes")}:<span>{insights?.preference.purposes.map((l) => Translate(l)).join(", ")}</span></p>
                                <p className="text-sm font-medium text-gray-500">{Translate("Stage")}:<span>{Translate(insights?.preference.stage)}</span></p>
                            </div>
                        </div>: null} */}
                {/* <div className="grid lg:grid-cols-4 gap-6 mb-12">
                            {insights?.preference.areas.length != 0 ? (<div className="rounded-lg border shadow-lg flex flex-col bg-white overflow-hidden">
                            <div className="p-6 flex-auto">
                                <p className="text-sm font-medium text-gray-500">{Translate("Area")}:</p>
                                <p className="text-3xl font-bold">{insights?.preference.areas.map((area) => Translate(area)).join(", ")}</p>
                            </div>
                        </div>):null}
                            {insights?.preference.budget != 0 ? (<div className="rounded-lg border shadow-lg flex flex-col bg-white overflow-hidden">
                            <div className="p-6 flex-auto">
                                <p className="text-sm font-medium text-gray-500">{Translate("Budget")}:</p>
                                <p className="text-3xl font-bold">{new Intl.NumberFormat('ja-JP',{ style: 'currency', currency: "JPY" }).format(Number(insights?.preference.budget)) }</p>
                            </div>
                        </div>):null}
                            {insights?.preference.looking_for_property.length != 0 ? (<div className="rounded-lg border shadow-lg flex flex-col bg-white overflow-hidden">
                            <div className="p-6 flex-auto">
                                <p className="text-sm font-medium text-gray-500">{Translate("I'm looking for")}:</p>
                                <p className="text-xl font-bold">{insights?.preference.looking_for_property.map((l) => Translate(l)).join(", ")}</p>
                            </div>
                        </div>):null}
                            {insights?.preference.looking_for_property.length != 0 ? (<div className="rounded-lg border shadow-lg flex flex-col bg-white overflow-hidden">
                            <div className="p-6 flex-auto">
                                <p className="text-sm font-medium text-gray-500">{Translate("Purposes")}:</p>
                                <p className="text-xl font-bold">{insights?.preference.purposes.map((l) => Translate(l)).join(", ")}</p>
                            </div>
                        </div>):null}
                            {insights?.preference.looking_for_property.length != 0 ? (<div className="rounded-lg border shadow-lg flex flex-col bg-white overflow-hidden">
                            <div className="p-6 flex-auto">
                                <p className="text-sm font-medium text-gray-500">{Translate("Stage")}:</p>
                                <p className="text-xl font-bold">{insights?.preference.stage}</p>
                            </div>
                        </div>):null}
                        </div>
                        <div className="grid lg:grid-cols-4 gap-6 mb-12">
                            <div className="rounded-lg border shadow-lg flex flex-col bg-white overflow-hidden">
                                <div className="p-6 flex-auto">
                                    <p className="text-sm font-medium text-gray-500">View properties</p>
                                    <p className="text-3xl font-bold">{insights?.views.data.length}</p>
                                </div>
                            </div>
                            <div className="rounded-lg border shadow-lg flex flex-col bg-white overflow-hidden">
                                <div className="p-6 flex-auto">
                                    <p className="text-sm font-medium text-gray-500">Favorite properties</p>
                                    <p className="text-3xl font-bold">{insights?.favorites.data.length}</p>
                                </div>
                            </div>
                        </div> */}
            </div>

            <div className="flex-none w-1/3 border-l bg-white sticky top-0">
                <PreferenceView preferences={preferences} customer={customer} stats={stats} />
            </div>
        </div>
    )
}