
import React from "react"
import { Link } from "react-router-dom";
import { ArrowRightIcon, ArrowTopRightOnSquareIcon, PencilIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import * as Constants from '../../../constants'
import ViilaLogo from "../Shared/ViilaLogo";
import FullName from "../Shared/FullName";
import * as Account from '../../../account';
import { Translate, TranslateWithLang } from "../../translate/Translate";
import DateOnly from "../Shared/DateOnly";
import AppContext from "../../../AppContext"
import EmptyStateOnActivityList from "./EmptyStateOnActivityList";

export default function GreetingCard(props) {

    const { showWelcomAtctions } = props

    const { language, account } = React.useContext(AppContext)

    const greeting = () => {
        var hour = new Date().getHours()
        if (hour < 6) {
            return Translate("Hi");
        } else if (hour < 12) {
            return Translate("Good morning")
        } else if (hour < 18) {
            return Translate("Good afternoon")
        } else if (hour < 24) {
            return Translate("Good evening")
        }
        return "Hi"
    }

    const expired = () => {
        const date1 = new Date();
        const date2 = new Date(account.user.subscription_end * 1000)
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays
    }

    return (

        <div className="p-4 bg-white rounded-lg shadow">
            <div className="flex flex-col lg:flex-row items-center gap-4">
                <div className="relative w-16 h-16 rounded-full bg-slate-200 border border-slate-300  flex items-center justify-center overflow-hidden">
                    {
                        account.user.company.image_url ?
                            <img className="w-full h-full object-cover" src={account.user.company.image_url} />
                            :
                            <div className="flex flex-col items-center justify-center">
                                <ViilaLogo className="w-6 h-6 fill-current text-slate-400" />
                                <Link className="text-xs border-b border-dashed border-b-primary text-primary flex items-center" to={`/v2/settings/profile`}>
                                    <span>Edit</span>
                                </Link>
                            </div>
                    }
                </div>

                <div className="">
                    <p className="text-lg font-bold"><span>{greeting()}</span>, <FullName firstname={account.user.first_name} lastname={account.user.last_name} /></p>
                    <div className="flex items-center text-sm font-medium text-slate-500 gap-4">
                        <div className="flex items-center gap-1">

                            {
                                account.user.company.name == "" ?
                                    <Link to={`/v2/settings/profile`} className="border-b border-dashed border-b-primary text-primary flex items-center" >
                                        <span>Update company name</span>
                                        <ArrowRightIcon className="w-3 h-3 ml-2" />
                                    </Link>
                                    :
                                    <p>{account.user.company.name}</p>
                            }
                        </div>
                    </div>
                    <div className="flex items-center gap-2 text-sm font-medium mt-1">
                        <div className="flex items-center gap-1">
                            <GlobeAltIcon className="w-4 h-4 text-slate-500" />
                            <span>{account.user.agent_reference_id}</span>
                        </div>
                        {
                            account.user.company.name == "" ? null
                                :
                                <a href={`${Constants.BASE_URL}/a/${account.user.agent_reference_id}`} target="_blank" className="flex items-center gap-1 text-primary">
                                    <span>{Translate("View on Viila")}</span> <ArrowTopRightOnSquareIcon className="w-4 h-4 text-primary" />
                                </a>
                        }
                    </div>
                </div>
                <div className="ml-auto">
                    <Link to={`/v2/settings/profile`} className="flex items-center text-xs font-semibold rounded bg-slate-100 px-2 py-2">
                        <PencilIcon className="w-4 h-4 mr-1 text-slate-500" />
                        <span className="text-slate-500">{Translate("Edit profile")}</span>
                    </Link>
                </div>
            </div>
            {
                showWelcomAtctions ?
                    <EmptyStateOnActivityList {...props} />
                    : null
            }
        </div>

    )
}