import React from "react"
import AppContext from "../../../AppContext"

export default function FullName(props) {

    const {language} = React.useContext(AppContext)   
    const {firstname,lastname} = props;
    return (
        <>
        {language === "ja"? 
        lastname + " " + firstname
        :
        firstname + " " + lastname
        }
        </>
    )
}