import React, { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom";

import * as Constants from '../../../constants'
import * as api from '../../services/agent'
import SideNavBar from "../SideNavBar";
import ScreenLoading from "../Components/ScreenLoading";
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';


export default function EditPropertyWizard(props) {
    const [errorMessage, setErrorMessage] = useState("");
    const [showError, setShowError] = useState(false);
    const [requestIsOpen, setRequestIsOpen] = useState(false);
    const [requestData, setRequestData] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const { featureList = [], language, Translate, history } = props
    const { id } = useParams();
    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [editDialog, setEditDialog] = useState("")
    const [data, setData] = useState({
        status: "active",
        transaction_type: "brokerage_general",
        property_type: "house",
        area: {},
        address: {},
        price: {
            amount: "",
            currency: "JPY"
        },
        land: {},
        layout: {},
        other_costs: [],
        apartments: [],
        features: [],
        latlng: {
            latitude: 36.3418518,
            longitude: 138.6179432,
        },
        built: {
            year: '',
            month: ''
        },
        document: {
            files: [],
            data: {}
        },
        images: [],
        archived_images: [],
    })
    const [selectedImageIndex, setSelectedImageIndex] = React.useState([]);

    
    function loadPropertyDetail(id) {
        return api.fetchPropertyDetailForEdit(id)
            .then(response => {
                response.features = response.features.map((feature, index) => {
                    var data = featureList.find(f => f.key === feature.title)
                    if (data) return { key: feature.title, type: feature.type, title: feature.title, index, data, value: feature.value };
                    return { key: feature.title, type: feature.type, title: feature.title, index, value: feature.value };
                })
                if (response.built.year !== undefined) {
                    if (response.built.year == 0) {
                        response.built.year = ""
                    } else {
                        response.built.year = `${response.built.year}`
                    }
                }
                if (response.built.month !== undefined) {
                    if (response.built.month == 0) {
                        response.built.month = ""
                    } else {
                        response.built.month = `${response.built.month}`
                    }
                }
                response.prefecture = { id: response.area.prefecture.id }
                
                setData(response)
                return response

            })
    }

    useEffect(() => {
        
        loadPropertyDetail(id).then((property) => {
            setLoading(false)
        })
        
    }, [id]);

    const onSelect = (index) => {
        var list = [...data.images];
        if (index >= list.length) {
            index = 0
        }
        if (index < 0) {
            index = list.length - 1
        }

        const i = selectedImageIndex.indexOf(index);
        if (i > -1) {
            selectedImageIndex.splice(i, 1);
        } else {
            selectedImageIndex.push(index)
        }
        setSelectedImageIndex(selectedImageIndex)

        //trigger loop images
        setData({ ...data, images: data.images });
    }


    const SortableItem = SortableElement(({ image, imageIndex }) => {
        return (
            <div index={imageIndex} key={`sortable-image-${imageIndex}`} className="flex flex-col w-full flex-none relative cursor-pointer" onClick={e => { 
                if (e.target.classList.contains("remove-photo")) {
                    e.preventDefault()
                } else {
                    onSelect(imageIndex)
                }
             }} >
                <img className={`object-cover h-32 w-full rounded ring-blue-600 ${(selectedImageIndex.indexOf(imageIndex) > -1 ? "ring-4" : "ring-1")}`} src={image.url} alt="" />
                {image.uploaded ? 
                    selectedImageIndex.indexOf(imageIndex) > -1 ? (
                    <div className="absolute top-0 left-0 p-1 bg-green-500 text-white">
                        {selectedImageIndex.indexOf(imageIndex) + 1}
                    </div>) :null 
                    : (
                    <div className="absolute top-0 left-0 p-1 bg-yellow-500 text-white">
                        <svg className="animate-spin  h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                )}
            </div>)
    })
    const SortableList = SortableContainer(({ images }) => {
        return (<div className="grid grid-cols-2 gap-4">
            {images.map((image, index) => (<SortableItem key={index} index={index} image={image} imageIndex={index} style={{ zIndex: 99999999 }} />))}
        </div>)
    })

    const onSortEnd = (obj) => {
        const { oldIndex, newIndex } = obj
        setData({ ...data, images: arrayMove(data.images, oldIndex, newIndex) })
    }


    return (
        <div className="w-full flex bg-slate-50">
            <SideNavBar active="properties" isPresented={true} />
            <main className="w-full h-full flex flex-col">

                <div className="z-50 bg-white border-b">
                    <div className="w-full container mx-auto p-4">
                        <div className="flex items-center">
                            <div>
                                <div className="flex flex-row mb-1">
                                    <Link to={`/v2/properties`} type="button" className="text-xs uppercase font-medium text-gray-600 inline-flex items-center">
                                        <svg className="fill-current text-gray-600 mr-2" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.890625 5.76953C0.890625 5.91602 0.943359 6.04492 1.05469 6.15625L5.70117 10.6973C5.80078 10.8027 5.92969 10.8555 6.08203 10.8555C6.38672 10.8555 6.62109 10.627 6.62109 10.3223C6.62109 10.1699 6.55664 10.041 6.46289 9.94141L2.19727 5.76953L6.46289 1.59766C6.55664 1.49805 6.62109 1.36328 6.62109 1.2168C6.62109 0.912109 6.38672 0.683594 6.08203 0.683594C5.92969 0.683594 5.80078 0.736328 5.70117 0.835938L1.05469 5.38281C0.943359 5.48828 0.890625 5.62305 0.890625 5.76953Z" />
                                        </svg>
                                        <span>{Translate("Listing")}</span>
                                    </Link>
                                </div>
                                <p className="text-2xl font-semibold">{Translate("video")}</p>
                            </div>
                            <div className="ml-auto">
                            </div>
                        </div>
                    </div>
                </div>

                <section className="flex w-full container mx-auto mt-4 md:flex-row flex-col gap-4 p-4">

                    <div className="bg-white h-full">
                        <div className="flex flex-row w-full h-full">
                            <div className="w-96 h-full overflow-y-auto">
                                <div className="p-4">
                                    <SortableList distance={2} images={data.images} axis="xy" onSortEnd={onSortEnd} />
                                </div>
                                <div>
                                    <button className=" w-full rounded px-4 py-2 bg-green-500 text-white font-semibold inline-flex items-center justify-center" onClick={() => {
                                        console.log("rendering")
                                    }}>
                                        <span className="mr-2">{Translate("render_video_button")}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {isLoading && (<ScreenLoading {...props} />)}
            
        </div>
    )
}