import React from "react"
import { Translate } from "../../translate/Translate";
import { useParams } from "react-router-dom";
import moment from "moment";
import * as api from '../../services/agent'
import * as Constants from '../../../constants'
import { Link } from 'react-router-dom';
import AppContext from "../../../AppContext";

export function NoteRow(props) {

    const {
        note,
        isSelected
    } = props

    return (
        <div className={`p-4 border-b cursor-pointer ${isSelected ? "bg-gray-100" : ""}`}>
            <div className="flex items-center">
                <div>
                    <p className="font-semibold text-sm leading-none ">{note.data.message ? note.data.message : ""} <span className="font-normal text-gray-500 ml-2 text-xs ">{moment(note.created_at).fromNow()}</span></p>
                </div>
                <div className="ml-auto flex-none ">
                    {
                        note.amount ?
                            <div className="flex items-center text-gray-500">
                                <span className="text-xs font-medium">{note.reply_amount}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                                </svg>
                            </div>
                            : null
                    }
                </div>
            </div>
        </div>
    )
}


export default function CustomerDetailNotes(props) {

    let { customerId, tab, id } = useParams();
    const account = props.account;
    const { customer, query, history, goTab } = props
    const [notes, setNotes] = React.useState([])
    const [selectedId, setSelectedId] = React.useState("")
    const [selectedNote, setSelectedNote] = React.useState(null)
    const [isNewNote, setIsNewNote] = React.useState(false)
    const [message, setMessage] = React.useState({
        data: {
            message: "",
            description: ""
        }
    })

    const handleMessageChange = (e) => {
        message.data.message = e.target.value
        setMessage({ ...message })
    }
    const handleDescriptionChange = (e) => {
        message.data.description = e.target.value
        setMessage({ ...message })
    }

    const loadNotes = (customerNo) => {
        api.customerNotes(customerNo)
            .then(data => {
                setNotes(data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const newNote = () => {
        if (customerId) {
            api.customerNewNote(customerId, message)
                .then(data => {
                    loadNotes(customerId)
                    setSelectedId(data)
                    setIsNewNote(false)
                    setMessage({
                        data: {
                            message: "",
                            description: ""
                        }
                    })
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }


    const onArchive = () => {
        api.customerArchiveNote(customerId, selectedNote.id)
            .then(data => {
                loadNotes(customerId)
                setSelectedId("")
            })
            .catch(error => {
                console.log(error);
            })
    }

    React.useEffect(() => {
        if (id)
            setSelectedId(id)
    }, [id])

    React.useEffect(() => {
        if (customerId)
            loadNotes(customerId)
    }, [customerId])

    React.useEffect(() => {
        if (selectedId) {
            setSelectedNote(notes.find(note => note.id == selectedId))
            history.replace(`/v2/customers/${customerId}/${tab}/${selectedId}${query.get("search") ? "?search=" + query.get("search") : ""}`)
        }
        else {
            setSelectedNote(null)
            history.replace(`/v2/customers/${customerId}/${tab}${query.get("search") ? "?search=" + query.get("search") : ""}`)
        }
    }, [notes, selectedId])

    return (
        isNewNote ?
            <div className="flex flex-col relative h-full overflow-hidden overflow-y-auto w-full">
                <div className="flex-1 flex flex-col relative  h-full overflow-hidden  overflow-y-auto bg-gray-100">
                    <div className="flex-1 px-4 py-4 overflow-hidden overflow-y-auto">
                        <div className="flex flex-col gap-4 ">
                            <div className="rounded-lg border shadow-sm w-full bg-white mt-4">
                                <div className="px-4 pt-4 font-semibold inline-flex">
                                    <a href="#" onClick={(e) => { setIsNewNote(false) }} >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                                        </svg>
                                    </a>
                                    {Translate("Add note")}
                                </div>
                                <div className="px-4 pt-4 pb-3">
                                    <textarea value={message.data.message} onChange={handleMessageChange} className="p-0 w-full border-0 rounded focus:ring-0 focus:outline-none" placeholder={Translate("Title")} rows={3}></textarea>
                                </div>
                                <div className="px-4 pt-4 pb-3">
                                    <textarea value={message.data.description} onChange={handleDescriptionChange} className="p-0 w-full border-0 rounded focus:ring-0 focus:outline-none" placeholder={Translate("Description")} rows={10}></textarea>
                                </div>
                                <div className="border-t p-4">
                                    <button disabled={message.data.message.trim().length === 0} type="button" onClick={(e) => { newNote() }} className="disabled:opacity-50 bg-blue-600 text-white text-sm font-semibold px-3 py-2 rounded">{Translate("Add")}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            :
            <div className="flex-1 flex relative  h-full overflow-hidden  overflow-y-auto">
                <div className="border-r w-72 flex flex-col h-full overflow-hidden  overflow-y-auto">
                    <a onClick={(e) => { setIsNewNote(true) }}>
                        <div className={`p-4 border-b cursor-pointer`} >
                            <div className="flex items-center text-center">
                                <div>
                                    <p className="font-semibold text-xl leading-none text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                                        </svg>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </a>

                    {notes ? notes.map((note, i) =>
                        <a key={`note-${note.id}`} onClick={() => { setSelectedId(note.id) }}>
                            <NoteRow note={note} isSelected={note.id === selectedId}></NoteRow>
                        </a>
                    )
                        : null
                    }
                </div>

                <div className="flex-1 flex flex-col relative  h-full overflow-hidden  overflow-y-auto bg-gray-100">
                    <div className="border-b px-4 py-4 bg-white">
                        <div className="border border-gray-300 rounded-md overflow-hidden inline-flex items-center justify-between w-auto divide-x shadow-sm">
                            <button onClick={onArchive} className="inline-flex items-center px-4 py-2 hover:bg-gray-100">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                                </svg>
                                <span className="text-sm font-semibold ml-2 text-gray-600">{Translate("Archive")}</span>
                            </button>
                        </div>
                    </div>
                    {
                        selectedNote && selectedNote.data && selectedNote.data.description ?
                            <div className="flex-1 px-4 py-4 overflow-hidden overflow-y-auto">
                                <div className="flex flex-col gap-4 ">
                                    <div className="rounded-lg border shadow-sm w-full bg-white">
                                        <div className="px-4 pt-4 pb-3">
                                            <p className="p-0 w-full h-full border-0 rounded focus:ring-0 focus:outline-none" > {selectedNote.data.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div> : null
                    }
                </div>
            </div>
    )
}
