import { useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import {  ClipboardDocumentIcon } from "@heroicons/react/24/outline"


export default function CopyClipboard({id, text, Translate}) {
    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom' })

    return (<Popover id={id}>
        <Popover.Button ref={setReferenceElement} className="w-full ">
            <CopyToClipboard 
                className="flex-none rounded px-2 text-sm font-semibold py-2 bg-blue-100 text-blue-600 flex items-center whitespace-nowrap"
                text={text}
                >
                    <div>
                        <ClipboardDocumentIcon className="w-5 h-5 mr-2"/>
                        {Translate("Copy")}    
                    </div>
            </CopyToClipboard>
        </Popover.Button>
        <Popover.Panel
            className="bg-blue-600 rounded-lg shadow-lg ring-1 p-1 ring-slate-200 w-24 text-center"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}>
            <p className="text-white font-semibold">{Translate("copied")}</p>
            <div className={`absolute left-10 -top-1 w-3 h-3 bg-blue-600 -z-10`} style={{"transform": "rotate(45deg)"}}></div>
        </Popover.Panel>
    </Popover>)
}