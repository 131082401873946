import React from "react"
import SideNavBar from "../SideNavBar";
import TopSearchbar from "../TopSearchbar";
import { Link } from 'react-router-dom';
import * as api from '../../services/agent'
import * as Account from '../../../account';
import moment from "moment";
import { useParams } from "react-router-dom";
import { Translate } from "../../translate/Translate";
import { PropertyStatusBadge } from "../Shared/PropertyStatusBadge"

export default function OfferDetail(props) {

    let { customerId, code } = useParams();

    const [account] = React.useState(Account.getUserAccount());

    const [otherData, setOtherData] = React.useState({ message: "" })
    const [data, setData] = React.useState({
        property: {
            property_id: "",
            images: [
                {
                    url: ""
                },
            ],
            price: {
                amount: ""
            }
        },
        have_seen_the_property: true,
        offer: {
            amount: "",
            percentage_from_list_price: ""
        },
        earnest_payment: {
            amount: "",
            percentage_from_offer_price: ""
        },
        payment: {
            method: "",
            down_payment_percentage: "",
            down_payment_amount: ""
        },
        terms: {
            additional: ""
        },
        contact: {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            address_1: "",
            address_2: "",
            prefecture: "",
            zip_code: "",
            country_code: ""
        },
        contract_signing: {
            preferred_date: ""
        },
        closing: {
            preferred_date: ""
        },
        replies: [],
        accepted_at: "",
        rejected_at: "",
        canceled_at: "",
        created_at: "",
        data: {
            message: ""
        }
    });

    const [replyingMessage, setReplyingMessage] = React.useState({
        data: {
            message: ""
        }
    })

    const handleOfferMessageChange = (e) => {
        otherData.message = e.target.value.trim()
        setOtherData(otherData)
    }

    const handleMessageChange = (e) => {
        replyingMessage.data.message = e.target.value.trim()
        setReplyingMessage(replyingMessage)
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (replyingMessage.data.message.trim().length === 0) {
            return;
        }
        onReply(data.id, replyingMessage)
        e.target.reset();
    }

    function OfferStatus(props) {

        if (data.canceled_at) {
            return (
                <div>
                    <p className="text-red-500 text-lg font-semibold">{Translate("Canceled")}</p>
                    <p className="text-sm">{moment(data.canceled_at).format("LLL")}</p>
                </div>
            )
        }

        if (data.accepted_at) {
            return (
                <div>
                    <p className="text-green-500 text-lg font-semibold">{Translate("Accepted")}</p>
                    <p className="text-sm">{moment(data.accepted_at).format("LLL")}</p>
                </div>
            )
        }

        if (data.rejected_at) {
            return (
                <div>
                    <p className="text-red-500 text-lg font-semibold">{Translate("Rejected")}</p>
                    <p className="text-sm">{moment(data.rejected_at).format("LLL")}</p>
                </div>
            )
        }

        return (
            <div></div>
        );

    }

    const listOffers = () => {
        if (data === null) {
            return []
        }
        return data
    }


    const acceptOffer = (offerId) => {
        api.acceptOffer(offerId, otherData)
            .then(data => {
                loadOfferDetail(code);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const rejectOffer = (offerId) => {
        api.rejectOffer(offerId, otherData)
            .then(data => {
                loadOfferDetail(code);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const onReply = (offerId, message) => {
        api.commentOffer(offerId, message)
            .then(response => {
                replyingMessage.data.message = ""
                loadOfferDetail(code);
            })
            .catch(error => {
                console.log(error)
            })
    }

    function loadOfferDetail(code) {
        api.offerDetail(code)
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    React.useEffect(() => {
        if (code !== undefined) {
            loadOfferDetail(code);
        }
    }, [code]);

    function offerPercent() {
        return Number(((Number(data.offer.amount) * 100) / Number(data.property.price.amount)) - 100).toFixed(0);
    }

    function earnestPercent() {
        return ((Number(data.earnest_payment.amount) * 100) / Number(data.offer.amount))
    }

    return (

        <div className="w-full flex bg-slate-50">
        <SideNavBar {...props} isPresented={true} />
        <div className="flex flex-col w-full relative">

            <div className="bg-white">
                <div className="w-full container mx-auto lg:px-8 px-4">

                    <div className="py-8">
                        <p className="text-3xl font-bold">{Translate("Offers")}</p>
                    </div>

                </div>
            </div>
            <div className="lg:p-8 p-4 flex items-center container mx-auto">


            <div className="">
                    <div className="w-full container mx-auto">
                        <div className="mb-8 flex justify-between items-center">
                            <div>
                                <div className="flex flex-row mb-1">
                                    <button type="button" onClick={(e) => { props.history.goBack() }} className="text-xs uppercase font-medium text-gray-600 inline-flex items-center">
                                        <svg className="fill-current text-gray-600 mr-2" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.890625 5.76953C0.890625 5.91602 0.943359 6.04492 1.05469 6.15625L5.70117 10.6973C5.80078 10.8027 5.92969 10.8555 6.08203 10.8555C6.38672 10.8555 6.62109 10.627 6.62109 10.3223C6.62109 10.1699 6.55664 10.041 6.46289 9.94141L2.19727 5.76953L6.46289 1.59766C6.55664 1.49805 6.62109 1.36328 6.62109 1.2168C6.62109 0.912109 6.38672 0.683594 6.08203 0.683594C5.92969 0.683594 5.80078 0.736328 5.70117 0.835938L1.05469 5.38281C0.943359 5.48828 0.890625 5.62305 0.890625 5.76953Z" />
                                        </svg>
                                        <span>{Translate(customerId ? "Customers" : "Offers")}</span>
                                    </button>
                                </div>

                                <p className="text-2xl font-semibold">{Translate("Offer detail")}</p>
                            </div>

                            <div className="py-2 flex justify-end">


                            </div>
                        </div>
                        {!data.code ? null :
                            <main className="w-full">

                                <div className="grid grid-cols-1 lg:grid-cols-4 lg:space-x-6">

                                    <div className="col-span-1">
                                        <div className="bg-white rounded-lg shadow overflow-hidden mb-4">
                                            {
                                                data.property.images.length > 0 ?
                                                    <img className="object-cover h-36 w-full" src={data.property.images[0].url} />
                                                    :
                                                    <div className="w-36 h-36 bg-gray-300"></div>
                                            }

                                            <div className="p-4">
                                                <p className="text-xs">{data.property.property_id}</p>
                                                <p className="text-lg font-medium">{data.property.name}</p>
                                                <p className="text-lg font-medium">
                                                    {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: data.property.price.currency ?? "JPY" }).format(Number(data.property.price.amount))}
                                                </p>
                                                <PropertyStatusBadge status={data.property.status}></PropertyStatusBadge>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-3">

                                        <div className="bg-white rounded-lg shadow mb-4 overflow-hidden">

                                            <div className="border-b p-4  bg-gray-50">
                                                <div className="flex items-center">

                                                    <div>
                                                        <p className="text-xl font-semibold">{Translate("Offer")} · {data.code}</p>
                                                        <p className="text-sm">{Translate("From")} {data.contact.first_name} {data.contact.last_name} · {moment(data.created_at).fromNow()}</p>
                                                    </div>
                                                    <div className="ml-auto text-right">

                                                        <OfferStatus data={data}></OfferStatus>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 lg:grid-cols-1">

                                                <div className="">
                                                    <div className="grid grid-cols-2 w-full p-4">

                                                        <div className="col-span-1 border-b py-2"><p>{Translate("Offer ID")}</p></div>
                                                        <div className="col-span-1 border-b py-2 text-left font-medium"><p>{data.code}</p></div>
                                                        <div className="col-span-1 border-b py-2"><p>{Translate("Property Price")}</p></div>
                                                        <div className="col-span-1 border-b py-2 text-left font-medium"><p>
                                                            {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: data.property.price.currency ?? "JPY" }).format(Number(data.property.price.amount))}
                                                        </p></div>
                                                        <div className="col-span-1 border-b py-2"><p>{Translate("Offer price")} <span className="text-gray-600 text-xs">{Translate("prefix % of list price")}{offerPercent() > 0 ? "+" : ""}{offerPercent()}{Translate("suffix % of offer price")}</span></p></div>
                                                        <div className="col-span-1 border-b py-2 text-left font-medium"><p>
                                                            {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: data.property.price.currency ?? "JPY" }).format(Number(data.offer.amount))}
                                                        </p></div>
                                                        <div className="col-span-1 border-b py-2"><p>{Translate("Earnest money")} <span className="text-gray-600 text-xs">{Translate("prefix % of offer price")}{earnestPercent()}{Translate("suffix % of offer price")}</span></p></div>
                                                        <div className="col-span-1 border-b py-2 text-left font-medium"><p>
                                                            {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: data.property.price.currency ?? "JPY" }).format(Number(data.earnest_payment.amount))}
                                                        </p></div>
                                                        <div className="col-span-1 border-b py-2"><p>{Translate("Payment")}</p></div>
                                                        <div className="col-span-1 border-b py-2 text-left font-medium"><p>{Translate(data.payment.method)}</p></div>
                                                        <div className="col-span-1 border-b py-2"><p>{Translate("Preferred contract signing date")}</p></div>
                                                        <div className="col-span-1 border-b py-2 text-left font-medium"><p>{moment(data.contract_signing.preferred_date).format("LL")}</p></div>
                                                        <div className="col-span-1 border-b py-2"><p>{Translate("Preferred closing date")}</p></div>
                                                        <div className="col-span-1 border-b py-2 text-left font-medium"><p>{moment(data.closing.preferred_date).format("LL")}</p></div>
                                                        <div className="col-span-1 border-b py-2"><p>{Translate("Additional terms")}</p></div>
                                                        <div className="col-span-1 border-b py-2 text-left font-medium"><p>{data.terms.additional}</p></div>
                                                        <div className="col-span-1 py-2">
                                                            <p>{Translate("Contact info")}</p>
                                                        </div>
                                                        <div className="col-span-1 py-2 font-medium">
                                                            <p>{data.contact.first_name} {data.contact.last_name}</p>
                                                            <p>{data.contact.email}</p>
                                                            <p>{data.contact.phone}</p>
                                                            <p>{data.contact.address_1} {data.contact.address_2}, {data.contact.city}, {data.contact.prefecture} {data.contact.zip_code}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-100 p-6 rounded">
                                            <OfferStatus></OfferStatus>
                                            {
                                                data.data.message === "" ?
                                                    null
                                                    :
                                                    <div className="mt-4">
                                                        <p className="mb-1 text-xs uppercase text-gray-600">{Translate("Your message to buyer")}</p>
                                                        <p className="whitespace-pre-wrap">”{data.data.message}”</p>
                                                    </div>
                                            }

                                        </div>
                                        <div className="mx-auto flex items-center justify-center">
                                            {
                                                data.rejected_at || data.accepted_at || data.canceled_at ? null :
                                                    <div className="w-full text-center">
                                                        <div className="w-full">
                                                            <textarea className="w-full rounded" required placeholder={Translate("textarea_placeholder_when_reply_to_offer")} onChange={handleOfferMessageChange}></textarea>
                                                        </div>
                                                        <div>
                                                            <button onClick={(e) => { rejectOffer(data.id) }} className="rounded bg-red-600 text-white py-2 px-4 font-medium mr-4">{Translate("Reject offer")}</button>
                                                            <button onClick={(e) => { acceptOffer(data.id) }} className="rounded bg-green-600 text-white py-2 px-4 font-medium">{Translate("Accept offer")}</button>
                                                        </div>
                                                        <p className="mt-4">{Translate("warning_message_offer")}</p>
                                                    </div>
                                            }
                                        </div>

                                        <div>
                                            <div className="mt-8">
                                                {
                                                    data.replies.map((reply, index) => (
                                                        <div key={index} className="flex mb-4">

                                                            <div className="pt-1">
                                                                <div className="rounded-full h-10 w-10 flex items-center justify-center bg-gray-600">
                                                                    <img className="w-10 h-10 object-cover rounded-full" decoding="async" loading="lazy"
                                                                        src={reply.user.image_url} />
                                                                </div>
                                                            </div>
                                                            <div className="ml-4 bg-white w-full border rounded">
                                                                <p className="font-medium text-sm bg-gray-100 p-3">{reply.user.first_name} {reply.user.last_name} ·<span
                                                                    className="ml-2 text-normal text-gray-600">{moment(reply.created_at).fromNow()}</span> </p>
                                                                <p className="whitespace-pre-wrap p-3">{reply.data ? reply.data.message : null}</p>
                                                                <div className="mt-0">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }


                                                <form onSubmit={onSubmit}>

                                                    <div className="flex mb-4">
                                                        <div className="pt-1">
                                                            <div className="rounded-full h-10 w-10 flex items-center justify-center bg-gray-600">
                                                                <img className="w-10 h-10 lazyload object-cover rounded-full" decoding="async" loading="lazy" src={account.user.image_url} />
                                                            </div>

                                                        </div>
                                                        <div className="ml-4 bg-white w-full border rounded">
                                                            <p className="font-medium text-sm bg-gray-100 p-3">{account.user.first_name}&nbsp;{account.user.last_name}</p>
                                                            <p className="p-3">
                                                                <textarea onChange={handleMessageChange} rows="4" className="block w-full rounded-lg border-gray-300 shadow"></textarea>

                                                                <button type="submit"
                                                                    className="mt-3 py-2 px-4 bg-green-500 text-white rounded font-medium">{Translate("Submit")}</button>
                                                            </p>
                                                        </div>
                                                    </div>

                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </main>
                        }
                    </div>
                </div >
             
            </div>
        </div>
    </div>
    )
}