import React, { useCallback } from "react"
import * as api from '../../services/agent'
import { PropertyStatusBadge } from "../Shared/PropertyStatusBadge"
import Select from 'react-select'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { Translate } from "../../translate/Translate";
import Error from '../Shared/Error'
import { PhotoManager } from "../Shared/PhotoManager";
import ApartmentFeatureList from "./ApartmentFeatureList"
import AppContext from "../../../AppContext"


export default function StepApartmentSelector(props) {

    const { language } = React.useContext(AppContext)

    const onSelected = (value) => {
        props.onSelected(value)
    }

    const [searchTerm, setSearchTerm] = React.useState("");
    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    const builtDateString = (month, year) => {
        const date = new Date(year, month, 1);  // 2009-11-10
        const monthString = date.toLocaleString(language, { month: 'short' });
        return monthString  + " " + year;
    }

    const getFeatureItemValue = (features, key) => {
        const feature = features.find(feature => {return feature.title == key});
        return feature.value;
    }
    return (
        <>
          <input
                type="text" className="rounded w-full mb-4"
                placeholder={Translate("Search")}
                value={searchTerm}
                onChange={handleChange}
            />
        <div className="bg-white shadow-lg border rounded-lg p-8 grid grid-cols-2 lg:grid-cols-4 gap-6">
          
            {
            props.options.filter(p =>
                p.name.toLowerCase().includes(searchTerm.toLowerCase()) || Translate(p.name).toLowerCase().includes(searchTerm.toLowerCase())
              ).map((item,index)=> (
                <button key={item.id} onClick={(e) => onSelected(item)} className={`py-4 text-center border rounded-lg font-bold justify-center flex flex-col px-4  ${props.data.apartment === item.id ? "bg-blue-600 text-white" : "text-gray-400"}`}>
                        <div className="">{Translate(item.name)}</div>
                        <div className="text-left text-xs font-meidum flex space-x-4">
                            <p>{Translate("Built")}: {builtDateString(item.built.month, item.built.year)}</p>
                            <p>{Translate("Floors")}: {item.amount_of_floors}</p>
                            <p>{Translate("Rooms")}: {item.amount_of_rooms}</p>
                            </div>
                        {/* <ApartmentFeatureList features={item.features}></ApartmentFeatureList> */}
                    </button>
            ))
            }
        </div>
        </>

    )
}