import React, {useRef, useState, useContext} from "react"
import { MAPBOX_TOKEN } from '../../../constants'
import MapView from "../../../v2/views/MapView";
import SelectedPropertiesPanel from "../../../v2/views/Components/SelectedPropertiesPanel"
import * as api from '../../../v2/services/agent'
import PropertyRequestBuilder from "../../../v2/views/Components/PropertyRequestBuilder";
import Geocoder from "../../../v2/views/Components/Geocoder"
import AppContext from "../../../AppContext"
import { Translate } from "../../translate/Translate";
import TopNavBar from '../Shared/TopNavBar'
import ReactMapGL, {
    Marker,
    // FlyToInterpolator,
    Popup,
    NavigationControl,
    FullscreenControl,
    ScaleControl,
    GeolocateControl,
    Source,
    Layer,
} from 'react-map-gl';

const geolocateStyle = {
    bottom: 125,
    right: 0,
    padding: '0px'
};

const fullscreenControlStyle = {
    bottom: 160,
    right: 0,
    padding: '0px'
};

const navStyle = {
    bottom: 30,
    right: 0,
    padding: '0px'
};

const scaleControlStyle = {
    bottom: 24,
    left: 0,
    padding: '0px'
};


export default function Map(props) {

    const { account, language } = useContext(AppContext)
    const mapRef = useRef()
    const [viewport, setViewport] = useState({
        latitude: 36.3418518,
        longitude: 138.6179432,
        zoom: 13
    });
    const onViewportChange = (evt) => {
        setViewport(evt.viewState)
    }

    const onClick =(event) => {
        console.log("event", event)
        const features = mapRef.current.queryRenderedFeatures(event.point);
        console.log("features", features)
    }

    const handleTextChange = (e) => {
        const filterValue = e.target.value
        const filter = ['any', ['==', '地番',filterValue]]
        mapRef.current.setFilter('karuizawa-boundary', filter)
    }

    return (
        <div className="absolute inset-0 flex flex-col">
            <div className="flex-none h-16">
                <TopNavBar active="map"></TopNavBar>
            </div>

            <div className=" w-full h-full flex flex-col bg-blue-200">

                <main className="w-full h-full flex flex-col">
                    <div className="flex flex-col w-full h-full relative overflow-hidden">
                        <div className=" flex items-center w-full h-full">

                            <div className="h-full w-full relative">
                                
                                <div className="flex flex-col w-full h-full relative">

                                    <div id="map" className=" h-full w-full bg-gray-500 relative border-t">
                                        <ReactMapGL
                                            ref={mapRef}
                                            attributionControl={false}
                                            doubleClickZoom={false}
                                            mapStyle="mapbox://styles/apisitviila/clfj0nw3d002j01mrx6ch29xd"
                                            mapboxAccessToken={MAPBOX_TOKEN}
                                            {...viewport}
                                            onMove={onViewportChange}
                                            style={{ width: "100%", height: "100%" }}
                                            onClick={onClick}
                                        >
                                            <ScaleControl position="bottom-right" style={scaleControlStyle} />
                                            <FullscreenControl position="bottom-right" style={fullscreenControlStyle} />
                                            <NavigationControl position="bottom-right" style={navStyle} />
                                            <GeolocateControl position="bottom-right" style={geolocateStyle} />
                                        </ReactMapGL>
                                    </div>
                                </div>
                                <div className="absolute top-3 left-1/2 transform -translate-x-1/2 w-1/3 flex flex-col">
                                    <div className="flex flex-col w-full relative">
                                        <div className="h-10 flex items-center border border-gray-300  rounded overflow-hidden bg-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                            <input id="search-geocoder" placeholder={Translate("Address")} onChange={handleTextChange} type="text" className=" w-full border-0 focus:outline-none focus:ring-0 focus:border-none placeholder-gray-300" />
                                            <button id="geocoder-reset-button" type="button" onClick={(e) => {
                                                e.stopPropagation()
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}