
import React from "react"
import TopNavBar from '../Shared/TopNavBar'
import { Translate } from "../../translate/Translate";
import { PricingTable } from "../Shared/PricingTable";

export default function Pricing(props) {
    return (
        <div className="">
            <TopNavBar active="pricing"></TopNavBar>
            <div className="mt-16 mb-16 w-full container mx-auto py-8 px-4">
                <div className="mb-8 flex justify-between items-center">
                    <div>
                        <p className="text-2xl font-semibold">{Translate("Pricing")}</p>
                    </div>
                </div>
                <main className="w-full">
                    <PricingTable></PricingTable>
                </main>

            </div>
        </div>
    );
}