import { Translate } from "../../../translate/Translate";
import { Tab } from '@headlessui/react'
import * as Account from '../../../../account';
import React from "react"

export default function PhotoForm({ data }) {
    const [account] = React.useState(Account.getUserAccount());

    const money = (value) => parseFloat(value.trim().replaceAll(",", "").normalize('NFKC').replace(/[^0-9]/g, ""))

    return (
        <div className="text-sm shadow rounded-lg bg-white divide-y">
            <div className="p-4">
                <p className="text-lg font-semibold">{Translate("Preview")}</p>
            </div>
            <div className="py-4 lg:py-4 px-6">
                <Tab.Group defaultIndex={0}>
                    <Tab.List className="w-full flex gap-1 items-center justify-between rounded-lg bg-slate-200 overflow-hidden p-1 mb-1">
                        <Tab className="flex items-center justify-center w-full focus:outline-none">
                            {({ selected }) => (
                                <div className={`rounded-lg w-full h-full py-2 text-sm font-medium 
                                    ${selected ? 'text-primary bg-white shadow' : 'text-slate-400 hover:bg-slate-100/60'}
                                    `}>
                                    {Translate("Feed")}
                                </div>
                            )}
                        </Tab>
                        <Tab className="flex items-center justify-center w-full focus:outline-none">
                            {({ selected }) => (
                                <div className={`rounded-lg w-full h-full py-2 text-sm font-medium 
                                            ${selected ? 'text-primary bg-white shadow' : 'text-slate-400 hover:bg-slate-100'}
                                            `}>
                                    {Translate("Property details")}
                                </div>
                            )}
                        </Tab>
                    </Tab.List>
                    <Tab.Panels>
                        <Tab.Panel className="grid lg:grid-cols-3">
                            <div className="mt-8">
                                <div className="bg-white lg:border shadow-sm hover:shadow-lg overflow-hidden relative transition duration-500 ease-in-out transform">
                                    <div className="flex items-center p-4">
                                        <div className="inline-flex items-center" rel="canonical">
                                            <div className="flex-none bg-gray-300 rounded-full h-8 w-8 overflow-hidden border"><img src={account.user.company.image_url} className="w-full h-full object-cover rounded-full" /></div>
                                            <div className="flex flex-col ml-2 truncate text-ellipsis">
                                                <div className=" inline-flex items-center">
                                                    <p className=" text-xs font-semibold leading-tight">{account.user.company.name}</p>
                                                </div>
                                                <div className="text-xs mt-0.5 text-gray-500 truncate text-ellipsis">
                                                    <div className="flex items-center">
                                                        <p className="capitalize text-xs ">{Translate(data.area.id)}&nbsp;·&nbsp;{Translate(data.property_type)}&nbsp;·&nbsp;{data.property_id ? data.property_id : account.user.agent_reference_id + "-PN000"}&nbsp;·&nbsp;</p>
                                                        <div className="inline-flex items-center " title="ページビュー数">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                                                            </svg>
                                                            <span className="text-xs">0</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" ml-auto flex-none">
                                            <div className="focus:outline-none" aria-label="favorite">
                                                <svg className="w-6 h-6 text-red-500 fill-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full flex-none relative">
                                        <div className="">
                                            <div className="absolute p-2 z-20">
                                                <div className="flex flex-wrap">
                                                    <div className="mr-2 mb-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs uppercase focus:outline-none bg-black bg-opacity-50">
                                                        <svg className="fill-current text-white w-3 h-3" viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.44531 13.9531H4.89062V15.2969C4.89062 16.9062 5.71094 17.7188 7.34375 17.7188H19.1953C20.8203 17.7188 21.6484 16.9062 21.6484 15.2969V6.99219C21.6484 5.39062 20.8203 4.57812 19.1953 4.57812H17.7422V3.3125C17.7422 1.70312 16.9219 0.890625 15.2891 0.890625H3.44531C1.80469 0.890625 0.992188 1.70312 0.992188 3.3125V11.5391C0.992188 13.1484 1.80469 13.9531 3.44531 13.9531ZM3.46094 12.6953C2.67969 12.6953 2.25 12.2812 2.25 11.4688V3.38281C2.25 2.57031 2.67969 2.14844 3.46094 2.14844H15.2734C16.0391 2.14844 16.4844 2.57031 16.4844 3.38281V4.57812H7.34375C5.71094 4.57812 4.89062 5.38281 4.89062 6.99219V12.6953H3.46094ZM6.14844 7.0625C6.14844 6.25 6.58594 5.83594 7.36719 5.83594H19.1719C19.9453 5.83594 20.3906 6.25 20.3906 7.0625V13.5859L17.5234 10.8828C17.1797 10.5625 16.7734 10.4062 16.3438 10.4062C15.9141 10.4062 15.5391 10.5547 15.1719 10.875L11.6641 13.9844L10.2656 12.7188C9.92969 12.4297 9.57812 12.2734 9.20312 12.2734C8.85938 12.2734 8.53125 12.4141 8.20312 12.7109L6.14844 14.4922V7.0625ZM10.3906 11.0781C11.3984 11.0781 12.2266 10.25 12.2266 9.22656C12.2266 8.22656 11.3984 7.38281 10.3906 7.38281C9.375 7.38281 8.54688 8.22656 8.54688 9.22656C8.54688 10.25 9.375 11.0781 10.3906 11.0781Z"></path>
                                                        </svg>
                                                        <span className="ml-1 font-semibold text-white text-xs">{data.images.length}</span>
                                                    </div>
                                                    {data.vr?.url? <div className="bg-indigo-600 inline-flex items-center text-white px-2 py-0.5 rounded-full font-semibold text-xs uppercase mr-2 mb-2 truncate">{Translate("3D Walkthrough")}</div>: null}
                                                    {data.video?.url? <div className="bg-red-600 text-white px-2 py-0.5 rounded-full mr-2 mb-2 truncate flex items-center justify-center">
                                                        <svg className="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                            <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                                                        </svg>
                                                    </div>: null}
                                                    
                                                </div>
                                            </div>

                                            {data.images.length === 0 ? (
                                                <div className="z-0 h-64 w-full object-cover bg-gray-100 relative">
                                                </div>
                                            ) : (<div className="w-full relative h-64 z-10">
                                                <img id="main-image" alt="" decoding="async" className="absolute top-0 left-0 hover:opacity-0 lazyload z-0 w-full h-64 object-cover"
                                                    src={data.images[0].url} />
                                                {data.images.length > 1 ? (<img alt="" decoding="async" className="lazyload z-0 w-full h-64 object-cover "
                                                    src={data.images[1].url} />) : null}

                                            </div>)}
                                        </div>
                                    </div>

                                    <div className="w-full p-4">
                                        <div className="">
                                            <div className="">
                                                <p className="text-sm lg:truncate">{data.name}</p>
                                                <div className="flex items-baseline">
                                                    <p className="font-bold text-lg leading-8">{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(money(`${data.price.amount}`) || 0))}</p>
                                                </div>
                                                <div className="flex mt-1">
                                                    <div className="flex space-x-6 lg:space-x-4 truncate overflow-ellipsis">
                                                        {
                                                            data.property_type == "apartment" && data.floor ?
                                                                <div>
                                                                    <p className="text-sm text-gray-600">{Translate("room_location_floor_title")}/{Translate("total_floors_title")}</p>
                                                                    <p className="text-sm font-medium truncate overflow-ellipsis">{data.floor}/{data.amount_of_floors}</p>
                                                                </div>
                                                                : null
                                                        }


                                                        {
                                                            data.property_type == "house" || data.property_type == "apartment" ?
                                                                <>
                                                                    {data.layout.name ? <div>
                                                                        <p className="text-sm text-gray-600">{Translate("Floor plan")}</p>
                                                                        <p className="text-sm font-medium truncate overflow-ellipsis">{data.layout.name}</p>
                                                                    </div> : null}
                                                                    {data.sqm ? <div className="tooltip">
                                                                        <p className="text-sm text-gray-600">{Translate("Building size")}</p>
                                                                        <p className="text-sm font-medium truncate overflow-ellipsis">{data.sqm} m²</p>
                                                                    </div> : null}
                                                                </>
                                                                : null
                                                        }

                                                        {data.land.sqm ?
                                                            < div >
                                                                <p className="text-sm text-gray-600">{Translate("Land size")}</p>
                                                                <p className="text-sm font-medium truncate overflow-ellipsis">{(data.land.sqm || 0).toLocaleString()}m²</p>
                                                            </div> : null}


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab.Panel>
                        <Tab.Panel className="px-4">
                            <div className="container mx-auto flex space-x-1 lg:rounded-lg lg:overflow-hidden px-4 mt-8">
                                {data.images.length === 0 ? (
                                    <>
                                        <div className="w-2/3 object-cover bg-gray-100 relative h-96 lg:h-192">
                                        </div>
                                        <div className="w-1/3 flex flex-col h-96 lg:h-192">
                                            <div className="bg-gray-100 h-1/2 border pb-1 w-full object-cover lazyloaded"></div>
                                            <div className="bg-gray-100 h-1/2 border pb-1 w-full object-cover lazyloaded"></div>
                                        </div>
                                    </>
                                ) : (<>
                                    <div className=" w-2/3  relative h-96 lg:h-192">
                                        <img decoding="async" data-image="0" className=" h-96 lg:h-192 w-full object-cover ls-is-cached lazyloaded" alt="" src={data.images[0].url} />
                                        <div className="absolute top-0 p-4 flex flex-wrap text-sm">
                                            {data.vr?.url? <div className="inline-flex items-center px-4 py-2 focus:outline-none rounded-lg bg-blue-600 text-white mr-2 mb-2" id="vr-opener">
                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.47656 16.4375C12.9062 16.4375 16.5625 12.7812 16.5625 8.35938C16.5625 3.9375 12.8984 0.28125 8.46875 0.28125C4.04688 0.28125 0.398438 3.9375 0.398438 8.35938C0.398438 12.7812 4.05469 16.4375 8.47656 16.4375ZM6.69531 8.59375C6.69531 6.11719 7.32812 3.63281 8.53125 1.89844C11.9453 1.92969 14.7031 4.57031 14.9297 7.92969C13.1875 9.14062 10.7031 9.78125 8.21875 9.78125C7.71875 9.78125 7.22656 9.75781 6.73438 9.69531C6.71094 9.33594 6.69531 8.96875 6.69531 8.59375ZM5.1875 8.59375C5.1875 8.875 5.19531 9.14844 5.21094 9.42969C4.00781 9.14062 2.90625 8.66406 2.03125 8.00781C2.17969 5.17969 4.10938 2.85156 6.73438 2.13281C5.71875 3.95312 5.1875 6.27344 5.1875 8.59375ZM8.21875 11.2891C10.5938 11.2891 12.9766 10.7344 14.8125 9.66406C14.2188 12.6172 11.625 14.8125 8.5 14.8281C7.75781 13.8516 7.25 12.6016 6.96094 11.2344C7.375 11.2656 7.79688 11.2891 8.21875 11.2891ZM2.17969 9.78906C3.125 10.3516 4.22656 10.75 5.39844 11C5.625 12.2969 6.03906 13.5156 6.64844 14.5625C4.40625 13.9062 2.6875 12.0859 2.17969 9.78906Z" fill="white"></path>
                                                </svg>
                                                <span className="ml-2 font-medium text-white">{Translate("3D Walkthrough")}</span>
                                            </div>: null}
                                            <p className="inline-flex items-center px-4 py-2 focus:outline-none rounded-lg bg-blue-600 mr-2 mb-2" id="gallery-opener">
                                                <svg className="fill-current text-white" width="22" height="18" viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.44531 13.9531H4.89062V15.2969C4.89062 16.9062 5.71094 17.7188 7.34375 17.7188H19.1953C20.8203 17.7188 21.6484 16.9062 21.6484 15.2969V6.99219C21.6484 5.39062 20.8203 4.57812 19.1953 4.57812H17.7422V3.3125C17.7422 1.70312 16.9219 0.890625 15.2891 0.890625H3.44531C1.80469 0.890625 0.992188 1.70312 0.992188 3.3125V11.5391C0.992188 13.1484 1.80469 13.9531 3.44531 13.9531ZM3.46094 12.6953C2.67969 12.6953 2.25 12.2812 2.25 11.4688V3.38281C2.25 2.57031 2.67969 2.14844 3.46094 2.14844H15.2734C16.0391 2.14844 16.4844 2.57031 16.4844 3.38281V4.57812H7.34375C5.71094 4.57812 4.89062 5.38281 4.89062 6.99219V12.6953H3.46094ZM6.14844 7.0625C6.14844 6.25 6.58594 5.83594 7.36719 5.83594H19.1719C19.9453 5.83594 20.3906 6.25 20.3906 7.0625V13.5859L17.5234 10.8828C17.1797 10.5625 16.7734 10.4062 16.3438 10.4062C15.9141 10.4062 15.5391 10.5547 15.1719 10.875L11.6641 13.9844L10.2656 12.7188C9.92969 12.4297 9.57812 12.2734 9.20312 12.2734C8.85938 12.2734 8.53125 12.4141 8.20312 12.7109L6.14844 14.4922V7.0625ZM10.3906 11.0781C11.3984 11.0781 12.2266 10.25 12.2266 9.22656C12.2266 8.22656 11.3984 7.38281 10.3906 7.38281C9.375 7.38281 8.54688 8.22656 8.54688 9.22656C8.54688 10.25 9.375 11.0781 10.3906 11.0781Z"></path>
                                                </svg>
                                                <span className="ml-2 font-medium text-white">{data.images.length}</span>
                                            </p>
                                            {data.video?.url? <div className="inline-flex items-center px-4 py-2 focus:outline-none rounded-lg bg-red-600 text-white mr-2 mb-2" id="vr-opener">
                                                <svg className="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                                                </svg>
                                            </div>: null}
                                        </div>
                                    </div>
                                    <div className="w-1/3 flex flex-col h-96 lg:h-192">
                                        {data.images.length > 1 ? (
                                            <img decoding="async" data-image="1" className="  h-1/2  pb-1 w-full object-cover lazyloaded" alt="" src={data.images[1].url} />
                                        ) : (<div className="bg-gray-100 h-1/2 border pb-1 w-full object-cover lazyloaded"></div>)}
                                        {data.images.length > 2 ? (
                                            <img decoding="async" data-image="2" className="  h-1/2  pb-1 w-full object-cover lazyloaded" alt="" src={data.images[2].url} />
                                        ) : (<div className="bg-gray-100 h-1/2 border pb-1 w-full object-cover lazyloaded"></div>)}
                                    </div>
                                </>)}

                            </div>
                            <div className="px-4 pb-4">
                                <div className="container mx-auto bg-white ">
                                    <div className="grid grid-cols-1 lg:grid-cols-3 items-center">

                                        <div className="lg:col-span-2 flex flex-wrap gap-x-4 gap-y-2 lg:gap-x-8 px-4 py-4 lg:py-6 ">
                                            <div>
                                                <p className="text-sm text-gray-600">{Translate("Listed price")}</p>
                                                <p className="lg:text-2xl font-bold">{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(data.price.amount || 0))}</p>
                                            </div>

                                            {
                                                data.property_type == "apartment" && data.floor ?
                                                    <div>
                                                        <p className="text-sm text-gray-600">{Translate("room_location_floor_title")}/{Translate("total_floors_title")}</p>
                                                        <p className="lg:text-2xl font-bold">{data.floor}/{data.amount_of_floors}</p>
                                                    </div>
                                                    : null
                                            }


                                            {
                                                data.property_type == "house" || data.property_type == "apartment" ?
                                                    <>
                                                        {data.layout && data.layout.name ? <div>
                                                            <p className="text-sm text-gray-600">{Translate("Floor plan")}</p>
                                                            <p className="lg:text-2xl font-bold">{data.layout.name}</p>
                                                        </div> : null}
                                                        {data.sqm ? <div className="tooltip">
                                                            <p className="text-sm text-gray-600">{Translate("Building size")}</p>
                                                            <p className="lg:text-2xl font-bold">{data.sqm} m²</p>
                                                        </div> : null}
                                                    </>
                                                    : null
                                            }

                                            {data.land.sqm ?
                                                < div >
                                                    <p className="text-sm text-gray-600">{Translate("Land size")}</p>
                                                    <p className="lg:text-2xl font-bold">{(data.land.sqm || 0).toLocaleString()}m²</p>
                                                </div> : null}

                                        </div>


                                        <div className="lg:ml-auto pb-6 lg:py-6">
                                            <div className="col-span-1 lg:col-span-1 px-4 lg:px-0">
                                                <div className="flex items-center w-full justify-between gap-4">

                                                    <p className=" w-full inline-flex items-center py-2 px-2 justify-center border border-gray-600 lg:border-0 rounded" >
                                                        <svg className="w-6 h-6 text-red-500 fill-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path>
                                                        </svg>
                                                        <span className="ml-2 text-sm font-semibold text-gray-600 whitespace-nowrap block lg:hidden">{Translate("Favorite")}</span>
                                                    </p>

                                                    <p type="button" id="share-button" className=" w-full inline-flex items-center  py-2 px-2 justify-center border border-gray-600 lg:border-0 rounded">
                                                        <svg className="w-6 h-6 fill-current text-gray-600 " viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.01367 9.05322C6.26562 9.05322 6.47656 8.84229 6.47656 8.59619V2.57861L6.44141 1.69971L6.83984 2.11572L7.72461 3.06494C7.80664 3.15869 7.92969 3.20557 8.04102 3.20557C8.28711 3.20557 8.46875 3.02979 8.46875 2.78955C8.46875 2.6665 8.42188 2.57275 8.33398 2.48486L6.35352 0.574707C6.23633 0.45752 6.13086 0.416504 6.01367 0.416504C5.89648 0.416504 5.79102 0.45752 5.67383 0.574707L3.69336 2.48486C3.60547 2.57275 3.55273 2.6665 3.55273 2.78955C3.55273 3.02979 3.73438 3.20557 3.97461 3.20557C4.0918 3.20557 4.21484 3.15869 4.29688 3.06494L5.1875 2.11572L5.58594 1.69971L5.54492 2.57861V8.59619C5.54492 8.84229 5.76172 9.05322 6.01367 9.05322ZM2.65039 13.6411H9.37109C10.5957 13.6411 11.2109 13.0317 11.2109 11.8247V5.97705C11.2109 4.77002 10.5957 4.16064 9.37109 4.16064H7.73633V5.104H9.35352C9.93359 5.104 10.2676 5.42041 10.2676 6.02979V11.772C10.2676 12.3813 9.93359 12.6978 9.35352 12.6978H2.66211C2.07617 12.6978 1.75391 12.3813 1.75391 11.772V6.02979C1.75391 5.42041 2.07617 5.104 2.66211 5.104H4.28516V4.16064H2.65039C1.42578 4.16064 0.810547 4.77002 0.810547 5.97705V11.8247C0.810547 13.0317 1.42578 13.6411 2.65039 13.6411Z"></path>
                                                        </svg>
                                                        <span className="ml-2 text-sm font-semibold text-gray-600 whitespace-nowrap block lg:hidden">{Translate("Share")}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 lg:col-span-2 px-4">
                                <div className="flex items-center">
                                    <ol className="flex items-baseline uppercase text-xs font-medium text-gray-600">
                                        <li className="flex">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-1 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                            </svg>
                                            {Translate(data.area.name ? data.area.name : data.area.id)}
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                            </svg>
                                            {Translate(data.property_type)}
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                            </svg>
                                            {data.property_id ? data.property_id : account.user.agent_reference_id + "-PN000"}
                                        </li>
                                    </ol>
                                </div>

                                <div className="grid grid-cols-1 mb-8 mt-2">
                                    <div className="col-span-1 flex items-center">
                                        <div>
                                            <div className="">
                                                <h1 className="text-xl lg:text-2xl font-semibold mb-1"> {data.name}</h1>
                                            </div>
                                            <div className="pt-2 flex space-x-2">
                                                <span className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-green-500  text-white uppercase whitespace-nowrap">
                                                    {Translate("Active")}
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className="">
                                    <a name="overview"></a>
                                    <div className="text-xl font-bold mb-4"> {Translate("Overview")}</div>
                                    <p className="whitespace-pre-wrap">{data.overview ? data.overview : "-"}</p>
                                    <div className="text-xl font-bold mt-4 mb-4">{Translate("Remarks")}</div>
                                    <p className="whitespace-pre-wrap">{data.remarks ? data.remarks : "-"}</p>
                                    <div className="text-xl font-bold mt-4 mb-4">{Translate("Regulations/Restrictions")}</div>
                                    <p className="whitespace-pre-wrap">{data.regulations ? data.regulations : "-"}</p>
                                </div>
                            </div>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div >
        </div >
    )
}