import { EnvelopeIcon, UserIcon } from "@heroicons/react/24/outline";
import React from "react"
import * as api from '../../services/agent'
import { Translate } from "../../translate/Translate";
import FullName from '../Shared/FullName'

export default function CustomerDetailProfile(props) {
    const attrs = {
        // "customer_no": false,
        // "email": false,
        "first_name": true,
        "last_name": true,
        "phone": true,
        "address_1": true,
        "address_2": true,
        "city": true,
        "prefecture": true,
        "zip_code": true,
    }
    const { customer } = props
    const [profile, setProfile] = React.useState(customer)
    const [fields, setFields] = React.useState({})

    React.useEffect(() => {
        var fields = {}
        for (var i in customer) {
            if (attrs[i] != undefined)
                fields[i] = {
                    id: i,
                    type: "text",
                    value: customer[i]
                }
        }
        setFields(fields)
        setProfile(customer)
    }, [customer])


    const handleDataChange = (e) => {
        var { name, value } = e.target

        //if name is set like "agent.license" then we assign it like below
        if (name.includes(".")) {
            let fields = name.split(".")
            let object = fields[0];
            let fieldName = fields[1];
            setProfile({ ...profile, [object]: { ...profile[object], [fieldName]: value } })
            return;
        }
        setProfile({ ...profile, [name]: value })
    }

    const updateCustomer = (e) => {
        e.preventDefault();
        api.updateCustomer(profile)
            .then(response => {
                alert("Success")
            })
            .catch(error => {

            })
    }

    return (
        <div className="flex relative h-full overflow-hidden overflow-y-auto w-full">
            <div className="container mx-auto flex lg:flex-row flex-col items-start bg-white">
                {/* left  */}
                <div className="w-full lg:w-80 p-4 flex-none flex">

                    <div className="rounded-lg shadow bg-white p-4 flex flex-col items-center w-full">
                        {
                            customer.image_url.length > 0 ? 
                            <img className="rounded-full w-20 h-20 ring-2 ring-primary ring-offset-2" src={customer.image_url} />
                            :
                            <div className="w-20 h-20 rounded-full bg-gray-200 flex items-center justify-center">
                                <UserIcon className="w-6 h-6"/>
                            </div>
                        }
                        
                        <p className="font-semibold mt-4 mb-1"><FullName firstname={customer.first_name} lastname={customer.last_name}></FullName></p>
                        <p className="text-xs mb-4">{customer.email}</p>
                        <a href={`mailto:${customer.email}`} className="flex items-center justify-center gap-2 text-sm font-medium w-full bg-primary/10 text-primary p-2 rounded-md">
                            <EnvelopeIcon className="w-4 h-4"/>
                            <span>Email</span>
                        </a>
                    </div>
                </div>
                {/* end left */}


                {/* start right  */}
                <div className="p-4 flex-1 w-full">
                    {/* <p className="font-semibold text-xl mb-6">{Translate("Profile")}</p> */}
                    
                    <form className="w-full" onSubmit={updateCustomer} >
                            <div className=" flex flex-col">
                                <div className="grid grid-cols-1 gap-4">
                                    <div>
                                        <p className="text-sm text-gray-500 font-semibold">{Translate("customer_no")}</p>
                                        <p>{customer.customer_no}</p>
                                    </div>
                                    {
                                        Object.keys(fields).map((key) => (
                                            <label className="flex flex-col w-full">
                                                <span className="text-sm text-gray-500 font-semibold">{Translate(fields[key].id)}</span>
                                                {attrs[fields[key].id] ?
                                                    <input type="text" name={fields[key].id} id={fields[key].id} value={profile[key]} onChange={handleDataChange} className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                                                    :
                                                    <input type="text" disabled name={fields[key].id} id={fields[key].id} value={profile[key]} className="text-gray-500 mt-1 focus:ring-gray-500 focus:border-white block w-full shadow-sm sm:text-sm border-white rounded-md" />

                                                }

                                            </label>

                                        ))
                                    }
                                </div>

                            </div>

                            <button type="submit" className="mt-4 btn text-sm bg-green-500 text-white">{Translate("Update")}</button>
                        </form>
                </div>
                {/* end right */}
            </div>
        </div>
    )
}