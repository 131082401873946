import * as Constants from '../../constants'

const slug = (text = '') => {
    return text.trim().toLowerCase().replaceAll(" ", "-")
}

const FloorAreaRatio = (data) => {
    const f = data.features.find((f) => f.title == "Floor Area Ratio")
    if (f) {
        return f.value
    }

    return ""
}
const BuildingCoverageRatio = (data) => {
    const f = data.features.find((f) => f.title == "Building Coverage Ratio")
    if (f) {
        return f.value
    }

    return ""
}

const HasSingleFAR = (data) => {
    return FloorAreaRatio(data).split("").filter((str) => str == "%").length == 1
}

const BulidingSizeExceedRatio = (data) => {
    if (data.property_type == 'apartment') {
        return false
    }
    if (FloorAreaRatio(data) == "" || HasSingleFAR(data) == false) {
        return false
    }
    return data.sqm > data.land.sqm
}

const FloorAreaSQM = (data) => {
    const f = data.features.find((f) => f.title === "Floor Area Ratio")
    if (f) {
        return (+f.value) * data.land.sqm
    }
    return 0
}

const HasBCRandFAR = (data) => {
    return FloorAreaRatio(data) !== "" && BuildingCoverageRatio(data) !== ""
}

const HasSingleBCR = (data) => {
    return BuildingCoverageRatio(data).split("").filter((str) => str == "%").length == 1
}

const BuildingCoverageSQM = (data) => {
    return +BuildingCoverageRatio(data) * data.land.sqm / 100
}

const PricePerSQM = (data) => {
    let size = data.sqm
    if (data.property_type === 'lot'){
        size = data.land.sqm
    }

    if (size <= 0) {
        size = 1
    }

    let perSQM = data.price.amount / size
    return perSQM
}

export function EmbedProperty(Translate, property) {    
    if (!property) return ""

    var blockquote = `<blockquote>
    ${Translate("Name")} ${property.name}<br>
    ${Translate("Price")} ${property.price ? new Intl.NumberFormat('ja-JP',{ style: 'currency', currency: property.price.currency ?? "JPY" }).format(Number(property.price.amount)) : null}<br>
    `

    if (property.property_type === "house"){
        blockquote += `
        ${Translate("Floor plan")} ${property.layout.name}<br>
        ${Translate("Building size")} ${property.sqm.toLocaleString()}m²<br>
        ${BulidingSizeExceedRatio(property) ? `${Translate("Building size exceeds Floor Area Ratio")} ${FloorAreaRatio(property)} · ${FloorAreaSQM(property)}m²<br>` : ""}
        ${Translate("Land size")} ${property.land.sqm.toLocaleString()}m²<br>
        ${HasBCRandFAR(property) ? `${Translate("BCR")}/${Translate("FAR")} ${BuildingCoverageRatio(property)}/${FloorAreaRatio(property)} <br> 
        ${HasSingleBCR(property) && HasSingleFAR(property) ? `${Translate("Building Coverage Ratio")} ${BuildingCoverageSQM(property)}m² <br>${Translate("Floor Area Ratio")} ${FloorAreaSQM(property)}m²` : "" }` : ""}
        `
    }

    if (property.property_type === "apartment"){
        blockquote += `
        ${property.floor !== "" ? `${Translate("room_location_floor_title")}/${Translate("total_floors_title")} ${property.floor}/${property.amount_of_floors}<br>`  : ""}
        ${Translate("Floor plan")} ${property.layout.name}<br>
        ${Translate("Building size")} ${property.sqm.toLocaleString()}m²<br>
        ${Translate("Price per m²")} ${PricePerSQM(property)}/m²
        `
    }

    if (property.property_type === "lot"){
        blockquote += `
        ${Translate("Land size")} ${property.land.sqm}}m²<br>
        ${Translate("Price per m²")} ${PricePerSQM(property)}/m<br>
        ${Translate("Building size")} ${property.sqm.toLocaleString()}m²<br>
        ${Translate("Price per m²")} ${PricePerSQM(property)}/m²
        ${HasBCRandFAR(property) ? `${Translate("BCR")}/${Translate("FAR")} ${BuildingCoverageRatio(property)}/${FloorAreaRatio(property)} <br> 
        ${HasSingleBCR(property) && HasSingleFAR(property) ? `${Translate("Building Coverage Ratio")} ${BuildingCoverageSQM(property)}m² <br>${Translate("Floor Area Ratio")} ${FloorAreaSQM(property)}m²` : "" }` : ""}
        `
    }

    blockquote += `</blockquote>`

    return `<div class="viila-property-card" data-property-no="${property.property_id}">${blockquote}</div>
        <script>
            (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = "${Constants.BASE_URL}/sdk/embed.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'viila-jssdk'));
        </script>
    `
}

export function EmbedProperties(properties) {    
   return ""
}


