import Index from "./index";
import NewMap from "./NewMap";
import Customers from "./Customers";
import Reports from "./Reports";
import SellerRequests from "./SellerRequests";
import SellerRequestDetail from "./SellerRequests/SellerRequestDetail";
import SellRequestProposalDetail from "./SellerRequests/SellRequestProposalDetail";
import Trade from "./Trade";
import RevenueReports from "./Reports/RevenueReports";
import Offers from "./OfferList";
import Properties from "./Properties";
import NewPropertySteps from "./Properties/NewPropertySteps";
import NewProperty from "./Properties/NewProperty";
import NewPropertyStepsWizard from "./Properties/NewPropertyStepsWizard";
import EditPropertyWizard from "./Properties/EditPropertyWizard";
import EditPropertyVideo from "./Properties/EditPropertyVideo";
import PropertyDetail from "./Properties/PropertyDetail";
import PropertyDetailSummary from "./Properties/PropertyDetailSummary";
import Settings from "./Settings";
import Login from "../../Public/Login";
import SettingsTeam from "./Settings/SettingsTeam";
import SettingsPassword from "./Settings/SettingsPassword";
import SettingsProfile from "./Settings/SettingsProfile";
import SettingsBilling from "./Settings/SettingsBilling";
import OfferDetail from "./OfferList/OfferDetail";
import Home from "./Home";
import Maps from "./Maps";
import Tools from "./Tools";
import Map from "./Map";
import Transactions from "./Transactions";
import Reverse from "./Reverse";



const routes = [
    {path:"/v2/reverse", Component: Reverse, title: "Real Estate Transaction Price Search"},
    {path:"/v2/transactions", Component: Transactions, title: "Real Estate Transaction Price Search"},
    // {path:"/v2/tools", Component: Tools, title: "Tools"},
    {path:"/v2/reports/revenue", Component: RevenueReports, title: "RevenueReports"},
    {path:"/v2/reports", Component: Reports, title: "Reports"},
    {path:"/v2/offers/:code", Component: OfferDetail, title: "OfferDetail"},
    {path:"/v2/customers/:customerId/offers/:code", Component: OfferDetail, title: "OfferDetail"},
    {path:"/v2/sellers/request/:requestId/proposal", Component: SellRequestProposalDetail , title: "SellRequestProposalDetail"},
    {path:"/v2/sellers/request/:requestId", Component: SellerRequestDetail , title: "SellerRequestDetail"},
    {path:"/v2/sellers/request", Component: SellerRequests , title: "SellerRequests"},
    {path:"/v2/trade", Component: Trade, title: "Trade"},
    {path:"/v2/offers", Component: Offers, title: "Offers"},
    {path:"/v2/customers/:customerId/:tab/:id/:messageId", Component: Customers, title: "Customers"},
    {path:"/v2/customers/:customerId/:tab/:id", Component: Customers, title: "Customers"},
    {path:"/v2/customers/:customerId/:tab", Component: Customers, title: "Customers"},
    {path:"/v2/customers/:customerId", Component: Customers, title: "Customers"},
    {path:"/v2/customers", Component: Customers, title: "Customers"},
    {path:"/v2/properties/new", Component: NewProperty, title: "NewProperty"},
    {path:"/v2/properties/steps", Component: NewPropertySteps, title: "NewPropertySteps"},
    // {path:"/v2/properties/wizard/:id/summary", Component: EditPropertyWizard, title: "EditPropertyWizard"},
    {path:"/v2/properties/wizard/:id/summary", Component: EditPropertyWizard},
    {path:"/v2/properties/wizard/:id/video", Component: EditPropertyVideo, title: "EditPropertyVideo"},
    {path:"/v2/properties/wizard/steps", Component: NewPropertyStepsWizard, title: "New property"},
    {path:"/v2/properties/:id/summary", Component: PropertyDetailSummary, title: "PropertyDetailSummary"},
    {path:"/v2/properties/:id", Component: PropertyDetail, title: "PropertyDetail"},
    {path:"/v2/properties", Component: Properties, title: "Properties"},
    {path:"/v2/settings/password", Component: SettingsPassword, title: "SettingsPassword"},
    {path:"/v2/settings/team", Component: SettingsTeam, title: "SettingsTeam"},
    {path:"/v2/settings/profile", Component: SettingsProfile, title: "SettingsProfile"},  
    {path:"/v2/settings/billing/:id", Component: SettingsBilling, title: "SettingsBilling"},  
    {path:"/v2/settings/billing", Component: SettingsBilling, title: "SettingsBilling"},  
    {path:"/v2/settings", Component: Settings, title: "Settings"},
    {path:"/v2/newmap", Component: NewMap, title: "NewMap"},
    {path:"/v2/maps", Component: Maps, title: "Maps"},
    {path:"/v2/map", Component: Map, title: "Map"},
    {path:"/v2", Component: Home, title: "Home"},
]

export const v2NonLoginRoutes = [
    {path:"/v2/login", Component: Login, title: "Login"},
    {path:"/v2", Component: Login, title: "Login"},
]

export default routes;