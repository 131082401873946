
import moment from "moment"
import { Translate } from "../../translate/Translate";
import { ArrowDownTrayIcon, ArrowRightIcon, ChatAltIcon, ChatIcon, ChevronRightIcon, CubeIcon, EllipsisHorizontalIcon, ArrowTopRightOnSquareIcon, EyeIcon, FlagIcon, HeartIcon, InformationCircleIcon, EnvelopeIcon, MapIcon, PencilIcon, PhotoIcon, VideoCameraIcon } from "@heroicons/react/24/solid"
import React, { Fragment } from "react"
import { Link } from 'react-router-dom';
import PropertyNewStatusBadge from "./../Shared/PropertyNewStatusBadge"
import PropertyTypeBadge from "../Components/PropertyTypeBadge";
import DateOnly from "../Shared/DateOnly"

export default function CustomerInsightRow({ value }) {

    // return (<div className="rounded-lg border shadow-sm w-full bg-white">
    //     <div className="p-6">
    //         <div className="flex mb-2">
    //             <p>
    //                 <span className="font-semibold">{Translate(value.target)}</span>
    //             </p>
    //             <p className="ml-auto text-gray-500 font-sm">{moment(value.created_at).fromNow()}</p>
    //         </div>


    //     </div>

    // </div>)

    return (<>
        {value.property ? <div className="flex items-start ">
            <div className={`bg-white w-full flex lg:flex-row flex-col mb-4`} >
                <div className="p-4 w-full">
                    <div className="flex items-start gap-4">
                        <div className="flex-none w-20 h-20 lg:w-24 lg:h-24 bg-slate-100 rounded overflow-hidden relative col-span-1">
                            {
                                value.property.images?.length === 0 ?
                                    <div className="w-full h-full rounded object-cover border border-dashed border-slate-400 overflow-hidden"></div>
                                    :
                                    <img className="w-full h-full object-cover" src={value.property.images ? value.property.images[0].url : ""} />
                            }
                            <div className="absolute top-0 left-0 p-1 flex flex-col items-start gap-1">
                                <div className="flex items-center text-xs bg-white rounded px-1 py-0.5">
                                    <PhotoIcon className="w-3 h-3 mr-1" />
                                    <span className="font-medium">{value.property.images?.length}</span>
                                </div>

                            </div>
                        </div>
                        <div className="w-5/6 h-20 col-span-1 text-right items-end inline-flex">
                            <p className="ml-auto text-gray-500 font-sm text-xs items-end flex">
                                {value.target === "view_history" ?
                                    <EyeIcon className='w-5 h-5 mr-4 text-gray-400'></EyeIcon> : null}
                                {value.target === "user_favorite" ?
                                    <HeartIcon className='w-5 h-5 mr-4 text-red-400'></HeartIcon> : null}
                                {value.created_at ? moment(value.created_at).fromNow() : ""}
                                

                            </p>
                        </div>
                    </div>

                    <Link to={`/v2/properties/wizard/${value.property.property_id}/summary`} className="flex-1 w-full flex flex-col gap-0.5">
                        <div className="font-medium line-clamp-1 truncate">
                            {value.property.name}
                        </div>
                        <div className="flex items-center gap-4">
                            <span className="text-sm">{value.property.short_info}</span>
                        </div>
                        {value.property.price ?
                            <div className="font-semibold">
                                {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: value.property.price?.currency }).format(Number(value.property.price?.amount))}
                            </div> : null}
                    </Link>
                    <div className="mt-4 grid grid-cols-2 gap-2 lg:flex lg:gap-4 text-xs">
                        {
                            value.property.property_type ?
                                <div className="flex flex-col gap-0.5">
                                    <p className="text-slate-400">{Translate("Property type")}</p>
                                    <PropertyTypeBadge className="text-xs" type={value.property.property_type} />
                                </div>
                                : null
                        }
                        {
                            value.property.status ?
                                <div className="flex flex-col gap-0.5">
                                    <p className="text-slate-400">{Translate("Status")}</p>
                                    <PropertyNewStatusBadge className="text-xs" status={value.property.status} />
                                </div>
                                : null
                        }
                        {
                            value.property.property_id ?
                                <div className="flex flex-col gap-0.5">
                                    <p className="text-slate-400">{Translate("Property ID")}</p>
                                    <p className="font-medium ">{value.property.property_id}</p>
                                </div>
                                : null
                        }
                        {
                            value.property.reference_id ?
                                <div className="flex flex-col gap-0.5">
                                    <p className="text-slate-400">{Translate("Reference ID")}</p>
                                    <p className="font-medium "> {value.property.reference_id}</p>
                                </div>
                                : null
                        }
                        {
                            value.property.published_at ?
                                <div className="flex flex-col gap-0.5">
                                    <p className="text-slate-400">{Translate("Published")}</p>
                                    {
                                        value.property.status === "draft" || value.property.status === "satei" ?
                                            <p className="font-medium ">-</p>
                                            :
                                            <p className="font-medium "><DateOnly date={value.property.published_at} /></p>
                                    }
                                </div>
                                : null
                        }
                        {
                            value.property.updated_at ?
                                <div className="flex flex-col gap-0.5">
                                    <p className="text-slate-400">{Translate("Last updated")}</p>
                                    <p className="font-medium ">{moment(value.property.updated_at).fromNow()}</p>
                                </div>
                                : null
                        }
                        {
                            value.property.created_at ?
                                <div className="flex flex-col gap-0.5">
                                    <p className="text-slate-400">{Translate("Time on Viila")}</p>
                                    <p className="font-medium ">{moment(moment()).diff(value.property.created_at, 'days') + 1} {Translate("number_of_days")}</p>
                                </div>
                                : null
                        }

                    </div>
                </div>

            </div>

        </div> : null}
    </>)
}