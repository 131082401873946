import React from "react"
import FullName from "../Shared/FullName";
import clsx from "clsx";
import { AtSymbolIcon, EnvelopeIcon, PhoneIcon, UserIcon } from "@heroicons/react/24/solid";

export function CustomerPhotoAndName(props) {
    const { customer } = props;
    return (
        <div className="flex items-center gap-3 relative">
            <div className={clsx("w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center", customer.user_id ? "ring-2 ring-primary ring-offset-1" : "")}>
                {
                    customer.image_url ?
                        <img src={customer.image_url} className="rounded-full bg-gray-100 border" />
                        :
                        <UserIcon className="w-6 h-6 text-gray-400" />
                }
            </div>
            <div className="">
                <p className="font-medium text-sm mb-0.5"><FullName firstname={customer.first_name} lastname={customer.last_name} /></p>
                <div className="flex flex-col text-gray-500">
                    <div className="flex items-center gap-1">
                        <EnvelopeIcon className="w-3 h-3"/><span className="text-xs">{customer.email}</span>
                    </div>
                    {
                customer.phone ? 
                    <div className="flex items-center gap-1">
                        <PhoneIcon className="w-3 h-3"/><span className="text-xs">{customer.phone}</span>
                    </div>
                : null
                }
                </div>
              
                {customer.tags ?
                    <div className="flex items-center gap-1 mt-2">
                        {customer.tags.map((item, index) => (
                            <span key={index} className=" rounded-full text-xs font-medium px-2 py-0.5 bg-blue-400 text-white">{item}</span>
                        ))}
                    </div>
                    : null}

            </div>
        </div>
    )
}