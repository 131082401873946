
import { PencilIcon, UserIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react"
import { Translate } from "../../translate/Translate";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";
export default function CustomerDetailHeader(props) {
    const { query,goTab,customer,history } = props

    return (
        <div className="flex-none border-b p-4 flex items-center shadow-sm bg-white gap-4">
             <div className={clsx("w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center", customer.user_id ? "ring-2 ring-primary ring-offset-1" : "")}>
                {
                    customer.image_url ?
                        <img src={customer.image_url} className="rounded-full bg-gray-100 border" />
                        :
                        <UserIcon className="w-6 h-6 text-gray-400" />
                }
            </div>
            <div className="">
                <p className="font-medium leading-none mb-1">{props.customer.first_name} {props.customer.last_name}</p>
                
                <div className="flex flex-col text-gray-500">
                    <div className="flex items-center gap-1">
                        <EnvelopeIcon className="w-3 h-3"/><span className="text-xs">{customer.email}</span>
                    </div>
                    {
                customer.phone ? 
                    <div className="flex items-center gap-1">
                        <PhoneIcon className="w-3 h-3"/><span className="text-xs">{customer.phone}</span>
                    </div>
                : null
                }
                </div>

            </div>
            <div className="ml-auto flex items-center">
                {props.selectedTab == "newmessage" ? null :
                    <button onClick={(e)=>{
                        goTab("newmessage")
                        history.replace(`/v2/customers/${customer.customer_no}/newmessage${query.get("search") ? "?search=" + query.get("search") : ""}`)
                    }} className="bg-blue-600 text-white rounded shadow px-2 py-2 flex items-center">
                        <PencilIcon className="w-4 h-4"/>
                        <span className="text-sm font-semibold ml-2">{Translate("Send Message")}</span>
                    </button>
                }
            </div>
        </div>
    )
}