import React, { useRef, useState, Fragment } from "react"
import { parseContext } from '../../../mapbox/parseContext'
import { MAPBOX_TOKEN } from '../../../constants'
import { Translate } from "../../translate/Translate";
import * as api from '../../services/agent'
import { Link } from 'react-router-dom';
import { throttle } from "lodash";

import 'mapbox-gl/dist/mapbox-gl.css';
import ReactMapGL, {
    Marker,
    // FlyToInterpolator,
    Popup,
    NavigationControl,
    FullscreenControl,
    ScaleControl,
    GeolocateControl,
    Source,
    Layer,
} from 'react-map-gl';
import InfoWindow from "./InfoWindow";
import InfoBuildingWindow from "./InfoBuildingWindow";

// added the following 6 lines.
import mapboxgl from 'mapbox-gl';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


const geolocateStyle = {
    bottom: 125,
    right: 0,
    padding: '0px'
};

const fullscreenControlStyle = {
    bottom: 160,
    right: 0,
    padding: '0px'
};

const navStyle = {
    bottom: 30,
    right: 0,
    padding: '0px'
};

const scaleControlStyle = {
    bottom: 24,
    left: 0,
    padding: '0px'
};


export default function MapView(props) {
    const [cursor, setCursor] = useState('auto')
    const query = new URLSearchParams(window.location.search);
    const { selectedGeocoderResult, apartments, properties, onAddedToCollection, selectedLatLng, onSelectedLatLng, setSelectedGeocoderResult } = props

    const mapRef = useRef()
    const [showHazardMap, setShowHazardMap] = useState(false)

    const hazardMaps = [
        {
            id: "debrisFlow",
            prefecture: "nagano",
            url: "https://disaportaldata.gsi.go.jp/raster/05_dosekiryukeikaikuiki_data/20/{z}/{x}/{y}.png",
        },
        {
            id: "collapseOfSteepSlopes",
            prefecture: "nagano",
            url: "https://disaportaldata.gsi.go.jp/raster/05_kyukeishakeikaikuiki_data/20/{z}/{x}/{y}.png",
        },
        {
            id: "landslide",
            prefecture: "nagano",
            url: "https://disaportaldata.gsi.go.jp/raster/05_jisuberikeikaikuiki_data/20/{z}/{x}/{y}.png",
        },
        {
            id: "mountainStream",
            prefecture: "nagano",
            url: "https://disaportaldata.gsi.go.jp/raster/05_dosekiryukikenkeiryu_data/20/{z}/{x}/{y}.png",
        },
        {
            id: "steepSlopeCollapse",
            prefecture: "nagano",
            url: "https://disaportaldata.gsi.go.jp/raster/05_kyukeisyachihoukai_data/20/{z}/{x}/{y}.png",
        },
        {
            id: "landslideDangerPoint",
            prefecture: "nagano",
            url: "https://disaportaldata.gsi.go.jp/raster/05_jisuberikikenkasyo_data/20/{z}/{x}/{y}.png",
        },
    ]
    //====mapbox
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [selectedApartment, setSelectedApartment] = useState(null);
    const [viewport, setViewport] = useState({
        latitude: query.get("lat") ? parseFloat(query.get("lat")) : 36.3418518,
        longitude: query.get("lng") ? parseFloat(query.get("lng")) : 138.6179432,
        zoom: query.get("zoom") ? parseFloat(query.get("zoom")) : 13
    });

    const throttled = React.useCallback(

        throttle((newViewport) => {
            const query = new URLSearchParams(window.location.search);
            query.set("lat", newViewport.latitude.toString())
            query.set("lng", newViewport.longitude.toString())
            query.set("zoom", newViewport.zoom.toString())

            props.history.replace({
                pathname: window.location.pathname,
                search: decodeURIComponent(query.toString())
            })
        }, 1000), []);

    React.useEffect(() => {
        throttled(viewport)
    }, [viewport]);

    React.useEffect(() => {
        if (selectedProperty === null) return;
        goToLatLng(selectedProperty.latlng.latitude, selectedProperty.latlng.longitude, viewport.zoom)
    }, [selectedProperty]);

    const hasRoomForSale = ['>', ['get', 'rooms_for_sale'], 0];
    const localizedRoomForSale = ['get', 'localized_rooms_for_sale']
    const buildingLocationLayerStyle = {
        id: 'building-location',
        source: "buildings",
        interactive: true,
        type: 'circle',
        paint: {
            'circle-radius': {
                'base': 4.5,
                'stops': [
                    [12, 4.5],
                    [22, 180]
                ]
            },
            "circle-opacity": 0.8,
            'circle-color': ['case', hasRoomForSale, "#2563EB", "#2d2d2d"],
            'circle-opacity': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                1,
                0.6
            ]
        }
    };


    const buildingsTextLayerStyle = {
        id: "building-name",
        source: "buildings",
        interactive: true,
        type: "symbol",
        layout: {
            "text-field": [
                'format',
                ['upcase', ['get', 'name']],
                { 'font-scale': 1 },
                '\n',
                {},
                ['downcase', ['case', hasRoomForSale, localizedRoomForSale, ""]],
                { 'font-scale': 1 }
            ],
            "text-size": 12,
            "text-offset": [0, -1],
            'text-anchor': 'bottom',
            'text-font': ['Noto Sans CJK JP Bold', 'Arial Unicode MS Bold'],
        },
        paint: {
            "text-color": ['case', hasRoomForSale, "#2563EB", "#2d2d2d"],
            "text-halo-color": '#fff',
            "text-halo-width": [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                20,
                1
            ],
            'text-opacity': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                1,
                0.8
            ]
        },
    }

    const layerStyle = {
        id: 'point',
        interactive: true,
        type: 'circle',
        paint: {
            'circle-radius': {
                'base': 4,
                'stops': [
                    [12, 4],
                    [22, 180]
                ]
            },
            "circle-opacity": 0.8,
            'circle-color': [
                'match',
                ['get', 'property_type'],
                'house',
                '#2563EB',
                'lot',
                '#34D399',
                'apartment',
                '#7C3AED',
            /* other */ '#ccc'
            ]
        }
    };

    const layerText = {
        id: "property-price",
        interactive: true,
        type: "symbol",
        layout: {
            "text-field": [
                'format',
                ['upcase', ['get', 'formatted_price']],
                { 'font-scale': 1 },
                // '\n',
                // {},
                // ['downcase', ['get', 'localized_status']],
                // { 'font-scale': 0.8 }

            ],
            "text-size": 12,
            "text-offset": [0, -0.5],
            'text-anchor': 'bottom',
            'text-font': ['Inter Medium', 'Arial Unicode MS Bold']
        },
        paint: {
            "text-color": [
                'match',
                ['get', 'status'],
                'draft',
                '#4B5563',
                'active',
                '#2563EB',
                'sold',
                '#DC2626',
                'business_negotiation',
                '#FBBF24',
                'inactive',
                '#374151',
            /* other */ '#ccc'
            ],
            "text-halo-color": "#fff",
            "text-halo-width": 1,
        },
    }

    const hazardmapLayer = {
        type: "raster",
        minzoom: 10,
        maxzoom: 17,
        paint: {
            "raster-opacity": 0.5
        }
    }

    const onViewportChange = (evt) => {
        setViewport(evt.viewState)
    }

    const goToLatLng = (lat, lng, zoom) => {
        // setViewport({
        //     ...viewport,
        //     longitude: lng,
        //     latitude: lat,
        //     zoom: zoom,
        //     transitionDuration: '250',
        //     transitionInterpolator: new FlyToInterpolator(),
        // });

        mapRef.current?.flyTo({ center: [lng, lat], duration: 250, });
    };

    const onSelectedMarker = (property) => {
        setSelectedProperty(property)
    }

    //move to the selected geocoding result after a user selects one of the results
    React.useEffect(() => {
        if (selectedGeocoderResult === null) return;
        goToLatLng(
            selectedGeocoderResult.features[0].geometry.coordinates[1],
            selectedGeocoderResult.features[0].geometry.coordinates[0],
            viewport.zoom)
    }, [selectedGeocoderResult])



    const onMapClick = (e) => {
        const building = e.target.queryRenderedFeatures(e.point, {
            layers: ['building-name', 'point']
        })

        const features = e.target.queryRenderedFeatures(e.point, {
            layers: ['property-price', 'point']
        })

        if (features.length !== 0) {
            const properties = features[0].properties

            let propertyID = properties.id;
            api.fetchPropertyDetail(propertyID)
                .then(data => {
                    setSelectedProperty(data);
                })
                .catch(error => {
                    console.log(error);
                })

        }

        if (building.length !== 0) {
            const apartment = building[0].properties
            let apartmentID = apartment.id;
            api.fetchApartmentDetail(apartmentID)
                .then(data => {
                    setSelectedApartment(data);
                })
                .catch(error => {
                    console.log(error);
                })

        }

    }

    const onMapDblClick = (e) => {
        // onSelectedLatLng(e.lngLat.lat, e.lngLat.lng)
        reverseGeocoding(e.lngLat)

    }

    const reverseGeocoding = ({ lat, lng }) => {
        if (lat === 0 || lng === 0) {
            return
        }
        let text = `${lng},${lat}`
        let url = `https://api.mapbox.com/search/v1/reverse/${text}?language=ja&access_token=${MAPBOX_TOKEN}`
        fetch(url)
            .then(response => response.json())
            .then(resp => {
                if (resp.features.length === 0) return;
                resp.features[0].geometry.coordinates = [lng, lat]
                setSelectedGeocoderResult({ ...resp, features: [resp.features[0]] })
            })
            .catch((e) => console.log("Geocoder failed due to: " + e));
    }

    function getCursor({ isHovering, isDragging }) {
        console.log("getCursor !!!")
        return isDragging ? 'grabbing' : isHovering ? 'pointer' : 'default';
    }

    function parse(feature) {
        return JSON.stringify(parseContext(feature))
    }

    return (
        <div className="flex flex-col w-full h-full relative">

            <div id="map" className=" h-full w-full bg-gray-500 relative border-t">
                <ReactMapGL
                    ref={mapRef}
                    attributionControl={false}
                    onClick={onMapClick}
                    doubleClickZoom={false}
                    onDblClick={onMapDblClick}
                    cursor={getCursor}
                    mapStyle="mapbox://styles/apisitviila/ckwqdi01c0pjz14pdgapgheaw"
                    mapboxAccessToken={MAPBOX_TOKEN}
                    {...viewport}
                    onMove={onViewportChange}
                    style={{ width: "100%", height: "100%" }}
                >
                    {
                        showHazardMap ?
                            hazardMaps.map((m) => (
                                <Source
                                    id={m.id}
                                    type="raster"
                                    tiles={[m.url]}
                                    tileSize={256}
                                    maxzoom={17}
                                >
                                    <Layer id={m.id} source={m.id} {...hazardmapLayer}></Layer>
                                </Source>
                            ))

                            : null
                    }

                    {properties ? <Source id="my-data" type="geojson" data={properties}>
                        <Layer {...layerStyle} />
                        <Layer {...layerText} />
                    </Source> : null}

                    {apartments ? <Source id="my-building" type="geojson" data={apartments}>
                        <Layer {...buildingLocationLayerStyle} />
                        <Layer {...buildingsTextLayerStyle} />
                    </Source> : null}

                    {selectedProperty && (
                        <Popup
                            className="p-0"
                            anchor="bottom"
                            longitude={selectedProperty.latlng.longitude}
                            latitude={selectedProperty.latlng.latitude}
                            closeOnClick={true}
                            closeButton={false}
                            onClose={(e) => setSelectedProperty(null)}
                        >
                            <InfoWindow version={props.version} openModel={props.openModel} onAddedToCollection={onAddedToCollection} property={selectedProperty} />
                        </Popup>
                    )}

                    {selectedApartment && (
                        <Popup
                            className="p-0"
                            anchor="bottom"
                            longitude={selectedApartment.building.latlng.longitude}
                            latitude={selectedApartment.building.latlng.latitude}
                            closeOnClick={true}
                            closeButton={false}
                            onClose={(e) => setSelectedApartment(null)}
                        >
                            <InfoBuildingWindow version={props.version} openModel={props.openModel} onAddedToCollection={onAddedToCollection} apartment={selectedApartment} />
                        </Popup>
                    )}

                    {
                        selectedGeocoderResult === null ? null
                            :
                            selectedGeocoderResult.features.map((feature) => (
                                <Fragment key={feature.properties.id}>
                                    <Marker
                                        latitude={feature.geometry.coordinates[1]}
                                        longitude={feature.geometry.coordinates[0]}
                                    >

                                        <svg className="animate-bounce" width="20" height="48" viewBox="0 0 20 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 32C14.9706 32 19 29.7614 19 27C19 24.2386 14.9706 22 10 22C5.02944 22 1 24.2386 1 27C1 29.7614 5.02944 32 10 32Z" fill="#C4C4C4" />
                                            <g opacity="0.3">
                                                <g opacity="0.3">
                                                    <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M10 32C15 32 19 29.8 19 27C19 24.2 15 22 10 22C5 22 1 24.2 1 27C1 29.8 5 32 10 32Z" fill="#BFBFBF" />
                                                </g>
                                            </g>
                                            <path d="M19.2499 10.4C19.1337 12.2242 18.6361 14.0037 17.7892 15.6235C16.8522 17.554 15.767 19.409 14.5433 21.1718C13.3604 22.9087 12.1771 24.4502 11.2892 25.5577C10.8454 26.1113 10.4757 26.5561 10.2171 26.8623C10.1327 26.9623 10.0601 27.0475 10.0007 27.1168C9.94069 27.0468 9.86819 26.9604 9.78339 26.859C9.52469 26.5502 9.15499 26.1019 8.71109 25.5443C7.82319 24.4289 6.63969 22.8779 5.45679 21.1343C4.23519 19.3683 3.14995 17.5117 2.21049 15.5808C1.3669 13.9749 0.869336 12.21 0.749887 10.4C0.700513 7.89475 1.64648 5.47212 3.38042 3.6632C5.11436 1.85428 7.49479 0.806665 9.99989 0.75C12.505 0.806665 14.8854 1.85428 16.6194 3.6632C18.3533 5.47212 19.2993 7.89475 19.2499 10.4V10.4Z" fill="#4264FB" stroke="#314CCD" strokeWidth="0.5" />
                                            <path d="M13.5502 10.0002C13.5502 10.7023 13.342 11.3887 12.9519 11.9725C12.5618 12.5563 12.0074 13.0113 11.3587 13.28C10.71 13.5487 9.99626 13.619 9.30763 13.482C8.61899 13.345 7.98644 13.0069 7.48997 12.5104C6.99349 12.0139 6.65539 11.3814 6.51841 10.6928C6.38143 10.0041 6.45173 9.29035 6.72042 8.64167C6.98912 7.99299 7.44413 7.43856 8.02792 7.04848C8.61172 6.6584 9.29807 6.4502 10.0002 6.4502C10.4664 6.44999 10.9282 6.54167 11.359 6.72C11.7898 6.89833 12.1812 7.15981 12.5109 7.4895C12.8406 7.81919 13.1021 8.21062 13.2804 8.64142C13.4587 9.07222 13.5504 9.53394 13.5502 10.0002Z" fill="white" stroke="#314CCD" strokeWidth="0.5" />
                                        </svg>
                                    </Marker>

                                    <Popup
                                        className="p-0"
                                        anchor="top"
                                        offsetLeft={10}
                                        offsetTop={-10}
                                        latitude={feature.geometry.coordinates[1]}
                                        longitude={feature.geometry.coordinates[0]}
                                        closeOnClick={true}
                                        closeButton={false}
                                        onClose={(e) => { }}
                                    >

                                        <div className="text-xs">
                                            <p className="p-2">{feature.properties.address || feature.properties.place_name}</p>
                                            <div className="border-t px-2 py-2">
                                                <Link to={`${props.version === 2 ?'/v2/properties/wizard/steps' : '/listing/new'}?data={"area":{"id":"karuizawa"},"property_type":"house","address":${parse(feature)},"latlng":{"latitude":${feature.geometry.coordinates[1]},"longitude":${feature.geometry.coordinates[0]}}}`} className="text-blue-600 font-semibold  flex items-center">
                                                    <span>{Translate("Create new property at this location")}</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                                    </svg>
                                                </Link>

                                            </div>
                                        </div>
                                    </Popup>

                                </Fragment>
                            ))

                    }
                    <ScaleControl position="bottom-right" style={scaleControlStyle} />
                    <FullscreenControl position="bottom-right" style={fullscreenControlStyle} />
                    <NavigationControl position="bottom-right" style={navStyle} />
                    <GeolocateControl position="bottom-right" style={geolocateStyle} />
                </ReactMapGL>

                <div className="absolute top-2 right-2 rounded border border-gray-300 bg-white px-2 py-1">
                    <div>
                        <div className="flex items-center gap-4">
                            <div className="inline-flex items-center">
                                <input id="map-option-hazard-map" name="property-type" type="checkbox" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded" checked={showHazardMap} onChange={(e) => { setShowHazardMap(!showHazardMap) }} />
                                <label htmlFor="map-option-hazard-map" className="ml-2 block text-sm">{Translate("show_hazard_map_button")}</label>
                            </div>
                            {/* <div className="inline-flex items-center">
                                <input id="map-option-land-price" name="property-type" type="checkbox" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded" />
                                <label htmlFor="map-option-land-price" className="ml-2 block text-sm">{Translate("Show Land price survey")}</label>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}