import React from "react"
import { Tab } from '@headlessui/react'
import PhotoForm from "../Components/StepWizard/PhotoForm";
import TourForm from "../Components/StepWizard/TourForm";
import ReelPreview from "../Components/StepWizard/ReelPreview";
import PhotoPreview from "../Components/StepWizard/PhotoPreview";

export default function StepPhotos(props) {
    const {Translate, generateVideo, data} = props
    return (
        <div className="flex w-full overflow-y-auto h-full">
            <form className="w-full h-full flex flex-col" noValidate={true}>
                <Tab.Group defaultIndex={0}>
                    <Tab.List className="flex-none flex  items-center w-full border-b">
                        <Tab className="flex items-center justify-center focus:outline-none">
                        {({ selected }) => (
                            <div className={`w-full h-full py-3 px-6 text-sm font-medium 
                                ${selected ? 'text-primary border-b-2 border-primary' : 'text-slate-400'}
                                `}>
                                {Translate("Photos")} ({data.images.length})
                            </div>
                        )}</Tab>
                          <Tab className="flex items-center justify-center focus:outline-none">
                        {({ selected }) => (
                            <div className={`w-full h-full py-3 px-6 text-sm font-medium 
                                ${selected ? 'text-primary border-b-2 border-primary' : 'text-slate-400'}
                                `}>
                                {Translate("Video or VR Tour")}
                            </div>
                        )}</Tab>
                        <Tab className="flex items-center justify-center focus:outline-none">
                        {({ selected }) => (
                              <div className={`w-full h-full py-3 px-6 text-sm font-medium 
                                ${selected ? 'text-primary border-b-2 border-primary' : 'text-slate-400'}
                                `}>
                                {Translate("Preview")}
                            </div>
                        )}</Tab>
                        {generateVideo && <Tab className="flex items-center justify-center focus:outline-none">
                        {({ selected }) => (
                              <div className={`w-full h-full py-3 px-6 text-sm font-medium 
                                ${selected ? 'text-primary border-b-2 border-primary' : 'text-slate-400'}
                                `}>
                                {Translate("Reel")}
                            </div>
                        )}</Tab>}
                    </Tab.List>
                    <Tab.Panels className="flex-grow h-full flex justify-center bg-gray-100  overflow-hidden">
                        <Tab.Panel className="w-full">
                            <PhotoForm {...props} />
                        </Tab.Panel>
                        <Tab.Panel className="w-full">
                            <TourForm {...props} />
                        </Tab.Panel>
                        <Tab.Panel className="flex-1 w-full">
                            <PhotoPreview {...props} />
                        </Tab.Panel>
                        {generateVideo && <Tab.Panel className="flex-1 w-full">
                            <ReelPreview {...props} />
                        </Tab.Panel>}
                    </Tab.Panels>
                </Tab.Group>
            </form>
        </div>
    )
}