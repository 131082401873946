import React, {useEffect} from "react"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Translate } from "../translate/Translate";
import SideNavBar from "./SideNavBar";
import MapView from "./MapView";
import TopSearchbar from "./TopSearchbar";
import * as api from '../services/agent'
import { Link } from 'react-router-dom';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
export default function NewMap(props) {
    const query = new URLSearchParams(window.location.search);

    const [graph, setGraph] = React.useState({
        labels: [],
        data:[]
    })

    const [propertiesResponse, setPropertiesResponse] = React.useState({
        type: 'FeatureCollection',
        features: [],
    });

    const [propertyRequest, setPropertyRequest] = React.useState(
        JSON.parse(query.get("query")) ??
        {
            list_status: ["active"],
            property_types: ["house", "lot", "apartment"],
            transaction_type: "",
            page_size: 500,
            price: {
                min: "",
                max: ""
            },
            sqm: {
                min: "",
                max: "",
            },
            lot: {
                min: "",
                max: "",
            },
            agents: [],
        }
    );

    const onPropertyRequestChange = (key, value) => {
        setPropertyRequest({ ...propertyRequest, [key]: value })
    }

    const onSetPropertyRequest = (request) => {
        setPropertyRequest({ ...request })
    }

    const emptyCollection = {
        properties: [],
    }
    const [collection, setCollection] = React.useState(emptyCollection);

    const onAddedToCollection = (property) => {
        console.log(property)
        let { properties } = collection;

        const matchPropertyID = (p) => p.id === property.id;
        const i = properties.findIndex(matchPropertyID);
        console.log(i);
        if (i < 0) {
            properties.push(property)
        }

        setCollection({ ...collection, properties: properties })
    }

    const [selectedGeocoderResult, setSelectedGeocoderResult] = React.useState(null)
    const onGeocoderResult = (result) => {
        console.log(result)
        setSelectedGeocoderResult(result)
    }

    const [selectedLatLng, setSelectedLatLng] = React.useState({ lat: 0, lng: 0 })
    const onSelectedLatLng = (lat, lng) => {
        setSelectedLatLng({ lat: lat, lng: lng })
    }

    useEffect(() => {
        api.viewAllStatistics('2022-01-01', '2022-05-16')
            .then((response) => {
                setGraph(response)
            })

        api.propertiesGeoJSONWithStats(query)
            .then((response) => {
                setPropertiesResponse(response)
            })

    }, [])


    return (
        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} isPresented={true} />
            <main className="w-full h-full flex flex-col">

                <TopSearchbar {...props} /> 

                <div className="flex-none flex items-center w-full  py-4 px-6 border-b">
                    <p className="text-gray-700 text-2xl font-semibold">{Translate("Dashboard")}</p>
                    <div className="ml-auto flex item-center gap-4">
                        <Link to="/v2/properties/wizard/steps" id="add-new-property-button" className="bg-blue-600 text-white font-semibold text-sm px-3 py-2 rounded shadow">
                        {Translate("Add new property")}
                        </Link>
                    </div>
                </div>

                <div className="flex flex-col relative h-1/2 w-full">
                    <div className="container mx-auto p-6">
                        <div className="grid lg:grid-cols-2 gap-6 mb-12">
                            <div className="flex flex-col gap-4">
                                {/* <div className="rounded-lg border shadow-sm w-full bg-white">
                                    <p className="p-6">view</p>
                                    
                                </div> */}
                                <div className="rounded-lg border shadow-lg flex flex-col bg-white overflow-hidden mb-12">
                                    <div className="p-6 flex-auto">
                                        <p className="text-3xl font-bold">Views</p>
                                    </div>
                                    <ul>
                                        {propertiesResponse.features.map(({properties}) => <li>{properties.id} - {properties.localized_status} views: {properties.number_of_ga_views}</li>)}
                                    </ul>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4">
                                <div className="rounded-lg border shadow-lg flex flex-col bg-white overflow-hidden mb-12">
                                    <div className="p-6 flex-auto">
                                        <p className="text-3xl font-bold">Graph</p>
                                    </div>
                                    <div className="container mx-auto p-6">
                                        <Bar data={{
                                            labels: graph.labels,
                                            datasets: [
                                                {
                                                label: 'Views',
                                                data: graph.data,
                                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                                },
                                            ],
                                            }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                    

                </div>

                <div className="flex flex-col w-full h-full relative overflow-hidden">
                    <div className=" flex items-center w-full h-full">

                        <div className="h-full w-full relative">
                            <MapView onSelectedLatLng={onSelectedLatLng} history={props.history} selectedGeocoderResult={selectedGeocoderResult} onAddedToCollection={onAddedToCollection} propertyRequest={propertyRequest} onSetPropertyRequest={onSetPropertyRequest} properties={propertiesResponse} onPropertyRequestChange={onPropertyRequestChange} ></MapView>
                        </div>
                    </div>


                </div>


            </main>
        </div>
    )
}