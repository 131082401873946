import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import CreateableSelectControl from "./CreateableSelectControl";
import InputField from "./InputField";
import TextAreaField from "./TextAreaField";
import RadioField from "./RadioField";

function FeatureValueField({ Translate, field, index, control, errors, clearErrors, register, getValues }) {
    const featureValues = getValues(`features.${index}.value`)
    const id = `feature-${(field.key ? field.key : field.title).replaceAll(" ", "-").toLowerCase()}-value`
    if (field.data === null || field.data === undefined) {
        return <InputField required control={control} errors={errors} clearErrors={clearErrors}
            name={`features.${index}.value`}
            id={id} />

    }

    if (field.data.type === "multiline_text") {
        return <TextAreaField required control={control} errors={errors} clearErrors={clearErrors}
            rows={3}
            name={`features.${index}.value`}
            placeholder={field.data.placeholder}
            id={id} />
    }

    if (field.data.type === "list_text") {
        return (
            <CreateableSelectControl
                control={control} errors={errors} clearErrors={clearErrors}
                name={`features.${index}.value`}
                id={id}
                options={field.data.values}
                getOptionLabel={({ value }) => Translate(value)}
            />
        )
    }



    if (field.data.type === "list_multiple_value") {
        return (<div className="grid grid-cols-2 gap-1 w-full">
            {field.data.values.map(({ value }, vIndex) => (
                <label className="inline-flex items-start" key={`${index}-${value}`}>
                    <input type="checkbox"
                        id={`${index}-${value}`}
                        name={`features.${index}.value`}
                        value={value}
                        checked={Array.isArray(featureValues) ? featureValues.includes(value)  : featureValues.split(',').includes(value)}
                        {...register(`features.${index}.value`)}
                    />
                    <span className="ml-2">{Translate(value)}</span>
                </label>
            ))}
        </div>)
    }

    if (field.data.type === "list") {
        return (<div className="grid grid-cols-2 gap-1 w-full">
            {field.data.values.map(({ value }, vIndex) => (<RadioField key={vIndex} name={`features.${index}.value`} data={value} {...{ register }} />))}
        </div>)
    }

    return <InputField required control={control} errors={errors} clearErrors={clearErrors}
        name={`features.${index}.value`}
        placeholder={field.data.placeholder}
        id={id} />

}

export default function FeatureField(props) {
    const { Translate, index, useFormReturn, featureList, removePropertyFeature, field, disabled, id, getValues } = props
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
    } = useSortable({ id: id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };


    const featureDataByKey = (key) => {
        var data = featureList.find(f => f.key === key)
        if (data) return data;
        return { key: key, type: "text", title: key, index: 0, value: "" };
    }

    const changeFeatureType = (feature) => {
        let featureData = featureDataByKey(feature.value ? feature.value : feature.label)
        setValue(`features.${index}`, {
            key: featureData.key,
            title: featureData.key,
            index: featureData.index,
            type: featureData.type,
            data: featureData,
            value: featureData.values && featureData.values.length > 0 ? featureData.values[0].value : undefined
        })
    }


    if (disabled) {
        return (<div className="grid grid-cols-2 gap-4 gap-y-0 mb-2 items-center" style={style} ref={setNodeRef} {...attributes}>
            <div className="col-span-1">
                <input value={Translate(field.title)} type="text" className="rounded w-full text-gray-400 border-gray-200" disabled />
            </div>

            <div className="col-span-1">
                <div className="flex items-center">
                    <div className="w-full ">
                        <input value={Translate(field.value)} type="text" className="rounded w-full text-gray-400 border-gray-200" disabled />
                    </div>
                    <div className="ml-auto pl-2">
                        <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">&nbsp;</label>
                    </div>
                </div>
            </div>
        </div>)
    }
    const { control, formState: { errors }, setValue, clearErrors, register } = useFormReturn

    return (
        <div className="grid grid-cols-2 gap-x-4 mb-4 rounded border p-4" ref={setNodeRef} style={style} {...attributes}>
            <div className="col-span-1 w-full">
                <div className="flex items-center w-full">

                    {
                        field.disable ? null :
                            <div className="pr-2 flex-none">
                                <button type="button" id={`remove-feature-${index}`} onClick={(e) => removePropertyFeature(index)} className="focus:outline-none text-xs font-medium text-red-500">
                                    <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </button>
                            </div>
                    }

                    <CreateableSelectControl
                        className="w-full"
                        control={control}
                        errors={errors}
                        clearErrors={clearErrors}
                        name={`features.${index}.key`}
                        id={`feature-${field.key || field.title}`.replaceAll(" ", "-").toLowerCase()}
                        options={featureList.map(({ title, key }) => ({ value: key, label: title }))}
                        onChangeCallback={(data) => {
                            changeFeatureType(data)
                        }} />
                </div>
            </div>
            <div className="col-span-1 flex w-full">
                <div className="flex w-full gap-2">
                    <div className="flex-1 w-full">
                        <FeatureValueField {...props} field={field} index={index} control={control} errors={errors} clearErrors={clearErrors} register={register} getValues={getValues} />
                    </div>
                    <button type="button" className='cursor-grab hover:bg-slate-200' ref={setActivatorNodeRef}  {...listeners}>
                        <svg viewBox="0 0 20 20" width="12">
                            <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}