import React, { useState, useEffect, useCallback, useRef } from "react"
import ReactMapGL, {
    Marker,
    NavigationControl,
    ScaleControl,
    GeolocateControl,
} from 'react-map-gl';
import TopNavBar from '../Shared/TopNavBar'
import * as api from '../../services/agent'
import { MAPBOX_TOKEN } from '../../../constants'
import { PropertyStatusBadge } from "../Shared/PropertyStatusBadge"
import Select from 'react-select'
import Error from '../Shared/Error'
import { PhotoManager } from "../Shared/PhotoManager";
import FeatureList from "./FeatureList";
import { parseContext } from '../../../mapbox/parseContext'
import { useLocation } from "react-router-dom";
import * as apiv2 from '../../../v2/services/agent'
import { Link } from "react-router-dom"
import { useDropzone } from 'react-dropzone'
import {v4 as uuid} from 'uuid';

export default function NewProperty(props) {
    const {Translate} = props
    const mapRef = useRef()

    const [viewport, setViewport] = useState({
        latitude: 36.3418518,
        longitude: 138.6179432,
        zoom: 12
    })
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showPhotoManager, setShowPhotoManager] = useState(false);

    const [apartments, setApartments] = useState([]);

    const search = useLocation().search;

    const [dataFromQueryString, setDataFromQueryString] = useState(JSON.parse(new URLSearchParams(search).get('data')) ?? null);

    const [creatableFeatureList, setCreatableFeatureList] = useState([]);

    const importingId = new URLSearchParams(search).get('importing') ? new URLSearchParams(search).get('importing') : ""

    const selectStyles = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: 'none'
        })
    };

    const geolocateStyle = {
        bottom: 125,
        right: 0,
        padding: '10px'
    };

    const navStyle = {
        bottom: 30,
        right: 0,
        padding: '10px'
    };

    const scaleControlStyle = {
        bottom: 24,
        left: 0,
        padding: '10px'
    };

    const [marker, setMarker] = useState({
        "latitude": 0,
        "longitude": 0
    });

    const [floors, setFloors] = useState(0);

    const [data, setData] = useState({
        "id": "",
        "area": {
            "id": "karuizawa",
        },
        "apartment": "",
        "reference_id": "",
        "property_id": "",
        "property_type": "house",
        "transaction_type": "brokerage_general",
        "layout": {
            "name": "",
            "bedrooms": 0,
            "bathrooms": 0
        },
        "sqm": "",
        "address": {},
        "latlng": {
            "latitude": 36.3418518,
            "longitude": 138.6179432
        },
        "land": {
            "sqm": ""
        },
        "images": [],
        "vr": {
            "url": ""
        },
        "features": [],
        "built": {
            "month": 11,
            "year": 2020
        },
        "name": "",
        "overview": "",
        "listing_type": "sell",
        "price": {
            "amount": "",
            "currency": "JPY",
            "updated_at": "2020-12-02T06:13:58.769377Z"
        },
        "other_costs": [],
        "status": "active",
        "listed_by": {},
        "remarks": "",
        "regulations": "",
        "privacy_mode": false,
        "is_private": false,
        "archived_images": [],
        "floor": "",
        "room_number": "",
        "document": {
            "data": { "message": "" },
            "files": [],
            "archived_files": [],
        },
    });

    useEffect(() => {
        if (importingId === "") return;
        apiv2.copyProperty(importingId)
            .then((response) => {
                var propertyData = response;
                propertyData.reference_id = "" //clear ref id 
                // this line here is to mask amount with thousand separator comma. e.g. 18000000 to 18,000,000
                propertyData.price.amount = response.price.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                propertyData.features = response.features ? response.features : [];
                setData({ ...data, ...propertyData });
                if (propertyData.property_type == "apartment") {
                    loadApartments(propertyData.area.id)
                    setFloors(Number(propertyData.amount_of_floors ?? 1))
                }
            })
            .catch(error => {
                console.log(error)
                setShowError(true)
                setErrorMessage(error && error.data ? error.data.message : error)
            })

    }, [importingId]);

    useEffect(() => {

        if (dataFromQueryString === null)
            return

        setData({ ...data, ...dataFromQueryString })

        if (dataFromQueryString.amount_of_floors)
            setFloors(Number(dataFromQueryString.amount_of_floors ?? 1))


    }, [dataFromQueryString]);

    function tr(text, search, replace) {
        // Make the search string a regex.
        var regex = RegExp('[' + search + ']', 'g');
        var t = text.replace(regex,
            function (chr) {
                // Get the position of the found character in the search string.
                var ind = search.indexOf(chr);
                // Get the corresponding character from the replace string.
                var r = replace.charAt(ind);
                return r;
            });
        return t;
    }

    const handleInputBlur = (e) => {
        var { name, value, min, max, selectionStart, selectionEnd, oldSelectionStart, oldSelectionEnd } = e.target

        oldSelectionStart = selectionStart;
        oldSelectionEnd = selectionEnd;

        value = e.target.dataset.type == "float" ? value.normalize('NFKC').replace(/。/g, ".").replace(/[^0-9.]/g, "") : value.normalize('NFKC').replace(/[^0-9]/g, "")

        if (value.lastIndexOf(".") !== value.indexOf("."))
            value = value.slice(0, value.indexOf("."))

        if (value && max && e.target.dataset.type == "int" && parseFloat(value) > max) {
            value = max
        }

        if (value && min && e.target.dataset.type == "int" && parseFloat(value) < min) {
            value = min
        }

        value = e.target.dataset.type == "int" ? value ? parseFloat(value) : 0 : value

        if ((e.target.dataset.type == "float") && isNaN(value))
            value = ""

        //if name is set like "land.sqm" then we assign it like below
        if (name.includes(".")) {
            let fields = name.split(".")
            let object = fields[0];
            let fieldName = fields[1];
            setData({ ...data, [object]: { ...data[object], [fieldName]: value } })
            return;
        }
        setData({ ...data, [name]: value })
        // e.target.setSelectionRange(oldSelectionStart, oldSelectionEnd);
    }

    const handleMapClick = (e) => {
        let location = {
            latitude: e.lngLat.lat,
            longitude: e.lngLat.lng,
        }
        setMarker(location)
        setData(data => ({ ...data, latlng: location }));
        reverseGeocoding({ lat: location.latitude, lng: location.longitude })
    }

    const onViewportChange = (evt) => {
        setViewport(evt.viewState)
    }

    useEffect(() => {
        if (data.latlng.latitude === undefined || data.latlng.longitude === undefined) {
            return
        }
        if (data.latlng.latitude == "" || data.latlng.longitude == "") {
            return
        }
        if (data.latlng.latitude != marker.latitude || data.latlng.longitude != marker.longitude) {
            setMarker(data.latlng)
        }
    }, [data.latlng])

    const handleInputChange = (e) => {
        var { name, value } = e.target

        //if name is set like "land.sqm" then we assign it like below
        if (name.includes(".")) {
            let fields = name.split(".")
            let object = fields[0];
            let fieldName = fields[1];
            setData({ ...data, [object]: { ...data[object], [fieldName]: value } })
            return;
        }
        setData({ ...data, [name]: value })
    }

    const handleDataChange = (e) => {

        var { name, value } = e.target
        if (e.target.dataset.type === "number") {
            if (e.type === "change") {
                value = parseFloat(value)
            } else if (e.type === 'blur' || e.key === 'Enter') {
                value = parseFloat(value)
            }
        }
        //if name is set like "land.sqm" then we assign it like below
        if (name.includes(".")) {
            let fields = name.split(".")
            let object = fields[0];
            let fieldName = fields[1];
            setData({ ...data, [object]: { ...data[object], [fieldName]: value } })
            return;
        }

        if (name === "property_type") {
            if (value !== "apartment") {
                data.apartment = ""
            }
            addFeaturesForType(data.property_type, value)
        }

        setData({ ...data, [name]: value })
    }

    //This is a hook when property_type and area.id changed
    useEffect(function () {
        if (data.property_type === "apartment") {
            loadApartments(data.area.id)
        }
    }, [data.property_type, data.area.id])

    const handlePriceChange = (e) => {

        var price = parseFloat(e.target.value.trim().replaceAll(",", "").normalize('NFKC').replace(/[^0-9]/g, ""))

        if (!isNaN(price))
            price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        else
            price = ""

        setData({ ...data, ["price"]: { ...data["price"], ["amount"]: price } })
    }

    const handlePrivacyModeChange = (e) => {
        setData({ ...data, privacy_mode: e.target.checked })
    }

    const handlePrivatePropertyChange = (e) => {
        setData({ ...data, is_private: e.target.checked })
    }

    const handleZipCodeChange = (e) => {
        let address = data.address
        address.zip_code = e.target.value
        setData({ ...data, address: address })
    }
    const handleCityChange = (e) => {
        let address = data.address
        address.city = e.target.value
        setData({ ...data, address: address })
    }
    const handleDistrictChange = (e) => {
        let address = data.address
        address.district = e.target.value
        setData({ ...data, address: address })
    }
    const handlePrefectureChange = (e) => {
        let address = data.address
        address.prefecture = e.target.value
        setData({ ...data, address: address })
    }
    const handleAddress2Change = (e) => {
        let address = data.address
        address.address_2 = e.target.value
        setData({ ...data, address: address })
    }

    const handleAddress1Change = (e) => {
        let address = data.address
        address.address_1 = e.target.value
        setData({ ...data, address: address })
    }

    const addFeature = () => {
        let feature = { index: 0, title: "", value: "" }
        //default data to first in the list of feature list
        let first = props.featureList[0];
        feature.data = first
        feature.key = first.key;
        feature.title = first.key;
        feature.index = first.index;
        feature.type = first.type;
        if (first.type === "list") {
            feature.value = first.values[0].value;
        }

        if (data.feature === null) {
            data.feature = []
        }
        data.features.push(feature)
        setData({ ...data, features: data.features })
    }

    const removeFeature = (index, feature) => {
        data.features.splice(index, 1)
        setData({ ...data, features: data.features })
    }

    const handleFeatureValueChange = (index, e) => {
        var { value } = e.target;
        data.features[index] = { ...data.features[index], value }
        setData({ ...data, features: data.features })
    }

    const handleFeatureArrayValueChange = (index, e) => {
        var { value } = e.target;
        var arr = data.features[index].value.split(',')
        if (arr.length > 0){
            let i = arr.indexOf(value);
            if (i !== -1) 
                arr.splice(i, 1);
             else 
                arr.push(value)
            value = String([...new Set(arr)]);
        }
        data.features[index] = { ...data.features[index], value }
        setData({ ...data, features: data.features })
    }

    const handleFeatureTitleChange = (feature, index) => {
        let featureData = featureDataByKey(feature.key ? feature.key : feature.title)
        if (featureData !== null) {
            data.features[index].key = featureData.key
            data.features[index].title = featureData.key
            data.features[index].index = featureData.index
            data.features[index].type = featureData.type
            data.features[index].data = featureData;
            if (featureData.values.length > 0) {
                data.features[index].value = featureData.values[0].value;
            }
        }
        setData({ ...data, features: data.features })
    }

    const addFeaturesForType = (oldType, newType) => {
        if (oldType)
            removeFeaturesForType(oldType)

        let key = []
        if (props.defaultFeatures[newType] !== undefined) {
            key = props.defaultFeatures[newType]
        }

        for (var i in key) {
            let indexes = data.features ? data.features.map((e, id) => e.key === key[i] ? id : '').filter(String) : [];
            if (indexes.length === 0) {
                let feature = { index: 0, title: "", value: "" }
                let first = featureDataByKey(key[i])

                if (first) {
                    feature.data = first
                    feature.key = first.key;
                    feature.title = first.key;
                    feature.index = first.index;
                    feature.type = first.type;

                    if (first.type === "list") {
                        feature.value = first.values[0].value;
                    }

                    if (data.feature === null) {
                        data.feature = []
                    }

                    data.features.push({...feature, id: uuid() })
                    setData({ ...data, features: data.features })
                }
            }
        }
    }

    const removeFeaturesForType = (property_type) => {
        let key = []
        if (props.defaultFeatures[property_type] !== undefined) {
            key = props.defaultFeatures[property_type]
        }
        for (var i in key) {
            let indexes = data.features.map((e, id) => e.key === key[i] ? id : '').filter(String);
            for (var j in indexes)
                removeFeature(indexes[j])
        }
    }

    const addCost = () => {
        let item = {
            title: "",
            description: "",
            type: "one-time",
            price: {
                amount: "",
                currency: "JPY"
            }
        }
        if (data.other_costs === null) {
            data.other_costs = []
        }
        data.other_costs.push(item)
        setData({ ...data, other_costs: data.other_costs })
    }

    const removeCost = (index) => {
        data.other_costs.splice(index, 1)
        setData({ ...data, other_costs: data.other_costs })
    }

    const handleCostChange = (index, e) => {
        var { name, value } = e.target;
        data.other_costs[index] = { ...data.other_costs[index], [name]: value }
        setData({ ...data, other_costs: data.other_costs })
    }

    const handleCostPriceChange = (index, e) => {
        data.other_costs[index].price.amount = e.target.value.trim().normalize('NFKC').replace(/[^0-9]/g, "")
        setData({ ...data, other_costs: data.other_costs })
    }

    const createNewProperty = (property) => {
        if (property.sqm)
            property.sqm = parseFloat(property.sqm)
        else
            property.sqm = 0

        if (property.land && property.land.sqm)
            property.land.sqm = parseFloat(property.land.sqm)
        else
            property.land = { sqm: 0 }

        if (property.latlng && property.latlng.latitude)
            property.latlng.latitude = parseFloat(property.latlng.latitude)

        if (property.latlng && property.latlng.longitude)
            property.latlng.longitude = parseFloat(property.latlng.longitude)

        if (property.price && property.price.amount)
            property.price.amount = property.price.amount.trim().replaceAll(",", "")


        api.createNewProperty(property)
            .then(response => {
                props.history.replace(`/listing/${response}`);
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }

    const submitProperty = (e) => {
        e.preventDefault();
        publishProperty()
    }
    const publishProperty = (e) => {
        data.status = "active"
        createNewProperty(data)
    }

    const saveAsDraft = (e) => {
        e.preventDefault()
        data.status = "draft"
        createNewProperty(data)
    }

    useEffect(() => {

        if (data.apartment === "" || apartments.length == 0) return;

        // if (data.features){
        //     let selectedApartment = apartments.find(({ id }) => id === data.apartment)
        //     if (selectedApartment && selectedApartment.features){
        //         setApartmentFeatures(selectedApartment.features)
        //         let featureFilterOut = data.features.filter(m => selectedApartment.features.find((k) => k.title == m.title) == null )
        //         setData({ ...data, features: featureFilterOut})
        //     }
        // }

        //assign default features when user selected an apartment building
        setData({ ...data, features: data.features.filter(m => apartments.find(({ id }) => id === data.apartment) ? apartments.find(({ id }) => id === data.apartment).features.find((k) => k.title == m.title) == null : true) })

    }, [apartments, data.apartment]);

    const loadApartments = (area) => {
        api.apartments(area)
            .then(response => {
                for (let i = 0; i < response.length; i++) {
                    response[i].name = Translate(response[i].name)
                }
                setApartments(response)
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }

    const reverseGeocoding = ({ lat, lng }) => {
        if (lat === 0 || lng === 0) {
            return
        }
        let text = `${lng},${lat}`
        let url = `https://api.mapbox.com/search/v1/reverse/${text}?language=ja&access_token=${MAPBOX_TOKEN}`
        fetch(url)
            .then(response => response.json())
            .then(resp => {

                if (resp.features.length === 0) return;

                setData({ ...data, address: parseContext(resp.features[0]), latlng: { latitude: lat, longitude: lng } })
            })
            .catch((e) => console.log("Geocoder failed due to: " + e));
    }

    const fetchAddress = (location) => {
        if (location.lat == 0 || location.lng == 0) {
            return
        }
        new window.google.maps.Geocoder()
            .geocode({ location: location })
            .then((response) => {
                if (response.results[0]) {
                    let address = {}
                    var address_components = response.results[0].address_components
                    for (var i in address_components) {
                        for (var j in address_components[i].types) {
                            switch (address_components[i].types[j]) {
                                case "postal_code":
                                    address.zip_code = address_components[i].short_name
                                    break;
                                case "administrative_area_level_2":
                                    address.district = address_components[i].short_name
                                    break;
                                case "administrative_area_level_1":
                                    address.prefecture = address_components[i].short_name
                                    break;
                                case "route":
                                    address.address_2 = address_components[i].short_name
                                    break;
                                case "premise":
                                    address.address_1 = address.address_1 ? address.address_1 + " " + address_components[i].short_name : address_components[i].short_name
                                    break;
                                case "sublocality_level_2":
                                    address.address_1 = address.address_1 ? address.address_1 + " " + address_components[i].short_name : address_components[i].short_name
                                    break;
                                case "sublocality_level_3":
                                    address.address_1 = address.address_1 ? address.address_1 + " " + address_components[i].short_name : address_components[i].short_name
                                    break;
                                case "locality":
                                    address.city = address_components[i].short_name
                                    break;
                                case "country":
                                    address.country_code = address_components[i].short_name
                                    break;
                            }
                        }
                    }
                    setData({ ...data, address: address })
                } else {
                    console.log("No results found");
                }
            })
            .catch((e) => console.log("Geocoder failed due to: " + e));
    }

    useEffect(() => {
        if (props.featureList.length > 0 && data.features && data.features.length > 0) {
            for (var index = 0; index < data.features.length; index++) {
                let f = data.features[index];
                let featureData = featureDataByKey(f.title ? f.title : f.key)
                if (featureData !== null) {
                    data.features[index].key = featureData.key;
                    data.features[index].title = featureData.key;
                    data.features[index].index = featureData.index;
                    data.features[index].type = featureData.type
                    data.features[index].data = featureData;

                    if (featureData.values.length > 0 && data.features[index].value === "") {
                        data.features[index].value = featureData.values[0].value;
                    }
                }
                data.features[index] = { ...data.features[index], ["title"]: f.title ? f.title : f.key }
            }
            setData({ ...data, features: data.features })
        }
    }, [props.featureList, data.features]);

    useEffect(function () {
        addFeaturesForType(null, data.property_type)
    }, [props.defaultFeatures, props.featureList])

    const featureDataByKey = (key) => {
        var data = props.featureList.find(f => f.key === key)
        if (data) return data;
        return { key: key, type: "text", title: key, index: 0, value: "" };
    }

    const onMarkerDrag = useCallback((event) => {
        setMarker({ latitude: event.lngLat.lat, longitude: event.lngLat.lng })
    }, []);

    //this is when a user drag marker
    const onMarkerDragEnd = useCallback((event) => {
        setMarker({ latitude: event.lngLat.lat, longitude: event.lngLat.lng })
        setData(d => ({ ...d, latlng: { latitude: event.lngLat.lat, longitude: event.lngLat.lng } }))
        reverseGeocoding({ lat: event.lngLat.lat, lng: event.lngLat.lng })
    }, []);

    const onDrop = React.useCallback((acceptedFiles, fileRejections) => {
        acceptedFiles.map((file) => {
            var reader = new FileReader();
            reader.onload = function (event) {
                var imageData = event.target.result;
                var base64Data = imageData.split("base64,")[1];
                var fileName = file.name.replace(/\.[^/.]+$/, "")
                api.uploadImage(base64Data, fileName)
                    .then(response => {
                        var document = { ...data.document }
                        var list = document.files ? [...document.files] : [];
                        list.push({
                            uploaded: true,
                            url: response.data,
                        })
                        document.files = list
                        setData({ ...data, document: document })
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
            reader.readAsDataURL(file);
        });

        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === "file-too-large") {
                    alert("file size is exceed limit (10MB)")
                }
            })
        })
    }, [data])

    const dropMessageAttachments = useDropzone({
        onDrop: onDrop,
        accept: 'image/jpeg, image/png, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        multiple: false,
        maxSize: 10 * 1024 * 1024, // 10MB
    });

    const removeFileAt = (index) => {
        var document = { ...data.document }
        var list = document.files ? [...document.files] : [];
        var archived = document.archived_files ? [...document.archived_files] : [];
        let removed = list.splice(index, 1)
        archived.push(...removed)
        document.archived_files = archived
        document.files = list
        setData({ ...data, document: document })
    }

    useEffect(function () {
        var list = []
        for (var i in props.featureList) {
            var feature = props.featureList[i]
            if (feature.property_type === "all" || feature.property_type === data.property_type)
                list.push({ value: feature.key, label: feature.title })
        }
        setCreatableFeatureList(list)
    }, [props.featureList, data.property_type])


    
    return (
        <div className="">
            <PhotoManager setData={setData} data={data} images={data.images} isPresented={showPhotoManager} dismiss={(e) => { setShowPhotoManager(false) }}></PhotoManager>
            <TopNavBar active="listing"></TopNavBar>
            <div className="mt-16 mb-16 w-full container mx-auto py-8 px-4">

                <div className="mb-8 flex justify-between items-center">
                    <div>
                        <div className="flex flex-row mb-1">
                            <Link to="/listing" type="button" className="text-xs uppercase font-medium text-gray-600 inline-flex items-center">
                                <svg className="fill-current text-gray-600 mr-2" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.890625 5.76953C0.890625 5.91602 0.943359 6.04492 1.05469 6.15625L5.70117 10.6973C5.80078 10.8027 5.92969 10.8555 6.08203 10.8555C6.38672 10.8555 6.62109 10.627 6.62109 10.3223C6.62109 10.1699 6.55664 10.041 6.46289 9.94141L2.19727 5.76953L6.46289 1.59766C6.55664 1.49805 6.62109 1.36328 6.62109 1.2168C6.62109 0.912109 6.38672 0.683594 6.08203 0.683594C5.92969 0.683594 5.80078 0.736328 5.70117 0.835938L1.05469 5.38281C0.943359 5.48828 0.890625 5.62305 0.890625 5.76953Z" />
                                </svg>
                                <span>{Translate("Listing")}</span>
                            </Link>
                        </div>

                        <p className="sm:text-xs md:text-xs lg:text-2xl font-semibold">{Translate("Create new property")}</p>
                    </div>
                </div>
                <form onSubmit={submitProperty}>
                    <div className="w-full mt-4">
                        <div className="grid grid-cols-1 divide-y text-sm shadow rounded-md bg-white">

                            <div className="col-span-1 py-6  px-6">
                                <div className="flex items-center">
                                    <div>
                                        <p className="text-xl font-medium">{Translate("Property info")}</p>
                                        <p className="text-xs">{Translate("This information will be displayed publicly on the website once you published.")}</p>
                                    </div>
                                    <div className="ml-auto">
                                        {/* <button className="btn btn-primary">{Translate("Publish")}</button> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Area")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <Select
                                            required
                                            styles={selectStyles}
                                            className="text-base border border-gray-600 rounded "
                                            name="area"
                                            id="area"
                                            options={props.areas}
                                            getOptionLabel={({ name }) => name}
                                            getOptionValue={({ id }) => id}
                                            onChange={e => {
                                                setViewport((d) => ({ ...d, ...e.latlng }))
                                                loadApartments(e.id)
                                                setData({ ...data, area: e })
                                            }}
                                            value={props.areas.filter(({ id }) => id === data.area.id)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Status")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <PropertyStatusBadge status={data.status}></PropertyStatusBadge>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3 items-center">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Transaction type")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <div className="flex flex-col lg:flex-row space-y-1 lg:space-y-0">
                                            <label className="inline-flex items-center mr-6">
                                                <input type="radio" name="transaction_type" id="transaction_type-seller" value="seller" onChange={handleDataChange} checked={data.transaction_type === "seller"} />
                                                <span className="ml-2">{Translate("Seller")}</span>
                                            </label>
                                            <label className="inline-flex items-center mr-6">
                                                <input type="radio" name="transaction_type" id="transaction_type-brokerage_general" value="brokerage_general" onChange={handleDataChange} checked={data.transaction_type === "brokerage_general"} />
                                                <span className="ml-2">{Translate("Brokerage (General)")}</span>
                                            </label>
                                            <label className="inline-flex items-center mr-6">
                                                <input type="radio" name="transaction_type" id="transaction_type-brokerage_exclusive" value="brokerage_exclusive" onChange={handleDataChange} checked={data.transaction_type === "brokerage_exclusive"} />
                                                <span className="ml-2">{Translate("Brokerage (Exclusive)")}</span>
                                            </label>
                                            <label className="inline-flex items-center mr-6">
                                                <input type="radio" name="transaction_type" id="transaction_type-brokerage_privileged_exclusive" value="brokerage_privileged_exclusive" onChange={handleDataChange} checked={data.transaction_type === "brokerage_privileged_exclusive"} />
                                                <span className="ml-2">{Translate("Brokerage (Privileged Exclusive)")}</span>
                                            </label>
                                            <label className="inline-flex items-center mr-6">
                                                <input type="radio" name="transaction_type" id="transaction_type-proxy" value="proxy" onChange={handleDataChange} checked={data.transaction_type === "proxy"} />
                                                <span className="ml-2">{Translate("Proxy")}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3 items-center">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Property Type")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <div className="flex">
                                            <label className="inline-flex items-center mr-6">
                                                <input type="radio" name="property_type" id="property_type-house" value="house" onChange={handleDataChange} checked={data.property_type === "house"} />
                                                <span className="ml-2">{Translate("House")}</span>
                                            </label>
                                            <label className="inline-flex items-center mr-6">
                                                <input type="radio" name="property_type" id="property_type-lot" value="lot" onChange={handleDataChange} checked={data.property_type === "lot"} />
                                                <span className="ml-2">{Translate("Land")}</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input type="radio" name="property_type" id="property_type-apartment" value="apartment" onChange={handleDataChange} checked={data.property_type === "apartment"} />
                                                <span className="ml-2">{Translate("Apartment")}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {data.property_type == "apartment" ?
                                <>
                                    <div className="col-span-1 py-4 lg:py-4 px-6">
                                        <div className="grid grid-cols-3 items-center">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Apartment")}</p>
                                            </div>
                                            <div className="col-span-3 lg:col-span-2">
                                                <Select
                                                    required
                                                    styles={selectStyles}
                                                    className="text-base border border-gray-600 rounded "
                                                    name="apartment"
                                                    id="apartment"
                                                    options={apartments}
                                                    getOptionLabel={({ name }) => name}
                                                    getOptionValue={({ id }) => id}
                                                    onChange={e => {
                                                        setFloors(Number(e.amount_of_floors ?? 1))
                                                        setData({ ...data, apartment: e.id, floor: "" })
                                                    }}
                                                    value={apartments.filter(({ id }) => id === data.apartment)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1 py-4 lg:py-4 px-6">
                                        <div className="grid grid-cols-3 items-center">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Floor")}</p>
                                            </div>
                                            <div className="col-span-3 lg:col-span-2">

                                                <Select
                                                    required
                                                    styles={selectStyles}
                                                    className="text-base border border-gray-600 rounded "
                                                    id="floor"
                                                    name="floor"
                                                    options={Array.from(Array(floors), (x, i) => { return { value: (i + 1).toString(), label: (i + 1).toString() } })}
                                                    onChange={e => {
                                                        setData({ ...data, floor: e.value })
                                                    }}
                                                    value={{ value: data.floor, label: data.floor }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1 py-4 lg:py-4 px-6">
                                        <div className="grid grid-cols-3 items-center">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Room number")}</p>
                                            </div>
                                            <div className="col-span-3 lg:col-span-2">
                                                <input required name="room_number" id="room_number" value={data.room_number} onChange={handleDataChange} type="text" placeholder={Translate("Room number")} className="rounded w-full" />
                                            </div>
                                        </div>
                                    </div>

                                </>
                                : null}
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3 items-center">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Property title")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <input required name="name" id="name" value={data.name} onChange={handleDataChange} type="text" placeholder={Translate("Tsurudamari Residence")} className="rounded w-full" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3 items-center">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Reference ID")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <input name="reference_id" id="reference_id" value={data.reference_id} onChange={handleDataChange} type="text" placeholder={Translate("Property ID in your system")} className="rounded w-full" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Photos")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-1">
                                        <button type="button" id="manage-photos-button" className="px-3 py-2 rounded text-sm bg-blue-600 text-white inline-flex items-center mr-3" onClick={e => setShowPhotoManager(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                            </svg>
                                            <span>{Translate("Manage Photos")} ({data.images.length})</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Video URL")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2 inline-flex items-center">
                                    <input name="video.url" id="video-url" value={data.video?.url || ""} onChange={handleDataChange} type="text" placeholder="https://youtube.com/..." className="rounded flex-1" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Virtual Tour URL")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <input name="vr.url" id="vr-url" value={data.vr === null ? "" : data.vr.url} onChange={handleDataChange} type="text" placeholder="https://..." className="rounded w-full" />
                                        <p className="text-xs text-gray-700 mt-2">{Translate("Increase engagement and get more views by adding virtual tour.")}</p>
                                        <p className="text-xs text-gray-700 mt-1"><a href="https://viila.co/services" target="_blank" className="text-indigo-600 font-medium">{Translate("Contact us")}</a> {Translate("to 3D Scan this home.")} <a href="https://viila.co/p/AN012-PN036?view=3d" className="text-indigo-600 font-medium" target="_blank">{Translate("See example")}</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3 items-top">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Size")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <div className="grid grid-cols-2 gap-4 mb-2">
                                            {
                                                data.property_type === "house" || data.property_type === "apartment" ?

                                                    <div className="col-span-1">
                                                        <label htmlFor="sqm" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Floor area")}</label>
                                                        <input required={data.property_type === "house" || data.property_type === "apartment" ? true : false}
                                                            name="sqm"
                                                            id="sqm"
                                                            value={data.sqm}
                                                            data-type="float"
                                                            onChange={handleInputChange}
                                                            onBlur={handleInputBlur}
                                                            onKeyUp={handleInputChange}
                                                            type="text"
                                                            step="0.01"
                                                            placeholder="94.3" className="rounded w-full" />
                                                    </div>

                                                    : null
                                            }

                                            {
                                                data.property_type === "house" || data.property_type === "lot" ?
                                                    <div className="col-span-1">
                                                        <label htmlFor="land.sqm" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Lot size")}</label>
                                                        <input required={data.property_type === "house" || data.property_type === "lot" ? true : false}
                                                            name="land.sqm"
                                                            id="land-sqm"
                                                            value={data.land.sqm}
                                                            data-type="float"
                                                            onChange={handleInputChange}
                                                            onBlur={handleInputBlur}
                                                            onKeyUp={handleInputChange}
                                                            type="text"
                                                            step="0.01"
                                                            placeholder="800.5" className="rounded w-full" />
                                                    </div>

                                                    : null
                                            }


                                        </div>

                                    </div>
                                </div>
                            </div>

                            {
                                data.property_type === "lot" || data.property_type === "apartment" ? null
                                    :
                                    <div className="col-span-1 py-4 lg:py-4 px-6">
                                        <div className="grid grid-cols-3">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Built")}</p>
                                            </div>
                                            <div className="col-span-3 lg:col-span-2">
                                                <div className="grid grid-cols-2 gap-4 mb-2">
                                                    <div className="col-span-1">
                                                        <label htmlFor="built.month" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Month")}</label>
                                                        <input required={data.property_type === "house" || data.property_type === "apartment" ? true : false} name="built.month" id="built-month" value={data.built.month === 0 ? "" : data.built.month} onChange={handleInputChange} onBlur={handleInputBlur} data-type="int" type="text" pattern="\d*" min="1" max="12" placeholder="10" className="rounded w-full" />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <label htmlFor="built.year" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Year")}</label>
                                                        <input required={data.property_type === "house" || data.property_type === "apartment" ? true : false} name="built.year" id="built-year" value={data.built.year === 0 ? "" : data.built.year} onChange={handleInputChange} onBlur={handleInputBlur} data-type="int" type="text" pattern="\d*" placeholder="2019" className="rounded w-full" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                            }
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3 items-center">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Price")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <div className="relative">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <span className="font-medium text-gray-600">
                                                    ¥
                                                </span>
                                            </div>

                                            <input required
                                                name="price.amount"
                                                id="price-amount"
                                                type="text"
                                                data-type="price"
                                                placeholder="50,000,000"
                                                className="pl-7 rounded w-full"
                                                // defaultValue={data.price.amount.trim().replaceAll(",","").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                // value={data.price.amount.trim().replaceAll(",","").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                value={data.price.amount}
                                                onMouseOver={event => {
                                                    var text = event.target.value.replaceAll(",", "")
                                                    setData({ ...data, ["price"]: { ...data["price"], ["amount"]: text } })
                                                }}
                                                onChange={event => {
                                                    setData({ ...data, ["price"]: { ...data["price"], ["amount"]: event.target.value } })
                                                }}
                                                onBlur={handlePriceChange}
                                                onMouseOut={handlePriceChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6" style={{ display: data.property_type == "apartment" ? "none" : "block" }}>
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Location")}</p>
                                        {/* <button className="text-xs font-medium text-indigo-600 mb-2">{Translate("Open Maps to pin exact location")}</button> */}
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">

                                        <div className="grid grid-cols-2 gap-4 mb-2">
                                            <div className="col-span-1">
                                                <label htmlFor="latlng.latitude" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Latitude")}</label>
                                                {data.property_type == "apartment" ? null : <input required name="latlng.latitude" id="latlng-latitude" value={data.latlng.latitude}
                                                    data-type="float"
                                                    onChange={handleInputChange}
                                                    onBlur={handleInputBlur}
                                                    onKeyUp={handleInputChange}
                                                    type="text"
                                                    step="0.001"
                                                    placeholder={data.latlng.latitude} className="rounded w-full" />}
                                            </div>
                                            <div className="col-span-1">
                                                <label htmlFor="latlng.longitude" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Longitude")}</label>
                                                {data.property_type == "apartment" ? null : <input required name="latlng.longitude" id="latlng-longitude" value={data.latlng.longitude}
                                                    data-type="float"
                                                    onChange={handleInputChange}
                                                    onBlur={handleInputBlur}
                                                    onKeyUp={handleInputChange}
                                                    type="text"
                                                    step="0.001"
                                                    placeholder={data.latlng.longitude} className="rounded w-full" />}
                                            </div>
                                            <div className="col-span-2">
                                                <div className="h-96 w-full bg-gray-300 rounded">
                                                    <ReactMapGL
                                                        ref={mapRef}
                                                        doubleClickZoom={false}
                                                        onClick={handleMapClick}
                                                        //onDblClick={handleMapClick}

                                                        mapStyle="mapbox://styles/apisitviila/ckwqdi01c0pjz14pdgapgheaw"
                                                        mapboxAccessToken={MAPBOX_TOKEN}
                                                        {...viewport}
                                                        onMove={onViewportChange}
                                                        style={{ width: "100%", height: "100%" }}
                                                    >
                                                        <Marker
                                                            color="#ef4444"
                                                            longitude={marker.longitude}
                                                            latitude={marker.latitude}
                                                            draggable
                                                            onDrag={onMarkerDrag}
                                                            onDragEnd={onMarkerDragEnd}></Marker>

                                                        <GeolocateControl style={geolocateStyle} />
                                                        <NavigationControl style={navStyle} />
                                                        <ScaleControl style={scaleControlStyle} />
                                                    </ReactMapGL>
                                                </div>
                                                <p className="text-sm mt-2 text-gray-600">{Translate("Click on the map to assign property location")}</p>
                                            </div>

                                            <div className="col-span-2 inline-flex items-center mb-2">
                                                <input id="privacy_mode" checked={data.privacy_mode} onChange={handlePrivacyModeChange} name="privacy_mode" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                                                <label htmlFor="privacy_mode" className="ml-2 block text-sm text-gray-900">{Translate("Show estimated area instead of an exact location on the map")}</label>
                                            </div>


                                            <div className={"col-span-2 mt-4 grid grid-cols-1 lg:grid-cols-2 gap-4 "}>
                                                <div className="lg:col-span-2">
                                                    <p>{Translate("Address")}</p>
                                                    <p className="text-xs text-gray-600">{Translate("address_remark")}</p>
                                                    <button type="button" id="reload-address" className="underline text-blue-700" onClick={e => reverseGeocoding({ lat: parseFloat(data.latlng.latitude), lng: parseFloat(data.latlng.longitude) })}>{Translate("Reload address")}</button>
                                                </div>
                                                <div className="lg:col-span-1">
                                                    <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Zipcode")}</label>
                                                    <input type="text" name="zip_code" id="zip_code" placeholder="" onChange={handleZipCodeChange} value={data.address.zip_code} className="rounded w-full" />
                                                </div>

                                                <div className="lg:col-span-1">
                                                    <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Prefecture")}</label>
                                                    <input type="text" name="prefecture" id="prefecture" placeholder="" onChange={handlePrefectureChange} value={data.address.prefecture} className="rounded w-full" />
                                                </div>

                                                <div className="lg:col-span-1">
                                                    <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("District")}</label>
                                                    <input type="text" name="district" id="district" placeholder="" onChange={handleDistrictChange} value={data.address.district} className="rounded w-full" />
                                                </div>

                                                <div className="lg:col-span-1">
                                                    <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("City")}</label>
                                                    <input type="text" name="city" id="city" placeholder="" onChange={handleCityChange} value={data.address.city} className="rounded w-full" />
                                                </div>

                                                <div className="lg:col-span-1">
                                                    <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Address 1")}</label>
                                                    <input type="text" name="address_1" id="address_1" placeholder="" onChange={handleAddress1Change} value={data.address.address_1} className="rounded w-full" />
                                                </div>

                                                <div className="lg:col-span-1">
                                                    <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Address 2")}</label>
                                                    <input type="text" name="address_2" id="address_2" placeholder="" onChange={handleAddress2Change} value={data.address.address_2} className="rounded w-full" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Overview")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <textarea required rows="6" id="overview" name="overview" value={data.overview} onChange={handleDataChange} placeholder={Translate("Describe this property")} className="rounded w-full" >
                                        </textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Remarks")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <textarea rows="2" name="remarks" id="remarks" value={data.remarks} onChange={handleDataChange} placeholder={Translate("Remarks")} className="rounded w-full" >
                                        </textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Regulations")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <textarea rows="2" name="regulations" id="regulations" value={data.regulations} onChange={handleDataChange} placeholder={Translate("Restrictions or regulations")} className="rounded w-full" >
                                        </textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 py-4 pt-8 lg:pb-4 px-6">
                                <p className="text-lg font-medium">{Translate("Property details")}</p>
                                <p className="text-sm text-gray-700">{Translate("Details of this property. e.g. Architecture, Zoning, Terrain, etc.")}</p>
                            </div>
                            {
                                data.property_type === "lot" ? null
                                    :
                                    <div className="col-span-1 py-4 lg:py-4 px-6">
                                        <div className="grid grid-cols-3">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Layout")}</p>
                                            </div>
                                            <div className="col-span-3 lg:col-span-2">
                                                <div className="grid grid-cols-2 gap-4 mb-2">
                                                    <div className="col-span-2">
                                                        <label htmlFor="layout.name" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Layout title")}</label>
                                                        <input required={data.property_type === "house" || data.property_type === "apartment" ? true : false} name="layout.name" id="layout-name" value={data.layout.name} onChange={handleDataChange} type="text" placeholder={Translate("3 bedrooms with storage")} className="rounded w-full" />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <label htmlFor="layout.bedrooms" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Bedrooms")}</label>
                                                        <input required={data.property_type === "house" || data.property_type === "apartment" ? true : false} name="layout.bedrooms" id="layout-bedrooms" data-type="number" value={data.layout.bedrooms === 0 ? "" : data.layout.bedrooms} onChange={handleInputChange} onBlur={handleInputBlur} data-type="int" type="text" pattern="\d*" placeholder="3" className="rounded w-full" />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <label htmlFor="layout.bedrooms" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Bathrooms")}</label>
                                                        <input required={data.property_type === "house" || data.property_type === "apartment" ? true : false} name="layout.bathrooms" id="layout-bathrooms" data-type="number" value={data.layout.bathrooms === 0 ? "" : data.layout.bathrooms} onChange={handleInputChange} onBlur={handleInputBlur} data-type="int" type="text" placeholder="1" className="rounded w-full" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                            }

                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Features")}</p>
                                    </div>

                                    <div className="col-span-3 lg:col-span-2">
                                        {
                                            apartments.find(({ id }) => id === data.apartment) && data.property_type === "apartment" ? apartments.find(({ id }) => id === data.apartment).features.map((feature, index) => (
                                                <div key={index} className="grid grid-cols-2 gap-4 gap-y-0 mb-2 items-center">
                                                    <div className="col-span-1">
                                                        <input name="value" id={`apartment-${feature.title}-key`} value={Translate(feature.title)} type="text" className="rounded w-full text-gray-400 border-gray-200" disabled />
                                                    </div>

                                                    <div className="col-span-1">
                                                        <div className="flex items-center">
                                                            <div className="w-full">
                                                                <input name="value" id={`apartment-${feature.title}-value`} value={Translate(feature.value)} type="text" className="rounded w-full text-gray-400 border-gray-200" disabled />
                                                            </div>
                                                            <div className="ml-auto pl-2">
                                                                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">&nbsp;</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : null
                                        }
                                        {data.features && <FeatureList {...props} Translate={Translate} features={data.features} creatableFeatureList={creatableFeatureList} handleFeatureTitleChange={handleFeatureTitleChange} handleFeatureArrayValueChange={handleFeatureArrayValueChange} handleFeatureValueChange={handleFeatureValueChange} removeFeature={removeFeature} setFeatures={(f) => {
                                            setData((d) => ({...d, features: f}))
                                        }}/>}
                                        <button type="button" id="add-feature" className="focus:outline-none text-xs font-medium text-indigo-600 mb-2" onClick={(e) => addFeature()}>+ {Translate("Add feature")}</button>
                                    </div>
                                </div>
                            </div>


                            <div className="col-span-1 py-4 pt-8 lg:pb-4 px-6">
                                <p className="text-lg font-medium">{Translate("Other Costs")}</p>
                                <p className="text-sm text-gray-700">{Translate("Beside the standard taxes and brokerage fees, Please list other necessary costs which buyer needs to pay. e.g. Management fee, Water supply reserve, etc.")}</p>
                            </div>
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Costs")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">


                                        {data.other_costs === null ? null : data.other_costs.map((cost, index) => (
                                            <div key={`cost-${index}`} className="grid grid-cols-3 gap-2 items-center mb-6">

                                                <div className="col-span-3 lg:col-span-1">
                                                    <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Cost title")}</label>
                                                    <input required name="title" id={`cost-${index}-title`} onChange={(e) => handleCostChange(index, e)} value={cost.title} type="text" placeholder={Translate("Management fee")} className="rounded w-full" />
                                                </div>

                                                <div className="col-span-2 lg:col-span-1">
                                                    <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Amount")}</label>
                                                    <input required
                                                        value={cost.price.amount.trim().replaceAll(",", "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                        data-type="price"
                                                        id={`cost-${index}-value`}
                                                        name={`cost-${index}-value`}
                                                        onChange={(e) => handleCostPriceChange(index, e)}
                                                        type="text"
                                                        placeholder="50,000" className="rounded w-full" />
                                                </div>

                                                <div className="col-span-1 lg:col-span-1">
                                                    <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Pay type")}</label>
                                                    <select name="type" id={`cost-${index}-type`} onChange={(e) => handleCostChange(index, e)} value={cost.type} className="rounded w-full">
                                                        <option value="monthly">{Translate("Monthly")}</option>
                                                        <option value="yearly">{Translate("Yearly")}</option>
                                                        <option value="one-time">{Translate("One time")}</option>
                                                    </select>
                                                </div>

                                                <div className="col-span-3 lg:col-span-3">
                                                    <div className="flex items-center">
                                                        <div className="w-full">
                                                            <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Remark")}</label>
                                                            <input id={`cost-${index}-description`} name="description" onChange={(e) => handleCostChange(index, e)} value={cost.description} type="text" placeholder={Translate("Mitsui Villa management")} className="rounded w-full" />
                                                        </div>
                                                        <div className="ml-auto">
                                                            <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">&nbsp;</label>
                                                            <button type="button" id={`remove-cost-${index}`} onClick={(e) => removeCost(index)} className="focus:outline-none text-xs font-medium text-red-500 ml-2">
                                                                <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        ))}
                                        <button type="button" id="add-cost" onClick={(e) => addCost()} className="focus:outline-none text-xs font-medium text-indigo-600 mb-2">+ {Translate("Add cost")}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 py-4 pt-8 lg:pb-4 px-6">
                                <p className="text-lg font-medium">{Translate("Property Materials")}</p>
                                <p className="text-sm text-gray-700">{Translate("Viila users will immediately receive property materials via email.")}</p>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Property Materials")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        {data.document.files === null ? null : data.document.files.map((file, index) => (
                                            <div className="flex mb-2">
                                                <a href={file.url} id={`file-${index}`} className="w-full" target="_blank" > <p>{file.url.substring(file.url.lastIndexOf('/') + 1)}</p></a>
                                                <button type="button" id={`remove-file-${index}`} onClick={(e) => removeFileAt(index)} className="ml-2 focus:outline-none text-xs font-medium text-red-500">
                                                    <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                </button>
                                            </div>

                                        ))}

                                        <div {...dropMessageAttachments.getRootProps()} className="mt-4 w-full flex items-center text-center mx-auto h-24 border-dashed border-2 rounded border-gray-400 bg-white">
                                            <div className="mx-auto text-xs">

                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto mb-2 stroke-current text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                                </svg>
                                                <input className="w-full h-full" {...dropMessageAttachments.getInputProps()} />
                                                <p><button type="button" className="focus:outline-none text-indigo-600 font-medium">{Translate("Upload files")}</button> {Translate("or drag and drop")}</p>
                                                <p className="text-xs">{Translate("JPG, PNG, PDF, XLS, DOC, DOCX")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1 pr-4">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Your auto-reply message.")}</p>
                                        <p className="text-xs text-gray-700 mb-2 lg:mb-0">{Translate("When a Viila user requests property materials, this message will be appeared in the automated email we send to the user.")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <textarea rows="3" onChange={(e) => {
                                            var document = data.document
                                            document.data = { message: e.target.value }
                                            setData({ ...data, document: document })
                                        }} value={data.document.data.message} type="text" placeholder={Translate("Reply message")} className="rounded w-full mt-4" />
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 py-4 pt-8 lg:pb-4 px-6">
                                <div className="flex">
                                    <input id="private_property" checked={data.is_private} onChange={handlePrivatePropertyChange} name="is_private" type="checkbox" className="m-4 h-6 w-6 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                                    <div className="ml-8">
                                        <p className="text-lg font-medium">{Translate("exclude_from_viila_map_checkbox_label")}</p>
                                        <p className="text-sm text-gray-700">{Translate("exclude_from_viila_map_description")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span1 py-2 bg-gray-200 border-t px-6 flex items-center">
                                <div className="py-2 ml-auto">
                                    <button id="save-as-draft" type="button" onClick={saveAsDraft} className="btn bg-gray-600 text-white mr-2">{Translate("Save as Draft")}</button>
                                    <button id="publish" type="submit" className="btn btn-primary">{Translate("Publish")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Error isPresented={showError} errorMessage={errorMessage} dismiss={(e) => { setShowError(false) }}></Error>
        </div>
    )
}