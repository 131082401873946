

const filterOptions = {
    distanceRange: [
        { id: 1, label: '1-5', min: 0, max: 5 },
        { id: 2, label: '6-10', min: 5, max: 10 },
        { id: 3, label: '11-30', min: 10, max: 30 },
        { id: 4, label: '31-60', min: 30, max: 60 },
        { id: 5, label: '60+', min: 60 },
    ],
    land_type: [
        "中古マンション等",
        "宅地(土地)",
        "宅地(土地と建物)",
        "林地",
        "農地"
    ],
    city_plan_zone: [
        "商業地域",
        "工業地域",
        "工業専用地域",
        "市街化区域及び市街化調整区域外の都市計画区域",
        "市街化調整区域",
        "準住居地域",
        "準工業地域",
        "準都市計画区域",
        "第１種中高層住居専用地域",
        "第２種中高層住居専用地域",
        "第１種低層住居専用地域",
        "第２種低層住居専用地域",
        "第１種住居地域",
        "第２種住居地域",
        "近隣商業地域",
        "都市計画区域外",
    ],
    transaction_period_year: ["2022", "2021", "2020", "2019", "2018", "2017"],
    transaction_period_quarter: [1, 2, 3, 4],
    layout: [
        "スタジオ",
        "１Ｋ/１Ｌ/１ＤＫ/１ＬＫ/１Ｒ/１Ｋ＋Ｓ/１Ｌ＋Ｓ/１ＬＤＫ/１Ｒ＋Ｓ/１ＤＫ＋Ｓ/１ＬＤ＋Ｓ/１ＬＫ＋Ｓ/１ＬＤＫ＋Ｋ/１ＬＤＫ＋Ｓ",
        "２Ｋ/２Ｌ/２ＤＫ/２ＬＫ/２Ｋ＋Ｓ/２Ｌ＋Ｓ/２ＬＤＫ/２ＤＫ＋Ｓ/２ＬＤ＋Ｓ/２ＬＫ＋Ｓ/２ＬＤＫ＋Ｋ/２ＬＤＫ＋Ｓ",
        "３Ｋ/３ＤＫ/３ＬＫ/３ＬＤＫ/３ＤＫ＋Ｓ/３ＬＤＫ＋Ｋ/３ＬＤＫ＋Ｓ",
        "４Ｋ/４ＤＫ/４ＬＤＫ/４ＤＫ＋Ｓ/４ＬＤＫ＋Ｓ",
        "５Ｋ/５ＤＫ/５ＬＤＫ/５ＬＤＫ＋Ｓ",
        "６ＤＫ/６ＬＤＫ",
        "７ＬＤＫ/７ＬＤＫ＋Ｓ",
        "メゾネット",
        "オープンフロア",
        "no_layout",
    ],
    landshape: [
        "unknown",
        "不整形",
        "台形",
        "ほぼ台形",
        "ほぼ整形",
        "正方形",
        "ほぼ正方形",
        "袋地等",
        "長方形",
        "ほぼ長方形",
    ],
    zone_area: [
        "住宅地",
        "商業地",
        "宅地見込地",
        "工業地",
    ],
    usage: [
        "店舗",
        "共同住宅",
        "事務所",
        "住宅",
        "作業場",
        "工場",
        "その他",
        "駐車場",
        "倉庫",
    ],
    building_structure: [
        "no_structure",
        "ＲＣ",
        "ＳＲＣ",
        "木造",
        "軽量鉄骨造",
        "ブロック造",
        "鉄骨造",
    ],
    frontage_direction: [
        "no_frontage",
        "北",
        "北東",
        "北西",
        "南",
        "南東",
        "南西",
        "接面道路無",
        "東",
        "西",
    ],
    is_renovated: [
        "unknown", "改装済", "未改装"
    ],
}


export default  filterOptions