import React, { useState, useRef, useEffect } from "react"
import {
    EyeIcon,
    MapIcon,
    HeartIcon,
    ChatBubbleBottomCenterTextIcon,
    ChevronUpIcon,
    ArrowRightIcon
} from "@heroicons/react/24/outline"
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form"
import moment from "moment";
import { yupResolver } from '@hookform/resolvers/yup';
import { Tab, Disclosure } from '@headlessui/react'

import * as Constants from '../../../constants'
import { editStepSummary } from "../../validators/property"
import * as api from '../../services/agent'
import SideNavBar from "../SideNavBar";
import * as Account from '../../../account';

import CopyClipboard from "../Shared/CopyClipboard"
import DocumentTitle from "../Components/DocumentTitle";
import WizardPropertySummary from "../Components/StepWizard/WizardPropertySummary"
import MoneyInputField from "../Components/StepWizard/MoneyInputField"
import CheckboxField from "../Components/StepWizard/CheckboxField";
import StatusSetting from "../Components/StepWizard/StatusSetting"
import ScreenLoading from "../Components/ScreenLoading";
import EditModal from "../Components/EditModal";
import AIGenerativeModal from "../Components/AIGenerativeModal";
import RequestProfessionalModal from "../Components/RequestProfessionalModal";
import { EmbedProperty } from "../../embed/Embed";
import BackToTopButton from "../Components/BackToTopButton"
import StatsCard from "../Components/StatsCard"
import Alert from '../Shared/Alert'
import Error from '../Shared/Error'
import { CubeIcon, PhotoIcon, PrinterIcon } from "@heroicons/react/24/outline";
import PropertyNewStatusBadge from "../Shared/PropertyNewStatusBadge"
import StatsCardWithRange from "../Components/StatsCardWithRange";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

export default function EditPropertyWizard(props) {
    const [errorMessage, setErrorMessage] = useState("");
    const [showError, setShowError] = useState(false);
    const [requestIsOpen, setRequestIsOpen] = useState(false);
    const [requestData, setRequestData] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const tabs = ["setting", "share", "boost"]
    const { featureList = [], language, Translate, history } = props
    const [tabDefault] = useState(history.location.hash ? tabs.findIndex((tab) => tab === history.location.hash.replace("#", "")) : 0);
    const { id } = useParams();
    const renderCheckIntervalRef = useRef(null)
    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const [aiDialogIsOpen, setAIDialogIsOpen] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [originalStatus, setOriginalStatus] = useState("")
    const [editDialog, setEditDialog] = useState("")
    const [data, setData] = useState({
        status: "active",
        transaction_type: "brokerage_general",
        property_type: "house",
        area: {},
        address: {},
        price: {
            amount: "",
            currency: "JPY"
        },
        land: {},
        layout: {},
        other_costs: [],
        tax_histories: [],
        tax_histories_delete: [],
        apartments: [],
        features: [],
        latlng: {
            latitude: 36.3418518,
            longitude: 138.6179432,
        },
        built: {
            year: '',
            month: ''
        },
        document: {
            files: [],
            data: {}
        },
        images: [],
        archived_images: [],
        lotnumber: {
            type: "FeatureCollection",
            features: []
        },
    })
    const [reels, setReels] = useState([])
    const [apartments, setApartments] = useState({});
    const [areas, setAreas] = useState({});
    const [subareas, setSubareas] = useState({});
    const [account] = useState(Account.getUserAccount());
    const [reach, setReach] = useState(0)
    const [newMessage, setNewMessage] = useState(0)

    const [nearby, setNearby] = useState({driving:{}, walking:{}})

    const [viewDateRange, setViewDateRange] = useState({
        from: moment().add(-13, "days").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        label: "Last 7 days"
    })
    const [impressionDateRange, setImpressionDateRange] = useState({
        from: moment().add(-13, "days").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        label: "Last 7 days"
    })
    const [pageViewsStats, setPageViewsStats] = useState({
        previous: [],
        datasets:[],
        diff: 0,
        total: 0
    })
    const [impressionStats, setImpressionStats] = useState({
        previous: [],
        datasets:[],
        diff: 0,
        total: 0
    })
    function addDays(theDate, days) {
        return new Date(theDate.getTime() + days*24*60*60*1000);
    }
    const [viewLabels, setViewLabels] = useState([])
    const [impressionLabels, setImpressionLabels] = useState([])

    function saveSurvey(want) {
        let survey = { property_id: data.property_id, data: { request: want } }
        api.createNewSurvey(survey)
            .then(response => {
                setRequestIsOpen(false)
                setShowAlert(true)
                setMessage("We will contact you shortly.")
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }

    const filterByReference = (arr1, arr2) => {
        let res = [];
        res = arr1.filter(el => {
            return !arr2.find(element => {
                return element && el ? element.title === el.title : false;
            });
        });
        return res;
    }
    const loadApartments = (area, { apartment }) => {
        return api.apartments(area, props.language)
            .then(apartmentsList => {
                setApartments((apartments) => ({ ...apartments, [area]: apartmentsList }))
                const selectedApartment = apartmentsList.find(({ id }) => id === apartment)
                return selectedApartment
            })
            .catch(error => {
                setShowError(true)
                setDialogIsOpen(false)
                setErrorMessage(error && error.data ? error.data.message : error)
            })
    }
    const loadAreas = (prefectureId) => {
        return api.areaByPrefectures(language, prefectureId)
            .then((res) => {
                setAreas({
                    ...areas,
                    [prefectureId]: res.data.filter((d) => d.level < 2),
                })
                var sa = {}
                res.data.filter((d) => d.level >= 2).forEach(function (a, i) {
                    if (!sa.hasOwnProperty(a.parent))
                        sa[a.parent] = []
                    sa[a.parent].push(a)
                });

                setSubareas({
                    ...subareas, ...sa
                })
                return {
                    ...areas,
                    [prefectureId]: res.data,
                }
            })
    }


    const useFormReturn = useForm({
        resolver: yupResolver(editStepSummary),
        mode: "onBlur",
        reValidateMode: 'onBlur',
        defaultValues: data
    })
    const { control, formState: { errors }, handleSubmit, reset, clearErrors } = useFormReturn

    function loadReach(id) {
        api.getReach(id)
            .then(response => {
                setReach(response);
            })
            .catch(error => {
                if (error.statusCode == 404) {
                    return
                }
                setShowError(true)
                setDialogIsOpen(false)
                setErrorMessage(error && error.data ? error.data.message : error)
            })
    }
    function loadView(id, dRange = null, nohalf = false) {
        let labels = []
        let previousLabels = []
        let from = new Date(viewDateRange.from)
        let to = new Date(viewDateRange.to)
        if (dRange) {
            from = new Date(dRange.from)
            to = new Date(dRange.to)
        }
        const diffTime = Math.abs(to - from);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        
        for (var i=0;i<=diffDays;i++) {
            var d = addDays(from,i)
            labels.push(d)
        }      
        if (!nohalf) {
            previousLabels = labels.slice(0, labels.length /2)
            labels = labels.slice(labels.length /2)
        } 

        setViewLabels(labels)
        api.viewStatistics({
            from,
            to,
            action: 'view',
            property_no : id,
        }).then((d) => {

            const datasets = []

            const matchLabel = (stat) => (e) => {
                if (!stat) {
                    return 0
                }
                const val = stat.find(ele => {
                    return new Date(ele.view_at).getTime() == new Date(e).getTime()
                })
                if (val) {
                    return Number(val.total_views)
                }
                return 0
            }
            let previous = [] 
            let data = []
            previous = previousLabels.map(matchLabel(d[id]))
            data = labels.map(matchLabel(d[id]))
            datasets.push({
                label: id,
                data: data,
                backgroundColor: 'rgba(20, 90, 255, 0.2)',
                hoverBackgroundColor: 'rgba(20, 90, 255, 1)',
                borderRadius: 4,
                borderWith:0,
                minBarLength:4,
            })
            let total = data.reduce((x,y) => x+y, 0)
            let previousTotal = previous.reduce((x,y) => x+y, 0)
            let diff = ((total - previousTotal) / previousTotal) * 100
            if (!Number.isFinite(diff)) {
                diff = undefined
            }
            // console.log("current", data, "previous", previous)
            // console.log("total", total, "previousTotal", previousTotal, "diff", diff)
            setPageViewsStats((d) => ({...d, datasets, previous, diff, total}))
        })
    }
    function loadImpression(id, dRange = null, nohalf = false) {
        let labels = []
        let previousLabels = []
        let from = new Date(impressionDateRange.from)
        let to = new Date(impressionDateRange.to)
        if (dRange) {
            from = new Date(dRange.from)
            to = new Date(dRange.to)
        }
        const diffTime = Math.abs(to - from);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        
        for (var i=0;i<=diffDays;i++) {
            var d = addDays(from,i)
            labels.push(d)
        }        
        if (!nohalf) {
            previousLabels = labels.slice(0, labels.length /2)
            labels = labels.slice(labels.length /2)
        }

        setImpressionLabels(labels)
        api.viewStatistics({
            from: from,
            to: to,
            action: 'compactcard',
            property_no : id,
        }).then((d) => {

            const datasets = []

            const matchLabel = (stat) => (e) => {
                if (!stat) {
                    return 0
                }
                const val = stat.find(ele => {
                    return new Date(ele.view_at).getTime() == new Date(e).getTime()
                })
                if (val) {
                    return Number(val.total_views)
                }
                return 0
            }
            let previous = [] 
            let data = []
            previous = previousLabels.map(matchLabel(d[id]))
            data = labels.map(matchLabel(d[id]))
            datasets.push({
                label: id,
                data: data,
                backgroundColor: 'rgba(20, 90, 255, 0.2)',
                hoverBackgroundColor: 'rgba(20, 90, 255, 1)',
                borderRadius: 4,
                borderWith:0,
                minBarLength:4,
            })
            let total = data.reduce((x,y) => x+y, 0)
            let previousTotal = previous.reduce((x,y) => x+y, 0)
            let diff = ((total - previousTotal) / previousTotal) * 100
            if (!Number.isFinite(diff)) {
                diff = undefined
            }
            // console.log("current", data, "previous", previous)
            // console.log("total", total, "previousTotal", previousTotal, "diff", diff)
            setImpressionStats((d) => ({...d, datasets, previous, diff, total}))
        })
    }

    function loadReel(id) {
        api.listVideo(id).then((res) => {
            // console.log("video response ", res)
            setReels(res.data)
        }).catch((error) => {
            console.error("error", error)
        })
    }

    function loadNewMessages(id, dRange = null) {
        let from = new Date(impressionDateRange.from)
        let to = new Date(impressionDateRange.to)
        if (dRange) {
            from = new Date(dRange.from)
            to = new Date(dRange.to)
        }
        api.newMessageStatistics({
            from: from,
            to: to,
            property_no : id,
        }).then((d) => {

            if (!d[id]) {
                return
            }
            
            setNewMessage(d[id].reduce((total, x) => {
                return total + (+x.amount)
            }, 0))
        })
    }
    function loadNearby(id) {
        return api.fetchNearby(id).then((res) => {
            // console.log("load nearby", res)
            setNearby(res)
        })
    }
    function loadPropertyDetail(id) {
        return api.fetchPropertyDetailForEdit(id)
            .then(response => {
                response.features = response.features.map((feature, index) => {
                    var data = featureList.find(f => f.key === feature.title)
                    if (data) return { key: feature.title, type: feature.type, title: feature.title, index, data, value: feature.value };
                    return { key: feature.title, type: feature.type, title: feature.title, index, value: feature.value };
                })
                if (response.built.year !== undefined) {
                    if (response.built.year == 0) {
                        response.built.year = ""
                    } else {
                        response.built.year = `${response.built.year}`
                    }
                }
                if (response.built.month !== undefined) {
                    if (response.built.month == 0) {
                        response.built.month = ""
                    } else {
                        response.built.month = `${response.built.month}`
                    }
                }
                response.prefecture = { id: response.area.prefecture.id }
                if (!response.lotnumber) {
                    response.lotnumber = { type: "FeatureCollection", features: [] }
                }
                response.viewport = {
                    ...response.latlng,
                    zoom: 17
                }
                loadAreas(response.prefecture.id)
                if (response.property_type === "apartment") {
                    loadApartments(response.area.id, response)
                        .then((apartment) => {
                            if (apartment) {
                                const features = filterByReference(response.features, apartment.features)
                                response.apartments = apartment.features.map((feature, index) => {
                                    const feat = { key: feature.title, type: feature.type, title: feature.title, index, value: feature.value == "" ? "-" : feature.value }
                                    var data = featureList.find(f => f.key === feature.title)
                                    if (data) return { ...feat, data };
                                    return feat;
                                })
                                setData({ ...response, features: features })
                            } else {
                                setData(response)
                            }
                            setOriginalStatus(response.status)
                            reset(response)
                            setLoading(false)
                        })
                } else {
                    setData(response)
                    setOriginalStatus(response.status)
                    reset(response)
                    setLoading(false)
                }
                return response

            })
            .catch(error => {
                if (error.statusCode == 404) {
                    window.location.href = `/v2/properties`
                    return
                }
                setLoading(false)
                setDialogIsOpen(false)
                setShowError(true)
                setErrorMessage(error && error.data ? error.data.message : error)
            })
    }

                                    
    const submitGenerateVideo = () => {
        setLoading(true)
        api.generateVideo(
            id, "reel", 
            data.images.slice(0, 5).map((img) => img.url),
        ).then((res) => {
            loadReel(id)
            renderCheckIntervalRef.current = setInterval(() => {
                loadReel(id)
            }, 30000)
            setLoading(false)
        }).catch((error) => {
            console.error("error", error)
            setLoading(false)
            setShowError(true)
            setErrorMessage(error && error.data ? error.data.message : error)
        })
    }

    useEffect(() => {
        if (featureList.length > 0 && id !== undefined) {
            loadPropertyDetail(id)
                .then((response) => {
                    const dateRange = {from: moment(response.published_at).format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD"), label: "Overall"}
                    loadNewMessages(id, dateRange)
                })
            loadNearby(id)
            loadReach(id)
            loadView(id, null, false)
            loadImpression(id, null, false)
            loadReel(id)
        }
    }, [id, featureList]);

    useEffect(() => {
        if (reels.length !== 0 && reels[0].status !== "pending") {
            console.log("clear interval")

            if (renderCheckIntervalRef.current != null) {
                clearInterval(renderCheckIntervalRef.current)
            }
        }
    }, [reels])

    useEffect(() => {
        if (dialogIsOpen == false && originalStatus != "" && data.status && data.status !== originalStatus) {
            if (data.status_info) {
                delete data.status_info
            }
            setData({ ...data, status: originalStatus })
            reset({ ...data, status: originalStatus })
        }
    }, [dialogIsOpen, originalStatus, data])

    const updateStatus = (status) => {
        if (originalStatus === status) {
            data.status = status
            if (data.status_info) {
                delete data.status_info
            }
            setData(data)
            reset(data)
        } else {
            let section = "status"
            const status_info = {
                remark: "",
            }
            if (status == "sold") {
                section = "sold_status"
                status_info.sold_price = ""
            }
            setEditDialog(section)
            setDialogIsOpen(true)
            setData({ ...data, status_info, status })
            reset({ ...data, status_info, status })
        }
    }

    const updateProperty = (data) => {
        setLoading(true)
        setDialogIsOpen(false)
        if (data.sqm)
            data.sqm = parseFloat(data.sqm)
        else
            data.sqm = 0

        if (data.property_type == "lot") {
            data.layout = {}
        }

        if (data.land && data.land.sqm)
            data.land.sqm = parseFloat(data.land.sqm)
        else
            data.land = { sqm: 0 }

        if (data.latlng && data.latlng.latitude)
            data.latlng.latitude = parseFloat(data.latlng.latitude)

        if (data.latlng && data.latlng.longitude)
            data.latlng.longitude = parseFloat(data.latlng.longitude)

        if (data.built) {
            if (data.built.year === "") {
                delete data.built.year
            } else {
                data.built.year = +data.built.year
            }
            if (data.built.month === "") {
                delete data.built.month
            } else {
                data.built.month = +data.built.month
            }
        }

        if (data.price && Number.isFinite(data.price.amount))
            data.price.amount = `${data.price.amount}`

        if (data.status_info && data.status_info.sold_price)
            data.status_info.sold_price = `${data.status_info.sold_price}`

        if (data.other_costs) {
            data.other_costs = data.other_costs.map((c) => {
                c.price.amount = `${c.price.amount}`
                return c
            })
        }

        if (data.property_type == "apartments" && data.features) {
            data.features = [...(data.apartments || []), ...data.features]
        }

        api.updateProperty(data)
            .then(d => {
                return Promise.all([
                    loadPropertyDetail(id),
                    loadReach(id),
                ]).then(() => {
                    setLoading(false)
                    setShowAlert(true)
                    setDialogIsOpen(false)
                    setAIDialogIsOpen(false)
                })

            })
            .catch(error => {
                setLoading(false)
                setDialogIsOpen(false)
                setAIDialogIsOpen(false)
                setShowError(true)
                setErrorMessage(error && error.data ? error.data.message : error)
            })
    }

    const handleUpdateProperty = () => {
        handleSubmit((d) => {
            setData({ ...data, ...d })
            updateProperty({ ...data, ...d })
        }, (error) => {
            console.log("error", error)
            // setShowError(true)
            // setErrorMessage(error && error.data ? error.data.message : error)
        })()
    }

    return (
        <div className="w-full flex bg-slate-50">
            {data.name && <DocumentTitle title={data.name} />}
            <SideNavBar {...props} isPresented={true} />
            <main className="w-full h-full flex flex-col">

                <div className="z-50 bg-white border-b">
                    <div className="w-full container mx-auto p-4">
                        <div className="flex items-center">
                            <div>
                                <div className="flex flex-row mb-1">
                                    <Link to={`/v2/properties`} type="button" className="text-xs uppercase font-medium text-gray-600 inline-flex items-center">
                                        <svg className="fill-current text-gray-600 mr-2" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.890625 5.76953C0.890625 5.91602 0.943359 6.04492 1.05469 6.15625L5.70117 10.6973C5.80078 10.8027 5.92969 10.8555 6.08203 10.8555C6.38672 10.8555 6.62109 10.627 6.62109 10.3223C6.62109 10.1699 6.55664 10.041 6.46289 9.94141L2.19727 5.76953L6.46289 1.59766C6.55664 1.49805 6.62109 1.36328 6.62109 1.2168C6.62109 0.912109 6.38672 0.683594 6.08203 0.683594C5.92969 0.683594 5.80078 0.736328 5.70117 0.835938L1.05469 5.38281C0.943359 5.48828 0.890625 5.62305 0.890625 5.76953Z" />
                                        </svg>
                                        <span>{Translate("Listing")}</span>
                                    </Link>
                                </div>
                                <p className="text-2xl font-semibold">{Translate("Update property")}</p>
                            </div>
                            <div className="ml-auto">
                                <a id="btn-view-on-villa" className="inline-flex items-center px-4 py-2 focus:outline-none rounded border border-primary text-primary gap-2" target="_blank" href={`${Constants.BASE_URL}/${data.area.id}/${data.name}/${data.property_id}`}>
                                    <span className="font-medium">{Translate("View on Viila")}</span>
                                    <ArrowTopRightOnSquareIcon className="w-4 h-4"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="flex w-full container mx-auto mt-4 md:flex-row flex-col gap-4 p-4">

                    <div className="flex-1 flex flex-col w-full relative">
                        {data.status == "draft" ? null: (<Tab.Group defaultIndex={tabDefault}>
                            <Tab.List className="w-full flex gap-1 items-center justify-between rounded-lg bg-slate-200 overflow-hidden p-1 mb-1">
                                <Tab className="flex items-center justify-center w-full focus:outline-none" >
                                    {({ selected }) => (<a href="#report" className={`rounded-lg w-full h-full py-2 text-sm font-medium 
                                            ${selected ? 'text-primary bg-white shadow' : 'text-slate-400 hover:bg-slate-100/60'}
                                        `}>
                                        {Translate("Report")}
                                    </a>)}
                                </Tab>
                                <Tab className="flex items-center justify-center w-full focus:outline-none">
                                    {({ selected }) => (<a href="#share" className={`rounded-lg w-full h-full py-2 text-sm font-medium 
                                            ${selected ? 'text-primary bg-white shadow' : 'text-slate-400 hover:bg-slate-100'}
                                        `}>
                                        {Translate("Share")}
                                    </a>)}
                                </Tab>
                                <Tab className="flex items-center justify-center w-full focus:outline-none">
                                    {({ selected }) => (<a href="#embed" className={`rounded-lg w-full h-full py-2 text-sm font-medium 
                                            ${selected ? 'text-primary bg-white shadow' : 'text-slate-400 hover:bg-slate-100'}
                                        `}>
                                        {Translate("Embed")}
                                    </a>)}
                                </Tab>
                                <Tab className="flex items-center justify-center w-full focus:outline-none">
                                    {({ selected }) => (<a href="#boost" className={`rounded-lg w-full h-full py-2 text-sm font-medium 
                                            ${selected ? 'text-primary bg-white shadow' : 'text-slate-400 hover:bg-slate-100'}
                                        `}>
                                        {Translate("Boost")}
                                    </a>)}
                                </Tab>
                            </Tab.List>
                            <Tab.Panels as="div" className="bg-white text-sm shadow rounded-lg p-4 mb-4">
                                <Tab.Panel>
                                <div className="flex items-center mb-2">
                                    <p className="text-lg font-semibold">{Translate("Report")}</p>
                                    <Link to={`/v2/reports?property=${data.property_id}`} className="flex items-center gap-2 ml-auto">
                                        <span>{Translate("view_detailed_report_button")}</span> <ArrowRightIcon className="w-4 h-4"/>
                                    </Link>
                                </div>
                                    {data.status != "draft" && data.status != "satei" ? <div className="grid lg:grid-cols-2 grid-cols-1 gap-2">
                                        {/* page view card */}
                                        <StatsCardWithRange {...props}
                                            language={language}
                                            title={Translate("Page views")} 
                                            value={pageViewsStats.total}  
                                            since={data.published_at}
                                            dateRange={viewDateRange}
                                            datasets={pageViewsStats.datasets}
                                            labels={viewLabels}
                                            diff={pageViewsStats.diff}
                                            onChange={(dateRange) => {
                                                setViewDateRange(dateRange)
                                                loadView(id, dateRange, dateRange.from === moment(data.published_at).format("YYYY-MM-DD"))
                                            }}>
                                                <div className={`flex items-center rounded-full p-1 bg-sky-100`}>
                                                    <EyeIcon className="w-4 h-4 text-sky-600" />
                                                </div>
                                        </StatsCardWithRange>
                                        {/* page view card */}

                                        {/* map impression view card */}
                                        <StatsCardWithRange {...props} bgClassName="bg-sky-100"
                                            language={language}
                                            title={Translate("Map impression")} 
                                            value={impressionStats.total}  
                                            since={data.published_at}
                                            dateRange={impressionDateRange}
                                            datasets={impressionStats.datasets}
                                            labels={impressionLabels}
                                            diff={impressionStats.diff}
                                            onChange={(dateRange) => {
                                                setImpressionDateRange(dateRange)
                                                loadImpression(id, dateRange, dateRange.from === moment(data.published_at).format("YYYY-MM-DD"))
                                            }}>
                                                <div className={`flex items-center rounded-full p-1 bg-sky-100`}>
                                                    <MapIcon className="w-4 h-4 text-sky-600" />
                                                </div>
                                        </StatsCardWithRange>
                                        {/* map impression view card */}

                                        {/* favorite card  */}
                                        <StatsCard title={Translate("Favorites")} value={data.number_of_favorites}>
                                            <div className="flex items-center gap-2 rounded-full p-1 bg-red-100">
                                                <HeartIcon className="w-4 h-4 text-red-600" />
                                            </div>
                                        </StatsCard>
                                        {/* end favorite card */}

                                        {/* message card */}
                                        <StatsCard title={Translate("Messages")} value={data.number_of_message} 
                                            newValue={newMessage}  
                                            bgClassName="bg-green-100">
                                            <div className="flex items-center gap-2 rounded-full p-1 bg-green-100">
                                                <ChatBubbleBottomCenterTextIcon className="w-4 h-4 text-green-600" />
                                            </div>
                                        </StatsCard>
                                        {/* end message card */}
                                    </div>: null}



{/* upsell service section */}
<div className="mt-4 border-t pt-2">
                                    <div className="flex items-center gap-2">
                                        <button id="request-photo" type="button" onClick={() => {
                                            setRequestData("Photos")
                                            setRequestIsOpen(true)
                                        }} className="focus:outline-none px-2 py-2 rounded bg-primary/5 flex items-center font-semibold text-primary">
                                            <PhotoIcon className="w-5 h-5 mr-2" />
                                            {Translate("Request taking photos")}
                                        </button>
                                        <button id="request-3d" type="button" onClick={() => {
                                            setRequestData("3D scan")
                                            setRequestIsOpen(true)
                                        }} className="focus:outline-none px-2 py-2 rounded bg-primary/5 flex items-center font-semibold text-primary">
                                            <CubeIcon className="w-5 h-5 mr-2" />
                                            {Translate("Request 3D scan")}
                                        </button>
                                    </div>
        </div>  
{/* /upsell service section */}
                                    
                                </Tab.Panel>
                                <Tab.Panel>
                                    <div className="flex flex-col gap-4">
                                        <div className="w-full">
                                            <div className="flex items-center gap-2">
                                                <input id="short-url-input" className="w-full rounded border focus:outline-none focus:ring-0 fo bg-transparent text-sm" type="text" readOnly={true} value={data.property_id ? `${Constants.BASE_URL}/p/${data.property_id}` : ""} />
                                                <CopyClipboard {...props} id="copy-url-button" text={data.property_id ? `${Constants.BASE_URL}/p/${data.property_id}` : ""} />
                                            </div>
                                        </div>

                                        <div className=" ">
                                            <p className="text-lg font-semibold mb-2 text-center">{Translate('Export as PDF')}</p>
                                            <div className="flex flex-col overflow-hidden">
                                                <a id="export-pdf" target="_blank" rel="noreferrer" href={`${Constants.BASE_URL}/p/${data.property_id}/pdf`} className=" rounded px-2 text-sm font-semibold py-2 bg-blue-100 text-blue-600 flex items-center justify-center">
                                                    <PrinterIcon className="w-5 h-5 mr-2" />
                                                    {Translate("Export")}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Panel>
                                <Tab.Panel>
                                    <div className="w-full">
                                        <p className="text-lg font-semibold mb-2">{Translate('Embed property')}</p>
                                        <div className="">
                                            <div className="flex flex-col gap-2">
                                                <textarea readOnly className="w-full">{data.property_id && EmbedProperty(Translate,data) !== "" && EmbedProperty(Translate,data)}</textarea>
                                                <CopyClipboard {...props} id="copy-embeded-button" text={data.property_id ? EmbedProperty(Translate,data) : ""} />
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Panel>
                                <Tab.Panel>
                                    <div className="py-4 rounded">
                                        <div className="flex rounded">
                                            <div className="text-base inline-flex items-start justify-start font-semibold mx-auto">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z" clipRule="evenodd" />
                                                </svg>
                                                <span className="ml-2 text-md font-semibold">{Translate("Your property is ready to reach users")}</span>
                                            </div>
                                        </div>
                                        <p className="text-md mb-2 px-2 text-center">{Translate("matched_property_title_1")} {reach} {Translate("matched_property_title_2")}</p>
                                        {(new Date(account.user.subscription_end * 1000)) >= (new Date()) ? null : (<button className="mt-2 rounded w-full px-2 text-sm font-semibold h-full text-center py-2 bg-blue-700 text-white">{Translate("Upgrade")}</button>)}
                                    </div>
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>)}

                        <WizardPropertySummary
                            {...props}
                            data={data}
                            apartments={apartments}
                            useFormReturn={useFormReturn}
                            handleEditArea={() => {
                                setEditDialog("area")
                                setDialogIsOpen(true)
                            }}
                            handleEditAddress={() => {
                                setEditDialog("address")
                                setDialogIsOpen(true)
                            }}
                            handleEditEssential={() => {
                                setEditDialog("info")
                                setDialogIsOpen(true)
                            }}
                            handleEditPhoto={() => {
                                setEditDialog("photo")
                                setDialogIsOpen(true)
                            }}
                            handleEditStory={() => {
                                setEditDialog("overview")
                                setDialogIsOpen(true)
                            }}
                            handleEditDetail={() => {
                                setEditDialog("detail")
                                setDialogIsOpen(true)
                            }}
                            handleEditMaterial={() => {
                                setEditDialog("material")
                                setDialogIsOpen(true)
                            }}
                            handleGenerateAI={() => {
                                setAIDialogIsOpen(true)
                            }}
                        />
                        <div className="p-4 bg-white rounded mt-12 border border-red-200">
                            <div className="flex items-center">
                                <div>
                                    <p className="font-semibold">{Translate("Delete this property")}</p>
                                    <p className="text-xs">{Translate("delete_property_detail")}</p>
                                </div>

                                <button id="delete-button" type="button" className="ml-auto py-2 px-3 text-red-500 bg-slate-100 border font-semibold rounded" onClick={e => updateStatus("delete")}>{Translate("Delete")}</button>
                            </div>
                        </div>
                        <div className="py-6 container mx-auto md:flex items-center hidden">
                            <BackToTopButton />
                        </div>
                    </div>
                    <div className="flex-none w-full lg:w-96 z-10">
                        <div className="sticky top-4">
                            <div className=" bg-white rounded-lg shadow overflow-hidden">
                                <div className="border-b px-4 py-4">
                                    <p className="font-semibold">{data.property_id}</p>
                                </div>
                                <div className="flex flex-col gap-4 mb-4 p-4">
                                    <div className="">
                                        <div className="grid grid-cols-3">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Price")}</p>
                                            </div>
                                            <div className="col-span-3 lg:col-span-2">
                                                <div className="relative mb-2">
                                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                        <span className="font-medium text-gray-600">¥</span>
                                                    </div>
                                                    <MoneyInputField
                                                        control={control}
                                                        errors={errors} clearErrors={clearErrors}
                                                        required={true}
                                                        placeholder={"50,000,000"}
                                                        name="price.amount"
                                                        className=""
                                                    />
                                                </div>
                                                <div className="">
                                                    <div className="">
                                                        <p className="text-xs font-semibold">{Translate("Estimate price")}</p>
                                                        <p className="text-xs">{Translate("Coming soon")}</p>
                                                    </div>
                                                </div>
                                                {data.previous_prices ? (<div className="flex flex-col text-sm mt-2">
                                                    <p className=" text-gray-700">{Translate("price_history_title")}</p>
                                                    <div className="grid grid-cols-1 items-center">
                                                        {data.previous_prices.map((price, index) =>
                                                        (<div className="col-span-1 inline-flex items-start" key={`previous-prices-${index}`} id={`previous-prices-${index}`}>
                                                            <div className="">
                                                                <span className="line-through mr-1">¥{price.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                <span>({moment(price.updated_at).fromNow()})</span>
                                                            </div>
                                                        </div>)
                                                        )}
                                                    </div>
                                                </div>) : null}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="grid grid-cols-3">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Status")}</p>
                                            </div>
                                            <StatusSetting status={data.status} onClick={(status) => {
                                                updateStatus(status)
                                            }} />
                                            {data.previous_status ? (
                                                <>
                                                    <div className="col-span-3 lg:col-span-3 mt-4">
                                                        <div className="grid grid-cols-1 items-center border-t-2 border-gray-100 ">
                                                            {data.previous_status.map((s, index) =>

                                                                <Disclosure key={'previous_status_' + index}>
                                                                    {({ open }) => (
                                                                        <>
                                                                            <Disclosure.Button className="border-gray-100 border-r-2 border-l-2 border-b-2 col-span-3 flex w-full justify-between bg-gray-50 px-4 py-2 font-medium text-gray-600 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">

                                                                                <div className="text-left col-span-2 text-sm ">
                                                                                    <PropertyNewStatusBadge status={s.status}></PropertyNewStatusBadge>
                                                                                </div>

                                                                                <div className="flex text-right col-span-1 text-xs">
                                                                                    {moment(s.created_at).format("LL")}
                                                                                    <ChevronUpIcon
                                                                                        className={`${open ? 'rotate-180 transform' : ''
                                                                                            } h-5 w-5 text-gray-500`}
                                                                                    />
                                                                                </div>
                                                                            </Disclosure.Button>
                                                                            <Disclosure.Panel className="border-gray-100 border-r-2 border-l-2 border-b-2 px-4 pt-4 pb-2 text-sm text-gray-500 bg-gray-100">
                                                                                <p className="text-left text-sm font-medium">{Translate("Remarks")}</p>
                                                                                <p className="mb-1 text-left text-xs">{s.remarks ? s.remarks : "-"}</p>
                                                                                {s.sold_price ?
                                                                                    <>
                                                                                        <p className="text-left text-sm font-medium">{Translate("update_property_status_actual_sold_price")}</p>
                                                                                        <p className="mb-1 text-left text-xs">{"¥" + s.sold_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                                                    </>
                                                                                    : null}
                                                                            </Disclosure.Panel>
                                                                        </>
                                                                    )}
                                                                </Disclosure>
                                                            )}
                                                        </div>
                                                    </div></>) : null}
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex items-start">
                                            <CheckboxField className="mt-1" id="is_private" name="is_private" control={control}>
                                                <p className="font-medium">{Translate("exclude_from_viila_map_checkbox_label")}</p>
                                                <p className="text-xs text-gray-700">{Translate("exclude_from_viila_map_description")}</p>
                                            </CheckboxField>
                                        </div>
                                    </div>
                                </div>

                                <div className="border-t border-b px-4 py-2">
                                    <p className="text-xs">{Translate("This information will be displayed publicly on the website once you published.")}</p>
                                </div>
                                <div className="flex p-4">
                                    <button id="update" type="button" className="w-full p-2 bg-primary text-white font-semibold rounded" onClick={handleUpdateProperty}>{Translate("update_property_button")}</button>
                                </div>
                            </div>

                            <div className="py-6 container mx-auto lg:hidden items-center flex">
                                <BackToTopButton />
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {isLoading && (<ScreenLoading {...props} />)}
            {dialogIsOpen ? (<EditModal {...props} apartments={apartments} loadApartments={loadApartments} areas={areas} loadAreas={loadAreas} subareas={subareas} section={editDialog} isOpen={dialogIsOpen} setIsOpen={setDialogIsOpen} data={data} setData={setData} originalStatus={originalStatus} updateProperty={updateProperty} generateVideo={submitGenerateVideo} reels={reels} />) : null}
            <AIGenerativeModal {...props} data={data} nearby={nearby} isOpen={aiDialogIsOpen} setIsOpen={setAIDialogIsOpen} updateProperty={updateProperty} />
            <Alert {...props} isPresented={showAlert} message={message} dismiss={(e) => { setShowAlert(false) }}></Alert>
            <Error {...props} isPresented={showError} errorMessage={errorMessage} dismiss={(e) => { setShowError(false) }}></Error>
            <RequestProfessionalModal {...props} setData={setRequestData} isOpen={requestIsOpen} setIsOpen={setRequestIsOpen} data={requestData} confirm={data => saveSurvey(data)}  />
        </div>
    )
}