
import React, { useEffect } from "react"
import PropertyDetailForm from "../Components/StepWizard/PropertyDetailForm";
import StepSummaryCard from "./StepSummaryCard"

export default function StepPropertyDetail(props) {
    const { data, useFormReturn } = props
    const { reset, watch } = useFormReturn
    const _data = watch()

    useEffect(() => {
        reset({ ...data });
    }, []);

    return (
        <div className="flex w-full overflow-y-auto p-4 h-full">
            <form className="w-full container mx-auto" noValidate={true}>
                <div className="flex w-full lg:flex-row flex-col gap-6">
                    <div className="flex-1 w-full pb-4">
                        <PropertyDetailForm {...props} className="divide-y shadow rounded-lg" useFormReturn={useFormReturn} />
                    </div>
                    <div className="flex-none w-full lg:w-80 ml-auto">
                        <div className="sticky top-0 pb-4">
                            <StepSummaryCard {...props} data={_data} />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}