import React from "react"
import { Translate } from "../../translate/Translate";
import { useParams } from "react-router-dom";
import moment from "moment";
import * as api from '../../services/agent'
import * as Constants from '../../../constants'
import { Link } from 'react-router-dom';
import AppContext from "../../../AppContext";


export default function CustomerNewThread(props) {
    const { query,goTab,customer,history } = props
    let { tab,customerId } = useParams();
    const [message, setMessage] = React.useState({
        data: {
            message: ""
        }
    })

    const handleMessageChange = (e) => {
        message.data.message = e.target.value
        setMessage({ ...message })
    }

    function newThread() {
        if (customerId) {
            api.customerNewmessage(customerId, message)
                .then(data => {
                    goTab("inbox")
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }

    return (


        <div className="flex flex-col relative h-full overflow-hidden overflow-y-auto w-full">
            <div className="flex-1 flex flex-col relative  h-full overflow-hidden  overflow-y-auto bg-gray-100">
                <div className="flex-1 px-4 py-4 overflow-hidden overflow-y-auto">
                    <div className="flex flex-col gap-4 ">
                        <div className="rounded-lg border shadow-sm w-full bg-white mt-4">
                            <div className="px-4 pt-4 font-semibold inline-flex">
                                <a href="#" onClick={(e)=>{goTab("inbox")}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                                    </svg>
                                </a>
                                {Translate("Send Message")}
                            </div>
                            <div className="px-4 pt-4 pb-3">
                                <textarea value={message.data.message} onChange={handleMessageChange} className="p-0 w-full border-0 rounded focus:ring-0 focus:outline-none" placeholder={Translate("new messages.")} rows={3}></textarea>
                            </div>
                            <div className="border-t p-4">
                                <button disabled={message.data.message.trim().length === 0} type="button" onClick={(e) => { newThread() }} className="disabled:opacity-50 bg-blue-600 text-white text-sm font-semibold px-3 py-2 rounded">{Translate("Send Message")}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

