export default function TrainStationDetail(props) {
    const {detail, Translate} = props

    if (detail.station_name_jp.length === 0) {
        return (<></>)
    }

    return (
        <div className="p-4 flex flex-col">
            <p className="font-medium">{Translate("station name")}</p>
            <div className="flex flex-col">
                <p className="font-medium mb-3">{detail.prefecture}({detail.city}) {detail.station_name_jp}</p>
                
                <p className="mb-3 font-medium">{Translate("Lines")} ({detail.routes.length})</p>
                <div className="flex flex-col gap-3">
                    {detail.routes.map((route) => (<div key={route.line_code} className="flex items-center gap-2">
                        <div className={`w-3 h-10 flex items-center justify-center`} style={{backgroundColor:`#${route.line_color_hex}`}}>
                        </div>
                        <div className="text-sm font-medium">
                            <a href={`https://maps.google.com/?q=${route.latlng.latitude},${route.latlng.longitude}`} target="_blank" rel="noreferer"><p>{route.line_name_official}</p></a>
                            <p className="text-xs text-gray-500">{route.line_name_kana}</p>
                        </div>
                        <div className="ml-auto text-sm text-gray-500">
                            <p>{route.line_type}</p>
                        </div>
                    </div>))}
                </div>
            </div>
        </div>
    )
}