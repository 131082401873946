import React, { useState, useCallback, useEffect } from "react"
import { debounce } from "lodash";
import { MapPinIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Translate } from "../../translate/Translate";

export default function PlaceSearchInput(props) {

    const emptyResult = {data: {
        candidates:[]
    }}

    const { onSelectedResult, clearAfterSelected } = props

    const [latlng, setLatLng] = useState({
        "latitude": 0,
        "longitude": 0
    });

    const [googleFindPlaceResponse, setGoogleFindPlaceResponse] = React.useState(emptyResult)
    const [searchText, setSearchText] = React.useState("")
    const [doSearch, setDoSearch] = React.useState(true)
    const [selectedResult, setSelectedResult] = React.useState(null)

    const searchPlaceAPI = async (text) => {
        var url = `https://viila.dev/api/v2/place?query=${text.trim()}`
        console.log(url)
        const response = await fetch(url);
        //empty object
        if (response.status !== 200) {
            return {data: {
                candidates:[]
            }}
        }
        const result = await response.json();
        return result
    }

    const findPlace = useCallback(
        debounce((text) => {
            searchPlaceAPI(text)
                .then((result) => {
                    // console.log(result)
                    setGoogleFindPlaceResponse(result)
                })

        }, 750)
        , [])

    useEffect(() => {
        if (doSearch === false) return;
        if (searchText.length < 2) return;
        findPlace(searchText.trim())
    }, [searchText, doSearch])

    const handleTextChange = (e) => {
        setDoSearch(true)
        let text = e.target.value;
        setSearchText(text)
    }

    const onSelected = (result) => {
        setDoSearch(false)
        setSelectedResult(result)
    }

    useEffect(() => {
        if (selectedResult === null) return;
        onSelectedResult(selectedResult)
        if (clearAfterSelected) {
            setSearchText("")
            setSelectedResult(null)
            setGoogleFindPlaceResponse(emptyResult)
        }
    }, [selectedResult])


    return (
        <div className="flex flex-col w-full relative">
            <div className="flex items-center border border-gray-300  rounded overflow-hidden bg-white">
                <MapPinIcon className="h-5 w-5 ml-2 text-gray-500" />
                <input value={searchText} onChange={handleTextChange} placeholder={Translate("...")} type="text" className="w-full border-0 focus:outline-none focus:ring-0 focus:border-none placeholder-gray-300" />
                <button className="p-2" id="geocoder-reset-button" type="button" onClick={(e) => {

                }}>
                    <XMarkIcon className="w-5 h-5 text-gray-500" />
                </button>
            </div>

            {/* result */}
            <div className={`absolute top-10 z-20 shadow w-full -mt-px border bg-white rounded-b flex flex-col divide-y ${googleFindPlaceResponse && googleFindPlaceResponse.data.candidates.length > 0 ? "" : "hidden"}`}>
                {
                    googleFindPlaceResponse.data.candidates.map((result, i) => (
                        <div id={`findplace-result-${i}`} key={result.place_id} className="w-full text-xs">
                            <div onClick={(e) => {
                                onSelected(result)
                            }} className="w-full py-2 px-2 hover:bg-blue-50 cursor-pointer flex items-center truncate overflow-ellipsis">
                                <MapPinIcon className="w-4 h-4 text-gray-500 mr-2"/>
                                <div>
                                    <p className="text-sm font-medium">{`${result.name}`}</p>
                                    {
                                        result.formatted_address ? 
                                        <p className="tex-xs text-gray-500">{`${result.formatted_address}`}</p>
                                        : null
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            {/* end result */}

        </div>
    )
}