
import React, {useState, useCallback, useEffect} from "react"
import { debounce } from "lodash";
import { Translate } from "../../translate/Translate";
import * as api from "../../services/agent"
import TrainIcon from "../../../Icons/TrainIcon";
import { XMarkIcon } from "@heroicons/react/24/solid";

export default function TrainSearcher(props) {
    const [latlng, setLatLng] = useState({
        "latitude": 0,
        "longitude": 0
    });
    const { onResult, selectedLatLng, clearAfterResult, station, resetSearchCallback } = props;
    const [searchText, setSearchText] = useState("")
    const [doSearch, setDoSearch] = useState(true)
    const [delaySec, setDelaySec] = React.useState(750)

    const emptyResponse = {
        data: [],
    }
    const [suggestResponse, setSuggestResponse] = useState(emptyResponse)
    const [selectedSuggestion, setSelectedSuggestion] = useState(null)
    const fetchMapboxSuggest = useCallback(
        debounce((text) => {
            api.trainStations(text)
            .then((response) => {
                console.log("response", response)
                setSuggestResponse(response)
            })
        }, delaySec)
    ,[])

    useEffect(() => {
        if (!station) return 
        if (selectedSuggestion != null) return 
        if (station.length === 0) return
        
        setDoSearch(false)
        setSearchText(station[0])
        api.trainStations(station[0])
            .then((response) => {
                console.log(response)
                setSelectedSuggestion(response.data[0])
            })

    }, [station, selectedSuggestion])

    useEffect(() => {
        setDelaySec(1000 - (searchText.length * 100))
        if (doSearch === false) return;
        if (searchText.length < 2) return;
        fetchMapboxSuggest(searchText.trim())
    }, [searchText, doSearch])

    const handleTextChange = (e) => {
        setDoSearch(true)
        let text = e.target.value;
        setSearchText(text)
    }

    const onSelectedSuggestion = (suggestion) => {
        setSelectedSuggestion(suggestion)
        setDoSearch(false)
        setSearchText(suggestion.station_name_jp)
        setSuggestResponse(emptyResponse)
    }

    useEffect(() => {
        if (selectedSuggestion === null) return;
        onResult(selectedSuggestion)
        if (clearAfterResult) {
            setSearchText("")
        }
    }, [selectedSuggestion])


    
    useEffect(() => {
        if (selectedLatLng === undefined) return;
        if (selectedLatLng.lat === 0 || selectedLatLng.lng === 0) return;
        if (selectedLatLng.lat !== latlng.lat || selectedLatLng.lng !== latlng.lng) {
            setLatLng(selectedLatLng)
            // reverseGeocoding(selectedLatLng.lat, selectedLatLng.lng)
        }
        
    }, [selectedLatLng])


    const resetSearch = () => {
        setDoSearch(true)
        onResult(null)
        setSelectedSuggestion(null)
        setSuggestResponse(emptyResponse)
        setSearchText("")
        resetSearchCallback && resetSearchCallback()
    }

    return (
        <div className="flex flex-col w-full relative">
            <div className="h-10 flex items-center border border-gray-300  rounded overflow-hidden bg-white">
                <TrainIcon className="h-5 w-5 ml-2 text-gray-500"/>
                <input id="search-geocoder" placeholder={Translate("...")} onChange={handleTextChange} value={searchText} type="text" className="w-full border-0 focus:outline-none focus:ring-0 focus:border-none placeholder-gray-300" />
                <button className="p-2" id="geocoder-reset-button" type="button" onClick={(e) => {
                    resetSearch()
                    e.stopPropagation()
                }}>
                    <XMarkIcon className="w-5 h-5 text-gray-500"/>
                </button>
            </div>
            {/* result */}
            <div className={`absolute top-10 z-20 shadow w-full -mt-px border bg-white rounded-b flex flex-col divide-y ${ suggestResponse.data.length > 0 ? "" : "hidden"}`}>
            {
                suggestResponse.data.map((suggestion,i) => (
                    <div id={`geocoder-item-${i}`} key={suggestion.station_code} className="w-full text-xs">
                        <div onClick={(e) => {
                            onSelectedSuggestion(suggestion)
                            e.stopPropagation()
                        }} className="w-full py-4 px-2 hover:bg-blue-50 cursor-pointer flex items-center truncate overflow-ellipsis">
                            <TrainIcon className="h-3 w-3 mr-2 text-gray-500"/>
                            <p className="text-xs lg:text-base">{`${suggestion.prefecture}(${suggestion.city}) ${suggestion.station_name_jp}`}</p>
                        </div>
                    </div>
                ))
            }
            </div>
            {/* end result */}
        </div>
    )
}