import React from "react"
import TopNavBar from '../Shared/TopNavBar'
import SettingsNavBar from './SettingsNavBar'
import * as Account from '../../../account'
import * as auth from '../../services/auth'

export default function SettingsPassword(props) {
    const {Translate} = props
    var msg = Translate("Reset password link has been sent to your email address.")
    const resetPassword = () => {
        auth.resetPassword(Account.getUserAccount().user.email)
        .then(response => {
            console.log(response)
            alert(msg)
        })
        .catch(error => {
            console.log(error);
        })
    }

    return (
        <div className="">
            <TopNavBar active="settings"></TopNavBar>
            <div className="mt-16 w-full container mx-auto py-8 px-4">
                <div className="mb-4">
                    
                    <p className="text-2xl font-semibold">{Translate("Password")}</p>
                </div>

                <SettingsNavBar active="password"></SettingsNavBar>
                
                <div className="w-full">
                    <form className="w-full">
                        <div className="grid grid-cols-1 divide-y text-sm shadow rounded-md overflow-hidden bg-white">
                            <div className="col-span-1 p-4 lg:p-8">
                                <p className="text-xl font-medium">{Translate("Password")}</p>
                                <p className="text-sm text-gray-500"></p>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6  ">
                                <button type="button" onClick={resetPassword} className="btn btn-primary">{Translate("Reset password")}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}