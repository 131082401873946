import React, { useState, useMemo, useEffect } from "react"
import { Link, useLocation } from 'react-router-dom';
import * as api from '../../v2/services/auth'
import * as Account from '../../account';
import { Translate, TranslateToHtml } from "../../v2/translate/Translate";
import ViilaSolid from '../../assets/logo/solid.svg'
import LearnMore from "../../Components/LearnMore";
function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Login(props) {

    let query = useQuery();

    const GOOGLE_OAUTH2_CLIENT_ID = '379613756082-57r2c458vvjajvgmr8ieqmbhrrs7059j.apps.googleusercontent.com'

    let auth;

    useEffect(() => {
        if (window.gapi?.auth2) {
            window.gapi.auth2.init({
                client_id: GOOGLE_OAUTH2_CLIENT_ID,
                scope: "profile email",
                redirect_uri: window.location.origin,
            }).then(() => {
                auth = window.gapi.auth2.getAuthInstance()

            })
        }
    })

    const [data, setData] = useState({
        email: "",
        password: "",
    })

    useEffect(() => {
        if (query.get("email") != "") {
            setData((d) => ({ ...d, email: query.get("email") || "" }))
        }
    }, [query])

    const [error, setError] = useState(null)

    const code = query.get('code')
    useEffect(() => {
        if (code) {
            api.signInByInvite(code)
                .then(response => {
                    Account.updateAccount(response)
                    window.location.href = localStorage.getItem("incoming_first_page") !== null ? localStorage.getItem("incoming_first_page") : (new Date(response.user.subscription_end * 1000)) >= (new Date()) ? `/v2` : `/`
                    localStorage.removeItem("incoming_first_page")
                })
                .catch(errorResponse => {

                })
        }
    }, [code]);

    const handleGoogleSignIn = async (e) => {
        e.preventDefault();
        try {
            if (!auth) {
                await window.gapi.auth2.init({
                    client_id: GOOGLE_OAUTH2_CLIENT_ID,
                    scope: "profile email",
                    redirect_uri: window.location.origin,
                })
                auth = window.gapi.auth2.getAuthInstance()
            }
            const gLoginResponse = await auth.signIn()
            var id_token = gLoginResponse.getAuthResponse().id_token;
            let data = {
                "token": id_token,
                "source": window.location.href,
            }
            api.googleSignIn(data)
                .then(response => {
                    Account.updateAccount(response)
                    window.location.href = localStorage.getItem("incoming_first_page") !== null ? localStorage.getItem("incoming_first_page") : (new Date(response.user.subscription_end * 1000)) >= (new Date()) ? `/v2` : `/`
                    localStorage.removeItem("incoming_first_page")
                })
                .catch(errorResponse => {
                    setError(errorResponse.data)
                    setTimeout(() => {
                        setError(null)
                    }, 3000);
                })
        } catch (error) {
            console.log("error", error)
            setError(error)
            setTimeout(() => {
                setError(null)
            }, 3000);
        }
    }

    const handleSignIn = (e) => {
        e.preventDefault();
        api.signIn(data)
            .then(response => {
                Account.updateAccount(response)
                window.location.href = localStorage.getItem("incoming_first_page") !== null ? localStorage.getItem("incoming_first_page") : (new Date(response.user.subscription_end * 1000)) >= (new Date()) ? `/v2` : `/`
                localStorage.removeItem("incoming_first_page")
            })
            .catch(errorResponse => {
                console.log(errorResponse)
                setError(errorResponse.data)
                setTimeout(() => {
                    setError(null)
                }, 5000);
            })
    }

    const handleDataChange = (e) => {
        var { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    const renderError = () => {
        if (error === null) {
            return <div className="font-medium text-sm">&nbsp;</div>;
        }
        return (
            <div className=" text-red-500 font-medium text-sm">
                {error.message}
            </div>
        )
    }
    return (



        <div className="absolute inset-0 w-full h-full overflow-hidden flex">
           <div className="hidden w-full lg:flex">
                <LearnMore/>
            </div>
            <div className="flex items-center justify-center px-4 lg:px-28 w-full">

            <div className="flex flex-col w-full lg:w-96 bg-white">

                    <div className="grid grid-cols-1 gap-4">

                        <div className="flex items-center mb-8">
                            <img src={ViilaSolid} className="w-10 h-10 mr-3" />
                            <span className="text-xl font-semibold">Viila Agent Dashboard</span>
                        </div>

                        <div className="mb-4">
                            <p className="text-lg font-medium mb-2">{Translate("Sign in to your account")}</p>
                            <p>{Translate("Don't have an account?")} <Link to="/signup" className="text-primary">{Translate("Sign up")}</Link> {Translate("for a free trial.")}</p>
                        </div>

                        {/* form here */}

                        {code ? null :
                            <form className="flex flex-col gap-4" onSubmit={handleSignIn}>
                                <label className="block">
                                    <span className="text-gray-700 text-sm font-medium">{Translate("Email address")}</span>
                                    <input
                                        name="email"
                                        onChange={handleDataChange}
                                        type="email"
                                        className="mt-1 rounded w-full"
                                        placeholder=""
                                        required
                                        value={data.email}
                                    />
                                </label>

                                <label className="block">
                                    <div className="flex items-center">
                                        <span className="text-gray-700 text-sm font-medium">{Translate("Password")}</span>
                                        <Link to="/resetpassword" className="text-sm text-primary ml-auto">
                                            {Translate("Forgot your password?")}
                                        </Link>
                                    </div>

                                    <input
                                        name="password"
                                        onChange={handleDataChange}
                                        type="password"
                                        className="mt-1 rounded w-full"
                                        placeholder=""
                                        required
                                    />
                                </label>


                                <div>
                                    <button id="login-button" disabled={data.email.trim().length === 0 || data.password.trim().length === 0} type="submit" className="group relative btn btn-primary w-full disabled:opacity-50">
                                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">

                                            <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                                            </svg>
                                        </span>
                                        {Translate("Sign in")}
                                    </button>
                                </div>
                            </form>
                        }


                        <div className="">
                            {renderError()}
                        </div>

                        <div className="">
                            <div className="mx-auto text-center text-xs">
                                {TranslateToHtml("signup_agree_title")}
                            </div>
                        </div>

                    </div>

                </div>
            </div>


        </div>

    );
}