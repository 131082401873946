import { useState, useEffect } from "react";
import PreferenceRow from "./PreferenceRow";

export default function PreferenceView(props) {
    const { preferences, customer, stats } = props

    const [selectedPreference, setSelectedPreference] = useState(null)

    const handleSelectedPreference = (index) => {
        setSelectedPreference(preferences[index])
    }

    useEffect(() => {
        if (Array.isArray(preferences) && preferences.length !== 0) {
            console.log("PreferenceView", preferences[0])
            setSelectedPreference(preferences[0])
        }
    }, [preferences])

    return (<>
        {
            preferences.map(
                (perf, index) => (selectedPreference && selectedPreference.code === perf.code ?
                    <PreferenceRow key={perf.code} max={preferences.length} index={index} handleSelectedPreference={handleSelectedPreference} preference={perf} customer={customer} stats={stats} />
                    : null)
            )}
    </>)
}