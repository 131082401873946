import React from "react"
import { Translate } from "../../translate/Translate";


export default function StatusInfo(props) {
    const [data, setData] = React.useState(null);
    const onConfirm = (e) => {
        props.confirm(data)
    }

    const onCancel = (e) => {
        props.dismiss()
    }

    React.useEffect(() => {
        setData(props.statusWithInfo)
    }, [props.statusWithInfo])

    if (props.isPresented === false) {
        return null;
    }

    const buttonTitle = () => {
        if (data.status === "sold") {
            return "Mark as Sold"
        } else if (data.status === "active") {
            return "Mark as Active"
        }
        else if (data.status === "business_negotiation") {
            return "Mark as Negotiation"
        }
        else if (data.status === "inactive") {
            return "Delist this property"
        }


        return "Confirm"

    }

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                    {Translate(buttonTitle())} · {props.property.property_id}
                                </h3>
                                <div className="">
                                    <p className="text-sm text-gray-500">
                                        {Translate("mark_property_to_sold_dialog_description")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {data.status != "sold" ? null :
                        <div className="col-span-1 py-4 lg:py-4 px-6">
                            <div className="grid grid-cols-3 items-center">
                                <div className="col-span-3 lg:col-span-1">
                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("update_property_status_actual_sold_price")}</p>
                                </div>
                                <div className="col-span-3 lg:col-span-2">
                                    <div className="relative">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <span className="font-medium text-gray-600">
                                                ¥
                                            </span>
                                        </div>
                                        <input required name="price.amount"
                                            value={data.status_info.sold_price ? data.status_info.sold_price.trim().replaceAll(",", "").replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}
                                            data-type="price"
                                            onChange={(e) => {
                                                var { value, selectionStart, selectionEnd, oldSelectionStart, oldSelectionEnd } = e.target
                                                oldSelectionStart = selectionStart;
                                                oldSelectionEnd = selectionEnd;
                                                value = e.target.dataset.type == "float" ? value.normalize('NFKC').replace(/。/g, ".").replace(/[^0-9.]/g, "") : value.normalize('NFKC').replace(/[^0-9]/g, "")

                                                if (value.lastIndexOf(".") !== value.indexOf("."))
                                                    value = value.slice(0, -1)

                                                setData({ ...data, status_info: { ...data.status_info, sold_price: e.target.dataset.type == "int" ? value ? parseFloat(value) : 0 : value } })

                                                //e.target.setSelectionRange(oldSelectionStart, oldSelectionEnd);

                                            }}
                                            type="text"
                                            placeholder="50,000,000" className="pl-7 rounded w-full" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-span-1 py-4 lg:py-4 px-6">
                        <div className="grid grid-cols-3 items-center">
                            <div className="col-span-3 lg:col-span-1">
                                <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("update_property_status_remark")}</p>
                            </div>
                            <div className="col-span-3 lg:col-span-2">
                                <input type="text" className="rounded w-full" name="remark" onChange={(e) => {
                                    setData({ ...data, status_info: { ...data.status_info, remark: e.target.value } })
                                }} />
                            </div>
                        </div>
                    </div>

                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

                        <button onClick={onConfirm} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                            {Translate(buttonTitle())}
                        </button>
                        <button onClick={onCancel} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                            {Translate("Cancel")}
                        </button>
                    </div>
                </div>
            </div>
        </div>

    );
}