import clsx from "clsx";
import React from "react"
import { useDropzone } from 'react-dropzone'

import * as api from '../../../services/agent'
import { Translate } from "../../../translate/Translate";
import TextAreaField from "./TextAreaField";

export default function PropertyMaterialForm(props) {
    const {useFormReturn, data, setData, className} = props
    const {control, formState: { errors }, setValue, clearErrors } = useFormReturn


    const onDrop = React.useCallback((acceptedFiles, fileRejections) => {
        acceptedFiles.forEach((file) => {
            var reader = new FileReader();
            reader.onload = function (event) {
                var imageData = event.target.result;
                var base64Data = imageData.split("base64,")[1];
                var fileName = file.name.replace(/\.[^/.]+$/, "")
                api.uploadImage(base64Data, fileName)
                    .then(response => {
                        var document = {...data.document}
                        var list = document.files ? [...document.files] : [];
                        list.push({
                            uploaded: true,
                            url: response.data,
                        })
                        document.files = list
                        setData({ ...data, document: document })
                        setValue("document", document)
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
            reader.readAsDataURL(file);
        });

        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === "file-too-large") {
                    alert("file size is exceed limit (10MB)")
                }
            })
        })
    }, [data])
    
    const dropMessageAttachments = useDropzone({
        onDrop: onDrop,
        accept: 'image/jpeg, image/png, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        multiple: false,
        maxSize: 10 * 1024 * 1024, // 10MB
    });

    const removeFileAt = (index) => {
        var document = {...data.document}
        var list = document.files ? [...document.files] : [];
        var archived = document.archived_files ? [...document.archived_files] : [];
        let removed = list.splice(index, 1)
        archived.push(...removed)
        document.archived_files = archived
        document.files = list
        setData({ ...data, document: document })
        setValue("document", document)
    }

    return (
    <div className={clsx("grid grid-cols-1 text-sm bg-white",className)}>
        <div className="py-6  px-6">
            <div className="flex items-center">
                <div>
                    <p className="text-lg font-medium">{Translate("Property Materials")}</p>
                    <p className="text-sm text-gray-700">{Translate("Viila users will immediately receive property materials via email.")}</p>
                </div>
            </div>
        </div>
        <div className="py-4 lg:py-4 px-6">
            <div className="col-span-1 py-4 lg:py-4 px-6">
                <div className="grid grid-cols-3">
                    <div className="col-span-3 lg:col-span-1">
                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Property Materials")}</p>
                    </div>
                    <div className="col-span-3 lg:col-span-2">
                        {data.document.files === null ? null : data.document.files.map((file, index) => (
                            <div className="flex mb-2">
                                <a href={file.url} id={`file-${index}`} className="w-full" target="_blank" > <p>{file.url.substring(file.url.lastIndexOf('/') + 1)}</p></a>
                                <button type="button" id={`remove-file-${index}`} onClick={(e) => removeFileAt(index)} className="ml-2 focus:outline-none text-xs font-medium text-red-500">
                                    <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </button>
                            </div>

                        ))}

                        <div {...dropMessageAttachments.getRootProps()} className="mt-4 w-full flex items-center text-center mx-auto h-24 border-dashed border-2 rounded border-gray-400 bg-white">
                            <div className="mx-auto text-xs">

                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto mb-2 stroke-current text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                </svg>
                                <input className="w-full h-full" {...dropMessageAttachments.getInputProps()} />
                                <p><button type="button" className="focus:outline-none text-indigo-600 font-medium">{Translate("Upload files")}</button> {Translate("or drag and drop")}</p>
                                <p className="text-xs">{Translate("JPG, PNG, PDF, XLS, DOC, DOCX")}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-3">
                    <div className="col-span-3 lg:col-span-1 pr-4">
                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Your auto-reply message.")}</p>
                        <p className="text-xs text-gray-700 mb-2 lg:mb-0">{Translate("When a Viila user requests property materials, this message will be appeared in the automated email we send to the user.")}</p>
                    </div>
                    <div className="col-span-3 lg:col-span-2">
                        <TextAreaField 
                            control={control} errors={errors} clearErrors={clearErrors}
                            required rows="6"
                            id="document-data-message" name="document.data.message"
                            placeholder={Translate("Reply message")} 
                            className="mt-4"
                            />
                    </div>
                </div>
            </div>
        </div>
    </div>)
}