import React, { useEffect } from "react"
import { Translate } from "../../translate/Translate";
import MoneyInputField from "../Components/StepWizard/MoneyInputField"
import StatusSetting from "../Components/StepWizard/StatusSetting"
import WizardPropertySummary from "../Components/StepWizard/WizardPropertySummary"
import CheckboxField from "../Components/StepWizard/CheckboxField";

export default function StepSummary(props) {
    const { data, useStep, useFormReturn, handlePublish, handleDraft } = props

    const { goto } = useStep

    const { reset, control, formState: { errors }, clearErrors } = useFormReturn

    useEffect(() => {
        reset({ ...data });
    }, []);


    return (
        <div className="flex w-full overflow-y-auto p-4 h-full">
            <form className="w-full container mx-auto" noValidate={true}>
                <div className="flex w-full lg:flex-row flex-col gap-6">
                    <div className="flex-1 w-full pb-4">
                        <WizardPropertySummary
                            {...props}
                            data={data}
                            useFormReturn={useFormReturn}
                            handleEditArea={(e) => { goto(0) }}
                            handleEditEssential={(e) => { goto(1) }}
                            handleEditAddress={(e) => { goto(2) }}
                            handleEditStory={(e) => { goto(3) }}
                            handleEditDetail={(e) => { goto(4) }}
                            handleEditPhoto={(e) => { goto(5) }}
                        />
                    </div>
                    <div className="flex-none w-full lg:w-80 ml-auto">
                        <div className="sticky top-0 pb-4">
                            <div className="text-sm shadow rounded-lg bg-white divide-y">
                                <div className="p-4">
                                    <p className="text-xl font-semibold">{Translate("Settings")}</p>
                                </div>

                                <div className="py-2 lg:py-2 px-2">
                                    <div className="col-span-1 py-4 lg:py-4 px-2">
                                        <div className="grid grid-cols-3">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Price")}</p>
                                            </div>
                                            <div className="col-span-3 lg:col-span-2">
                                                <div className="relative">
                                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                        <span className="font-medium text-gray-600">¥</span>
                                                    </div>
                                                    <MoneyInputField
                                                        control={control}
                                                        errors={errors} clearErrors={clearErrors}
                                                        required={true}
                                                        placeholder={"50,000,000"}
                                                        id="price-amount"
                                                        name="price.amount"
                                                        className=""
                                                    />
                                                </div>
                                                <div className="mt-2">
                                                    <div className="">
                                                        <div className="rounded mx-auto p-2">
                                                            <p className="text-xs font-bold text-blue-600">{Translate("Estimate price")}</p>
                                                            {/* <p className="text-xs text-blue-600 inline-flex">
                                                                {Translate("more details")}
                                                                <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg>
                                                            </p> */}
                                                            {/* <p className="text-xl font-bold">¥10,090,753</p> */}
                                                            <p className="text-lg font-bold">{Translate("Coming soon")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1 py-4 lg:py-4 px-2">
                                        <div className="grid grid-cols-3">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Status")}</p>
                                            </div>
                                            {data.status !== "" ? <StatusSetting status={data.status} disabled={true} /> : null}
                                        </div>
                                    </div>
                                    <div className="py-4 pt-8 lg:pb-4">
                                        <CheckboxField id="is_private" name="is_private" control={control}>
                                            <p className="-mt-2 text-lg font-medium">{Translate("exclude_from_viila_map_checkbox_label")}</p>
                                            <p className="text-sm text-gray-700">{Translate("exclude_from_viila_map_description")}</p>
                                        </CheckboxField>
                                    </div>
                                </div>
                                <hr />
                                <div className="py-4 lg:py-4 px-2">
                                    <p className="text-xs">{Translate("This information will be displayed publicly on the website once you published.")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    )
}