
import React, { useState, useCallback, useEffect } from "react"
import { debounce } from "lodash";
import * as api from '../../../services/agent'

export default function LotSearch(props) {
    const [latlng, setLatLng] = useState({
        "latitude": 0,
        "longitude": 0
    });
    const { onResult, selectedLatLng, address, Translate, resetSearchCallback, selectedMapID } = props;
    // const { onResult, selectedLatLng, address, Translate, resetSearchCallback, mapIDs } = props;
    const [searchText, setSearchText] = useState("")
    const [doSearch, setDoSearch] = useState(true)
    const [delaySec, setDelaySec] = React.useState(750)

    const [suggestResponse, setSuggestResponse] = useState([])
    const [selectedSuggestion, setSelectedSuggestion] = useState(null)
    const fetchMapboxSuggest = debounce((text) => {
            console.log(selectedMapID, text)
            if (!selectedMapID) return;
            if (!text) return;
            api.lotSearch(selectedMapID, text)
                .then(data => {
                    console.log(1, data)
                    setSuggestResponse(data)
                })
                .catch(error => {
                    console.log(error);
                })
    }, delaySec)

    useEffect(() => {
        if (!address) return
        if (selectedSuggestion != null) return
        if (address.length === 0) return
        setDoSearch(false)
        setSearchText(address)
        let url = `https://api.mapbox.com/search/v1/suggest/${address}?language=ja&limit=5&session_token=&country=JP&access_token=`
        fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setSelectedSuggestion(data.suggestions[0])
            })

    }, [address, selectedSuggestion])

    useEffect(() => {
        setDelaySec(1000 - (searchText.length * 100))
        if (doSearch === false) return;
        // if (searchText.length < 5) return;
        if (searchText.length == 0) return;
        fetchMapboxSuggest(searchText.trim())
    }, [searchText, doSearch])

    const handleTextChange = (e) => {
        setDoSearch(true)
        let text = e.target.value;
        setSearchText(text)
    }

    const onSelectedSuggestion = (suggestion) => {
        onResult(suggestion)
        setSelectedSuggestion(suggestion)
        setDoSearch(false)
        setSearchText(suggestion.lot_number)
        setSuggestResponse([])

    }

    // useEffect(() => {
    //     if (selectedSuggestion === null) return;
    //     fetchSuggestionDetail(selectedSuggestion)
    // }, [selectedSuggestion])

    // const fetchSuggestionDetail = (suggestion) => {

    //     let url = `https://api.mapbox.com/search/v1/retrieve?session_token=&access_token=`
    //     let data = suggestion.action.body;
    //     postData(url, data)
    //         .then(data => {
    //             onResult(data);
    //         });
    // }

    const reverseGeocoding = (lat, lng) => {

        let text = `${lng},${lat}`
        let url = `https://api.mapbox.com/search/v1/reverse/${text}?language=ja&access_token=`
        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.features.length === 0) return;
                let feature = data.features[0];
                setSearchText(feature.properties.description)
            });
    }

    useEffect(() => {
        if (selectedLatLng === undefined) return;
        if (selectedLatLng.lat === 0 || selectedLatLng.lng === 0) return;
        if (selectedLatLng.lat !== latlng.lat || selectedLatLng.lng !== latlng.lng) {
            setLatLng(selectedLatLng)
            reverseGeocoding(selectedLatLng.lat, selectedLatLng.lng)
        }

    }, [selectedLatLng])


    const resetSearch = () => {
        setDoSearch(true)
        onResult(null)
        setSelectedSuggestion(null)
        setSuggestResponse([])
        setSearchText("")
        resetSearchCallback && resetSearchCallback()
    }

    return (
        <div className="flex flex-col w-full relative">
            <div className="h-10 flex items-center border border-gray-300  rounded overflow-hidden bg-white">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2 text-gray-500" viewBox="0 0 24 24" fill="currentColor">
                    <path fillRule="evenodd"
                        d="M8.157 2.175a1.5 1.5 0 00-1.147 0l-4.084 1.69A1.5 1.5 0 002 5.251v10.877a1.5 1.5 0 002.074 1.386l3.51-1.453 4.26 1.763a1.5 1.5 0 001.146 0l4.083-1.69A1.5 1.5 0 0018 14.748V3.873a1.5 1.5 0 00-2.073-1.386l-3.51 1.452-4.26-1.763zM7.58 5a.75.75 0 01.75.75v6.5a.75.75 0 01-1.5 0v-6.5A.75.75 0 017.58 5zm5.59 2.75a.75.75 0 00-1.5 0v6.5a.75.75 0 001.5 0v-6.5z"
                        clipRule="evenodd" />
                </svg>
                <input id="search-geocoder" placeholder={Translate("lotnumbers_input_placeholder")} onChange={handleTextChange} value={searchText} type="text" className=" w-full border-0 focus:outline-none focus:ring-0 focus:border-none placeholder-gray-300" />
                <button id="geocoder-reset-button" type="button" onClick={(e) => {
                    resetSearch()
                    e.stopPropagation()
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>

            </div>
            {/* result */}
            <div className={`absolute top-10 z-20 shadow w-full -mt-px border bg-white rounded-b flex flex-col divide-y ${suggestResponse.length > 0 ? "" : "hidden"}`}>
                {
                    suggestResponse.map((suggestion, i) => (

                        <div id={`geocoder-item-${i}`} key={suggestion.id} className="px-3 py-2 flex items-center border-b cursor-pointer lot-action gap-3 hover:bg-gray-100" onClick={(e) => {
                            onSelectedSuggestion(suggestion)
                            e.stopPropagation()
                        }}>
                            <div className="flex-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    className="w-4 h-4 text-gray-500">
                                    <path fillRule="evenodd"
                                        d="M8.157 2.175a1.5 1.5 0 00-1.147 0l-4.084 1.69A1.5 1.5 0 002 5.251v10.877a1.5 1.5 0 002.074 1.386l3.51-1.453 4.26 1.763a1.5 1.5 0 001.146 0l4.083-1.69A1.5 1.5 0 0018 14.748V3.873a1.5 1.5 0 00-2.073-1.386l-3.51 1.452-4.26-1.763zM7.58 5a.75.75 0 01.75.75v6.5a.75.75 0 01-1.5 0v-6.5A.75.75 0 017.58 5zm5.59 2.75a.75.75 0 00-1.5 0v6.5a.75.75 0 001.5 0v-6.5z"
                                        clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm w-full lot-number font-semibold">番: {suggestion.lot_number}</span>
                                <span className="text-sm w-full lot-number-area">市区町村名: {suggestion.lot_number_area}</span>
                            </div>
                        </div>
                    ))
                }
            </div>
            {/* end result */}
        </div>
    )
}