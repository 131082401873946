import PriceRange from "./PriceRange"

export default function TransactionSummary(props) {
    const { summary, selected, className, minutes, Translate } = props

    return (
        <div className={className ? className : "flex flex-col"}>
            <table className="table-fixed text-xs">
                <thead className="">
                    <tr className="bg-gray-100 sticky top-0 z-40">
                        <th className="text-left p-2 w-32 ">{Translate("Distance")}</th>
                        <th className="text-right p-2 w-12 ">{Translate("Data")}</th>
                        <th className="text-right p-2 w-36 ">{Translate("Average")}</th>
                        <th className="text-center p-2">{Translate("Range")}</th>
                    </tr>
                </thead>
                <tbody>
                    {summary.trade_summary.filter((ts) => ts.nearest_station == selected).map((ts, index) => (
                        <tr key={`${ts.nearest_station}${ts.distance}`} className={minutes !== undefined && ts.distance === minutes ? "bg-green-200" : ""}>
                            <td className="flex items-center gap-2 p-2 ">
                                <div className="flex items-center justify-center relative">
                                    <div className="flex-none w-4 h-4 bg-white ring-2 ring-green-500 rounded-full flex items-center justify-center z-20">
                                        <div className="w-1 h-1 bg-green-500 rounded-full"></div>
                                    </div>
                                    {index != 0 && (<div className="h-6 w-0.5 bottom-4 z-10 bg-green-500 absolute"></div>)}
                                </div>
                                <p className="font-medium  whitespace-nowrap">{ts.distance === 0 ? "unknown" : `${ts.distance} ${Translate("minutes")}`} </p>
                            </td>
                            <td className="p-2 text-right flex-none">
                                <p className="">{ts.counts}</p>
                            </td>
                            <td className="text-right p-2 ">
                                <p className="">{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(ts.avg_price_per_sqm))}/m²</p>
                            </td>
                            <td className="p-2 overflow-hidden bg-transparent">
                                <PriceRange data={ts} />
                            </td>
                        </tr>))}

                </tbody>
            </table>
        </div>
    )
}