import { useEffect, useState } from 'react'
import { Translate } from "../../translate/Translate";
import { Link } from "react-router-dom";
import FullName from '../Shared/FullName';
import * as Constants from '../../../constants'
import DateTime from '../Shared/DateTime';
import { Popover, Transition } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { ChatBubbleBottomCenterIcon } from '@heroicons/react/24/solid'
import { ArrowUturnLeftIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline'


export default function ActivityMessageAgent(props) {
    const { activity } = props;

    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom' })


    return (
        <div key={activity.id} className="flex flex-col items-start  w-full">
            <div className='w-full p-4 flex items-start'>
                <div className='flex items-center gap-2'>
                    <div className="w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center">
                        <ArrowUturnLeftIcon className='w-5 h-5 text-primary/40' />
                    </div>
                    <div>
                        <p className="font-medium leading-none mb-1">
                            <FullName firstname={activity.user.first_name} lastname={activity.user.last_name}></FullName>
                        </p>
                        <p className='text-xs'><DateTime date={activity.created_at} /> · Team</p>
                    </div>
                </div>
                <div className='ml-auto'>

                    <Popover>
                        <Popover.Button ref={setReferenceElement} className="focus:outline-none">
                            <EllipsisHorizontalIcon className='w-6 h-6 text-slate-400' />
                        </Popover.Button>

                        <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Popover.Panel
                                className="bg-white rounded-lg shadow-lg w-56 ring-1 ring-slate-200"
                                ref={setPopperElement}
                                style={styles.popper}
                                {...attributes.popper}
                            >
                                <div className=''>
                                    <div className='p-2 w-full'>
                                        <a target="_blank" href={`${Constants.BASE_URL}/p/${activity.property_no}`} className="flex items-center gap-1 text-sm">
                                            <span>View {activity.property.property_id} on Viila</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </Popover>

                </div>
            </div>
            <div className='w-full px-4 pb-4'>
                <Link to={activity.dashboard_url} target="_blank">
                    <div className='flex items-center gap-4'>
                        <div className='w-full '>
                            {activity.event == "mark_as_reply_mail" ? <span className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-blue-100 text-gray-600 uppercase">{Translate("marked_as_replied")}</span> : null}
                            {activity.log.message ? <p>{activity.log.message}</p> : null}
                        </div>

                    </div>
                </Link>
            </div>
            <div className='w-full'>
                {/* footer */}

            </div>
        </div>
    )
}