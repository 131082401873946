import React, {Fragment, useRef} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    XMarkIcon
} from "@heroicons/react/24/outline"

export default function ConfirmSubmitProposalModal(props) {
  const {Translate, isOpen, setIsOpen, proposal} = props

  const onConfirm = (e) => {
    props.confirm()
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment} >
        <Dialog as="div" className="relative z-20" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 ">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                        as="h3"
                        className="text-lg p-4 font-medium leading-6 text-gray-900"
                    >
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                    {Translate("submit_proposal_box_submit_proposal")}
                                </h3>
                            </div>
                        </div>
                    </Dialog.Title>
                    <Dialog.Description className="p-4 pt-0 flex flex-1 ">
                      <div className="flex flex-col w-full divide-y mt-8">
                          <div className="flex border-b border-dotted border-gray-400 py-1">
                              <p className="text-gray-700 flex-none">{Translate("Price")}</p>
                              <p className="ml-auto font-medium">{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(proposal.min_price.amount))} ~ {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(proposal.max_price.amount))}</p>
                          </div>
                          <div className="flex border-b border-dotted border-gray-400 py-1">
                              <p className="text-gray-700 flex-none">{Translate("Commission")}</p>
                              <p className="ml-auto font-medium">{proposal.commission}%</p>
                          </div>
                          <div className="flex border-b border-dotted border-gray-400 py-1">
                              <p className="text-gray-700 flex-none">{Translate("Detail")}</p>
                          </div>
                          <div className="py-1 flex overflow-y-auto max-h-96">
                            <p className="font-medium whitespace-pre-wrap ">{proposal.remarks}</p>
                          </div>
                      </div>
                    </Dialog.Description>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-2xl">
                        <button onClick={onConfirm} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                            {Translate("Submit")}
                        </button>
                        <button onClick={onCancel} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                            {Translate("Cancel")}
                        </button>
                    </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
