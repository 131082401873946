
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import React from "react"
import V1App from './v1/App';
import V2App from './v2/App';
import AppContext from "./AppContext";
import * as Account from "./account";
import { createBrowserHistory } from "history";
import { Redirect } from "react-router-dom";
import Login from "./Public/Login";
import Signup from "./Public/Signup";
import ResetPassword from "./Public/ResetPassword/ResetPassword";
import Policy from "./Public/Policy/Policy";
import Term from "./Public/Term";
import DocumentTitle from "./v2/views/Components/DocumentTitle";
import NewPassword from "./Public/NewPassword";
import { TranslateWithLang } from "./v2/translate/Translate";
import AcceptInvitation from "./Public/AcceptInvitation";

const history = createBrowserHistory();

function App() {
  const [account] = React.useState(Account.getUserAccount());
  const [language, setLanguage] = React.useState(account === null ? "ja" : account.user.settings !== undefined ? account.user.settings.language : "ja");

  const appContextValue = { account, language, setLanguage };

  if (window.location.pathname != '/')
      localStorage.setItem("incoming_first_page", window.location.href)
  else 
      localStorage.removeItem("incoming_first_page");


  
  if (account === null) {
    return (
      <AppContext.Provider value={appContextValue}>
        <Router history={history}>
          <Switch>

          <Route path="/accept_invite/:code" render={(props) => (<>
              <DocumentTitle title={"Login"} />
              <AcceptInvitation {...props} />
          </>)}>
          </Route>

            <Route path="/login"
              render={(props) => (<>
                <DocumentTitle title={"Login"} />
                <Login {...props} />
              </>)}
            />
            <Route path="/signup" render={(props) => (<>
              <DocumentTitle title={"Sign up"} />
              <Signup {...props} />
            </>)}>
            </Route>
            <Route path="/resetpassword/:code" render={(props) => (<>
              <DocumentTitle title={"Reset Password"} />
              <NewPassword {...props} />
            </>)}>
            </Route>
            <Route path="/resetpassword" render={(props) => (<>
              <DocumentTitle title={"Reset Password"} />
              <ResetPassword {...props} />
            </>)}>
            </Route>

            <Route path="/terms"
              render={(props) => (<>
                <DocumentTitle title={"Terms"} />
                <Term {...props} Translate={(key) => {
                  return TranslateWithLang(language, key)
                }} />
              </>)}
            />
            <Route path="/policy"
              render={(props) => (<>
                <DocumentTitle title={"Policy"} />
                <Policy {...props} Translate={(key) => {
                  return TranslateWithLang(language, key)
                }} />
              </>)}
            />
            <Route path="/"
              render={(props) => (<>
                <DocumentTitle title={"Login"} />
                <Login {...props} />
              </>)}
            />

          </Switch>
        </Router>
      </AppContext.Provider>
    )
  }

  return (
    <AppContext.Provider value={appContextValue}>
      <Router history={history}>
        <Switch>
          {/* Redirect for /properties */}
          <Route 
            exact
            path="/properties"
            render={({location}) => <Redirect to={{ pathname: "/v2/properties", search: location.search }}  />}
          />

          {/* Redirect for /properties/wizard/:id/summary */}
          <Route 
            exact
            path="/properties/wizard/:id/summary"
            render={({ match,location }) => {
              const { id } = match.params;
              return <Redirect to={{pathname:`/v2/properties/wizard/${id}/summary`, search: location.search}} />;
            }}
          />

          {/* Redirect for /customers/:id/offers/:code */}
          <Route 
            exact
            path="/customers/:id/offers/:code"
            render={({ match,location }) => {
              const { id, code } = match.params;
              return <Redirect to={{pathname:`/v2/customers/${id}/offers/${code}`, search: location.search}} />;
            }}
          />

          {/* Redirect for /customers/:id/inbox/:code */}
          <Route 
            exact
            path="/customers/:id/inbox/:code"
            render={({ match, location }) => {
              const { id, code } = match.params;
              return <Redirect to={{pathname:`/v2/customers/${id}/inbox/${code}`, search: location.search}} />;
            }}
          />

          {/* Redirect for /settings/billing/:id */}
          <Route 
            exact
            path="/settings/billing/:id"
            render={({ match, location }) => {
              const { id } = match.params;
              return <Redirect to={{pathname:`/v2/settings/billing/${id}`, search: location.search}} />;
            }}
          />
          <Route path='/v2' component={V2App} />
          <Route path='/' component={V1App} />
        </Switch>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
