import {useState,useCallback, useRef} from "react"
import * as turf from '@turf/turf'
import { debounce } from 'lodash';
import ReactMapGL, {
    Marker,
    ScaleControl,
    Source,
    Layer,
    NavigationControl,
    GeolocateControl,
} from 'react-map-gl';
import {
    geolocateStyle,
    navStyle,
    scaleControlStyle,
} from '../../../../mapbox/style'
import Geocoder from "../Geocoder"
import CheckboxField from "./CheckboxField";

import { MAPBOX_TOKEN } from '../../../../constants'

export default function MarkerMap(props) {
    const {Translate} = props
    const {_data, control} = props

    const mapRef = useRef()

    const [marker, setMarker] = useState({
        latitude: _data.latlng?.latitude || 36.3418518,
        longitude: _data.latlng?.longitude || 138.6179432,
    });
    const [viewport, setViewport] = useState({
        latitude: _data.viewport?.latitude || 36.3418518,
        longitude: _data.viewport?.longitude || 138.6179432,
        zoom: _data.viewport?.zoom || 14,
    })

    const circleStyle = {
        id: 'radius',
        interactive: false,
        type: 'fill',
        paint: {
            'fill-color': '#45b6fe',
            'fill-opacity': 0.1
        }
    }

    const onMapLoad = (event) => {
        mapRef.current.on('moveend', () => {
          // do something
        //   console.log('moveend')
        });
    }

    const onMapClick = (e) => {
        if (_data.property_type === "apartment" && _data.apartment !== undefined && _data.apartment !== "") {
            e.preventDefault()
            return
        }
        let location = {
            latitude: e.lngLat.lat,
            longitude: e.lngLat.lng,
        }
        
        setMarker(location)
        // getPlaceNearby({lat: location.latitude, lng: location.longitude})
        props.setLatlng([], {...location, zoom: e.zoom})
        // mapRef.current.flyTo({
        //     center:e.lngLat
        // })
    }

    const onMarkerDrag = useCallback((event) => {
        setMarker({ latitude: event.lngLat.lat, longitude: event.lngLat.lng })
    }, []);

    //this is when a user drag marker
    const onMarkerDragEnd = useCallback((e) => {
        let location = {
            latitude: e.lngLat.lat,
            longitude: e.lngLat.lng,
        }
        setMarker(location)

        props.setLatlng([], {...location, zoom: e.zoom})
        // setValue("latlng", location)
        // getAddress({ lat: location.latitude, lng: location.longitude })
        // getPlaceNearby({lat: location.latitude, lng: location.longitude})
    }, []);

    const onViewportChange = debounce((evt) => {
        // console.log("onViewportChange", evt.viewState)
        setViewport(evt.viewState)
        props.onViewportChange(evt.viewState)
    }, 500)

    return (
    <>
        <div className="mb-4">
            <div className="flex flex-col relative">
                {_data.property_type === "apartment" && _data.apartment !== undefined && _data.apartment !== "" ? null :
                    <Geocoder
                        {...props}
                        selectedLatLng={{ lat: 0, lng: 0 }}
                        onResult={(result) => {
                            if (result == null) return
                            if (result.features.length === 0) return

                            const feature = result.features[0]
                            const { geometry } = feature
                            const location = { latitude: geometry.coordinates[1], longitude: geometry.coordinates[0] }
                            setMarker(location)
                            setViewport((v) => ({ ...v, ...location }))

                            props.onGeocoderResult(feature, location)
                            // setViewport((v) => ({ ...v, ...location }))
                            // setValue("address", {
                            //     ...getValues("address"),
                            //     ...parseContext(feature)
                            // }, {
                            //     shouldTouch: true
                            // })
                            // setValue("latlng", location)
                            mapRef.current.flyTo({center: [location.longitude ,location.latitude], zoom: viewport.zoom < 17 ? 17:viewport.zoom })
                            // getPlaceNearby({lat:location.latitude, lng: location.longitude})
                        }}
                    ></Geocoder>}
            </div>
        </div>

        <div className="h-96 w-full">
            <ReactMapGL
                ref={mapRef}
                doubleClickZoom={false}
                onLoad={onMapLoad}
                mapStyle="mapbox://styles/apisitviila/ckwk55so8393b14mdforyhaxf"
                mapboxAccessToken={MAPBOX_TOKEN}
                initialViewState={viewport}
                onMove={onViewportChange}
                onClick={onMapClick}
                style={{ width: "100%", height: "100%" }}
            >
                {_data.privacy_mode ?
                    (<Source id="radius" type="geojson"
                        data={turf.circle([marker.longitude, marker.latitude], 1000, {
                            units: 'meters',
                        })} >
                        <Layer {...circleStyle} />
                    </Source>) : null}
                <Marker id="marker"
                    color="#ef4444"
                    longitude={marker.longitude}
                    latitude={marker.latitude}
                    draggable={_data.property_type !== "apartment"}
                    onDrag={onMarkerDrag}
                    onDragEnd={onMarkerDragEnd}>
                </Marker>
                <GeolocateControl style={geolocateStyle} />
                <NavigationControl style={navStyle} />
                <ScaleControl style={scaleControlStyle} />
            </ReactMapGL>
        </div>
        <div className="mt-4">
            <div className="flex items-start">
                <CheckboxField id="privacy_mode" name="privacy_mode" control={control} className="mt-1">
                    <div className="font-medium">
                        <p className="text-sm">{Translate("Hide location mark")}</p>
                        <p className="text-xs text-gray-700">{Translate("Show estimated area instead of an exact location on the map")}</p>
                    </div>
                </CheckboxField>
            </div>
        </div>

    </>)
}