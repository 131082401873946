import React from "react"

export default function TourForm(props) {
    const { data, setData, Translate } = props
    const isYoutube = (url) => url.includes("youtube.com")
    const isYoutuDotbe = (url) => url.includes("youtu.be")
    const embededLink = (url) => {
        if (isYoutube(url)) {
            const link = new URL(url)
            let videoID = link.searchParams.get("v")
            return `https://www.youtube.com/embed/${videoID}`
        }
        if (isYoutuDotbe(url)) {
            const link = new URL(url)
            return `https://www.youtube.com/embed/${link.pathname}`
        }
        return url
    }
    const isValidURL = (url) => {
        try {
            const link = new URL(url)
            return link
        } catch (error) {
            console.log("error", error)
            return undefined
        }
    }
    return (
        <div className="bg-white h-full">
            <div className="flex flex-row w-full h-full">
                <div className="w-full h-full overflow-y-auto">
                    <div className=" mx-4 my-4 p-8">
                        <div className="col-span-1 py-4 px-6">
                            <div className="grid grid-cols-3">
                                <div className="col-span-3 lg:col-span-1">
                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Video URL")}</p>
                                </div>
                                <div className="col-span-3 lg:col-span-2">
                                    <input name="video.url" id="video-url" value={data.video?.url || ""} onChange={(e) => {
                                        setData((data) => ({...data, video: {url: e.target.value }}))
                                    }} type="text" placeholder="https://youtube.com/..." className="rounded w-full" />
                                    <div className="my-2 h-80 w-full">
                                        {data.video?.url !== "" ? 
                                            isValidURL(data.video?.url) ? 
                                                (<iframe src={embededLink(data.video?.url)} title="video-preview" className="w-full h-full"></iframe>): 
                                                (<div className="w-full h-full bg-slate-300 items-center flex">
                                                    <p className="mx-auto">Invalid URL</p>
                                                </div>)   
                                            :(<div className="w-full h-full bg-slate-300"></div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-span-1 py-4 px-6">
                            <div className="grid grid-cols-3">
                                <div className="col-span-3 lg:col-span-1">
                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Virtual Tour URL")}</p>
                                </div>
                                <div className="col-span-3 lg:col-span-2">
                                    <input name="vr.url" id="vr-url" value={data.vr?.url || ""} onChange={(e) => {
                                        setData((data) => ({...data, vr: {url: e.target.value }}))
                                    }} type="text" placeholder="https://my.matterport.com/..." className="rounded w-full" />
                                    <p className="text-xs text-gray-700 mt-2">{Translate("Increase engagement and get more views by adding virtual tour.")}</p>
                                    <p className="text-xs text-gray-700 mt-1">
                                        <a href="https://viila.co/services" target="_blank" rel="noreferrer" className="text-indigo-600 font-medium">{Translate("Contact us")}</a> 
                                        {Translate("to 3D Scan this home.")} 
                                        <a href="https://viila.co/p/AN012-PN036?view=3d" rel="noreferrer"  className="text-indigo-600 font-medium" target="_blank">{Translate("See example")}</a>
                                    </p>
                                    <div className="my-2 h-80 w-full">
                                        {data.vr?.url ? 
                                            isValidURL(data.vr?.url) ? 
                                                (<iframe src={data.vr?.url} title="tour-preview" className="w-full h-full"></iframe>): 
                                                (<div className="w-full h-full bg-slate-300 items-center flex">
                                                    <p className="mx-auto">Invalid URL</p>
                                                </div>)   
                                            :(<div className="w-full h-full bg-slate-300"></div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}