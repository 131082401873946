
import clsx from "clsx";
import React from "react"
import { Translate } from "../../translate/Translate";
import { CustomerPhotoAndName } from "./CustomerPhotoAndName";

export default function CustomerRow(props) {
    const { customer } = props;
    return (
        <div className="relative flex items-center  px-4 py-4">
            <CustomerPhotoAndName customer={customer} />
            {customer.unreplied ?
                <div className="absolute top-0 right-0 mt-2 mr-2 ring-2 ring-white  bg-blue-600 text-white text-xs font-medium rounded-full text-center w-5 h-5 flex items-center justify-center leading-none">{customer.unreplied}</div>
                : null
            }
        </div>
    )
}