
import React, { useState, useCallback, useEffect } from "react"
import { debounce } from "lodash";

import { MAPBOX_TOKEN } from "../../../constants";

export default function Geocoder(props) {
    const [latlng, setLatLng] = useState({
        "latitude": 0,
        "longitude": 0
    });
    const { onResult, selectedLatLng, address, Translate, resetSearchCallback } = props;
    const mapboxToken = MAPBOX_TOKEN
    const [searchText, setSearchText] = useState("")
    const [doSearch, setDoSearch] = useState(true)
    const [delaySec, setDelaySec] = React.useState(750)
    const emptyResponse = {
        suggestions: [],
    }
    const [suggestResponse, setSuggestResponse] = useState(emptyResponse)
    const [selectedSuggestion, setSelectedSuggestion] = useState(null)
    const fetchMapboxSuggest = useCallback(
        debounce((text) => {
            let url = `https://api.mapbox.com/search/v1/suggest/${text}?language=ja&limit=5&session_token=&country=JP&access_token=${mapboxToken}`
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    setSuggestResponse(data)
                });
        }, delaySec)
        , [])

    useEffect(() => {
        if (!address ) return 
        if (selectedSuggestion != null) return 
        if (address.length === 0) return
        setDoSearch(false)
        setSearchText(address)
        let url = `https://api.mapbox.com/search/v1/suggest/${address}?language=ja&limit=5&session_token=&country=JP&access_token=${mapboxToken}`
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    setSelectedSuggestion(data.suggestions[0])
                })

    }, [address, selectedSuggestion])

    useEffect(() => {
        setDelaySec(1000 - (searchText.length * 100))
        if (doSearch === false) return;
        if (searchText.length < 5) return;
        fetchMapboxSuggest(searchText.trim())
    }, [searchText, doSearch])

    const handleTextChange = (e) => {
        setDoSearch(true)
        let text = e.target.value;
        setSearchText(text)
    }

    const onSelectedSuggestion = (suggestion) => {
        setSelectedSuggestion(suggestion)
        setDoSearch(false)
        setSearchText(suggestion.feature_name)
        setSuggestResponse(emptyResponse)
        
    }

    useEffect(() => {
        if (selectedSuggestion === null) return;
        fetchSuggestionDetail(selectedSuggestion)
    }, [selectedSuggestion])

    async function postData(url = '', data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        return response.json(); // parses JSON response into native JavaScript objects
    }

    const fetchSuggestionDetail = (suggestion) => {
        let url = `https://api.mapbox.com/search/v1/retrieve?session_token=&access_token=${mapboxToken}`
        let data = suggestion.action.body;
        postData(url, data)
            .then(data => {
                onResult(data);
            });
    }

    const reverseGeocoding = (lat, lng) => {

        let text = `${lng},${lat}`
        let url = `https://api.mapbox.com/search/v1/reverse/${text}?language=ja&access_token=${mapboxToken}`
        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.features.length === 0) return;
                let feature = data.features[0];
                setSearchText(feature.properties.description)
            });
    }

    useEffect(() => {
        if (selectedLatLng === undefined) return;
        if (selectedLatLng.lat === 0 || selectedLatLng.lng === 0) return;
        if (selectedLatLng.lat !== latlng.lat || selectedLatLng.lng !== latlng.lng) {
            setLatLng(selectedLatLng)
            reverseGeocoding(selectedLatLng.lat, selectedLatLng.lng)
        }

    }, [selectedLatLng])


    const resetSearch = () => {
        setDoSearch(true)
        onResult(null)
        setSelectedSuggestion(null)
        setSuggestResponse(emptyResponse)
        setSearchText("")
        resetSearchCallback && resetSearchCallback()
    }

    return (
        <div className="flex flex-col w-full relative">
            <div className="h-10 flex items-center border border-gray-300  rounded overflow-hidden bg-white">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <input id="search-geocoder" placeholder={Translate("Address")} onChange={handleTextChange} value={searchText} type="text" className=" w-full border-0 focus:outline-none focus:ring-0 focus:border-none placeholder-gray-300" />
                <button id="geocoder-reset-button" type="button" onClick={(e) => {
                    resetSearch()
                    e.stopPropagation()
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>

            </div>
            {/* result */}
            <div className={`absolute top-10 z-20 shadow w-full -mt-px border bg-white rounded-b flex flex-col divide-y ${suggestResponse.suggestions.length > 0 ? "" : "hidden"}`}>
                {
                    suggestResponse.suggestions.filter((suggestion) => suggestion.context.find((ctx) => ctx.layer === "postcode")).map((suggestion, i) => (
                        <div id={`geocoder-item-${i}`} key={suggestion.feature_name} className="w-full text-xs">
                            <div onClick={(e) => {
                                onSelectedSuggestion(suggestion)
                                e.stopPropagation()
                            }} className="w-full py-4 px-2 hover:bg-blue-50 cursor-pointer flex items-center truncate overflow-ellipsis">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                </svg>
                                <p className="text-xs lg:text-base">{suggestion.feature_name}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
            {/* end result */}
        </div>
    )
}