import { _authorizedRequest, _authorizedDownloadRequest, _request } from '../http'

export async function fetchProperties(query, lang) {
    var l = lang === "en" ? `/en` : ""
    var url = `/v2${l}/properties`
    if (query !== null) {
        url = `/v2${l}/properties?query=${JSON.stringify(query)}`
    }
    return _authorizedRequest("GET", url, null, lang)
}

export async function fetchPropertiesFeed(query, lang) {
    var l = lang === "en" ? `/en` : ""
    var url = `/v2${l}/properties/feed`
    if (query !== null) {
        url = `/v2${l}/properties/feed?query=${JSON.stringify(query)}`
    }
    return _authorizedRequest("GET", url, null)
}


export async function fetchPropertyDetail(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/properties/${id}`, null)
}

export async function fetchPropertyDetailForEdit(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/properties/${id}/edit`, null)
}

export async function fetchNearby(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/properties/${id}/nearby`, null)
}

export async function fetchGenerativeDescriptions(id) {
    return _authorizedRequest("GET", `/v2/properties/${id}/description`, null)
}

export async function fetchGenerativeDescription(data) {
    return _authorizedRequest("POST", `/v2/properties/description`, data)
}
export async function useGenerativeDescription(id) {
    return _authorizedRequest("POST", `/v2/properties/description/${id}/used`, null)
}
export async function removeGenerativeDescription(id) {
    return _authorizedRequest("DELETE", `/v2/properties/description/${id}`, null)
}

export async function fetchPlaceNearby({ lat, lng, area_id }) {
    return _authorizedRequest("GET", `/v2/properties/nearby?lat=${lat}&lng=${lng}&area_id=${area_id}`, null)
}

export async function copyProperty(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/properties/${id}/copy`, null)
}

export async function copyPropertyAsDraft(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/properties/${id}/copy_as_draft`, null)
}

export async function fetchPropertyCompact(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/p/${id}/compact`, null)
}

export async function createNewProperty(data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/properties/viila`, data)
}

export async function updateProperty(data, lang) {
    var l = lang === "en" ? `/en` : ""
    if (data.id === undefined) {
        throw new Error("id is empty");
    }
    return _authorizedRequest("POST", `/v2${l}/properties/${data.id}/viila`, data)
}

export async function updatePropertyStatus(data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/properties/status`, data)
}

export async function updatePropertyPrivate(data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/properties/private`, data)
}

export async function updatePropertyPublic(data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/properties/public`, data)
}

export async function updatePropertyPin(data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/properties/pin`, data)
}

export async function updatePropertyUnpin(data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/properties/unpin`, data)
}

export async function deletePropertyTax(propertyID, year, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("DELETE", `/v2${l}/properties/${propertyID}/tax/${year}`, null)
}

export async function uploadImage(base64Image, fileName, lang) {
    var l = lang === "en" ? `/en` : ""
    let data = {
        fileBase64: base64Image,
        fileName: fileName
    }
    return _authorizedRequest("POST", `/v2${l}/file/upload`, data)
}

export async function uploadAndGetFullContent(base64Image, fileName, lang) {
    var l = lang === "en" ? `/en` : ""
    let data = {
        fileBase64: base64Image,
        fileName: fileName
    }
    return _authorizedRequest("POST", `/v2${l}/file/upload/getfullcontent`, data)
}

export async function features(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/features`, null)
}
export async function defaultFeatures(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/features/default`, null)
}


export async function fetchThreads(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/threads`, null)
}

export async function replyToThread(threadID, data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/threads/${threadID}/reply`, data)
}

export async function archiveThread(threadID, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("DELETE", `/v2${l}/threads/${threadID}`, null)
}

export async function profile(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/profile`, null)
}

export async function updateProfile(data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("PUT", `/v2${l}/profile`, data)
}

export async function tryPropertyEasy() {
    return _authorizedRequest("GET", `/v2/profile/trypropertyeasy`, null)
}

export async function inviteTeamMember(email, platform, lang) {
    var l = lang === "en" ? `/en` : ""
    let data = {
        email: email,
        platform:platform,
    }
    return _authorizedRequest("POST", `/v2${l}/invitation/invite`, data)
}


export async function team(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/team`, null)
}

export async function deleteTeam(code, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("DELETE", `/v2${l}/team/${code}`, null)
}

export async function offers(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/offers`, null)
}

export async function acceptOffer(offerId, data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/offers/${offerId}/accept`, data)
}

export async function rejectOffer(offerId, data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/offers/${offerId}/reject`, data)
}



export async function commentOffer(offerId, data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/offers/${offerId}/reply`, data)
}


export async function offerDetail(code, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/offers/${code}`, null)
}


export async function invitation(code, lang) {
    var l = lang === "en" ? `/en` : ""
    return _request("GET", `/v2${l}/invitations/${code}`, null)
}

export async function areas(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/areas`, null)
}

export async function createNewSurvey(data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/survey`, data)
}

export async function regions(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/m/regions`, null)
}

export async function prefectures(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/prefectures`, null)
}
export async function areaByPrefectures(lang, prefecture) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/prefectures/${prefecture}`, null)
}
export async function areaByPrefecturesLv(lang, prefecture) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/prefectures/${prefecture}/lv`, null)
}

export async function cities(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/m/cities`, null)
}

export async function statistics(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/statistics`, null)
}

export async function viewStatistics(data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/statistics/views`, data)
}

export async function mapImpressionStatistics(data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/statistics/number_of_map_impression`, data)
}
export async function messageStatistics(data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/statistics/number_of_message`, data)
}
export async function newMessageStatistics(data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/statistics/number_of_new_messages`, data)
}

export async function soldStatistics(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/statistics/sold`, null)
}

export async function viewAllStatistics(from, to, properties, action, lang) {
    var l = lang === "en" ? `/en` : ""
    let data = {
        from: from,
        to: to,
        properties: properties,
        action: action,
    }
    return _authorizedRequest("POST", `/v2${l}/statistics/allviews`, data)
}

export async function addEmailEvent(user_id, event, lang) {
    var l = lang === "en" ? `/en` : ""
    let data = {
        user_id: user_id,
        event: event,
    }
    return _authorizedRequest("POST", `/v2${l}/invitation/email`, data)
}

export async function removeEmailEvent(user_id, event, lang) {
    var l = lang === "en" ? `/en` : ""
    let data = {
        user_id: user_id,
        event: event,
    }
    return _authorizedRequest("DELETE", `/v2${l}/invitation/email`, data)
}

export async function emailEvents(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/events`, null)
}

export async function apartments(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/apartments/agentdashboard/${id.length === 0 ? 'all' : id}`, null)
}

export async function apartmentsCompact(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/apartments/agentdashboard/${id.length === 0 ? 'all' : id}/compact`, null)
}

export async function fetchApartmentDetail(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/apartments/all/detail/${id}`, null)
}

export async function customer(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/customers/${id}`, null)
}

export async function customerPreferences(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/customers/${id}/preferences`, null)
}

export async function customerPreferenceDetail(id, code, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/customers/${id}/preferences/${code}`, null)
}

export async function customerInsight(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/customers/${id}/insight`, null)
}

export async function customers(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/customers`, null)
}

export async function customerNotes(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/customers/${id}/notes`, null)
}
export async function customerNoteInfo(id, noteId, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/customers/${id}/notes/${noteId}`, null)
}
export async function customerNewNote(id, data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/customers/${id}/notes`, data)
}
export async function customerNewNoteDetail(id, noteId, data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/customers/${id}/notes/${noteId}`, data)
}
export async function customerArchiveNote(id, noteId, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("DELETE", `/v2${l}/customers/${id}/notes/${noteId}`, null)
}

export async function customerThreadInfo(id, source, refID, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/customers/${id}/${source}/${refID}`, null)
}

export async function addCustomer(data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/customers`, data)
}

export async function updateCustomer(data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/customers/${data.customer_no ? data.customer_no : data.id}`, data)
}

export async function saveCustomerInterest(id, data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/customers/${id}/interest`, data)
}

export async function customerOffer(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/customers/${id}/offer`, null)
}

export async function customerNewmessage(id, data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/customers/${id}/newmessage`, data)
}

export async function saveCollection(data, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/links`, data)
}

export async function propertiesGeoJSON(query = {}, lang) {
    var l = lang === "en" ? `/en` : ""
    var url = `/v2${l}/properties/geojson`
    if (query !== null) {
        url = `/v2${l}/properties/geojson?query=${JSON.stringify(query)}`
    }
    return _authorizedRequest("GET", url, null)
}

export async function propertiesGeoJSONWithStats(query = {}, lang) {
    var l = lang === "en" ? `/en` : ""
    var url = `/v2${l}/properties/geojson`
    if (query !== null) {
        url = `/v2${l}/properties/geojson?query=${JSON.stringify({ ...query, request_statistic: true, customer_id: "" })}`
    }
    return _authorizedRequest("GET", url, null)
}

export async function getPropertyRegistraionDetail(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/properties/${id}/registration`, null)
}

export async function getReach(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/properties/${id}/reach`, null)
}

export async function agents(query, lang) {
    var l = lang === "en" ? `/en` : ""
    var url = "/v2${l}/agents"
    if (query !== null) {
        url = `/v2${l}/agents?query=${JSON.stringify(query)}`
    }
    return _authorizedRequest("GET", url, null)
}


export async function publicPropertyDetail(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _request("GET", `/v2${l}/p/${id}`, null)
}

export async function getSubscriptions(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/subscriptions`, null)
}

export async function getSubscription(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/subscriptions/${id}`, null)
}

export async function getInvoce(id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/subscriptions/invoice/${id}`, null)
}

export async function startSubscription(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("POST", `/v2${l}/subscriptions`, null)
}


export async function doneSubscription(session_id, lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("PUT", `/v2${l}/subscriptions/${session_id}`, null)
}

export async function checkSubscription(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("GET", `/v2${l}/subscriptions/check`, null)
}

export async function unsubscription(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("DELETE", `/v2${l}/subscriptions`, null)
}

export async function activities(query, lang) {
    var l = lang === "en" ? `/en` : ""
    var url = `/v2${l}/activities`
    if (query !== null) {
        url = `/v2${l}/activities?query=${JSON.stringify(query)}`
    }
    return _authorizedRequest("GET", url, null)
}

export async function generateVideo(propertyNo, videoType, images) {
    const url = `/v2/videos`
    return _authorizedRequest("POST", url, {
        image_urls: images,
        property_no: propertyNo,
        type: videoType,
    })

}

export async function listVideo(propertyNo) {
    const url = `/v2/videos/${propertyNo}`
    return _authorizedRequest("GET", url, null)

}

export async function trainStations(query, stationGroupCode) {
    return _authorizedRequest("GET", `/v2/trains?query=${query}${stationGroupCode ? `&station_group_code=${stationGroupCode}`:""}`, null)
}

export async function trainRoutes(stationGroupCode) {
    return _authorizedRequest("GET", `/v2/trains/${stationGroupCode}`, null)
}

export async function listSellRequest() {
    return _authorizedRequest("GET", `/v2/sell_request`, null)
}

export async function fetchPlace(text) {
    return _authorizedRequest("GET", `/v2/place/?query=${text.trim()}`, null)
}
export async function fetchPlaceDetail(placeID) {
    return _authorizedRequest("GET", `/v2/place/${placeID}`, null)
}

export async function fetchSellRequestDetail(id) {
    return _authorizedRequest("GET", `/v2/sell_request/${id}`, null)
}

export async function fetchSellRequestEstimate(id) {
    return _authorizedRequest("GET", `/v2/sell_request/${id}/estimate`, null)
}

export async function fetchTradeTransaction(request) {
    return _authorizedRequest("POST", `/v2/trade/transactions`, request)
}

// export async function fetchTradeTransaction(request) {
//     return _authorizedRequest("POST", `/v2/trade/transactions/search`, request)
// }

export async function exportTradeTransaction(request) {
    return _authorizedDownloadRequest("POST", `/v2/trade/transactions/csv`, request)
}

// export async function fetchTradeFilterOptions(request) {
//     return _authorizedRequest("POST", `/v2/trade/transactions/options`, request)
// }

// export async function fetchTradeStats(request) {
//     return _authorizedRequest("POST", `/v2/trade/transactions/stats`, request)
// }

// export async function fetchTradeSummary(request) {
//     return _authorizedRequest("POST", `/v2/trade/transactions/summary`, request)
// }

export async function declineSellRequest(id) {
    return _authorizedRequest("PUT", `/v2/sell_request/${id}/decline`, null)
}

export async function updateSellRequestReply(data) {
    return _authorizedRequest("PUT", `/v2/sell_request/autoreply`, data)
}

export async function getSellRequestReply() {
    return _authorizedRequest("GET", `/v2/sell_request/autoreply`, null)
}

export async function submitProposal(id, {
    min_price,
    max_price,
    commission,
    remarks,
    services_offered,
}) {

    var services = []
    for (const [key, value] of Object.entries(services_offered)) {
        if (value){
            services.push(key)
        }
    }

    return _authorizedRequest("PUT", `/v2/sell_request/${id}/proposals`, {
        min_price: { amount: `${min_price.amount}` },
        max_price: { amount: `${max_price.amount}` },
        commission: `${commission}`,
        remarks,
        services_offered : services,
    })
}

export async function servicesProvide(lang) {
    var l = lang === "en" ? `/en` : ""
    return _authorizedRequest("Get", `/v2${l}/sell_request/services_offer`, null)
}

export async function viewProposal(id, proposal_id) {
    return _authorizedRequest("Get", `/v2/sell_request/${id}/myproposals/${proposal_id}/detail`, null)
}

export async function newSellRequestMessage(id, data) {
    return _authorizedRequest("Put", `/v2/sell_request/${id}/message`, data)
}

export async function viewSellRequest(id) {
    return _authorizedRequest("Put", `/v2/sell_request/${id}/view`, null)
}

export async function newPropertyFromSellRequest(id) {
    return _authorizedRequest("PUT", `/v2/sell_request/${id}/newproperty`, null)
}

export async function landboundaryCity(prefecture,city) {
    return _authorizedRequest("GET", `/v2/prefectures/find/${prefecture ? prefecture : ""}/${city ? city : ""}/`, null)
}

export async function lotSearch(selectedMapID,text) {
    return _authorizedRequest("GET", `/v2/landboundary/${selectedMapID}/lotnumbers/${text}`, null)
}

export async function landboundaryCities({lat,lng}) {
    return _authorizedRequest("GET", `/v2/prefectures/latlng/${lat}/${lng}/`, null)
}

export async function lotSearchByMapList({text="",maps=[]}) {
    return _authorizedRequest("POST", `/v2/landboundary/search`, {
        text,
        maps,
    })
}