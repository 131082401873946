
import React, { Fragment, useEffect, useState } from "react"
import SideNavBar from "../SideNavBar";

import { useHistory, useParams, Link } from 'react-router-dom';
import ProposalModal from "../Components/SellerRequest/ProposalModal";
import * as api from '../../services/agent'
import DeclineModal from "../Components/SellerRequest/DeclineModal";
import ProposalBox from "../Components/SellerRequest/ProposalBox";
import { SellRequestState } from "../Shared/SellRequestState";
import { SellRequestStatusBadge } from "../Shared/SellRequestStatusBadge"
import MessageBox from "../Components/SellerRequest/MessageBox";
import SellerRequestDetailBox from "../Components/SellerRequest/SellerRequestDetailBox";
import { ArrowRightIcon, CheckCircleIcon, ClockIcon, DocumentIcon } from "@heroicons/react/24/solid";
import moment from "moment"
import Countdown from "../Shared/Countdown";

export default function SellerRequest(props) {
    const { Translate } = props
    let history = useHistory();
    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const [declineDialogIsOpen, setDeclineDialogIsOpen] = useState(false)
    const [sellRequest, setSellRequest] = useState({
        "id": "",
        "user_id": "",
        "property_type": "",
        "contact": { "first_name": "", "last_name": "", "email": "", "phone": "", "address_1": "", "address_2": "", "city": "", "prefecture": "", "zip_code": "", "region": "", "country_code": "", "image_url": "", "role": "", "settings": { "language": "" }, "stripe_customer_id": "" },
        "data": { "floor_plan": "", "full_address": "", "lot_sqm": "", "property_owner": "", "reason_to_sell": [], "request_to_agents": [], "room_sqm": "", "same_address": false, "time_to_sell": "" },
        "latlng": { "latitude": 36.36351776123047, "longitude": 138.5756378173828 },
        "agents": [],
        "proposals": [],
        "created_at": new Date(),
        "approved_at": new Date(),
        "status": "pending",
        "proposal_amount": 0,
        "sell_request_no": "",
        "viila_data": {}
    })
    const propertyDetail = [
        "floor_plan",
        "lot_sqm",
        "apartment_name",
        "room_sqm",
        "landshape",
        "structure",
    ]
    const { requestId } = useParams();

    function loadDetail(requestId) {
        api.fetchSellRequestDetail(requestId)
            .then((res) => {
                setSellRequest(res.data)
            })
    }
    useEffect(() => {
        loadDetail(requestId)
    }, [requestId])

    const handleDecline = () => {
        api.declineSellRequest(requestId)
            .then((res) => {
                history.push(`/v2/sellers/request`);
            })
    }

    const onMessageSubmit = (d) => {
        let data = { data: d }
        return api.newSellRequestMessage(requestId, data)
            .then((res) => {
                setSellRequest((sr) => ({ ...sr, activities: res.data }))
            })
    }

    const messages = () => {
        return sellRequest.activities && sellRequest.activities[props.account.user.agent_reference_id] ?
            sellRequest.activities[props.account.user.agent_reference_id].filter((a) => (a.activity === "sell_status_message" || a.activity === "sell_status_agent_message")) : []
    }

    const otherAgentAccepted = () => {
        return sellRequest.status === "proposal_accepted" && sellRequest.accepted_agent !== props.account.user.agent_reference_id
    }
    const sellerDeclined = () => {
        const proposal = getProposal()
        if (!proposal) return false
        return proposal.status === "declined"
    }

    const getProposal = () => {
        return sellRequest.proposals.hasOwnProperty(props.account.user.agent_reference_id) && sellRequest.proposals[props.account.user.agent_reference_id].length > 0 && sellRequest.proposals[props.account.user.agent_reference_id][0]
    }

    const gotAccepted = () => {
        return sellRequest.status === "proposal_accepted" && sellRequest.accepted_agent === props.account.user.agent_reference_id
    }

    const newProperty = () => {
        if (gotAccepted())
            api.newPropertyFromSellRequest(requestId)
                .then((res) => {
                    window.location.href = `/v2/properties/wizard/${res.data.property_id}/summary`
                })
    }

    const getFilenameAndExtension = (url) => {
        const filenameWithExtension = url.substring(url.lastIndexOf('/') + 1);
        const filename = filenameWithExtension.substring(0, filenameWithExtension.lastIndexOf('.'));
        const extension = filenameWithExtension.substring(filenameWithExtension.lastIndexOf('.') + 1);
        return  filename + "." + extension;
      }

    return (
        // <div className="absolute inset-0 w-full h-full flex bg-slate-50">
        <div className="w-full flex ">
            <SideNavBar {...props} isPresented={true} />

            <div className="flex flex-col w-full relative">

                <div className="border-b">
                    <div className="w-full container mx-auto lg:px-8 p-4">
                        <div className="items-center">
                            <div className="">
                                <div className="w-full flex items-center">
                                    <Link to={`/v2/sellers/request`} type="button" className="text-xs uppercase font-medium text-gray-600 inline-flex items-center">
                                        <svg className="fill-current text-gray-600 mr-2" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.890625 5.76953C0.890625 5.91602 0.943359 6.04492 1.05469 6.15625L5.70117 10.6973C5.80078 10.8027 5.92969 10.8555 6.08203 10.8555C6.38672 10.8555 6.62109 10.627 6.62109 10.3223C6.62109 10.1699 6.55664 10.041 6.46289 9.94141L2.19727 5.76953L6.46289 1.59766C6.55664 1.49805 6.62109 1.36328 6.62109 1.2168C6.62109 0.912109 6.38672 0.683594 6.08203 0.683594C5.92969 0.683594 5.80078 0.736328 5.70117 0.835938L1.05469 5.38281C0.943359 5.48828 0.890625 5.62305 0.890625 5.76953Z" />
                                        </svg>
                                        <span>{Translate("Back")}</span>
                                    </Link>
                                    <div className="ml-auto text-right">
                                        {/* <SellRequestStatusBadge status={sellRequest.agent_state}></SellRequestStatusBadge> */}
                                        {gotAccepted() && sellRequest.property_id === "" && <button className="bg-primary text-white font-medium px-3 py-2 rounded disabled:bg-blue-300" onClick={e => newProperty()}>{Translate("sell_request_detail_create_property")}</button>}
                                        {gotAccepted() && sellRequest.property_id !== "" && <Link to={`/v2/properties/wizard/${sellRequest.property_id}/summary`} type="button" className="bg-primary text-white font-medium px-3 py-2 rounded disabled:bg-blue-300">{Translate("sell_request_detail_view_property")} · {sellRequest.property_id}</Link>}
                                    </div>
                                </div>
                                <div className="p-2">
                                    <p className="text-sm">{Translate("Request No.")}</p>
                                    <p className="text-xl font-bold">{sellRequest.sell_request_no}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mx-auto flex lg:flex-row flex-col items-start p-8 gap-5 ">
                    <div className="w-full lg:w-2/3">

                        <div className="rounded border border-gray-300 px-5 py-5 mb-8">
                            {
                                sellRequest.agent_state === "waiting_for_submit" || sellRequest.agent_state === "waiting_for_review" ?
                                    <div className="w-full text-red-500 pb-4 mb-4">
                                        <p className="text-lg font-semibold">{Translate("Expires in")} {<Countdown language={props.language} from={sellRequest.approved_at} hour={24 * 30} />}</p>
                                    </div>
                                    : null
                            }


                            <div className="flex flex-col">
                                <SellRequestState state={sellRequest.agent_state} approved_at={sellRequest.approved_at}></SellRequestState>
                            </div>

                        </div>

                        <div className="rounded overflow-hidden bg-white ring-1 ring-slate-200">
                            <div className="flex flex-col">

                                <div className="w-full flex items-center border-b px-5 py-5">
                                    <div>
                                        <p className="inline-flex items-center gap-2">
                                            {/* <UserIcon className="w-6 h-6" /> */}
                                            <div>
                                                <p className="font-medium text-lg">{Translate("Contact Info")}</p>
                                                <p className="text-xs text-gray-500">{Translate("sell_request_detail_personal_info")}</p>
                                            </div>
                                        </p>
                                    </div>
                                    <div className="ml-auto">
                                        <div className="flex items-center gap-1">
                                            <CheckCircleIcon className="w-5 h-5 text-green-500" />
                                            <span className="font-medium text-sm text-green-500">{Translate("sell_request_detail_verified")}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full grid grid-cols-2 p-5 gap-5">
                                    <div className="font-medium">
                                        <p className="text-gray-500 flex-none text-sm mb-0.5">{Translate("full_name")}</p>
                                        <p className={`${gotAccepted() ? "" : "blur"}`}>{sellRequest.contact.first_name} {sellRequest.contact.last_name}</p>
                                    </div>
                                    <div className="font-medium">
                                        <p className="text-gray-500 flex-none text-sm mb-0.5">{Translate("email")}</p>
                                        <p className={` ${gotAccepted() ? "" : "blur"}`}>{sellRequest.contact.email}</p>
                                    </div>

                                    <div className="font-medium ">
                                        <p className="text-gray-500 flex-none text-sm mb-0.5">{Translate("phone_number")}</p>
                                        <p className={`${gotAccepted() ? "" : "blur"}`}>{sellRequest.contact.phone}</p>
                                    </div>

                                    <div className="font-medium">
                                        <p className="text-gray-500 flex-none text-sm mb-0.5">{Translate("current_address")}</p>
                                        <p className={`${gotAccepted() ? "" : "blur"}`}>{sellRequest.contact.address_1}
                                        </p>
                                    </div>

                                </div>



                                <div className="border-t p-5 bg-gray-50 text-center">
                                    <p className="text-sm font-medium">{gotAccepted() ? Translate("sell_request_contract_note_contact_seller") : Translate("sell_request_contract_note_show_when_accepted")}</p>
                                </div>

                            </div>
                        </div>

                        {sellRequest.viila_data && sellRequest.viila_data.documents && sellRequest.viila_data.documents.length > 0 ? 
                        <div className="mt-6 rounded overflow-hidden bg-white ring-1 ring-slate-200">
                            <div className="flex flex-col">

                                <div className="w-full flex items-center border-b px-5 py-5">
                                    <div>
                                        <p className="inline-flex items-center gap-2">
                                            {/* <UserIcon className="w-6 h-6" /> */}
                                            <div>
                                                <p className="font-medium text-lg">{Translate("System Message")}</p>
                                                <p className="text-xs text-gray-500"></p>
                                            </div>
                                        </p>
                                    </div>
                                </div>

                                <div className="w-full grid grid-cols-2 p-5 gap-5">
                                    <div className="font-medium">
                                        <p className="text-gray-500 flex-none text-sm mb-0.5">{Translate("Documentation about this property")}</p>
                                        
                                            <div className="flex flex-col items-start w-full py-2">
                                                <div className=" flex flex-col">
                                                    {sellRequest.viila_data.documents.map(doc => 
                                                        <span className="flex flex-row mt-2">
                                                            <DocumentIcon className="w-4 h-4 text-slate-500"></DocumentIcon>
                                                            <a className="text-xs font-medium" target="_blank" href={doc}>{getFilenameAndExtension(doc)}</a>
                                                        </span>
                                                    )}
                                                </div>
                                            </div> 
                                    </div>
                                </div>
                            </div>
                        </div>: null }

                        {sellRequest.proposals.length == 0 ? null : sellRequest.proposals.hasOwnProperty(props.account.user.agent_reference_id) && sellRequest.proposals[props.account.user.agent_reference_id].length > 0 ?

                            (<div className="mt-6 rounded bg-white overflow-hidden ring-1 ring-slate-200">
                                <ProposalBox {...props} proposal={getProposal()} activities={sellRequest.activities[props.account.user.agent_reference_id] || []} />
                            </div>) :
                            (<div className="mt-6 rounded bg-white overflow-hidden ring-1 ring-slate-200">
                                <div className="flex flex-col">
                                    {sellRequest.status !== "accepted" ? (
                                        <div className="p-5">
                                            <div className="text-lg font-medium flex flex-col items-center justify-center">
                                                <ClockIcon className="w-12 h-12 text-yellow-400" />
                                                <p>{Translate("sell_request_detail_seller_is_waiting")}</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="p-5 text-gray-500 text-center text-sm">
                                            <p>{Translate("sell_request_detail_thanks_for_submitting")}</p>
                                            <p>{Translate("sell_request_detail_no_other_action")}</p>
                                        </div>
                                    )}



                                </div>
                                <div className="p-5 bg-gray-50 border-t flex items-center flex-col lg:flex-row gap-5">
                                    <div className="font-medium text-primary tex-sm">
                                        {
                                            sellRequest.status !== "proposal_accepted" ?
                                                sellRequest.proposal_amount == 0 ?
                                                    <p>{Translate("sell_request_detail_be_the_first")}</p>
                                                    :
                                                    <p>{sellRequest.proposal_amount} {Translate("sell_request_detail_other_agents")}</p>
                                                :
                                                null
                                        }
                                    </div>

                                    <div className="lg:ml-auto flex items-center gap-5">
                                        {sellRequest.status === "pending" ?
                                            <div>
                                                <button type="button" className="bg-gray-200 text-red-500 text-sm px-4 py-2 rounded font-medium" onClick={() => {
                                                    setDeclineDialogIsOpen(true)
                                                }}>{Translate("sell_request_detail_decline")}</button>
                                            </div>
                                            : null}
                                        {(sellRequest.status === "proposal_accepted" || moment(sellRequest.approved_at).add(30, 'd') < (new Date()).getTime()) ? null :
                                            <div className="ml-auto">
                                                <Link to={`/v2/sellers/request/${sellRequest.sell_request_no}/proposal`} type="button" className="bg-blue-600 text-white text-sm px-4 py-2 rounded font-medium flex items-center gap-1">
                                                    {Translate("sell_request_detail_create_proposal")} <ArrowRightIcon className="w-4 h-4" />
                                                </Link>
                                            </div>}
                                    </div>
                                </div>
                            </div>)
                        }
                        <div className="mt-6 rounded bg-white overflow-hidden ring-1 ring-slate-200">
                            <MessageBox {...props}
                                onSubmit={onMessageSubmit}
                                messages={messages()}
                                disabled={otherAgentAccepted() || sellerDeclined()} />
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3">
                        <SellerRequestDetailBox {...props} data={sellRequest} />
                    </div>
                </div>
                {/* <footer className="mt-auto border-t flex-none items-center w-full bg-white">
                    
                    {otherAgentAccepted() && (<div className="py-2 px-6 text-center">
                        <p className="text-md lg:text-xl font-bold">Thanks for your participantion, No other action required (viewed only)</p>
                    </div>)}

                    {sellRequest.status !== "accepted" && !sellRequest.proposals[props.account.user.agent_reference_id] ? <div className="py-2 px-6 text-center">
                        <p className="text-md lg:text-xl font-bold">Customer is waiting for your proposal</p>
                    </div> : null}

                    <div className="px-6 py-2 flex lg:flex-row flex-col-reverse justify-center lg:ml-auto w-full lg:w-auto items-center text-sm rounded  font-semibold gap-2">
                        {sellRequest.status == "pending" && <div>
                            <button type="button" className="btn bg-gray-200 text-red-500 w-40" onClick={() => {
                                setDeclineDialogIsOpen(true)
                            }}>Declined</button>
                        </div>}
                        {sellRequest.status === "proposal_accepted" || (sellRequest.status !== "proposal_accepted" && sellRequest.proposals.hasOwnProperty(props.account.user.agent_reference_id) && sellRequest.proposals[props.account.user.agent_reference_id].length > 0) ? null :
                            <div className="lg:ml-auto">
                                <Link to={`/v2/sellers/request/${sellRequest.id}/proposal`} type="button" className="btn bg-blue-600 text-white w-40">
                                    Submit Proposal
                                </Link>
                            </div>}
                    </div>
                </footer> */}
            </div>
            {dialogIsOpen && <ProposalModal {...props} requestId={requestId} isOpen={dialogIsOpen} setIsOpen={setDialogIsOpen} />}
            <DeclineModal {...props} confirm={handleDecline} isOpen={declineDialogIsOpen} setIsOpen={setDeclineDialogIsOpen} />
        </div>)
}