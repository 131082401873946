import React from "react"
import { Translate } from "../../translate/Translate";


export default function StepArea(props) {

    const onSelected = (value) => {
        props.onSelected(props.name, value)
    }

    return (
        <div className="bg-white shadow-lg border rounded-lg p-8 grid grid-cols-2 lg:grid-cols-4 gap-6">
            {
                props.options.map((area, index) => (
                    <button key={area.id} id={area.id} onClick={(e) => onSelected(area.id)} className={`py-4 text-center border rounded-lg font-bold justify-center flex flex-col items-center  ${props.area.id === area.id ? "bg-blue-600 text-white" : "text-gray-400"}`}>
                        {Translate(area.title)}
                    </button>
                ))
            }
        </div>

    )
}