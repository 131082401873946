
import React, { useEffect } from "react"
import StepSummaryCard from "./StepSummaryCard"
import AddressForm from "../Components/StepWizard/AddressForm"
import Lotnumber from "../Components/Lotnumber"

export default function StepAddress(props) {
    const { data, useFormReturn } = props
    const { reset, watch } = useFormReturn
    const _data = watch()

    useEffect(() => {
        reset({ ...data });
    }, []);

    return (
        <div className="flex w-full overflow-y-auto p-4 h-full">
            <form className="w-full container mx-auto" noValidate={true}>
                <div className="flex w-full lg:flex-row flex-col gap-6">
                    <div className="flex-1 w-full pb-4">
                        <AddressForm className="shadow rounded-lg" {...props} useFormReturn={useFormReturn} />
                        {/* <Lotnumber></Lotnumber> */}
                    </div>
                    <div className="flex-none w-full lg:w-80 ml-auto">
                        <div className="sticky top-0 pb-4">
                            <StepSummaryCard {...props} data={_data} />
                        </div>
                    </div>
                </div>

            </form>
        </div>
    )
}