
import React from "react"

import { PlusIcon, HomeIcon } from "@heroicons/react/24/outline";
import { Link, useHistory } from "react-router-dom";
import { Translate, TranslateWithLang } from "../../translate/Translate";

export default function EmptyStateWithCreateNewPropertyAction(props) {
    
return (
    <div className="flex flex-col items-center justify-center p-4 text-slate-400 text-sm">
    <HomeIcon className="w-6 h-6 mb-2"/>
    <p className="font-medium ">No properties</p>
    <p className="mb-4">Get started by creating a new property</p>
    
    <Link to="/v2/properties/wizard/steps" id="add-new-property-button" className="hover:text-dark-primary text-primary font-semibold flex items-center justify-center w-full p-2">
        <PlusIcon className="w-5 h-5 mr-1"/>
        <span>{Translate("New Property")}</span>
    </Link>

</div>
)
}