
import React from "react"
import TopNavBar from '../Shared/TopNavBar'
import { Translate } from "../../translate/Translate";
import * as api from '../../services/agent'
import AppContext from "../../../AppContext";   
import { Link } from 'react-router-dom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

import { Line } from 'react-chartjs-2';
import { locale } from "moment";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  
export default function Reports(props) {
    const { language } = React.useContext(AppContext)
    const pageViewtitle = Translate("Page views")
    const options = {
        responsive: true,
        maintainAspectRatio:false,
        bezierCurve : true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Propety Page View',
          },
        },
        scales: {
            x: {
                grid: {
                    borderDash: [8, 4],
                    display: true,
                },
                ticks: {
                    callback: function(value, index, ticks) {
                        if (labels.length == 0)
                            return ""

                        if (labels[index])
                            return labels[index].toLocaleDateString("ja-JP");

                        return ""
                    }
                }
            },
            y: {
                grid: {
                    borderDash: [8, 4],
                    display: true,
                    drawBorder: false,
                },
                beginAtZero: false
            }
        },
        plugins: {
            legend: true,
            tooltip: {
                displayColors: false,
                callbacks: {
                    title: function(tooltipItems) {
                        var options = {weekday: "short", year: "numeric", month: "short", day: "numeric"};

                        return new Date(tooltipItems[0].label).toLocaleDateString(language,options);
                    },
                    label: function(context) {
                    var label = context.parsed.y || '';
                     return label + " " + pageViewtitle;
                    }
                }
            },
        }
      };

    function formatDate(d) {
        var month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    
    const today = new Date();
    //yyyy-MM-dd
    const [dateRange, setDateRange] = React.useState(
        {
            from: formatDate(new Date(today.setDate(today.getDate() - 30))), 
            to: formatDate(new Date())
        }
    )

    function addDays(theDate, days) {
        return new Date(theDate.getTime() + days*24*60*60*1000);
    }

    const labels = React.useMemo(() => {
        const labels = []
        const from = new Date(dateRange.from)
        const to = new Date(dateRange.to)
        const diffTime = Math.abs(to - from);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        
        for (var i=0;i<=diffDays;i++) {
            var d = addDays(from,i)
            labels.push(d)
        }        
        return labels
    })


    const [datasets, setDatasets] = React.useState([])
    
    function loadData(){
        const properties = new URLSearchParams(window.location.search).get('properties')
        api.viewStatistics(dateRange.from,dateRange.to,JSON.parse(properties),'view')
        .then(d => {
            var datasets = []
            Object.keys(d).forEach(function(key) {

                var stat = d[key]
                
                datasets.push(
                    {
                        label: key,
                        data: labels.map((e) => {
                            let val = stat.find(element => { 
                                return new Date(element.view_at).getTime() == new Date(e).getTime()
                            })
                            if (val)
                                return Number(val.total_views)
                            
                            return 0

                        }),
                        borderColor: stringToColour(key+key+key),
                        backgroundColor: stringToColour(key+key+key),
                        fill: true,
                        pointRadius: 3,
                      }
                )
            });
            setDatasets(datasets)
        })
        .catch(error => {
            console.log(error);
        })
    }
    React.useEffect(() => {
        loadData()
    }, [dateRange]);

    var stringToColour = function(str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '#';
        for (var i = 0; i < 3; i++) {
          var value = (hash >> (i * 8)) & 0xFF;
          colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
      }


    return (
        <div className="">
            <TopNavBar active="pricing"></TopNavBar>
            <div className="mt-16 mb-16 w-full container mx-auto py-8 px-4">
            <div>
                        <div className="flex flex-row mb-1">
                            <Link to="/listing"  className="text-xs uppercase font-medium text-gray-600 inline-flex items-center">
                                <svg className="fill-current text-gray-600 mr-2" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.890625 5.76953C0.890625 5.91602 0.943359 6.04492 1.05469 6.15625L5.70117 10.6973C5.80078 10.8027 5.92969 10.8555 6.08203 10.8555C6.38672 10.8555 6.62109 10.627 6.62109 10.3223C6.62109 10.1699 6.55664 10.041 6.46289 9.94141L2.19727 5.76953L6.46289 1.59766C6.55664 1.49805 6.62109 1.36328 6.62109 1.2168C6.62109 0.912109 6.38672 0.683594 6.08203 0.683594C5.92969 0.683594 5.80078 0.736328 5.70117 0.835938L1.05469 5.38281C0.943359 5.48828 0.890625 5.62305 0.890625 5.76953Z" />
                                </svg>
                                <span></span>
                            </Link>
                        </div>
                    </div>
                <div className="mb-8 flex justify-between items-center">
                    <div>
                        <p className="text-2xl font-semibold">{Translate("report")} </p>
                    </div>
                </div>
                <main className="w-full">

                    <div className="flex items-center gap-4">
                        <label className="flex flex-col gap-1"> 
                            <span className="text-xs text-gray-500">{Translate("report_date_from")}</span>
                            <input type="date" className="rounded border-gray-500" value={dateRange.from} onChange={e=>{
                                setDateRange({...dateRange,from:e.target.value})
                            }}></input>
                        </label>
                        <label className="flex flex-col gap-1"> 
                            <span className="text-xs text-gray-500">{Translate("report_date_to")}</span>
                            <input type="date" className="rounded border-gray-500" value={dateRange.to} onChange={e=>{
                                setDateRange({...dateRange,to:e.target.value})
                            }}></input>
                        </label>
                    </div>

                    <div className="h-96 w-full" >
                         <Line options={options} data={{labels: labels,datasets: datasets}} />
                    </div>
                </main>

            </div>
        </div>
    )

}