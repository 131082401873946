import { useState } from 'react'
import { Switch } from '@headlessui/react'
import { Translate } from '../../../translate/Translate'


export default function SqmTsuboSwitch({enabled, setEnabled}) {
  
    return (<div className='flex items-center gap-2'>
        <p>{Translate("sqm_to_tsubo_sqm")}</p>
        <div className="flex items-center">
            <Switch
            checked={enabled}
            onChange={setEnabled}
            className={`${enabled ? 'bg-blue-500' : 'bg-gray-300'}
                relative inline-flex h-[21px] w-[37px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
            <span className="sr-only">{Translate("sqm_to_tsubo_use_setting")}</span>
            <span
                aria-hidden="true"
                className={`${enabled ? 'translate-x-4' : 'translate-x-0'}
                pointer-events-none inline-block h-[17px] w-[17px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
            </Switch>
        </div>
        <p>{Translate("sqm_to_tsubo_tsubo")}</p>
    </div>)
  }
  