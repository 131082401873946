import {useState,useRef, useCallback, useEffect} from "react"
import * as turf from '@turf/turf'
import { debounce } from 'lodash';
import ReactMapGL, {
    Marker,
    ScaleControl,
    Source,
    Layer,
    NavigationControl,
    GeolocateControl,
} from 'react-map-gl';

import {
    geolocateStyle,
    navStyle,
    scaleControlStyle,
} from '../../../../mapbox/style'
import LotSearch from "./LotSearch"
import CheckboxField from "./CheckboxField";

import * as api from '../../../../v2/services/agent'
import { MAPBOX_TOKEN } from '../../../../constants'

const prefectures = {
    "01": "hokkaido",
    "02": "aomori",
    "03": "iwate",
    "04": "miyagi",
    "05": "akita",
    "06": "yamagata",
    "07": "fukushima",
    "08": "ibaraki",
    "09": "tochigi",
    "10": "gunma",
    "11": "saitama",
    "12": "chiba",
    "13": "tokyo",
    "14": "kanagawa",
    "15": "niigata",
    "16": "toyama",
    "17": "ishikawa",
    "18": "fukui",
    "19": "yamanashi",
    "20": "nagano",
    "21": "gifu",
    "22": "shizuoka",
    "23": "aichi",
    "24": "mie",
    "25": "shiga",
    "26": "kyoto",
    "27": "osaka",
    "28": "hyogo",
    "29": "nara",
    "30": "wakayama",
    "31": "tottori",
    "32": "shimane",
    "33": "okayama",
    "34": "hiroshima",
    "35": "yamaguchi",
    "36": "tokushima",
    "37": "kagawa",
    "38": "ehime",
    "39": "kochi",
    "40": "fukuoka",
    "41": "saga",
    "42": "nagasaki",
    "43": "kumamoto",
    "44": "oita",
    "45": "miyazaki",
    "46": "kagoshima",
    "47": "okinawa",
}

export default function LotMap(props) {
    const {Translate} = props
    const {data, _data, control} = props


    const [cursor, setCursor] = useState('auto');
    const [hover, setHover] = useState({type:"FeatureCollection", features:[]})
    const [lotnumbers, setLotnumbers] = useState({type:"FeatureCollection", features:[]})
    const [marker, setMarker] = useState({
        latitude: _data.latlng?.latitude || 36.3418518,
        longitude: _data.latlng?.longitude || 138.6179432,
    });
    const [viewport, setViewport] = useState({
        latitude: _data.viewport?.latitude || 36.3418518,
        longitude: _data.viewport?.longitude || 138.6179432,
        zoom: _data.viewport?.zoom || 14,
    })

    const [sources, setSources] = useState({
        "apisitviila.lotnumbers-nagano-20321": true
    })
    const [mapID, setMapID] = useState("20321")
    const [source, setSource] = useState("apisitviila.lotnumbers-nagano-20321")
    const mapRef = useRef()
    const onMouseEnter = (event) => {
        setCursor('pointer')
    }
    const onMouseMove = (event) => {
        if (event.features && event.features.length > 0) {
            const feature = event.features[0]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
            const found = mapRef.current.querySourceFeatures(source,{
                sourceLayer: source,
                filter: ["==", "id", feature.id]
            })

            let list = selectBoundary(found)
            setHover((h) => ({...h, features: list}))
        }
    }
    const onMouseLeave = (event) => {
        setCursor('auto')
        if (event.features && event.features.length > 0) {
            setHover((h) => ({...h, features: []}))
        }
    }
    
    const circleStyle = {
        id: 'radius',
        interactive: false,
        type: 'fill',
        paint: {
            'fill-color': '#45b6fe',
            'fill-opacity': 0.1
        }
    }

    const onMapLoad = (event) => {
        mapRef.current.on('moveend', () => {
          // do something
        //   console.log('moveend')
        });
        
        if (event.target.getZoom() < 16 || source === "") {
            return
        }
        const foundFeatures = mapRef.current.queryRenderedFeatures({
            source: source,
            sourceLayer: source,
            layers: ['viila-boundary']
         })
         if (foundFeatures.length > 0) {
            let lotnumbers = foundFeatures.map((f) => {
               f.geometry.type = 'Point'
               f.geometry.coordinates = [f.properties['代表点経度'], f.properties['代表点緯度']]
               return f
            });
            setLotnumbers({
               type:'FeatureCollection', 
               features: lotnumbers,
            })
         }
    }

    const onViewportChange = debounce((evt) => {
        // console.log("onViewportChange", evt.viewState)
        setViewport(evt.viewState)
        props.onViewportChange(evt.viewState)
    }, 500)

    const onMoveEnd = (event) => {
        // console.log("on move end")
        if (source !== "") {
            if (event.target.getZoom() < 16) {
                return
            }
            const foundFeatures = mapRef.current.queryRenderedFeatures({
                source: source,
                sourceLayer: source,
                layers: ['viila-boundary']
            })
            if (foundFeatures.length > 0) {
                let lotnumbers = foundFeatures.map((f) => {
                    f.geometry.type = 'Point'
                    f.geometry.coordinates = [f.properties['代表点経度'], f.properties['代表点緯度']]
                    return f
                });
                setLotnumbers({
                    type:'FeatureCollection', 
                    features: lotnumbers,
                })
            }
        } 
    }

    function selectBoundary(features){
        var list = features
        var foundFeature = features[0]
        if (features.length > 1) {
           //union all found features
           var union = features.reduce((a, b) => turf.union(a, b), features[0])
           //assign properties back
           union.properties = foundFeature.properties
           list = []
           list.push(union)
        }
        return list         
     }

    const onLotClick = (event) => {
        // console.log("on lot click", (event.features || []).map((f) => f.id))
        if (event.features && event.features.length > 0 && source !== "") {
            const feature = event.features[0]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
            const found = mapRef.current.querySourceFeatures(source,{
                sourceLayer: source,
                filter: ["==", "id", feature.id]
            })

            let list = selectBoundary(found)
            const updatedArray = _data.lotnumber.features.filter((element) => !list.some((feat) => feat.properties.id === element.properties.id));
            updatedArray.push(...list.filter((element) => !_data.lotnumber.features.some((feat) => feat.properties.id === element.properties.id)).map((feat) => ({type:"Feature", geometry: feat.geometry, properties: feat.properties})));
            
            if (updatedArray.length > 0) {
                const center = turf.centerOfMass({type:"FeatureCollection", features: updatedArray })
                setMarker({ latitude: center.geometry.coordinates[1], longitude:  center.geometry.coordinates[0] })
                // setValue("lotnumber", {type:"FeatureCollection", features: updatedArray })
                // setValue("latlng", { latitude: center.geometry.coordinates[1], longitude:  center.geometry.coordinates[0] })
                // setValue("viewport", { latitude: center.geometry.coordinates[1], longitude:  center.geometry.coordinates[0], zoom: viewport.zoom })
                props.setLatlng(updatedArray, { latitude: center.geometry.coordinates[1], longitude:  center.geometry.coordinates[0], zoom: viewport.zoom})
            } else {
                props.setLatlng(updatedArray)
            }
        }
    }

    useEffect(() => {
        if (_data.area?.id == "miyota") {
            setSources((s) => ({...s, "apisitviila.lotnumbers-nagano-20323":true}))
            setMapID("20323")
            setSource("apisitviila.lotnumbers-nagano-20323")
        } else {
            setMapID("20321")
            setSource("apisitviila.lotnumbers-nagano-20321")
        }
    }, [_data.area.id])

    return (
    <>
        <div className="mb-4">
            <div className="flex flex-col relative">
                {data.property_type === "apartment" && data.apartment !== undefined && data.apartment !== "" ? null :
                <LotSearch {...props} 
                    // mapIDs={Object.keys(sources)}
                    selectedMapID={mapID} 
                    onResult={(result) => {
                        const {latitude, longitude} = result.location.latlng
                        mapRef.current.flyTo({
                            center: [longitude, latitude], 
                            zoom: viewport.zoom > 16 ? viewport.zoom: 16,
                            essential: true,
                        })
                        mapRef.current.once('moveend', () => {
                            const foundFeatures = mapRef.current.queryRenderedFeatures({
                                source: source,
                                sourceLayer: source,
                                filter: ['all', ['==', '地番', result.lot_number], ['==', '地図名', result.map_name]]
                            })

                            let updatedArray = selectBoundary(foundFeatures)
                            
                            if (updatedArray.length > 0) {
                                const center = turf.centerOfMass({type:"FeatureCollection", features: updatedArray })
                                setMarker({ latitude: center.geometry.coordinates[1], longitude:  center.geometry.coordinates[0] })
                                props.setLatlng(updatedArray, { latitude: center.geometry.coordinates[1], longitude:  center.geometry.coordinates[0], zoom: viewport.zoom})
                            } else {
                                props.setLatlng(updatedArray)
                            }
                            props.onLotSearchResult(result.location.latlng)
                        })
                    }} />}
            </div>
        </div>

        <div className="h-96 w-full">
            <ReactMapGL
                ref={mapRef}
                doubleClickZoom={false}
                onLoad={onMapLoad}
                mapStyle="mapbox://styles/apisitviila/ckwk55so8393b14mdforyhaxf"
                mapboxAccessToken={MAPBOX_TOKEN}
                initialViewState={viewport}
                onMove={onViewportChange}
                onMoveEnd={onMoveEnd}
                cursor={cursor}
                onMouseEnter={onMouseEnter}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
                interactiveLayerIds={["viila-boundary-fills"]}
                onClick={onLotClick}
                style={{ width: "100%", height: "100%" }}
            >
                {Object.keys(sources).map((s) => (<Source key={s} id={s} type="vector" promoteId="id" tolerance={0} url={`mapbox://${s}`}>
                    {source === s ? (<>
                        <Layer id="viila-boundary" 
                            type="line" 
                            source-layer={source} 
                            source={source} 
                            minzoom={12} 
                            paint={{
                            "line-color": "#1454ff",
                            "line-opacity": [
                                "interpolate",
                                ["linear"],
                                ["zoom"],
                                12,
                                0.1,
                                22,
                                1
                            ]
                            }}></Layer>
                        <Layer id="viila-boundary-fills" 
                            type="fill" 
                            source-layer={source} 
                            source={source} 
                            minzoom={12} 
                            paint={{
                                "fill-color": "#1454FF",
                                "fill-opacity": 0
                            }}></Layer>
                        </>):null}
                </Source>))}
                <Source id="hoverLots" type="geojson" data={hover}>
                </Source>
                <Layer id="hover-lots"
                    type="fill"
                    source="hoverLots"
                    paint={{
                        'fill-color': '#1454FF',
                        'fill-opacity': 0.5
                    }}
                    ></Layer>
                <Source id="selectedLots" type="geojson" data={_data.lotnumber}>
                </Source>
                <Layer id="selected-lots"
                    type="fill"
                    source="selectedLots"
                    paint={{
                        'fill-color': '#1454FF',
                        'fill-opacity': 0.5
                    }}
                    ></Layer>
                <Source id="lotnumber-label" type="geojson" data={lotnumbers}></Source>
                <Layer id="viila-lotnumbers" 
                    type="symbol" 
                    source="lotnumber-label" 
                    minzoom={16} 
                    layout={{
                        "text-field": ["to-string", ["get", "地番"]],
                        "text-font": [
                        "Noto Sans CJK JP Bold",
                        "Arial Unicode MS Regular"
                        ],
                        "text-size": 14
                    }}
                    paint={{
                        "text-halo-color": "#ffffff",
                        "text-halo-width": 1,
                        "text-color": "#1454ff"
                    }}></Layer>
                {_data.privacy_mode ?
                    (<Source id="radius" type="geojson"
                        data={turf.circle([marker.longitude, marker.latitude], 1000, {
                            units: 'meters',
                        })} >
                        <Layer {...circleStyle} />
                    </Source>) : null}
                <Marker id="marker"
                    color="#ef4444"
                    longitude={marker.longitude}
                    latitude={marker.latitude}>
                </Marker>
                <GeolocateControl style={geolocateStyle} />
                <NavigationControl style={navStyle} />
                <ScaleControl style={scaleControlStyle} />
            </ReactMapGL>
        </div>
        <div className="mt-4">
            <div className="flex items-start">
                <CheckboxField id="privacy_mode" name="privacy_mode" control={control} className="mt-1">
                    <div className="font-medium">
                        <p className="text-sm">{Translate("Hide location mark")}</p>
                        <p className="text-xs text-gray-700">{Translate("Show estimated area instead of an exact location on the map")}</p>
                    </div>
                </CheckboxField>
            </div>
        </div>
    </>)
}