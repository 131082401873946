import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { Translate } from "../../v2/translate/Translate";
import * as Account from '../../account';
import * as api from '../services/agent'
import ViilaLogo from "./Shared/ViilaLogo";
import { MapIcon, ChartBarIcon, ChatBubbleBottomCenterIcon, CogIcon, DocumentTextIcon, HomeIcon, RectangleStackIcon, SwatchIcon, UserGroupIcon, ReceiptPercentIcon, MagnifyingGlassIcon, GlobeAltIcon, RectangleGroupIcon, CurrencyYenIcon, ListBulletIcon, ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/solid";
import WhatsNewModal from "../../Components/WhatsNewModal"

export default function SideNavBar(props) {

    var [account] = React.useState(Account.getUserAccount());
    const [isMobileMenuPresented, setIsMobileMenuPresented] = React.useState(false);
    const [isTidyMode, setIsTidyMode] = React.useState(true)

    const countMustbeUpdated = () => {
        return props && props.statistics && props.statistics.must_be_updated_properties
    }

    const countNewMessage = () => {
        var sum = 0
        for (var i in props.customers) {
            sum += props.customers[i].unreplied ?? 0
        }
        return sum
    }

    const logout = () => {
        Account.Logout();
        window.location.href = "/v2/login"
    }

    function subscription() {
        api.startSubscription()
            .then(checkout_url => {
                window.open(checkout_url, "_self")
            })
            .catch(error => {
                console.log(error);
            })
    }

    function checkSubscription() {
        api.checkSubscription()
            .then(response => {
                console.log(response)
                Account.updateAccount(response)
            })
            .catch(error => {
                console.log(error);
            })
    }

    function unsubscription() {
        if (window.confirm("Would you like to unsubscribe?") == true) {
            api.unsubscription()
                .then(response => {
                    Account.updateAccount(response)
                    window.location.reload()
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }



    const homeMenu = {
        id: "/v2",
        title: "Dashboard",
        url: "/v2",
        icon: <HomeIcon className="w-6 h-6" />
    }
    const menus = [
        // {
        //     id: "/v2/maps",
        //     title: "Maps",
        //     url: "/v2/maps",
        //     icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        //         <path strokeLinecap="round" strokeLinejoin="round" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
        //     </svg>
        // },
        {
            id: "/v2/properties",
            title: "Properties",
            url: "/v2/properties",
            icon: <ListBulletIcon className="w-6 h-6" />,
            badge: () => { return countMustbeUpdated() },
        },
        {
            id: "/v2/customers",
            title: "Customers",
            url: "/v2/customers",
            icon: <ChatBubbleBottomCenterTextIcon className="w-6 h-6" />,
            badge: () => { return countNewMessage() }
        },
        {
            id: "/v2/map",
            title: "Map",
            url: "/v2/map",
            icon: <MapIcon className="w-6 h-6" />,
            badge: () => { return 0 },
        },

        {
            id: "/v2/sellers/request",
            title: "Offers",
            url: "/v2/sellers/request",
            icon: <CurrencyYenIcon className="w-6 h-6" />,
            badge: () => { return 0 },
        },
        {
            id: "/v2/transactions",
            title: "Trade Explore",
            url: "/v2/transactions",
            icon: <MagnifyingGlassIcon className="w-6 h-6" />,
            badge: () => { return 0 },
        },
        {
            id: "/v2/offers",
            title: "Offers",
            url: "/v2/offers",
            icon: <DocumentTextIcon className="w-6 h-6" />,
            badge: () => { return 0 },
        },
        {
            id: "/v2/reports",
            title: "Reports",
            url: "/v2/reports",
            icon: <ChartBarIcon className="w-6 h-6" />,
            badge: () => { return 0 },
        },
        // {
        //     id: "/v2/tools",
        //     title: "Tools",
        //     url: "/v2/tools",
        //     icon: <SwatchIcon className="w-6 h-6" />,
        //     badge: () => { return 0 },
        // },
        {
            id: "/v2/settings",
            title: "Settings",
            url: "/v2/settings",
            icon: <CogIcon className="w-6 h-6" />,
            badge: () => { return 0 },
        },
    ]

    const [showWhatsNewModal, setShowWhatsNewModal] = useState(false);
    const releaseDate = '2024-09-01'; // Update this date when there is a new release
    const hasSeenWhatsNewKey = `hasSeenWhatsNew_${releaseDate}`;
    const hasSeenWhatsNew = localStorage.getItem(hasSeenWhatsNewKey);

    useEffect(() => {
        if (!hasSeenWhatsNew) {
            setShowWhatsNewModal(true);
        }
    }, []);

    return (<div className={`sticky top-0 h-screen z-50`}>
        <WhatsNewModal
            isOpen={showWhatsNewModal}
            onClose={() => {
                setShowWhatsNewModal(false);
                localStorage.setItem(hasSeenWhatsNewKey, 'true');
            }}
        />
        <div className={`h-full flex-none absolute top-0 left-0 lg:relative flex flex-col`}>
            {
                isMobileMenuPresented ?
                    <div className="z-40 fixed top-0 left-0 w-screen h-full bg-black bg-opacity-80"></div>
                    : null
            }

            <div className="lg:hidden border-r border-primary z-50 bg-primary text-white">
                <button className="p-4 rounded" onClick={(e) => {
                    setIsMobileMenuPresented(!isMobileMenuPresented)
                }}>
                    {
                        isMobileMenuPresented == false ?
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                    }
                </button>
            </div>

            <div className={`${isMobileMenuPresented === true ? "" : "hidden"} flex lg:flex flex-col items-start h-full  ${isTidyMode ? "" : "w-64"}  border-r border-primary bg-primary z-50`}>

                <div className={`relative flex items-center text-white px-4 py-4 w-full  ${isTidyMode ? "justfiy-center mx-auto" : ""}`}>
                    <Link to="/v2">
                        <svg className="w-8 h-8 fill-current" viewBox="0 0 54 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 26.4301C0 27.5607 0.883074 28.5392 2.29599 28.5392C2.98038 28.5392 3.59853 28.1695 4.15045 27.7347L6.66721 25.6473V45.8686C6.66721 49.0866 8.63205 51 12.0098 51H41.8798C45.2355 51 47.2224 49.0866 47.2224 45.8686V25.5386L49.8716 27.7347C50.4015 28.1695 51.0196 28.5392 51.704 28.5392C53.0065 28.5392 54 27.7347 54 26.4736C54 25.7343 53.713 25.1472 53.139 24.6689L47.2224 19.7549V10.4922C47.2224 9.51378 46.5822 8.90497 45.5887 8.90497H42.5421C41.5707 8.90497 40.9084 9.51378 40.9084 10.4922V14.5365L30.157 5.64347C28.2363 4.05621 25.8078 4.05621 23.8872 5.64347L0.883074 24.6689C0.286999 25.1472 0 25.7995 0 26.4301Z" />
                            <path d="M47.4741 3.33977C47.4741 5.18428 45.9559 6.67954 44.0831 6.67954C42.2103 6.67954 40.6921 5.18428 40.6921 3.33977C40.6921 1.49527 42.2103 0 44.0831 0C45.9559 0 47.4741 1.49527 47.4741 3.33977Z" />
                        </svg>
                    </Link>

                    {isTidyMode ?
                        null
                        :
                        <div className="flex items-center">
                            <span className="ml-2 text-3xl tracking-wide text-white font-bold">viila</span>
                        </div>
                    }
                </div>

                <div className="px-2 flex flex-col gap-1 w-full" id="menu">

                    <Link key={homeMenu.id} to={homeMenu.url} className={`flex items-center gap-2 p-2 ${isTidyMode ? "justify-center" : ""} hover:bg-blue-800 hover:bg-opacity-100 hover:text-white rounded w-full ${window.location.pathname === homeMenu.id ? "bg-blue-800 text-white" : "text-white text-opacity-50"}`}>
                        {homeMenu.icon}
                        {isTidyMode ? "" :
                            <span className="font-medium">{Translate(homeMenu.title)}</span>
                        }
                    </Link>

                    {
                        menus.map((m) => (
                            <Link key={m.id} to={m.url} className={`flex items-center gap-2 p-2 ${isTidyMode ? "justify-center" : ""} hover:bg-blue-800 hover:bg-opacity-100 hover:text-white rounded w-full ${window.location.pathname.startsWith(m.id) ? "bg-blue-800 text-white" : "text-white text-opacity-50"}`}>
                                <div className="relative">
                                    {m.icon}
                                    {
                                        m.badge() > 0 ?
                                            <div className="absolute top-0 right-0 rounded-full bg-red-600 text-white text-xs font-medium w-4 h-4 flex items-center justify-center -mt-1.5 -mr-1.5">{m.badge()}</div>
                                            : null
                                    }
                                </div>
                                {isTidyMode ? "" :
                                    <span className="font-medium">{Translate(m.title)}</span>
                                }
                            </Link>

                        ))
                    }


                </div>
                {/* <div className="mt-auto flex flex-row-reverse px-2 py-2 w-full">
                    <button type="button" onClick={(e)=>{setIsTidyMode(!isTidyMode)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 text-white text-opacity-50 ${isTidyMode ? "" : "transform rotate-180"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                        </svg>
                    </button>
                </div> */}
                <div className="border-t border-blue-800 py-4 mt-4">
                    <button className="text-white text-xs" onClick={() => setShowWhatsNewModal(true)}>{Translate("What's new?")}</button>
                </div>

                <div className="mt-auto w-full">
                    {/* <div className="w-full py-0.5 text-center text-sm font-bold text-white">
                        <a href="/v1">{Translate("v.1")}</a>
                    </div> */}
                    <div className="px-2 py-2">
                        <button onClick={logout} className={`text-white flex items-center gap-2 p-2 ${isTidyMode ? "justify-center" : ""}   hover:bg-blue-800 hover:bg-opacity-100 hover:text-white rounded w-full`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                            </svg>
                            {isTidyMode ? "" :
                                <span className=" font-medium">{Translate("Logout")}</span>
                            }
                        </button>
                    </div>
                    {/* <div className="w-full py-0.5 bg-dark-primary text-center text-sm font-bold text-white">
                        {account && account.user && (new Date(account.user.subscription_end * 1000)) >= (new Date()) ?
                            <a href={`https://billing.stripe.com/p/login/aEUeVOeOh6T4bCM4gg?prefilled_email=${account.user.email}`} target="_blank">{Translate("paid")}</a> : <a href="#" onClick={e => subscription()}>{Translate("free")}</a>}
                    </div> */}
                </div>
                <div className="p-3 border-t border-blue-800 w-full">
                    <div className={`flex items-center ${isTidyMode ? "justify-center" : ""} `}>
                        {
                            account.user.image_url ?
                                <img className="w-8 h-8 rounded-full border" src={account.user.image_url} />
                                :
                                <div className="w-8 h-8 rounded-full border bg-white flex items-center justify-center">
                                    <ViilaLogo className="w-4 h-4 fill-current text-primary" />
                                </div>
                        }

                        {isTidyMode ? "" :
                            <div className="ml-3 w-auto overflow-ellipsis truncate">
                                <div className="text-sm font-medium text-white">{account ? account.user.first_name : ""}&nbsp;{account ? account.user.last_name : ""}</div>
                                <div className="text-xs font-medium text-gray-50 overflow-ellipsis truncate">{account ? account.user.company.name : ""}</div>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    </div>)
}