
import { BanIcon, ChatBubbleBottomCenterIcon, EyeSlashIcon, GlobeAltIcon, HomeIcon, PencilIcon, KeyIcon, TrashIcon, ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react"
import { Translate } from "../../translate/Translate";

export default function PropertyNewStatusBadge(props) {

    const { className } = props
    function renderBadge(status) {
        switch (status) {
            case 'inactive':
                return (
                    <div id="status-badge-inactive" className={clsx("font-medium flex items-center text-sm rounded whitespace-nowrap", className)}>
                        <EyeSlashIcon className="flex-none w-4 h-4 mr-1 text-stone-500" /><span className="hidden lg:block">{Translate("Inactive")}</span>
                    </div>
                );
            case 'active':
                return (
                    <div id="status-badge-active" className={clsx("font-medium flex items-center text-sm rounded whitespace-nowrap", className)}>
                        <GlobeAltIcon className="flex-none w-4 h-4 mr-1 text-success" /><span className="hidden lg:block">{Translate("Active")}</span>
                    </div>
                );
            case 'business_negotiation':
                return (
                    <div id="status-badge-business_negotiation" className={clsx("font-medium flex items-center text-sm rounded whitespace-nowrap", className)}>
                        <ChatBubbleLeftRightIcon className="flex-none w-4 h-4 mr-1 text-orange-500" /><span className="hidden lg:block">{Translate("business_negotiation")}</span>
                    </div>
                );
            case 'sold':
                return (
                    <div id="status-badge-sold" className={clsx("font-medium flex items-center text-sm rounded whitespace-nowrap", className)}>
                        <KeyIcon className="flex-none w-4 h-4 mr-1 text-red-600" /><span className="hidden lg:block">{Translate("Sold")}</span>
                    </div>
                );
            case 'draft':
                return (
                    <div id="status-badge-draft" className={clsx("font-medium flex items-center text-sm rounded whitespace-nowrap", className)}>
                        <PencilIcon className="flex-none w-4 h-4 mr-1 text-yellow-400" /><span className="hidden lg:block">{Translate("Draft")}</span>
                    </div>
                );
            case 'delete':
                return (
                    <div id="status-badge-sold" className={clsx("font-medium flex items-center text-sm rounded whitespace-nowrap", className)}>
                        <TrashIcon className="flex-none w-4 h-4 mr-1 text-red-600" /><span className="hidden lg:block">{Translate("Delete")}</span>
                    </div>
                );
            default:
                return '';
        }
    }

    return (
        renderBadge(props.status)
    )
}
