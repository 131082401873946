
import React from "react"

export function TimelineStepInprogress(props) {
    return <div className="flex flex-col items-center justify-center w-full relative">
        <div className="z-20 flex-none w-6 h-6 bg-white ring-2 ring-primary rounded-full flex items-center justify-center">
            <div className="w-2 h-2 bg-primary rounded-full animate-pulse"></div>
        </div>
        {!props.is_last ? <div className="z-10 h-0.5 w-1/2 bg-gray-400 absolute right-0"></div> : null}
        {!props.is_first ? <div className="z-10 h-0.5 w-1/2 bg-primary absolute left-0"></div> : null}
    </div>
}