import { Controller } from "react-hook-form";
import CreatableSelect from 'react-select/creatable';
import { Translate, TranslateWithLang } from "../../../translate/Translate";
import clsx from 'clsx';
import { Fragment, useState } from "react"
import { usePopper } from 'react-popper'

export default function CreateableSelectControl({ name, id, className, options = [], getOptionLabel, getOptionValue, control, onChangeCallback, clearErrors, placeholder }){
    const [referenceRef, setReferenceRef] = useState(null);
    const [popperRef, setPopperRef] = useState(null);
    const { styles, attributes } = usePopper(referenceRef, popperRef, {
        placement: "bottom-start",
        modifiers: [
        {
            name: "offset",
            enabled: true,
            options: {
            offset: [0, 10]
            }
        },
        ]
    });

    const selectStyles = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: 'none'
        })
    };

    const borderStyle = (invalid, touched) => {

        if (invalid) {
            return "error border-red-600 border-2 focus:border-red-600"
        }
        // if (touched) {
        //     return "border-green-600 border-2"
        // }
        return ""
    }

    return (<Controller name={name} control={control}
        render={({ field: { value, onChange, onBlur }, fieldState: { invalid, isTouched, error } }) => {
            return (<Fragment>
                <div ref={setReferenceRef} className={className}>
                    <CreatableSelect styles={selectStyles}
                        name={`${name}`.replace(".", "-")}
                        id={`${id || name}`.replace(".", "-")}
                        inputId={`${id || name}-search`.replace(".","-")}
                        instanceId={`${id || name}`.replace(".", "-")}
                        className={clsx("text-base border border-gray-600 rounded", borderStyle(invalid, isTouched), className)}
                        options={options}
                        placeholder={placeholder}
                        onChange={(data) => {
                            onChange(data.value)
                            onChangeCallback && onChangeCallback(data)
                        }}
                        onFocus={() => {
                            clearErrors(name)
                        }}
                        onBlur={onBlur}
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        value={{ value: value, label: Translate(value) }}
                        defaultValue={options.filter((option) => getOptionValue ? value === getOptionValue(option):value === option.value)}
                    />
                </div>
                <div ref={setPopperRef} className={`bg-red-600 text-white rounded-lg p-1 ${invalid ? "block":"hidden"}`} style={styles.popper} {...attributes.popper}>
                    <p className="text-white p-1 font-semibold">{error?.message || error?.id?.message}</p>
                    <div className={`absolute left-2 -top-1 w-3 h-3 bg-red-600 -z-10 ${invalid ? "block":"hidden"}`} style={{"transform": "rotate(45deg)"}}></div>
                </div>
            </Fragment>)
        }} />)
}