
import React from "react"
import SideNavBar from "../SideNavBar";
import { Translate } from "../../translate/Translate";
import * as api from '../../services/agent'
import AppContext from "../../../AppContext";
import moment from "moment";
import { Line } from 'react-chartjs-2';
import { MagnifyingGlassIcon, PrinterIcon } from "@heroicons/react/24/outline";
import DateRangeCommon from "../Components/DateRangeCommon";
import PropertyCardInList from "../Properties/PropertyCardInList"
import Select from 'react-select'


export default function Reports(props) {
    const { language } = React.useContext(AppContext)
    const pageViewtitle = Translate("Page views")
    const query = new URLSearchParams(window.location.search);
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        bezierCurve: true,
        scales: {
            x: {
                grid: {
                    borderDash: [8, 4],
                    display: true,
                },
                ticks: {
                    callback: function (value, index, ticks) {
                        if (labels.length === 0)
                            return ""

                        if (labels[index])
                            return labels[index].toLocaleDateString("ja-JP");

                        return ""
                    }
                }
            },
            y: {
                grid: {
                    borderDash: [8, 4],
                    display: true,
                    drawBorder: false,
                },
                beginAtZero: false
            }
        },
        plugins: {
            legend: true,
            tooltip: {
                displayColors: false,
                callbacks: {
                    title: function (tooltipItems) {
                        var options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };

                        return new Date(tooltipItems[0].label).toLocaleDateString(language, options);
                    },
                    label: function (context) {
                        var label = context.parsed.y || '';
                        return label + " " + pageViewtitle;
                    }
                }
            },
        }
    };

    function formatDate(d) {
        var month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const today = new Date();
    //yyyy-MM-dd
    const [dateRange, setDateRange] = React.useState(
        {
            from: formatDate(new Date(today.setDate(today.getDate() - 30))),
            to: formatDate(new Date())
        }
    )

    function addDays(theDate, days) {
        return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
    }

    const labels = React.useMemo(() => {
        const labels = []
        const from = new Date(dateRange.from)
        const to = new Date(dateRange.to)
        const diffTime = Math.abs(to - from);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        for (var i = 0; i <= diffDays; i++) {
            var d = addDays(from, i)
            labels.push(d)
        }
        return labels
    })


    const [datasets, setDatasets] = React.useState([])

    function loadData() {
        const properties = new URLSearchParams(window.location.search).get('properties')
        var data = {
            from: dateRange.from,
            to: dateRange.to,
            properties: JSON.parse(properties),
            action: 'view',
            //property_no : 'AN002-PN152',
        }
        api.viewStatistics(data)
            .then(d => {
                var datasets = []
                Object.keys(d).forEach(function (key) {

                    var stat = d[key]

                    datasets.push(
                        {
                            label: key,
                            data: labels.map((e) => {
                                let val = stat.find(element => {
                                    return new Date(element.view_at).getTime() === new Date(e).getTime()
                                })
                                if (val)
                                    return Number(val.total_views)

                                return 0

                            }),
                            borderColor: stringToColour(key + key + key),
                            backgroundColor: stringToColour(key + key + key),
                            fill: true,
                            pointRadius: 3,
                        }
                    )
                });
                setDatasets(datasets)
            })
            .catch(error => {
                console.log(error);
            })
    }



    const [reportData, setReportData] = React.useState([])
    const [property, setProperty] = React.useState(null)
    const [properties, setProperties] = React.useState([])
    const [views, setViews] = React.useState({})
    const [mapImpressions, setMapImpressionss] = React.useState({})

    const [viewDateRange, setViewDateRange] = React.useState({
        from: moment().add(-7, "days").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        label: "Last 7 days",
        diff: 7,
    })

    const blankReport = (from, to) => {
        var report = []
        for (var m = moment(from); m.isBefore(to); m.add(1, 'days')) {
            report.push({
                "date": m.format('YYYY/MM/DD'),
                "page_views": "0",
                "map_impressions": "0"
            })
        }
        return report
    }

    function loadView(id) {
        let from = new Date(viewDateRange.from)
        let to = new Date(viewDateRange.to)
        api.viewStatistics({
            from,
            to,
            action: 'view',
            property_no: id,
        }).then((d) => {
            var data = {}
            if (d[id]) {
                var listReport = d[id]
                for (var i in listReport) {
                    var viewAt = moment(listReport[i].view_at).format("YYYY/MM/DD");
                    data[viewAt] = listReport[i].total_views
                }
            }
            setViews(data)
        })
    }
    function loadImpression(id) {
        let from = new Date(viewDateRange.from)
        let to = new Date(viewDateRange.to)
        api.viewStatistics({
            from: from,
            to: to,
            action: 'compactcard',
            property_no: id,
        }).then((d) => {
            var data = {}
            if (d[id]) {
                var listReport = d[id]
                for (var i in listReport) {
                    var viewAt = moment(listReport[i].view_at).format("YYYY/MM/DD");
                    data[viewAt] = listReport[i].total_views
                }
            }
            setMapImpressionss(data)
        })
    }

    var stringToColour = function (str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '#';
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }

    React.useEffect(() => {
        if (viewDateRange.from && viewDateRange.to) {
            setReportData(blankReport(viewDateRange.from, viewDateRange.to))
            if (property) {
                loadView(property.property_id)
                loadImpression(property.property_id)
            }
        }
    }, [viewDateRange]);

    React.useEffect(() => {
        if (property) {
            loadView(property.property_id)
            loadImpression(property.property_id)
        }
    }, [property]);

    React.useEffect(() => {
        if (props.properties && props.properties.items && props.properties.items[0]) {
            setProperties(props.properties.items.sort((a, b) => (a.property_id > b.property_id) ? 1 : ((b.property_id > a.property_id) ? -1 : 0)))
        }
    }, [props.properties]);

    React.useEffect(() => {
        if (properties && properties.length) {
            if (query && query.get("property")) {
                var prop = properties.find((prop) => prop.property_id === query.get("property"))
                if (prop) {
                    setProperty(prop)
                    return
                }
            }
            setProperty(properties[0])
        }
    }, [properties, query]);


    const [lineChartData, setLineChartData] = React.useState({ labels: [], datasets: [] })
    React.useEffect(() => {
        setLineChartData({
            labels: reportData.map((item) => { return item.date }),
            datasets: [
                {
                    data: reportData.map((item) => { return views[item.date] ? Number(views[item.date]) : 0 }),
                    label: "Page View",
                    borderColor: "#145AFF",
                    fill: true,
                    backgroundColor: "#145AFF",
                    lineTension: 0.2,
                    pointRadius: 4,
                    pointBackgroundColor: "#145AFF",
                    hoverRadius: 5,
                    hoverBackgroundColor: "#fff",
                    hoverBorderWidth: 4,
                },
                {
                    data: reportData.map((item) => { return mapImpressions[item.date] ? Number(mapImpressions[item.date]) : 0 }),
                    label: "Map Impression",
                    borderColor: "#1EBD53",
                    lineTension: 0.2,
                    pointRadius: 4,
                    pointBackgroundColor: "#1EBD53",
                    hoverRadius: 5,
                    hoverBackgroundColor: "#fff",
                    hoverBorderWidth: 4,
                    // backgroundColor: (context) => {
                    //     const ctx = context.chart.ctx;
                    //     const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                    //     gradient.addColorStop(0, "rgba(20, 90, 255, 1)");
                    //     gradient.addColorStop(1, "rgba(255,255,255,1)");
                    //     return gradient;
                    //   },
                }
            ]
        })

    }, [reportData, views, mapImpressions]);

    const selectStyles = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: 'none'
        })
    };

    const lineChartOption = {
        type: 'line',
        options: {
            interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false
            },
            maintainAspectRatio: false,
            bezierCurve: true,
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: true,
                    displayColors: false,
                    position: 'average',
                    bodyFont: { weight: "bold", size: 16 },
                    callbacks: {
                        label: function (context) {
                            let value = ""

                            if (context.parsed.y !== null) {
                                value = new Intl.NumberFormat('en-US').format(context.parsed.y);
                            }
                            return value + " " + context.dataset.label;
                        }
                    }
                }
            },
            scales: {
                x: {
                    display: true,
                    beginAtZero: true,
                    title: {
                        display: true
                    },
                    grid: {
                        drawBorder: true,
                        display: false,
                        borderColor: 'rgba(20, 90, 255, 0.2)',
                    },
                },
                y: {
                    display: true,
                    title: {
                        display: false,
                    },
                    beginAtZero: true,
                    grid: {
                        drawBorder: false,
                        display: true,
                    }
                }
            }

        }
    };

    return (


        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} isPresented={true} />
            <div className="flex flex-col w-full relative">

                <div className="bg-white">
                    <div className="w-full container mx-auto lg:px-8 px-4">

                        <div className="py-8">
                            <p className="text-3xl font-bold">{Translate("Reports")}</p>
                        </div>

                        {/* search, sort and filter area */}
                        <div className="mb-6 flex lg:flex-row flex-col gap-x-8 gap-y-4 flex-wrap">

                            <div className=" flex items-center gap-4 w-full">
                                <div className="flex items-center bg-slate-200 rounded h-12  w-full">
                                    <MagnifyingGlassIcon className="flex-none w-5 h-5 text-slate-400 ml-3" />
                                    {/* <input type="text" className="flex-1 border-none bg-transparent h-full w-40 focus:outline-none focus:ring-0" /> */}

                                    <Select
                                        styles={selectStyles}
                                        className="p-2 mb-2 flex-1 border-none bg-transparent h-full w-40 focus:outline-none focus:ring-0"
                                        options={properties}
                                        onChange={(property) => {
                                            props.history.replace({
                                                pathname: window.location.pathname,
                                                search: `?property=${property.property_id}`
                                            })
                                            setProperty(property)
                                        }}
                                        getOptionLabel={(property) => property.property_id + " · " + property.name}
                                        getOptionValue={(property) => property}
                                        value={property}
                                    />
                                    <button className="ml-auto flex-none px-3 text-sm font-semibold bg-primary text-white h-full" >{Translate("Search")}</button>
                                </div>
                            </div>

                        </div>

                        {/* end search, sort and filter area */}

                        {/* <main className="w-full">

                            <div className="flex items-center gap-4">
                                <label className="flex flex-col gap-1">
                                    <span className="text-xs text-gray-500">{Translate("report_date_from")}</span>
                                    <input type="date" className="rounded border-gray-500" value={dateRange.from} onChange={e => {
                                        setDateRange({ ...dateRange, from: e.target.value })
                                    }}></input>
                                </label>
                                <label className="flex flex-col gap-1">
                                    <span className="text-xs text-gray-500">{Translate("report_date_to")}</span>
                                    <input type="date" className="rounded border-gray-500" value={dateRange.to} onChange={e => {
                                        setDateRange({ ...dateRange, to: e.target.value })
                                    }}></input>
                                </label>
                            </div>

                            <div className="h-96 w-full" >
                                <Line options={options} data={{ labels: labels, datasets: datasets }} />
                            </div>
                        </main> */}

                    </div>

                </div>
                
                {property ? <div className="mx-auto lg:px-8 px-4 pt-4 pb-2 flex items-center container">
                    <div className="flex items-center w-full">
                        <PropertyCardInList hidelink={true} property={property} className="w-full" />
                    </div>
                </div>:null}

                
                <div className="lg:p-8 pt-0 lg:pt-0 p-4 flex items-center container mx-auto">
                    <div className="p-4 rounded-lg shadow bg-white w-full">
                        <div className="flex items-center mb-8">
                            <div>
                                <p className="font-bold">{property ? property.property_id : ""}</p>
                                <p className="font-semibold">Page view and Map Impression</p>
                            </div>
                            <div className="ml-auto flex items-center gap-2">
                                <DateRangeCommon since={moment()} dateRange={viewDateRange} Translate={Translate}
                                    onChange={(dateRange) => {
                                        setViewDateRange(dateRange)
                                    }}
                                />
                                <button className="rounded px-2 py-1 bg-slate-200 text-slate-500 font-medium flex items-center gap-1" onClick={() => {
                                    window.print()
                                }}>
                                    <PrinterIcon className="w-5 h-5" /><span>{Translate("print_report_button")}</span>
                                </button>
                            </div>
                        </div>
                        <div className="h-80">
                            <Line height={300} options={lineChartOption.options} data={lineChartData}></Line>
                        </div>

                        <div>
                            <table className="table-fixed border-collapse border w-full">
                                <thead>
                                    <tr>
                                        <th className="border border-slate-200 p-2 bg-slate-100">Date</th>
                                        <th className="border border-slate-200 text-right p-2 bg-slate-100">Page views</th>
                                        <th className="border border-slate-200 text-right p-2 bg-slate-100">Map Impressions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reportData.map((item) => (
                                            <tr key={item.date}>
                                                <td className="border border-slate-200 p-2">{item.date}</td>
                                                <td className="border border-slate-200 p-2 text-right">{views[item.date] ? Number(views[item.date]) : 0}</td>
                                                <td className="border border-slate-200 p-2 text-right">{mapImpressions[item.date] ? Number(mapImpressions[item.date]) : 0}</td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}