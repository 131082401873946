import React, {Fragment} from "react"
import { Menu, Transition } from '@headlessui/react'
import { PropertyStatusBadge } from "./PropertyStatusBadge"
import { Link } from 'react-router-dom';
import moment from "moment";
import { EmbedProperty } from "../../../v2/embed/Embed";
import { Translate } from "../../translate/Translate";
import * as Constants from '../../../constants'
import CopyClipboard from "./CopyClipboard";
export function PropertyRow(props) {

    const formatTime = (t) => {
        return moment(t).fromNow()
    }

    function markAsInActive(id) {
        let status = "inactive"
        props.onSetStatus(props.data.id, status, props.index)
    }

    function markAsActive(id) {
        let status = "active"
        props.onSetStatus(props.data.id, status, props.index)
    }

    function markAsNegotiation(id) {
        let status = "business_negotiation"
        props.onSetStatus(props.data.id, status, props.index)
    }


    const markAsSold = (id) => {
        let status = "sold"
        props.onSetStatus(props.data.id, status, props.index)
    }

    const markAsPrivate = (id) => {
        props.onSetPrivate(props.data.id, true, props.index)
    }

    const markAsPublic = (id) => {
        props.onSetPrivate(props.data.id, false, props.index)
    }
    
    const pin = (id) => {
        props.onSetPin(props.data.id, true, props.index)
    }

    const unpin = (id) => {
        props.onSetPin(props.data.id, false, props.index)
    }



    return (

        <tr key={props.data.id} className="">
            <td className="px-6 py-4 text-center whitespace-no-wrap text-sm leading-5">
                <input type="checkbox" checked={props.checked} onChange={e=>props.onChange(props.data.id)} className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
            </td>
            <td className="px-6 py-4 whitespace-no-wrap">
                <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                        {
                            props.data.images.length === 0 ?
                                <div className="h-10 w-10 rounded object-cover border border-dashed border-gray-600 overflow-hidden"></div>
                                :
                                <img className="h-10 w-10 rounded object-cover" src={props.data.images[0].url} alt="" />
                        }
                    </div>
                    <div className="ml-4 truncate overflow-ellipsis">
                        <div className="text-xs font-medium inline-flex items-center">
                            {props.data.property_id}
                            <a href={`${Constants.BASE_URL}/p/${props.data.property_id}`} target="_blank">
                                <div className="ml-4 inline-flex items-center text-blue-600">
                                    <span>{Translate("View on Viila")}</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                    </svg>
                                </div>
                            </a>
                            </div>
                        <div className=" font-medium text-indigo-700 truncate overflow-ellipsis text-sm w-40">
                            <Link className="truncate overflow-ellipsis" to={'/listing/' + props.data.id}>
                                {props.data.name.length == 0 ? Translate("untitled") : props.data.name}
                            </Link>
                        </div>
                        <div className="text-sm">
                            {Translate(props.data.property_type)} · {props.data.images.length} {Translate("photos")}
                        </div>
                    </div>
                    <div className="ml-6 flex space-x-4">
                        {props.data.is_pinned ?
                        <svg className="w-3 fill-current text-gray-600" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.326172 8.58008C0.326172 9.07812 0.660156 9.40625 1.1875 9.40625H4.35742V12.1426C4.35742 13.0391 4.73242 13.7832 4.87891 13.7832C5.01953 13.7832 5.39453 13.0391 5.39453 12.1426V9.40625H8.56445C9.0918 9.40625 9.42578 9.07812 9.42578 8.58008C9.42578 7.34375 8.43555 6.03711 6.78906 5.43945L6.5957 2.75C7.45117 2.26367 8.1543 1.71289 8.45898 1.32031C8.61133 1.12109 8.6875 0.921875 8.6875 0.746094C8.6875 0.388672 8.41211 0.125 8.00195 0.125H1.75586C1.33984 0.125 1.07031 0.388672 1.07031 0.746094C1.07031 0.921875 1.14062 1.12109 1.29297 1.32031C1.59766 1.71289 2.30078 2.26367 3.15625 2.75L2.96289 5.43945C1.31641 6.03711 0.326172 7.34375 0.326172 8.58008Z"/>
                        </svg>
                        : null
                        }
                        {props.data.is_private ?
                        <svg className="w-3 fill-current text-gray-800" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.10352 5.39258L6.1875 6.48242V4.68359C7.14258 4.44336 7.8457 3.57617 7.8457 2.54492C7.8457 1.33203 6.86719 0.335938 5.64844 0.335938C4.42383 0.335938 3.45117 1.33203 3.45117 2.54492C3.45117 3.57031 4.14844 4.4375 5.10352 4.68359V5.39258ZM5.01562 2.66797C4.61719 2.66797 4.26562 2.31641 4.26562 1.90625C4.26562 1.50195 4.61719 1.15625 5.01562 1.15625C5.43164 1.15625 5.77148 1.50195 5.77148 1.90625C5.77148 2.31641 5.43164 2.66797 5.01562 2.66797ZM9.20508 11.7266C9.38086 11.9023 9.67383 11.9023 9.84375 11.7266C10.0137 11.5508 10.0195 11.2695 9.84375 11.0879L1.66992 2.91992C1.49414 2.75 1.20117 2.74414 1.02539 2.91992C0.855469 3.08984 0.855469 3.38867 1.02539 3.55859L9.20508 11.7266ZM5.10352 10.1152C5.10352 11.8438 5.4082 12.7871 5.64844 12.7871C5.86523 12.7871 6.18164 11.9492 6.18164 10.4961V9.68164L5.10352 8.59766V10.1152Z"/>
                        </svg>
                        : null
                        }
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 text-center whitespace-no-wrap text-sm leading-5">
                {props.data.reference_id}
            </td>
            <td className="px-6 py-4 text-right whitespace-no-wrap text-sm leading-5">
                {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: props.data.price.currency }).format(Number(props.data.price.amount))}
            </td>
            <td className="px-6 py-4 text-center whitespace-no-wrap text-sm leading-5 text-blue-600">
                <a href={`${props.url}?properties=["${props.data.property_id}"]`} >{props.data.page_view}</a>
            </td>
            <td className="px-6 py-4 text-center  whitespace-no-wrap">
            <PropertyStatusBadge status={props.data.status}></PropertyStatusBadge>

            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-center ">
                <p className="text-xs">{Translate("Created")} : {formatTime(props.data.created_at)}</p>
                <p className="text-xs">{Translate("Updated")} : {formatTime(props.data.updated_at)}</p>
                {props.data.status == "active" || props.data.status == "business_negotiation" ? <p className="text-xs">{Translate("Next update")} : {moment(props.data.next_update_at).format("YYYY-MM-DD")}</p> : null }
            </td>
            <td className="px-6 py-4 relative">
                {props.data.status !== "draft" ? <CopyClipboard {...props} text={EmbedProperty(Translate, props.data)} />: null}
            </td>
            <td className="px-6 py-4 relative">
                <Menu as="button" className="focus:outline-none">
                    <Menu.Button>
                        <svg className="fill-current text-gray-700" width="4" height="15" viewBox="0 0 4 15" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.86719 11.7188C1 11.7188 0.304688 12.4219 0.304688 13.3047C0.304688 14.1641 1 14.8672 1.86719 14.8672C2.73438 14.8672 3.42969 14.1641 3.42969 13.3047C3.42969 12.4219 2.73438 11.7188 1.86719 11.7188ZM1.86719 5.9375C1 5.9375 0.304688 6.63281 0.304688 7.5C0.304688 8.35938 1 9.05469 1.86719 9.05469C2.73438 9.05469 3.42969 8.35938 3.42969 7.5C3.42969 6.63281 2.73438 5.9375 1.86719 5.9375ZM1.86719 0.132812C1 0.132812 0.304688 0.828125 0.304688 1.69531C0.304688 2.57812 1 3.27344 1.86719 3.27344C2.73438 3.27344 3.42969 2.57812 3.42969 1.69531C3.42969 0.828125 2.73438 0.132812 1.86719 0.132812Z" />
                        </svg>
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="overflow-hidden origin-top-right z-50 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                            <div className="px-1 py-1 ">
                                <Menu.Item as="button" type="button" onClick={(e) => { markAsInActive(props.data.id);  }} className="bg-white w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{Translate("Delist this property")}</Menu.Item>
                                <Menu.Item as="button" type="button" onClick={(e) => { markAsActive(props.data.id);  }} className="bg-white w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{Translate("Mark as Active")}</Menu.Item>
                                <Menu.Item as="button" type="button" onClick={(e) => { markAsNegotiation(props.data.id);  }} className="bg-white w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{Translate("Mark as Negotiation")}</Menu.Item>
                                <Menu.Item as="button" type="button" onClick={(e) => { markAsSold(props.data.id);  }} className="bg-white w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{Translate("Mark as Sold")}</Menu.Item>
                                <hr/>
                                {
                                props.data.is_private ? <Menu.Item as="button" onClick={(e) => { markAsPublic(props.data.id);  }} className="bg-white w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{Translate("Set to public")}</Menu.Item>
                                : <Menu.Item as="button" onClick={(e) => { markAsPrivate(props.data.id);  }} className="bg-white w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{Translate("Set to private")}</Menu.Item>
                                }
                                <hr/>
                                {
                                props.data.is_pinned ? <Menu.Item as="button" onClick={(e) => { unpin(props.data.id);  }} className="bg-white w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{Translate("unpin_button")}</Menu.Item>
                                : <Menu.Item as="button" onClick={(e) => { pin(props.data.id);  }} className="bg-white w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{Translate("pin_button")}</Menu.Item>
                                }
                                <hr/>
                                <Menu.Item as="button" onClick={(e) => {  }} className="bg-white w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{Translate("Cancel")}</Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </td>
        </tr>

    )
}

