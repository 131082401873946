import React, { Fragment } from "react"
import { Dialog, Transition } from '@headlessui/react'
import PropertyCardInList from "../Properties/PropertyCardInList";
import { Translate } from "../../translate/Translate";

export default function ImportPropertyDialog(props) {

    return (
        <Transition appear show={props.isOpen} as={Fragment} >
            <Dialog as="div" className="relative z-[100] inset-x-0 top-0" onClose={props.closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25"></div>
                </Transition.Child>

                <div className="fixed top-0 left-0 inset-0 w-full h-fit outline-none">
                    <div className="flex items-center justify-end h-full">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="border-none shadow-lg relative flex flex-col lg:w-4/5 w-full h-fit pointer-events-auto bg-white bg-clip-padding  outline-none text-current">
                                <Dialog.Title
                                    as="div"
                                    className="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md"
                                >
                                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                                        {Translate("import_property_button_in_infowindow")} {props.property ? " · " + props.property.property_id : null}
                                    </h3>
                                    <button type="button" id="close" onClick={props.closeModal}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </Dialog.Title>
                                <div className="items-center flex relative flex-1  overflow-hidden">
                                    {props.property ? <PropertyCardInList property={props.property} className="w-full p-4" /> : null}
                                </div>
                                <div className="mt-auto flex flex-shrink-0 flex-wrap items-center justify-end p-4 rounded-b-md">
                                    <div className="ml-auto">
                                        <button id="update" type="button" className="btn btn-primary" onClick={e => { props.copyPropertyAsDraft(props.property.property_id) }} >{Translate("import_property_button_in_infowindow")}</button>
                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}