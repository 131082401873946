
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import React, {useContext, useState} from "react"

import DocumentTitle from '../v2/views/Components/DocumentTitle'
import Home from './views/Home';
import Listing from './views/Listing';
import Settings from './views/Settings';
import SettingsPassword from './views/Settings/SettingsPassword';
import NewProperty from './views/Listing/NewProperty';
import PropertyDetail from './views/Listing/PropertyDetail';

import SettingsTeam from "./views/Settings/SettingsTeam";
import * as api from './services/agent'
import * as Account from "../account";
import moment from "moment";
import "moment/locale/ja"
import Inbox from "./views/Inbox";
import OfferList from "./views/OfferList";
import OfferDetail from './views/OfferList/OfferDetail';

import MapSearch from "./views/Map";
import MapLot from "./views/MapLot";

import SettingsProfile from "./views/Settings/SettingsProfile";
import { TranslateWithLang } from "./translate/Translate";
import Pricing from "./views/Pricing";
import Reports from "./views/Reports";
import NewPropertySteps from "./views/Listing/NewPropertySteps";
import AppContext from "../AppContext";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Term from "../Public/Term";
import Policy from "../Public/Policy/Policy";

const redirectRoutes = [
  {origin:"/login", destination:"/app/login"},
  {origin:"/accept_invite/:code", destination:"/app/accept_invite/:code"},
  {origin:"/signup", destination:"/app/signup"},
  {origin:"/resetpassword/:code", destination:"/app/resetpassword/:code"},
  {origin:"/resetpassword", destination:"/app/resetpassword"},
  {origin:"/pricing", destination:"/app/pricing"},
  {origin:"/offers/:code", destination:"/app/offers/:code"},
  {origin:"/reports", destination:"/app/reports"},
  {origin:"/offers", destination:"/app/offers"},
  {origin:"/map", destination:"/app/map"},
  {origin:"/inbox", destination:"/app/inbox"},
  {origin:"/resetpassword/:code", destination:"/app/resetpassword/:code"},
  {origin:"/settings/password", destination:"/app/settings/password"},
  {origin:"/settings/team", destination:"/app/settings/team"},
  {origin:"/settings/profile", destination:"/app/settings/profile"},
  {origin:"/settings", destination:"/app/settings"},
  {origin:"/listing/steps", destination:"/app/listing/steps"},
  {origin:"/listing/new", destination:"/app/listing/new"},
  {origin:"/listing/:id", destination:"/app/listing/:id"},
  {origin:"/listing", destination:"/app/listing"}
]

function App() {

  const query = new URLSearchParams(window.location.search);
  const [properties, setProperties] = useState({ items: [] });

  const [pinnedProperties, setPinnedProperties] = useState({ items: [] });

  const [messages, setMessages] = useState(null);

  const [keywords, setKeywords] = useState("");
  const [filters, setFilters] = useState(JSON.parse(query.get("query") ?? '{"status":"active"}'));

  const [featureList, setFeatureList] = useState([]);
  const [defaultFeatures, setDefaultFeatures] = useState({});
  const [areas, setAreas] = useState([]);
  const [prefectures, setPrefectures] = useState([]);

  const [statistics, setStatistics] = useState({ number_of_new_messages: 0, number_of_properties: 0, total_views: 0, number_of_offers: 0, number_of_properties_by_status: [], must_be_updated_properties:0 });

  const {account, language} = useContext(AppContext)

  moment.locale(language);

  function loadProperties(query) {
    api.fetchProperties(query)
      .then(data => {
        setProperties(data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  function loadPinnedProperties() {
    let query = JSON.parse('{"is_pinned":true}')
    api.fetchProperties(query)
      .then(data => {
        setPinnedProperties(data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  function loadThreds() {
    api.fetchThreads()
      .then(response => {
        setMessages(response);
      })
      .catch(error => {
        console.log(error);
      })
  }

  function loadFeatures(lang) {
    api.features(lang)
      .then(response => {
        setFeatureList(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  function loadDefaultFeatures() {
    api.defaultFeatures()
      .then(response => {
        setDefaultFeatures(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  function loadPrefecture(lang) {
    api.prefectures(lang)
      .then(response => {
        setPrefectures(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  function loadAreas(lang) {
    api.areas(lang)
      .then(response => {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].name = TranslateWithLang(lang, response.data[i].name)
        }
        setAreas(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  function loadStatistics() {
    api.statistics()
      .then(response => {
        setStatistics(response);
      })
      .catch(error => {
        console.log(error);
      })
  }



  React.useEffect(() => {
    if (account === null) {
      return;
    }
    loadAreas(language)
    loadFeatures(language)
    loadPrefecture(language)
  }, [language]);

  React.useEffect(() => {
    if (account === null) {
      return;
    }

    if (window.gtag) {
      window.gtag('set', {
        'user_id': account.user.id
      });
    }
    loadStatistics()
    loadAreas(language)
    loadFeatures(language)
    loadDefaultFeatures()
    loadThreds()
    loadPinnedProperties()
    loadAgentProfile()
  }, [account]);

  React.useEffect(() => {
    if (account === null) {
      return;
    }
    loadProperties(filters);
  }, [filters, account]);


  const handleFilterChange = (e) => {
    const { name, value } = e.target
    setFilters({ ...filters, [name]: value })
  }

  const handlePropertyRequestChange = (key, value) => {
    setFilters({ ...filters, [key]: value })
  }

  const handleFilterKeyword = (e) => {
    setKeywords(e.target.value.trim())
  }

  const search = (e) => {
    setFilters({ ...filters, keywords: keywords, page: 1 })
  }

  const [agentProfile, setAgentProfile] = React.useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    image_url: "",
    company: {
      name: "",
      url: "",
      image_url: "",
    },
    agent: {
      info: "",
      license: "",
      service_languages: [],
      agent_data: {
        place_id: "",
      }
    },
  });

  function loadAgentProfile() {
    api.profile()
      .then(response => {
        setAgentProfile(response);
      })
      .catch(error => {
        console.log(error)
      })
  }

  const Translate = (key) => {
    return TranslateWithLang(language, key)
  }

  if (account === null) {
    return (
      <Router>
        <Switch>
          <Redirect to="/" />
        </Switch>
      </Router>
    )
  }



  return (
    <Router>
      <Switch>
        <Redirect from="/login" to="/" />
        {/* <Redirect from="/login" to="/app" /> */}
        {redirectRoutes.map((route, i) => {
          // return (<Redirect key={route.destination} from={route.origin} to={route.destination} />)
          return (<Redirect key={route.destination} from={route.destination} to={route.origin} />)
        })}
        <Route path="/pricing"
          render={(props) => (<>
            <DocumentTitle title={""} />
            <Pricing {...props} Translate={Translate} />
          </>)}
        />

        <Route path="/offers/:code"
          render={(props) => (<>
            <DocumentTitle title={"OfferDetail"} />
            <OfferDetail {...props} Translate={Translate} account={account} />
          </>)}
        />

        <Route path="/reports"
          render={(props) => (<>
            <DocumentTitle title={"Reports"} />
            <Reports {...props} Translate={Translate} account={account} />
          </>)}
        />

        <Route path="/offers"
          render={(props) => (<>
            <DocumentTitle title={"Offer List"} />
            <OfferList {...props} Translate={Translate} account={account} />
          </>)}
        />


        <Route path="/map"
          render={(props) => (<>
            <DocumentTitle title={"Map Search"} />
            <MapSearch {...props} Translate={Translate} account={account} />
          </>)}
        />
        <Route path="/karuizawa"
          render={(props) => (<>
            <DocumentTitle title={"Map Search"} />
            <MapLot {...props} Translate={Translate} account={account} />
          </>)}
        />

        <Route path="/inbox"
          render={(props) => (<>
            <DocumentTitle title={"Inbox"} />
            <Inbox {...props} Translate={Translate} data={messages} account={account} />
          </>)}
        />

        <Route path="/settings/password"
          render={(props) => (<>
            <DocumentTitle title={"Password Settings"} />
            <SettingsPassword {...props} Translate={Translate} account={account} />
          </>)}
        />

        <Route path="/settings/team"
          render={(props) => (<>
            <DocumentTitle title={"Team Settings"} />
            <SettingsTeam {...props} Translate={Translate} account={account} />
          </>)}
        />

        <Route path="/settings/profile"
          render={(props) => (<>
            <DocumentTitle title={"Profile Settings"} />
            <SettingsProfile {...props} Translate={Translate} areas={areas} prefectures={prefectures}  />
          </>)}
        />

        <Route path="/settings"
          render={(props) => (<>
            <DocumentTitle title={"Settings"} />
            <Settings {...props} Translate={Translate} />
          </>)}
        />

        <Route path="/listing/steps"
          render={(props) => (<>
            <DocumentTitle title={"NewPropertySteps"} />
            <NewPropertySteps {...props} featureList={featureList} areas={areas} defaultFeatures={defaultFeatures} />
          </>)}
        />
        <Route path="/listing/new"
          render={(props) => (<>
            <DocumentTitle title={"NewProperty"} />
            <NewProperty {...props} Translate={Translate} featureList={featureList} areas={areas} language={language} defaultFeatures={defaultFeatures} />
          </>)}
        />

        <Route path="/listing/:id"
          render={(props) => (<>
            <DocumentTitle title={"PropertyDetail"} />
            <PropertyDetail {...props} Translate={Translate} featureList={featureList} areas={areas} language={language} />
          </>)}
        />

        <Route path="/listing"
          render={(props) => (<>
            <DocumentTitle title={"Listing"} />
            <Listing {...props} onPropertyRequestChange={handlePropertyRequestChange} statistics={statistics} setFilters={setFilters}  filters={filters} keywords={keywords} pinnedProperties={pinnedProperties} properties={properties} reloadPinnedProperties={loadPinnedProperties} handleFilterChange={handleFilterChange} handleFilterKeyword={handleFilterKeyword} search={search} Translate={Translate} />
            </>
          )}
        />

        <Route path="/terms"
          render={(props) => (<>
            <DocumentTitle title={"Term"} />
            <Term {...props} Translate={Translate} />
            </>
          )}
        />
        <Route path="/policy"
          render={(props) => (<>
            <DocumentTitle title={"Policy"} />
            <Policy {...props} Translate={Translate} />
            </>
          )}
        />
        <Route path="/"
          render={(props) => (<>
            <DocumentTitle title={"Home"} />
            <Home {...props} Translate={Translate} agentProfile={agentProfile} messages={messages} account={account} properties={properties} pinnedProperties={pinnedProperties} statistics={statistics} />
          </>)}
        />
      </Switch>
    </Router>
  );
}

export default App;
