import CreatableSelect from 'react-select/creatable';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

export default function FeatureItem(props) {
    const { Translate, id, index, feature, creatableFeatureList, handleFeatureTitleChange, handleFeatureValueChange, handleFeatureArrayValueChange , removeFeature  } = props

    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
    } = useSortable({id: id});
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const selectStyles = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: 'none'
        })
    };

    const toCreatableList = (f) => {
        var list = []
        for (var i in f) {
            list.push({ value: f[i].value, label: f[i].title })
        }
        return list
    }


    const renderFeatureValue = (feature, index) => {
        const id = `feature-${(feature.key ? feature.key : feature.title).replaceAll(" ", "-").toLowerCase()}-value`
        if (feature.data === null || feature.data === undefined) {
            return <input required name={id} id={id} onChange={(e) => handleFeatureValueChange(index, e)} value={feature.value} type="text" className="rounded w-full" />;
        }

        if (feature.data.type === "multiline_text") {
            return <textarea required name={id} id={id} rows="3" onChange={(e) => handleFeatureValueChange(index, e)} value={feature.value} placeholder={feature.data.placeholder} className="rounded w-full" />
        }

        if (feature.data.type === "list_text") {
            return (
                <CreatableSelect
                    styles={selectStyles}
                    className="text-base border border-gray-600 rounded "
                    name={id} id={id}
                    options={toCreatableList(feature.data.values)}
                    onChange={({ value, label }) => handleFeatureValueChange(index, { target: { value: value } })}
                    value={{ value: feature.value, label: Translate(feature.value) }} />
            )
        }

        if (feature.data.type === "list_multiple_value") {
            return (<div className="grid grid-cols-2 gap-1 w-full">
                {feature.data.values.map(({ value }, vIndex) => (
                    <label className="inline-flex items-start">
                        <input type="checkbox"
                            name={id} 
                            key={`feature-option-${(feature.key || feature.title)}-${value}`} 
                            id={`feature-option-${(feature.key || feature.title)}-${value}`} 
                            value={value}
                            checked={Array.isArray(feature.value) ? feature.value.includes(value) : feature.value.split(',').includes(value)}
                            onChange={(e) => handleFeatureArrayValueChange(index, e)} 
                        />
                        <span className="ml-2">{Translate(value)}</span>
                    </label>
                ))}
            </div>)
        }

        if (feature.data.type === "list") {
            return (<div className="grid grid-cols-2 gap-1 w-full">
                {feature.data.values.map(({title, value}, vIndex) => (<label key={vIndex}>
                    <input type="radio" name={id} 
                    key={`feature-option-${(feature.key || feature.title)}-${value}`} 
                    id={`feature-option-${(feature.key || feature.title)}-${value}`} 
                    value={value} 
                    onChange={(e) => handleFeatureValueChange(index, e)} 
                    checked={value === feature.value} />
                    <span className="ml-2">{title}</span>
                </label>))}
            </div>)
        }

        return <input required name={id} id={id} onChange={(e) => handleFeatureValueChange(index, e)} value={feature.value} type="text" placeholder={feature.data.placeholder} className="rounded w-full" />
    }


    return (<div className="grid grid-cols-2 gap-4 gap-y-0 mb-2 rounded border p-4" ref={setNodeRef} {...attributes} style={style} >
        <div className="col-span-1">
            <div className="flex w-full gap-2">
                <button type="button" id={`remove-feature-${(feature.key ? feature.key : feature.title).replaceAll(" ", "-").toLowerCase()}`} onClick={(e) => removeFeature(index, feature)} className="focus:outline-none text-xs font-medium text-red-500">
                    <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                </button>
                <div className="flex-1 w-full">
                    <CreatableSelect
                        styles={selectStyles}
                        className="text-base border border-gray-600 rounded "
                        name={`feature-${(feature.key ? feature.key : feature.title).replaceAll(" ", "-").toLowerCase()}`}
                        id={`feature-${(feature.key ? feature.key : feature.title).replaceAll(" ", "-").toLowerCase()}`}
                        options={creatableFeatureList}
                        onChange={({ value, label }) => handleFeatureTitleChange({ key: value, title: label }, index)}
                        value={{ value: feature.key, label: Translate(feature.key) }}
                    />
                </div>
            </div>
        </div>

        <div className="col-span-1">
            <div className="flex items-center">
                <div className="w-full">
                    {renderFeatureValue(feature, index)}
                </div>
                <div className="ml-auto pl-2">
                    <button type="button" className='cursor-grab hover:bg-slate-200' ref={setActivatorNodeRef} {...listeners}>
                        <svg viewBox="0 0 20 20" width="12">
                            <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>)
}