
import React, { useState, Fragment, useEffect } from "react"
import { Link, useHistory } from "react-router-dom";
import SideNavBar from "../SideNavBar";
import TopSearchbar from "../TopSearchbar";
import { Translate, TranslateWithLang } from "../../translate/Translate";
import * as api from '../../services/agent'
import ActivityCard from "../Components/ActivityCard";
import DateOnly from "../Shared/DateOnly";
import AppContext from "../../../AppContext"
import * as Account from '../../../account';
import { Tab } from '@headlessui/react'
import { ChevronDownIcon, PlusIcon, ArrowTrendingUpIcon, HeartIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { Popover, Transition } from '@headlessui/react'
import { usePopper } from 'react-popper'

import * as Constants from '../../../constants'
import EmptyStateWithCreateNewPropertyAction from "./EmptyStateWithCreateNewPropertyAction";
import ActivityIcons from "../Shared/ActivityIcons";
import BackToTopButton from "../Components/BackToTopButton"
import GreetingCard from "./GreetingCard";
import PropertyTypeBadge from "../Components/PropertyTypeBadge"
import PropertyNewStatusBadge from "../Shared/PropertyNewStatusBadge"
import { BuildingOfficeIcon, ChatBubbleBottomCenterTextIcon, EyeIcon, HomeIcon, MapIcon, UsersIcon, ArrowRightIcon } from "@heroicons/react/24/outline";

export default function Home(props) {

    const { statistics, customers } = props

    const { language, account } = React.useContext(AppContext)
    const history = useHistory()
    const today = new Date();
    const [showAlert, setShowAlert] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showStatusInfo, setShowStatusInfo] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    const [selectedSummaryTab, setSelectedSummaryTab] = useState(0);
    const [showWelcomAtctions, setShowWelcomAtctions] = useState(false)

    const [dateRange, setDateRange] = React.useState(
        {
            from: formatDate(new Date(today.setDate(today.getDate() - 30))),
            to: formatDate(new Date())
        }
    )
    const [query, setQuery] = useState({ page: 1, page_size: 50, start: dateRange.from, end: dateRange.to });

    const session_id = new URLSearchParams(window.location.search).get('session_id')
    const cancel_session_id = new URLSearchParams(window.location.search).get('cancel_session_id')

    const [mostViews, setMostViews] = useState(null);
    const [mostFavorites, setMostFavorites] = useState(null);
    const [activities, setActivities] = useState(null);

    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom' })



    function formatDate(d) {
        var month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const mostViewProperties = () => {
        api.fetchPropertiesFeed(JSON.parse('{"page_size":5,"order":"page_views_popular_high_to_low"}'))
            .then(response => {
                setMostViews(response);
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }

    const mostFavoriteProperties = () => {
        api.fetchPropertiesFeed(JSON.parse('{"page_size":5,"order":"favorite_popular_high_to_low"}'))
            .then(response => {
                setMostFavorites(response);
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }

    function tryPropertyEasy() {
        api.tryPropertyEasy()
            .then(url => {
                window.open(url, "_blank")
            })
            .catch(error => {
                console.log(error);
            })
    }




    const loadActivities = async (q) => {
        api.activities(q)
            .then(response => {
                setActivities(response);
            })
            .catch(error => {
                setShowError(true)
                console.log("error", error)
            })
    }


    React.useEffect(() => {
        if (session_id) {
            api.doneSubscription(session_id)
                .then(response => {
                    Account.updateAccount(response)
                    window.location.href = `/v2/settings/billing`
                })
                .catch(errorResponse => {
                    console.log(errorResponse)

                })
        }
    }, [session_id]);

    React.useEffect(() => {
        // if (dateRange.from !== query.start || dateRange.to !== query.end) {
        setQuery({ ...query, start: dateRange.from, end: dateRange.to })
        // }else {

        // }
    }, [dateRange]);

    React.useEffect(() => {
        mostViewProperties()
        mostFavoriteProperties()
    }, []);

    React.useEffect(() => {
        loadActivities(query)
        history.replace({
            pathname: window.location.pathname,
            search: `?${session_id ? `session_id=${session_id}&` : ""}${cancel_session_id ? `cancel_session_id=${cancel_session_id}&` : ""}query=${JSON.stringify(query)}`
        })
    }, [query])


    const numberOfPropertiesByType = React.useMemo(() => {
        var result = {
            "apartment": 0,
            "house": 0,
            "lot": 0,
        }

        statistics.number_of_properties_by_status.map((t) => {
            const sum = Number(t.active) + Number(t.business_negotiation) + Number(t.sold) + Number(t.inactive) + Number(t.draft)
            result[t.type] = result[t.type] + sum
        })
        return result
    })


    const numberOfPropertiesByStatus = React.useMemo(() => {
        var result = {
            "apartment": { draft: '0', active: '0', business_negotiation: '0', inactive: '0', sold: '0' },
            "house": { draft: '0', active: '0', business_negotiation: '0', inactive: '0', sold: '0' },
            "lot": { draft: '0', active: '0', business_negotiation: '0', inactive: '0', sold: '0' },
        }

        statistics.number_of_properties_by_status.map((t) => {
            result[t.type] = t
        })
        return result
    })


    return (
        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} active="/" isPresented={true} />
            <div className="flex flex-col w-full">

                {/* <div className="sticky top-0  z-50 bg-white">
                    <TopSearchbar {...props} />
                </div> */}

                <div className="lg:flex w-full max-w-screen-2xl mx-auto">

                    {/* starts left column */}
                    {/* <div className="w-60 p-8 hidden lg:block">
                        <div className="sticky top-24">
                            <div className="flex flex-col gap-4 w-full">
                                <button className="bg-slate-200 font-semibold w-full px-3 py-2 rounded inline-flex items-center">
                                    <RssIcon className="w-6 h-6" />
                                    <span className=" ml-2">Feed</span>
                                </button>
                            </div>
                        </div>
                    </div> */}
                    {/* ends left column */}

                    {/* start middle column */}
                    <div className="px-4 lg:px-8 flex-1 flex flex-col gap-4 relative z-40 pt-6">
                        <div className="flex gap-3">
                            {
                                account && account.user ?
                                    <button onClick={() => { tryPropertyEasy() }} className="w-5/6 bg-green-600 text-white px-2 py-2 font-semibold text-xs flex items-center justify-center rounded gap-2">
                                        {Translate("Try PropertEasy For Free")}
                                        <ArrowRightIcon className="w-4 h-4" />
                                    </button>
                                    : null
                            }
                        </div>

                        <GreetingCard showWelcomAtctions={showWelcomAtctions} statistics={statistics} />


                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">

                            <div className="rounded-lg shadow bg-white p-4">
                                <div className="flex items-center mb-4">
                                    <p className="text-sm font-semibold text-slate-500">{Translate("Messages")}</p>
                                    <div className="ml-auto">

                                    </div>
                                </div>
                                <Link to="/v2/customers" className="flex items-center gap-2">

                                    <div className="rounded-full bg-green-100 p-2">
                                        <ChatBubbleBottomCenterTextIcon className="w-6 h-6 text-green-600" />
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <p className="text-2xl font-bold ">{statistics.number_of_messages}</p>
                                        {
                                            Number(statistics.number_of_new_messages) > 0 ?
                                                <Link to="/v2/customers" className="flex items-center">
                                                    <span className="rounded-full text-xs font-medium px-2 py-0.5 bg-green-500 text-white">{statistics.number_of_new_messages}</span>
                                                </Link>
                                                : null
                                        }

                                    </div>
                                </Link>
                            </div>

                            <div className="rounded-lg shadow bg-white p-4">
                                <div className="flex items-center mb-4">
                                    <p className="text-sm font-semibold text-slate-500">{Translate("Page views")}</p>
                                    <div className="ml-auto">

                                    </div>
                                </div>
                                <div className="flex items-center gap-2">
                                    <div className="rounded-full bg-sky-100 p-2">
                                        <EyeIcon className="w-6 h-6 text-sky-600" />
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <p className="text-2xl font-bold ">
                                            {new Intl.NumberFormat('en-US').format(Number(statistics.total_views))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="rounded-lg shadow bg-white p-4">
                                <div className="flex items-center mb-4">
                                    <p className="text-sm font-semibold text-slate-500">{Translate("Customers")}</p>
                                    <div className="ml-auto">

                                    </div>
                                </div>
                                <Link to="/v2/customers" className="flex items-center gap-2">
                                    <div className="rounded-full bg-yellow-100 p-2">
                                        <UsersIcon className="w-6 h-6 text-yellow-600" />
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <p className="text-2xl font-bold ">
                                            {new Intl.NumberFormat('en-US').format(Number(customers?.length))}
                                        </p>
                                        {
                                            Number(statistics.number_of_offers) > 0 ?
                                                <Link to="/v2/offers" className="flex items-center">
                                                    <span className="rounded-full text-xs font-medium px-2 py-0.5 bg-green-500 text-white">{statistics.number_of_offers} {Translate("number_of_offers_suffix")}</span>
                                                </Link>
                                                : null
                                        }
                                    </div>
                                </Link>
                            </div>

                        </div>

                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">

                            <div className="rounded-lg shadow bg-white p-4">
                                <div className="flex items-center mb-4">
                                    <p className="text-sm font-semibold text-slate-500">{Translate("House")}</p>
                                    <div className="ml-auto">
                                        <Link className="text-primary" to={`/v2/properties/wizard/steps?data={"property_type":"house"}`}><PlusIcon className="w-6 h-6" /></Link>
                                    </div>
                                </div>
                                <div className="flex items-center gap-2">
                                    <div className="rounded-full bg-blue-100 p-2">
                                        <HomeIcon className="w-6 h-6 text-blue-600" />
                                    </div>
                                    <div>
                                        <Link to={`/v2/properties?query={"property_types":["house"],"list_status":[]}`} className="text-2xl font-bold">{numberOfPropertiesByType["house"]}</Link>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="flex flex-col items-start gap-2">
                                        <Link to={`/v2/properties?query={"property_types":["house"],"list_status":["draft"]}`} className="flex items-center w-full">
                                            <PropertyNewStatusBadge status="draft" />
                                            <p className="ml-auto font-medium text-slate-500">{new Intl.NumberFormat('en-US').format(Number(numberOfPropertiesByStatus["house"]?.draft))}</p>
                                        </Link>
                                        <Link to={`/v2/properties?query={"property_types":["house"],"list_status":["active"]}`} className="flex items-center w-full">
                                            <PropertyNewStatusBadge status="active" />
                                            <p className="ml-auto font-medium text-slate-500">{new Intl.NumberFormat('en-US').format(Number(numberOfPropertiesByStatus["house"]?.active))}</p>
                                        </Link>
                                        <Link to={`/v2/properties?query={"property_types":["house"],"list_status":["business_negotiation"]}`} className="flex items-center w-full">
                                            <PropertyNewStatusBadge status="business_negotiation" />
                                            <p className="ml-auto font-medium text-slate-500">{new Intl.NumberFormat('en-US').format(Number(numberOfPropertiesByStatus["house"]?.business_negotiation))}</p>
                                        </Link>
                                        <Link to={`/v2/properties?query={"property_types":["house"],"list_status":["sold"]}`} className="flex items-center w-full">
                                            <PropertyNewStatusBadge status="sold" />
                                            <p className="ml-auto font-medium text-slate-500">{new Intl.NumberFormat('en-US').format(Number(numberOfPropertiesByStatus["house"]?.sold))}</p>
                                        </Link>
                                        <Link to={`/v2/properties?query={"property_types":["house"],"list_status":["inactive"]}`} className="flex items-center w-full">
                                            <PropertyNewStatusBadge status="inactive" />
                                            <p className="ml-auto font-medium text-slate-500">{new Intl.NumberFormat('en-US').format(Number(numberOfPropertiesByStatus["house"]?.inactive))}</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="rounded-lg shadow bg-white p-4">
                                <div className="flex items-center mb-4">
                                    <p className="text-sm font-semibold text-slate-500">{Translate("Apartment")}</p>
                                    <div className="ml-auto">
                                        <Link className="text-primary" to={`/v2/properties/wizard/steps?data={"property_type":"apartment"}`}><PlusIcon className="w-6 h-6" /></Link>
                                    </div>
                                </div>
                                <div className="flex items-center gap-2">
                                    <div className="rounded-full bg-violet-100 p-2">
                                        <BuildingOfficeIcon className="w-6 h-6 text-violet-600" />
                                    </div>
                                    <div>
                                        <Link to={`/v2/properties?query={"property_types":["apartment"],"list_status":[]}`} className="text-2xl font-bold">{numberOfPropertiesByType["apartment"]}</Link>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="flex flex-col items-start gap-2">
                                        <Link to={`/v2/properties?query={"property_types":["apartment"],"list_status":["draft"]}`} className="flex items-center w-full">
                                            <PropertyNewStatusBadge status="draft" />
                                            <p className="ml-auto font-medium text-slate-500">{new Intl.NumberFormat('en-US').format(Number(numberOfPropertiesByStatus["apartment"]?.draft))}</p>
                                        </Link>
                                        <Link to={`/v2/properties?query={"property_types":["apartment"],"list_status":["active"]}`} className="flex items-center w-full">
                                            <PropertyNewStatusBadge status="active" />
                                            <p className="ml-auto font-medium text-slate-500">{new Intl.NumberFormat('en-US').format(Number(numberOfPropertiesByStatus["apartment"]?.active))}</p>
                                        </Link>
                                        <Link to={`/v2/properties?query={"property_types":["apartment"],"list_status":["business_negotiation"]}`} className="flex items-center w-full">
                                            <PropertyNewStatusBadge status="business_negotiation" />
                                            <p className="ml-auto font-medium text-slate-500">{new Intl.NumberFormat('en-US').format(Number(numberOfPropertiesByStatus["apartment"]?.business_negotiation))}</p>
                                        </Link>
                                        <Link to={`/v2/properties?query={"property_types":["apartment"],"list_status":["sold"]}`} className="flex items-center w-full">
                                            <PropertyNewStatusBadge status="sold" />
                                            <p className="ml-auto font-medium text-slate-500">{new Intl.NumberFormat('en-US').format(Number(numberOfPropertiesByStatus["apartment"]?.sold))}</p>
                                        </Link>
                                        <Link to={`/v2/properties?query={"property_types":["apartment"],"list_status":["inactive"]}`} className="flex items-center w-full">
                                            <PropertyNewStatusBadge status="inactive" />
                                            <p className="ml-auto font-medium text-slate-500">{new Intl.NumberFormat('en-US').format(Number(numberOfPropertiesByStatus["apartment"]?.inactive))}</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="rounded-lg shadow bg-white p-4">
                                <div className="flex items-center mb-4">
                                    <p className="text-sm font-semibold text-slate-500">{Translate("Land")}</p>
                                    <div className="ml-auto">
                                        <Link className="text-primary" to={`/v2/properties/wizard/steps?data={"property_type":"lot"}`}><PlusIcon className="w-6 h-6" /></Link>
                                    </div>
                                </div>
                                <div className="flex items-center gap-2">
                                    <div className="rounded-full bg-lime-100 p-2">
                                        <MapIcon className="w-6 h-6 text-lime-600" />
                                    </div>
                                    <div>
                                        <Link to={`/v2/properties?query={"property_types":["lot"],"list_status":[]}`} className="text-2xl font-bold">{numberOfPropertiesByType["lot"]}</Link>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="flex flex-col items-start gap-2">
                                        <Link to={`/v2/properties?query={"property_types":["lot"],"list_status":["draft"]}`} className="flex items-center w-full">
                                            <PropertyNewStatusBadge status="draft" />
                                            <p className="ml-auto font-medium text-slate-500">{new Intl.NumberFormat('en-US').format(Number(numberOfPropertiesByStatus["lot"]?.draft))}</p>
                                        </Link>
                                        <Link to={`/v2/properties?query={"property_types":["lot"],"list_status":["active"]}`} className="flex items-center w-full">
                                            <PropertyNewStatusBadge status="active" />
                                            <p className="ml-auto font-medium text-slate-500">{new Intl.NumberFormat('en-US').format(Number(numberOfPropertiesByStatus["lot"]?.active))}</p>
                                        </Link>
                                        <Link to={`/v2/properties?query={"property_types":["lot"],"list_status":["business_negotiation"]}`} className="flex items-center w-full">
                                            <PropertyNewStatusBadge status="business_negotiation" />
                                            <p className="ml-auto font-medium text-slate-500">{new Intl.NumberFormat('en-US').format(Number(numberOfPropertiesByStatus["lot"]?.business_negotiation))}</p>
                                        </Link>
                                        <Link to={`/v2/properties?query={"property_types":["lot"],"list_status":["sold"]}`} className="flex items-center w-full">
                                            <PropertyNewStatusBadge status="sold" />
                                            <p className="ml-auto font-medium text-slate-500">{new Intl.NumberFormat('en-US').format(Number(numberOfPropertiesByStatus["lot"]?.sold))}</p>
                                        </Link>
                                        <Link to={`/v2/properties?query={"property_types":["lot"],"list_status":["inactive"]}`} className="flex items-center w-full">
                                            <PropertyNewStatusBadge status="inactive" />
                                            <p className="ml-auto font-medium text-slate-500">{new Intl.NumberFormat('en-US').format(Number(numberOfPropertiesByStatus["lot"]?.inactive))}</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="flex items-center pt-4">
                            <p className="text-lg font-bold">{Translate("recent_activity_feed")}</p>
                            <div className="ml-auto">
                                <Popover>
                                    <Popover.Button ref={setReferenceElement} className="focus:outline-none">
                                        <div className="flex items-center gap-1 text-sm font-medium">
                                            {/* <FilterIcon className="w-4 h-4 text-slate-400" /> */}
                                            <DateOnly date={dateRange.from} /> – <DateOnly date={dateRange.to} />
                                            <ChevronDownIcon className="w-5 h-5 text-slate-400" />
                                        </div>

                                    </Popover.Button>

                                    <Transition
                                        enter="transition duration-100 ease-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-100 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom="transform scale-100 opacity-100"
                                        leaveTo="transform scale-95 opacity-0"
                                    >
                                        <Popover.Panel
                                            className="bg-white rounded-lg shadow-lg w-96 ring-1 ring-slate-200 p-4"
                                            ref={setPopperElement}
                                            style={styles.popper}
                                            {...attributes.popper}
                                        >

                                            <div className="flex items-center gap-4 w-full justify-between">
                                                <label className="flex flex-col gap-1 w-full">
                                                    <span className="text-xs text-slate-500 font-medium">{TranslateWithLang(language, "report_date_from")}</span>
                                                    <input type="date" className="text-sm rounded border-slate-400" value={dateRange.from} onChange={e => {
                                                        setDateRange({ ...dateRange, from: e.target.value })
                                                    }}></input>
                                                </label>
                                                <label className="flex flex-col gap-1 w-full">
                                                    <span className="text-xs text-slate-500 font-medium">{TranslateWithLang(language, "report_date_to")}</span>
                                                    <input type="date" className="text-sm rounded border-slate-400" value={dateRange.to} onChange={e => {
                                                        setDateRange({ ...dateRange, to: e.target.value })
                                                    }}></input>
                                                </label>
                                            </div>


                                        </Popover.Panel>
                                    </Transition>
                                </Popover>



                            </div>
                        </div>

                        {/* <div className="flex">
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: [], to: "" }) }} >all</a>
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["favorite_property"], to: "" }) }} >favorite</a>
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["unfavorite_property"], to: "" }) }} >unfavorite</a>
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["change_status_property"], to: "" }) }} >change_status_property</a>
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["change_status_property"], to: "sold" }) }} >sold properties</a>

                        </div>
                        <div className="flex">
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["new_offer"], to: "" }) }} >new_offer</a>
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["accept_offer"], to: "" }) }} >accept_offer</a>
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["cancel_offer"], to: "" }) }} >cancel_offer</a>
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["reject_offer"], to: "" }) }} >reject_offer</a>
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["reply_from_agent_offer"], to: "" }) }} >reply_from_agent_offer</a>
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["reply_from_user_offer"], to: "" }) }} >reply_from_user_offer</a>

                        </div>
                        <div className="flex">
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["online_consultation"], to: "" }) }} >online_consultation</a>
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["tour"], to: "" }) }} >tour</a>
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["contact_company"], to: "" }) }} >contact_company</a>
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["new_message"], to: "" }) }} >new_message</a>
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["new_link"], to: "" }) }} >new_link</a>
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["new_message_from_agent"], to: "" }) }} >new_message_from_agent</a>


                        </div>

                        <div className="flex">
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["reply_mail"], to: "" }) }} >reply_mail</a>
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["mark_as_reply_mail"], to: "" }) }} >mark_as_reply_mail</a>
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["auto_reply_mail"], to: "" }) }} >auto_reply_mail</a>
                        </div>

                        <div className="flex">
                            <a className="text-xs font-bold mr-4" href="#" onClick={e => { setQuery({ ...query, events: ["new_customer"], to: "" }) }} >new_customer</a>
                        </div> */}




                        <div className="flex flex-col gap-4">
                            {
                                activities && activities.items ? activities.items.map((activity, index) => (
                                    <div key={activity.id} className="bg-white rounded-lg shadow">
                                        <ActivityCard key={activity.id} activity={activity}></ActivityCard>
                                    </div>
                                ))
                                    :

                                    <div className="text-center p-8">
                                        <div className="flex gap-4 items-center justify-center mb-4">
                                            <ActivityIcons event="new_message" />
                                            <ActivityIcons event="favorite_property" />
                                            <ActivityIcons event="change_status_property" />
                                        </div>
                                        <p className="font-medium">No recent acvitity</p>
                                        <p className="text-slate-500">Get started by creating a new property</p>
                                    </div>
                            }
                        </div>

                        {activities && activities.items &&

                            <div id="pager" className="w-full">
                                <div className="mb-4 flex items-center justify-between">
                                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                        <div>
                                            {activities.total_item === 0 ?
                                                <p className="text-sm leading-5 text-gray-700">{TranslateWithLang(language, "No result")}</p>
                                                :
                                                <p className="text-sm leading-5 text-gray-700">
                                                    {TranslateWithLang(language, "Showing")}<span className="font-medium px-1">{activities.from}-{activities.to} {TranslateWithLang(language, "Total")} {activities.total_item}</span>{TranslateWithLang(language, "results")}
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="py-6 container mx-auto flex items-center">
                            <BackToTopButton />
                        </div>
                    </div>
                    {/* ends middle column */}

                    {/* starts right column */}
                    <div className="w-96 pr-8 hidden lg:block z-10">
                        <div className="sticky top-6">


                            {/* Viila news */}
                            {/* <div className="w-full shadow rounded-lg p-4 bg-white mb-4">
                                <p className="font-semibold mb-2">Viila news</p>
                                <p>Agent Dashboard V2 is now publicly available!</p>
                            </div> */}
                            {/* ends Viila news */}


                            <Tab.Group defaultIndex={0}>
                                <Tab.List className="w-full flex gap-1 items-center justify-between rounded-lg bg-slate-200 overflow-hidden p-1 mb-1">
                                    <Tab className="flex items-center justify-center w-full focus:outline-none">
                                        {({ selected }) => (
                                            <div className={`rounded-lg w-full h-full py-2 text-sm font-medium 
                                                ${selected ? 'text-primary bg-white shadow' : 'text-slate-400 hover:bg-slate-100/60'}
                                                `}>
                                                {Translate("Most viewed")}
                                            </div>
                                        )}
                                    </Tab>
                                    <Tab className="flex items-center justify-center w-full focus:outline-none">
                                        {({ selected }) => (
                                            <div className={`rounded-lg w-full h-full py-2 text-sm font-medium 
                                                ${selected ? 'text-primary bg-white shadow' : 'text-slate-400 hover:bg-slate-100'}
                                                `}>
                                                {Translate("Most favorited")}
                                            </div>
                                        )}
                                    </Tab>
                                </Tab.List>
                                <Tab.Panels>
                                    <Tab.Panel>
                                        <div className="bg-white rounded-lg shadow p-4 w-full">
                                            <div className=" mb-3 flex items-center">
                                                <div className="p-2 bg-green-100 rounded-full">
                                                    <ArrowTrendingUpIcon className="text-green-600 h-5 w-5" />
                                                </div>
                                                <div className="ml-2">
                                                    <p className="font-bold">{Translate("Most viewed properties")}</p>
                                                    <p className="text-gray-600 text-xs">{Translate("Last 30 days")}</p>
                                                </div>

                                            </div>
                                            <div className="flex flex-col gap-1">
                                                {
                                                    mostViews && mostViews.items === null &&
                                                    <EmptyStateWithCreateNewPropertyAction />
                                                }
                                                {
                                                    (mostViews && mostViews.items) && mostViews.items.map((property, index) => (
                                                        <Link key={property.id} className="flex items-start gap-4 hover:bg-slate-100 p-2 rounded" to={`/v2/properties/wizard/${property.property_id}/summary`}>
                                                            <div className="text-gray-500 text-sm">
                                                                {index + 1}
                                                            </div>
                                                            <div className="truncate overflow-ellipsis pr-4">
                                                                <p className="text-sm truncate overflow-ellipsis font-medium">{property.name}</p>
                                                                <p className="text-xs text-gray-500">{TranslateWithLang(language, property.property_type)} · {property.property_id}</p>
                                                            </div>
                                                            <div className="ml-auto text-sm font-bold">
                                                                {new Intl.NumberFormat('en-US').format(Number(property.page_view_popular))}
                                                            </div>
                                                        </Link>
                                                    ))
                                                }


                                            </div>
                                        </div>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <div className="bg-white rounded-lg shadow p-4 w-full">
                                            <div className="mb-3 flex items-center">
                                                <div className="p-2 bg-red-100 rounded-full">
                                                    <HeartIcon className="text-red-500 h-5 w-5" />
                                                </div>
                                                <div className="ml-2">
                                                    <p className="font-bold">{Translate("Most favorited properties")}</p>
                                                    <p className="text-gray-600 text-xs">{Translate("All time")}</p>
                                                </div>
                                            </div>
                                            <div className="flex flex-col gap-1">

                                                {
                                                    mostFavorites && mostFavorites.items === null &&
                                                    <EmptyStateWithCreateNewPropertyAction />
                                                }
                                                {mostFavorites && mostFavorites.items ? mostFavorites.items.map((property, index) => (
                                                    <Link key={property.id} className="flex items-start gap-4 hover:bg-slate-100 p-2 rounded" to={`/v2/properties/wizard/${property.property_id}/summary`}>
                                                        <div className="text-gray-500 text-sm">
                                                            {index + 1}
                                                        </div>
                                                        <div className="truncate overflow-ellipsis">
                                                            <p className="text-sm truncate overflow-ellipsis font-medium pr-4">{property.name}</p>
                                                            <p className="text-xs text-gray-500">{TranslateWithLang(language, property.property_type)} · {property.property_id}</p>
                                                        </div>
                                                        <div className="ml-auto text-sm font-bold">
                                                            {new Intl.NumberFormat('en-US').format(Number(property.number_of_favorites))}
                                                        </div>
                                                    </Link>
                                                )) : null}
                                            </div>
                                        </div>
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>

                        </div>
                    </div>
                    {/* ends right column */}
                </div>

            </div>
        </div>
    )
}