import React, { useState, useEffect } from "react"
import { useFieldArray } from "react-hook-form"
import { Translate } from "../../../translate/Translate";
import InputField from "./InputField";
import NumberInputField from "./NumberInputField";
import FeatureField from "./FeatureField";
import FeatureList from "./FeatureList";
import CostField from "./CostField";
import TaxField from "./TaxField";
import clsx from "clsx";

export default function PropertyDetailForm(props) {
    const { featureList, data, setData, useFormReturn, className } = props
    const { setValue, watch, getValues, control, formState: { errors }, clearErrors } = useFormReturn

    const { fields: featureFields, append: appendFeature, remove: removeFeature, move, replace } = useFieldArray({
        control,
        name: "features"
    });
    const { fields: apartmentFeatures } = useFieldArray({
        control,
        name: "apartments"
    });
    const { fields: costsFields, append: appendCost, remove: removeCost } = useFieldArray({
        control,
        name: "other_costs"
    });

    const { fields: taxFields, append: appendTax, remove: removeTax } = useFieldArray({
        control,
        name: "tax_histories"
    });

    const { fields: taxDelFields, append: appendTaxDel, remove: removeTaxDel } = useFieldArray({
        control,
        name: "tax_histories_delete"
    });

    const addPropertyFeature = () => {
        let feature = { index: 0, title: "", value: "" }
        //default data to first in the list of feature list
        let first = props.featureList[0];
        feature.data = first
        feature.key = first.key;
        feature.title = first.title;
        feature.index = first.index;
        feature.type = first.type;
        if (first.values.length > 0) {
            feature.value = first.values[0].value;
        }
        feature.addbyuser = true
        appendFeature(feature)
    }

    const removePropertyFeature = (index, feature) => {
        removeFeature(index)
    }

    const addCost = () => {
        appendCost({
            title: "",
            description: "",
            type: "one-time",
            price: {
                amount: "",
                currency: "JPY"
            }
        })
    }


    const addTax = () => {
        var firstYear = ""
        var start_year = new Date().getFullYear();
        for (var i = start_year; true; i--) {
            if (taxFields.find(d => d.year === i.toString()))
                continue;

            firstYear = i.toString()
            break;
        }
        appendTax({
            year: firstYear,
            remarks: "",
            price: {
                amount: "",
                currency: "JPY"
            }
        })
    }

    const removeOtherCost = (index) => {
        removeCost(index)
    }

    const removeTaxHistories = (index) => {
        removeTax(index)
        if (taxFields) {
            var tax = taxFields.at(index)
            if (tax)
                appendTaxDel(tax)
        }
    }

    return (
        <div className={clsx("bg-white flex flex-col h-full overflow-y-scroll", className)}>
            <div className="grid grid-cols-1 text-sm bg-white">
                <div className="p-4">
                    <div className="flex items-center">
                        <div>
                            <p className="text-xl font-medium">{Translate("Property details")}</p>
                            <p className="text-sm text-gray-700">{Translate("Details of this property. e.g. Architecture, Zoning, Terrain, etc.")}</p>
                        </div>
                    </div>
                </div>
                <div className="p-4">
                    <div className="col-span-1">

                        {
                            data.property_type === "lot" ? null :

                                <div className="grid grid-cols-3 mb-12">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Layout")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <div className="grid grid-cols-2 gap-y-2 gap-x-4">
                                            <div className="col-span-2">
                                                <label htmlFor="layout.name" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Layout title")}</label>
                                                <InputField control={control} errors={errors} clearErrors={clearErrors}
                                                    required={data.property_type === "house" || data.property_type === "apartment"}
                                                    name="layout.name" id="layout-name"
                                                    placeholder={Translate("3 bedrooms with storage")} className="rounded w-full"
                                                />
                                            </div>
                                            <div className="col-span-1">
                                                <label htmlFor="layout.bedrooms" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Bedrooms")}</label>
                                                <NumberInputField control={control} errors={errors} clearErrors={clearErrors}
                                                    required={data.property_type === "house" || data.property_type === "apartment"}
                                                    name="layout.bedrooms" id="layout-bedrooms"
                                                    placeholder="3" className="rounded w-full"
                                                />
                                            </div>
                                            <div className="col-span-1">
                                                <label htmlFor="layout.bedrooms" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Bathrooms")}</label>
                                                <NumberInputField control={control} errors={errors} clearErrors={clearErrors}
                                                    required={data.property_type === "house" || data.property_type === "apartment"}
                                                    name="layout.bathrooms" id="layout-bathrooms"
                                                    placeholder="1" className="rounded w-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                        }

                        <div className="grid grid-cols-3">
                            <div className="col-span-3 lg:col-span-1">
                                <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Features")}</p>
                            </div>

                            <div className="col-span-3 lg:col-span-2">
                                {
                                    apartmentFeatures ? apartmentFeatures.map((field, index) => <FeatureField {...props} field={watch(`apartments.${index}`)} key={field.id} index={index} featureList={featureList} disabled={true} />) : null
                                }
                                {featureFields && <FeatureList {...props} featureFields={featureFields} replace={replace} featureList={featureList} useFormReturn={useFormReturn} removePropertyFeature={removePropertyFeature} />}
                                <button type="button" id="add-feature" className="focus:outline-none text-xs font-medium text-indigo-600 mb-2" onClick={(e) => {
                                    addPropertyFeature()
                                }}>+ {Translate("Add feature")}</button>
                            </div>
                        </div>


                        {/* <div className="grid grid-cols-3 mt-8">
                        <div className="col-span-3 lg:col-span-1">
                            <p className="text-lg font-medium">{Translate("Other Costs")}</p>
                        </div>
                        <div className="col-span-3 lg:col-span-2">
                            <p className="text-sm text-gray-700">{Translate("Beside the standard taxes and brokerage fees, Please list other necessary costs which buyer needs to pay. e.g. Management fee, Water supply reserve, etc.")}</p>
                        </div>
                    </div>

                    <div className="grid grid-cols-3 mt-4">
                        <div className="col-span-3 lg:col-span-1">
                            <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Costs")}</p>
                        </div>
                        <div className="col-span-3 lg:col-span-2">
                            {costsFields.map((cost, index) => <CostField key={index} index={index} useFormReturn={useFormReturn} removeOtherCost={removeOtherCost} />)}
                            <button type="button" id="add-cost" onClick={(e) => addCost()} className="focus:outline-none text-xs font-medium text-indigo-600 mb-2">+ {Translate("Add cost")}</button>
                        </div>
                    </div> */}

                    </div>
                </div>
            </div >
            <div className={clsx("grid grid-cols-1 text-sm rounded-b-md bg-white", className)}>
                <div className="p-4">
                    <div className="flex items-center">
                        <div>
                            <p className="text-lg font-medium">{Translate("Other Costs")}</p>
                            <p className="text-sm text-gray-700">{Translate("Beside the standard taxes and brokerage fees, Please list other necessary costs which buyer needs to pay. e.g. Management fee, Water supply reserve, etc.")}</p>
                        </div>
                    </div>
                </div>
                <div className="p-4">
                    <div className="col-span-1 py-4">
                        <div className="grid grid-cols-3">
                            <div className="col-span-3 lg:col-span-1">
                                <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Costs")}</p>
                            </div>
                            <div className="col-span-3 lg:col-span-2">
                                {costsFields ? costsFields.map((cost, index) => <CostField key={cost.id} index={index} useFormReturn={useFormReturn} removeOtherCost={removeOtherCost} />) : null}
                                <button type="button" id="add-cost" onClick={(e) => addCost()} className="focus:outline-none text-xs font-medium text-indigo-600 mb-2">+ {Translate("Add cost")}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className={clsx("grid grid-cols-1 text-sm rounded-b-md bg-white", className)}>
                <div className="p-4">
                    <div className="flex items-center">
                        <div>
                            <p className="text-lg font-medium">{Translate("tax_history_title")}</p>
                        </div>
                    </div>
                </div>
                <div className="p-4">
                    <div className="col-span-1 py-4">
                        <div className="grid grid-cols-3">
                            <div className="col-span-3 lg:col-span-1">

                            </div>
                            <div className="col-span-3 lg:col-span-2">
                                {taxFields ? taxFields.map((tax, index) => <TaxField data={taxFields} key={`${tax.year}_${index}`} index={index} useFormReturn={useFormReturn} removeTaxHistories={removeTaxHistories} />) : null}
                                <button type="button" id="add-tax" onClick={(e) => addTax()} className="focus:outline-none text-xs font-medium text-indigo-600 mb-2">+ {Translate("add_tax_button")}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}