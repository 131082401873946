export default function FilterItem(props) {
    const {Translate, name, filterRequest, onChange, setFilterRequest, options, all} = props
    return (<div className="flex flex-col">
        <div className="font-medium mb-2">{Translate(name)}</div>
        <div className="flex items-center gap-x-4 gap-y-2 flex-wrap">
            {all && <label className="flex items-center" htmlFor={`${name}-option-all`}>
                <input type="checkbox" value="" name={name} id={`${name}-option-all`} checked={filterRequest[name].length === options[name].length} onChange={(e) => {
                    setFilterRequest((data) => {
                        if (e.target.checked) {
                            data[name] = options[name]
                        } else {
                            data[name] = []
                        }
                        return {...data}
                    })
                    onChange()
                }} className="mr-2 rounded accent-primary focus:outline-none focus:ring-0 border-2 border-slate-400"  />
                <div id={`${name}-option-all-label`} className="font-medium flex items-center text-sm rounded whitespace-nowrap">
                    <span className="hidden lg:block">All</span>
                </div>
            </label>}
            {options[name].map((o, i) => (<label key={i} className="flex items-center" htmlFor={`${name}-option-${o}`}>
                <input type="checkbox" value={o} name={name} id={`${name}-option-${o}`} checked={name === "layout" ? filterRequest[name].filter((i) => {
                    console.log("i",i, i.includes(o), o)
                    return i.includes(o)
                }).length: filterRequest[name].includes(o)} onChange={(e) => {
                    setFilterRequest((data) => {
                        if (e.target.checked) {
                            data[name].push(o)
                        } else {
                            const index = data[name].indexOf(o)
                            data[name].splice(index, 1)
                        }
                        return {...data}
                    })
                    onChange()
                }} className="mr-2 rounded accent-primary focus:outline-none focus:ring-0 border-2 border-slate-400"  />
                <div id={`${name}-option-${o}-label`} className="font-medium flex items-center text-sm rounded whitespace-nowrap">
                    <span className="hidden lg:block">{o}</span>
                </div>
            </label>))}
        </div>
    </div>)
}