import { Fragment, useEffect, useState } from 'react'
import { Translate } from "../../translate/Translate";
import { Link } from "react-router-dom";
import DateTime from '../Shared/DateTime';
import FullName from '../Shared/FullName';
import ViilaLogo from '../Shared/ViilaLogo'
import * as Constants from '../../../constants'
import { Popover, Transition } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { InformationCircleIcon, EllipsisHorizontalIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { PropertyStatusBadge } from "../Shared/PropertyStatusBadge"

export default function ActivityNormal(props) {
    const { activity } = props;

    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom' })

    function renderBadge(status, to) {
        switch (status) {
            case 'set_pin_property':
                return (to === "true" ?
                    <div className='flex'>
                        <svg className="w-4 h-4 fill-current text-primary" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">1<path d="M0.326172 8.58008C0.326172 9.07812 0.660156 9.40625 1.1875 9.40625H4.35742V12.1426C4.35742 13.0391 4.73242 13.7832 4.87891 13.7832C5.01953 13.7832 5.39453 13.0391 5.39453 12.1426V9.40625H8.56445C9.0918 9.40625 9.42578 9.07812 9.42578 8.58008C9.42578 7.34375 8.43555 6.03711 6.78906 5.43945L6.5957 2.75C7.45117 2.26367 8.1543 1.71289 8.45898 1.32031C8.61133 1.12109 8.6875 0.921875 8.6875 0.746094C8.6875 0.388672 8.41211 0.125 8.00195 0.125H1.75586C1.33984 0.125 1.07031 0.388672 1.07031 0.746094C1.07031 0.921875 1.14062 1.12109 1.29297 1.32031C1.59766 1.71289 2.30078 2.26367 3.15625 2.75L2.96289 5.43945C1.31641 6.03711 0.326172 7.34375 0.326172 8.58008Z"></path></svg>
                        <p className='text-xs'>{Translate("Pinned")}</p>
                    </div>
                    :
                    <div className='flex'>
                        <svg className="text-red-600 fill-current w-4 h-4" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">2<path d="M4.01562 0.5C3.46094 0.5 3.10156 0.851562 3.10156 1.32812C3.10156 1.55469 3.1875 1.8125 3.39062 2.07812C3.78906 2.60938 4.73438 3.35156 5.88281 4L5.8125 5.02344L13.5859 12.7891C14 12.625 14.2422 12.2656 14.2422 11.7734C14.2422 10.125 12.9219 8.38281 10.7266 7.58594L10.4688 4C11.6094 3.35156 12.5469 2.625 12.9453 2.10156C13.1562 1.82812 13.2578 1.5625 13.2578 1.32812C13.2578 0.851562 12.8906 0.5 12.3438 0.5H4.01562ZM14.4922 16.7656C14.7344 17 15.1172 17 15.3438 16.7656C15.5781 16.5312 15.5859 16.1484 15.3438 15.9141L1.22656 1.80469C0.992188 1.57031 0.601562 1.5625 0.367188 1.80469C0.140625 2.03125 0.140625 2.42188 0.367188 2.65625L14.4922 16.7656ZM2.10938 11.7734C2.10938 12.4375 2.55469 12.875 3.25781 12.875H7.49219V16.5234C7.49219 17.7188 7.98438 18.7109 8.17969 18.7109C8.375 18.7109 8.86719 17.7188 8.86719 16.5234V12.9844H9.32031L4.50781 8.15625C3 9.03906 2.10938 10.4297 2.10938 11.7734Z" /></svg>
                        <p className='text-xs'>{Translate("Unpinned")}</p>
                    </div>
                );
            case 'delete_property':
                return <span className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-red-100 text-red-600">{Translate(status)}</span>;
            default:
                return <span className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-blue-100 text-blue-600">{Translate(status)}</span>;
        }
    }

    return (
        <div key={activity.id} className="flex flex-col items-start  w-full">
            <div className='w-full p-4 flex items-start'>
                <div className='flex items-center gap-2'>
                    <div className="w-10 h-10 rounded-full bg-slate-100 flex items-center justify-center">
                        <InformationCircleIcon className='w-5 h-5 text-slate-400' />
                    </div>
                    <div>
                        <p className="font-medium leading-none mb-1">
                            <FullName firstname={activity.user.first_name} lastname={activity.user.last_name}></FullName>
                        </p>
                        <p className='text-xs'><DateTime date={activity.created_at} /> · {Translate("Team")}</p>
                    </div>
                </div>
                <div className='ml-auto'>
                    {activity.property.property_id ?
                        <Popover>
                            <Popover.Button ref={setReferenceElement} className="focus:outline-none">
                                <EllipsisHorizontalIcon className='w-6 h-6 text-slate-400' />
                            </Popover.Button>

                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                            >
                                <Popover.Panel
                                    className="bg-white rounded-lg shadow-lg w-56 ring-1 ring-slate-200"
                                    ref={setPopperElement}
                                    style={styles.popper}
                                    {...attributes.popper}
                                >
                                    <div className=''>
                                        <div className='p-2 w-full'>
                                            <a target="_blank" href={`${Constants.BASE_URL}/p/${activity.property_no}`} className="flex items-center gap-1 text-sm">
                                                <span>View {activity.property.property_id} on Viila</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </Popover>
                        : null}
                </div>
            </div>

            <div className='w-full px-4 pb-4'>
                {<a href={activity.dashboard_url} rel="_blank" target="_blank">
                    <div className='flex items-center gap-4'>
                        {activity.property.property_id ?
                            <div className='flex-none w-20 h-20 border rounded bg-slate-200'>
                                {
                                    activity.property.images[0].url ?
                                        <img className="w-full h-full object-cover" src={activity.property.images ? activity.property.images[0].url : ""} />
                                        : null
                                }
                            </div> : null}
                        <div>
                            <p className='font-semibold text-sm mb-1'>
                                {activity.property ? activity.property.property_id : ""}
                            </p>
                            <div className='flex items-center gap-2'>
                                {renderBadge(activity.event, activity.log?.to)}
                            </div>

                            {activity.log && activity.log.message ? <p>{Translate("Remark")} : {activity.log.message}</p> : null}
                        </div>

                    </div>
                </a>}
            </div>

            <div className='w-full'>
                {/* footer */}
            </div>
        </div>

    )
}