import React from "react"
import SideNavBar from "../SideNavBar";
import TopSearchbar from "../TopSearchbar";
import SettingsNavBar from "./SettingsNavBar";
import * as Account from '../../../account'
import * as auth from '../../services/auth'

export default function SettingsPassword(props) {
    const {Translate} = props
    var msg = Translate("Reset password link has been sent to your email address.")
    const resetPassword = () => {
        auth.resetPassword(Account.getUserAccount().user.email)
            .then(response => {
                console.log(response)
                alert(msg)
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (

        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} isPresented={true} />
            <div className="flex flex-col w-full relative">

                <div className="bg-white">
                    <div className="w-full container mx-auto lg:px-8 px-4">

                        <div className="py-8">
                            <p className="text-3xl font-bold">{Translate("Settings")}</p>
                        </div>

                    </div>
                </div>
                <div className="lg:p-8 p-4 flex items-center container mx-auto">
                    {/* content starts here */}

                    <div className="w-full">
                        <div className="mb-4">

                            <p className="text-2xl font-semibold">{Translate("Password")}</p>
                        </div>

                        <SettingsNavBar active="password"></SettingsNavBar>

                        <div className="flex w-full shadow rounded-lg bg-white overflow-hidden">
                            <form className="w-full">
                                <div className="grid grid-cols-1 divide-y text-sm">
                                    <div className="col-span-1 p-4 lg:p-8">
                                        <p className="text-xl font-medium">{Translate("Password")}</p>
                                        <p className="text-sm text-gray-500"></p>
                                    </div>

                                    <div className="col-span-1 py-4 lg:py-4 px-6  ">
                                        <button type="button" onClick={resetPassword} className="btn btn-primary">{Translate("Reset password")}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    {/* content ends here */}
                </div>
            </div>
        </div>
    )
}