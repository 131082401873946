export const parseContext = (feature) => {
    var address = {full_address:feature.properties.address || feature.properties.place_name,address_1:feature.properties.address_number}

    var city = feature.properties.context.find(c=>c.layer.includes("locality"))
    if (city)
        address.city = city.name

    var district =  feature.properties.context.find(c=>c.layer.includes("place"))
    if (district)
        address.district = district.name

    var cityWithDistrict =  feature.properties.context.find(c=>c.layer.includes("city") && c.name.match(/(.*)\((.*)\)/))
    if (cityWithDistrict){
        let [, district, city] = cityWithDistrict.name.match(/(.*)\((.*)\)/)
        address.district = city
        address.city = district
    }

    var prefecture =  feature.properties.context.find(c=>c.layer.includes("region") || c.layer.includes("prefecture"))
    if (prefecture)
        address.prefecture = prefecture.name

    var postcode =  feature.properties.context.find(c=>c.layer.includes("postcode"))
    if (postcode)
        address.zip_code = postcode.name
    
    var countryCode =  feature.properties.context.find(c=>c.layer.includes("country"))
    if (countryCode)
        address.country_code = countryCode.name

    // console.log("parseContext", feature.properties.context, address)
    return address

}