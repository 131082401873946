import React from "react"
import { XMarkIcon } from "@heroicons/react/24/solid";

export function TimelineStepDeclined(props) {
    return <div className="flex flex-col items-center justify-center w-full relative">
        <div className="z-20 flex-none w-6 h-6 bg-red-500 rounded-full flex items-center justify-center">
            <XMarkIcon className="w-4 h-4 text-white" />
        </div>
        {!props.is_last ? <div className="z-10 h-0.5 w-1/2 bg-red-500 absolute right-0"></div> : null}
        {!props.is_first ? <div className="z-10 h-0.5 w-1/2 bg-red-500 absolute left-0"></div> : null}
    </div>
}