
import React from "react"
import SelectedPropertyRow from './SelectedPropertyRow'
import { Translate } from "../../translate/Translate";
import * as api from '../../services/agent'
import * as Constants from '../../../constants'

export function SendToCustomerForm(props) {
    const {
        onSuccess,
        collection
    } = props
    const [customer, setCustomer] = React.useState({});
    const [data, setData] = React.useState({});

    const createMapLink = () => {
        let d = {
            properties : collection && collection.properties ? collection.properties.map(function(c) { return c.property_id;}) : [],
            data : data,
            customers : customer && Object.keys(customer).length === 0  && Object.getPrototypeOf(customer) === Object.prototype ? [] : [customer],
        }
        api.saveCollection(d)
        .then(response => {
          onSuccess(response) //we can pass result from api to here
        })
        .catch(error => {
          console.log(error);
        })
    }
    return (
        <div className=" flex flex-col gap-2 p-4 transition duration-500 ease-in-out">
            <label className="block">
                <span className="text-gray-500 text-sm font-medium">{Translate("Email")}</span>
                <input
                    name="email"
                    type="email"
                    className="mt-1 rounded w-full"
                    placeholder=""
                    required   
                    onChange={e => {
                        customer.email = e.target.value
                        setCustomer(customer)
                    }}
                />
            </label>
            <label className="block">
                <span className="text-gray-500 text-sm font-medium">{Translate("Message")}</span>
                <textarea
                    className="mt-1 rounded w-full"
                    placeholder=""
                    required
                    onChange={e => {
                        data.message = e.target.value
                        setData(data)
                    }}
                />
            </label>
            <button onClick={(e)=>createMapLink()} className="w-full py-2 bg-blue-600 font-medium text-white rounded">Send Map</button>
        </div>
    )
}

export function MapLinkForm(props) {
    const {
        onSuccess,
        collection,
    } = props

    const createMapLink = () => {
        let d = {
            properties : collection && collection.properties ? collection.properties.map(function(c) { return c.property_id;}) : [],
            customers : [],
        }
        api.saveCollection(d)
        .then(response => {
          onSuccess(response) //we can pass result from api to here
        })
        .catch(error => {
          console.log(error);
        })
    }

    return (
        <div className=" flex flex-col gap-2 p-4 transition duration-500 ease-in-out">
            <button onClick={(e)=>{createMapLink()}} className="w-full py-2 bg-blue-600 font-medium text-white rounded">{Translate("create_a_map_link_button")}</button>
        </div>
    )
}

export function CreateMapSuccess(props) {
    const {
        onClose,
        createMapLinkResponse
    } = props

    return (
        <div className="w-ful h-full bg-white flex flex-col items-center p-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-blue-600 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <p className="text-xl font-semibold mb-4">{Translate("map_link_created_title")}</p>
            <p className="p-2 bg-gray-50 rounded text-sm  text-gray-600 mb-8">
                <a target="_blank" href={`${Constants.BASE_URL}/maps/${createMapLinkResponse.id}`}>{`${Constants.BASE_URL}/maps/${createMapLinkResponse.id}`}</a>
            </p>
            

            <button onClick={onClose} className="bg-blue-600 font-semibold text-white py-2 w-full rounded">{Translate("close_button")}</button>
        </div>
    )
}

export default function SelectedPropertiesPanel(props) {
    const {
        onClose,
        onRemovedFromCollection,
        collection
    } = props
    const properties = collection.properties

    const customerForm = "customerForm"
    const maplinkForm = "maplinkForm"

    const [selectedForm, setSelectedForm] = React.useState(customerForm)

    
    const [createMapLinkResponse, setCreateMapLinkResponse] = React.useState(null)

    const onCreateMapLinkSuccess = (result) => {
        setCreateMapLinkResponse(result)
    }

    return (
        <div className="flex-none w-80 h-full bg-white flex flex-col items-center relative">
            {
                createMapLinkResponse !== null ?
                <div className="absolute w-full h-full">
                    <CreateMapSuccess createMapLinkResponse={createMapLinkResponse} onClose={props.onClose}></CreateMapSuccess>
                </div>
                : null
            }
            
            <div className="flex-none flex items-center px-4 py-2 w-full border-b">
                <p className="text-lg font-medium">{Translate("collection_panel_title")}&nbsp;({properties.length})</p>
            </div>
            <div className="w-full flex flex-col divide-y px-4 overflow-y-auto">
                {
                    properties.map((p) => (
                        <SelectedPropertyRow onRemovedFromCollection={props.onRemovedFromCollection} property={p}></SelectedPropertyRow>
                    ))
                }
            </div>
            <div className="mt-auto flex-none border-b border-t w-full">
                {/* <div className="flex items-center justify-between border-b px-4">
                    <button onClick={(e)=> {setSelectedForm(customerForm)}} className={`w-full text-sm font-semibold h-full py-2 
                    ${selectedForm === customerForm ? "text-blue-600 border-b-2 border-blue-600" : "text-gray-500"}`}
                    >
                        Send to customer
                        </button>
                    <button onClick={(e)=> {setSelectedForm(maplinkForm)}} 
                    className={`w-full text-sm font-semibold h-full py-2 
                    ${selectedForm === maplinkForm ? "text-blue-600 border-b-2 border-blue-600" : "text-gray-500"}`}
                    >
                        Map Link
                    </button>
                </div>

                {
                    selectedForm === customerForm ?
                    <SendToCustomerForm></SendToCustomerForm>
                    : null
                }
                                
                                {
                    selectedForm === maplinkForm ?
                    <MapLinkForm></MapLinkForm>
                    : null
                } */}

                {/* <SendToCustomerForm collection={collection} onSuccess={onCreateMapLinkSuccess}></SendToCustomerForm>
                <div className="text-center text-gray-400 text-xs">or</div> */}
                <MapLinkForm collection={collection} onSuccess={onCreateMapLinkSuccess}></MapLinkForm>
            </div>
        </div>
    );
}