import { Fragment, useEffect, useState } from 'react'
import { Translate } from "../../translate/Translate";
import { Link } from "react-router-dom";
import DateTime from '../Shared/DateTime';
import FullName from '../Shared/FullName';
import ViilaLogo from '../Shared/ViilaLogo'
import * as Constants from '../../../constants'
import { Popover, Transition } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { HeartIcon, XMarkIcon,EllipsisHorizontalIcon } from '@heroicons/react/24/solid'

export default function ActivityFavorite(props) {
    const { activity } = props;

    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom' })

    return (


        <div key={activity.id} className="flex flex-col items-start  w-full">
            <div className='w-full p-4 flex items-center'>

                <div className='flex items-start w-full gap-2'>
                    <div className='flex items-center gap-2'>
                        <div className={`w-10 h-10 rounded-full ${activity.event == "favorite_property" ? "bg-red-100" : "bg-gray-100"}  flex items-center justify-center`}>
                        {activity.event == "favorite_property" ?
                    
                    <HeartIcon className='w-5 h-5 text-red-400'></HeartIcon>
                        :
                        <XMarkIcon className='w-5 h-5 text-slate-400'/>
                    }
                            {/* {
                                activity.customer_no ?
                                    <div className='relative'>
                                        <img className="w-full h-full rounded-full object-cover" src={activity.image_url} />
                                        <HeartIcon className='w-6 h-6 absolute bottom-0 right-0 p-1 bg-white rounded'/>
                                    </div>
                                    :
                                    <div className='w-full h-full rounded-full bg-white border border-slate-400 flex items-center justify-center'>
                                        <ViilaLogo className="h-4 w-4 fill-current text-slate-400"></ViilaLogo>
                                    </div>
                            } */}
                        </div>

                        <div className="flex flex-col">
                            {
                                activity.customer_no ?
                                    <Link to={`/v2/customers/${activity.customer_no}/profile`}>
                                        <div className='flex flex-col'>
                                            <div className="font-medium text-primary flex items-center gap-1">
                                                <p className='leading-none mb-1'>
                                                    <FullName firstname={activity.user.first_name} lastname={activity.user.last_name}></FullName>
                                                </p>
                                            </div>
                                            <p className="text-xs">
                                                <DateTime date={activity.created_at} />
                                                <span> · {activity.user.email}</span>
                                                <span> · {activity.customer_no}</span>
                                            </p>
                                        </div>
                                    </Link>
                                    :
                                    <div className='flex flex-col'>
                                        <div className="font-medium text-primary flex items-center gap-1">
                                            <p className='leading-none mb-1'>
                                                <FullName firstname={activity.user.first_name} lastname={activity.user.last_name}></FullName>
                                            </p>
                                        </div>
                                        <p className="text-xs">
                                            <DateTime date={activity.created_at} />
                                            <span> · {activity.user.email}</span>
                                        </p>
                                    </div>
                            }

                        </div>
                    </div>
                    <div className='ml-auto flex items-start'>


                        <Popover>
                            <Popover.Button ref={setReferenceElement} className="focus:outline-none">
                                <EllipsisHorizontalIcon className='w-6 h-6 text-slate-400'/>
                            </Popover.Button>

                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                            >
                                <Popover.Panel
                                    className="bg-white rounded-lg shadow-lg w-56 ring-1 ring-slate-200"
                                    ref={setPopperElement}
                                    style={styles.popper}
                                    {...attributes.popper}
                                >
                                    <div className=''>
                                        <div className='p-2 w-full'>
                                            <a target="_blank" href={`${Constants.BASE_URL}/p/${activity.property_no}`} className="flex items-center gap-1 text-sm">
                                                <span>View {activity.property.property_id} on Viila</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </Popover>

                    </div>
                </div>

            </div>

            <div className='w-full px-4 pb-4'>

                <Link to={activity.dashboard_url} target="_blank" rel="_blank">
                    {activity.event == "favorite_property" ?
                    <div className='flex items-center gap-4 '>
                        <p>Added {activity.property_no} to favorite</p>
                    </div>
                        :
                        <div className='flex items-center gap-4'>
                        <p>Removed {activity.property_no} from favorite</p>
                    </div>
                    }
                </Link>
            </div>

            <div className='w-full'>
                {/* footer */}

            </div>
        </div>

    )
}