import { ArrowTopRightOnSquareIcon, ChevronRightIcon, DocumentPlusIcon, DocumentTextIcon, ListBulletIcon, MapPinIcon, PaperClipIcon, RectangleGroupIcon } from "@heroicons/react/24/outline";
import React from "react"
import * as Account from '../../../account';
import PropertyTypeBadge from "../Components/PropertyTypeBadge";
import { Disclosure, Transition } from '@headlessui/react'
import Price from "../Shared/Price";
export default function StepSummaryCard(props) {
    const { data, language, Translate, apartments } = props
    const { step, useStep, handleDraft, handleBack, handlePublish, updateProperty, handleNext } = props
    const monthName = (month) => {
        const date = new Date();
        date.setMonth(month - 1);
        return date.toLocaleString(language === "en" ? "en-US" : "ja-JP", {
            month: 'short',
        });
    }

    const [account] = React.useState(Account.getUserAccount());

    return (
        <div className="text-sm shadow rounded-lg bg-white">

            <div className="p-4 border-b">
                <p className="text-lg font-semibold">{Translate("Summary")}</p>
            </div>

            <div className="py-2">
                <Disclosure defaultOpen={true}>
                    {({ open }) => (
                        <div className="flex flex-col px-4 divide-y divide-dotted">
                            <Disclosure.Button>
                                <div className="flex items-center py-2 w-full gap-2">
                                    <div className="flex items-center gap-2">
                                        <PropertyTypeBadge onlyIcon={true} type={data.property_type} /><span className="font-semibold">{Translate("Property info")}</span>
                                    </div>
                                    <ChevronRightIcon className={`ml-auto w-4 h-4 ${open ? "transform rotate-90" : ""}`} />
                                </div>
                            </Disclosure.Button>

                            <Transition
                                show={open}

                            >
                                <Disclosure.Panel static>
                                    <div className="divide-y divide-dotted">
                                        <div className="flex items-center py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("Area")}</p>
                                            <p className="font-medium">{Translate(data.area.name ? data.area.name : data.area.id)}</p>
                                        </div>
                                        <div className="flex items-center py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("Property type")}</p>
                                            <p className="font-medium">{Translate(data.property_type)}</p>
                                        </div>
                                        <div className="flex items-center py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("Reference ID")}</p>
                                            <p className="font-medium">{data.reference_id ? data.reference_id : "-"}</p>
                                        </div>
                                        <div className="flex items-center py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("Transaction type")}</p>
                                            <p className="font-medium">{Translate(data.transaction_type)}</p>
                                        </div>
                                        <div className="flex items-center py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("Price")}</p>
                                            <p className="font-medium">{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: props.data.price.currency }).format(Number(props.data.price.amount.toString().trim().replaceAll(",", "").normalize('NFKC').replace(/[^0-9]/g, "")))}</p>
                                        </div>

                                        {data.property_type === "apartment" && data.apartment !== "" && data.area?.id !== "" && apartments && apartments[data.area?.id] && apartments[data.area?.id].find(({ id }) => id === data.apartment) ? (
                                            <>
                                                <div className="flex items-center py-1 w-full gap-2">
                                                    <p className="flex-none w-1/3 text-gray-600">{Translate("Apartment")}</p>
                                                    <p className=" font-medium ">{apartments[data.area?.id].find(({ id }) => id === data.apartment)?.name}</p>
                                                </div>
                                                <div className="flex items-center py-1 w-full gap-2">
                                                    <p className="flex-none w-1/3 text-gray-600">{Translate("Floor")}</p>
                                                    <p className=" font-medium ">{data.floor}</p>
                                                </div>
                                                <div className="flex items-center py-1 w-full gap-2">
                                                    <p className="flex-none w-1/3 text-gray-600">{Translate("Room number")}</p>
                                                    <p className=" font-medium ">{data.room_number ? data.room_number : "–"}</p>
                                                </div>
                                            </>
                                        ) : null}

                                        {data.property_type === "house" ? (
                                            <>
                                                <div className="flex items-center py-1 w-full gap-2">
                                                    <p className="flex-none w-1/3 text-gray-600">{Translate("Land size")}</p>
                                                    <p className=" font-medium">{(data.land.sqm || 0).toLocaleString()}m²</p>
                                                </div>
                                                <div className="flex items-center py-1 w-full gap-2">
                                                    <p className="flex-none w-1/3 text-gray-600">{Translate("Building size")}</p>
                                                    <p className=" font-medium ">{(data.sqm || 0).toLocaleString()}m²</p>
                                                </div>
                                                <div className="flex items-center py-1 w-full gap-2">
                                                    <p className="flex-none w-1/3 text-gray-600">{Translate("Built")}</p>
                                                    <p className=" font-medium">{data.built.month !== "" ? `${monthName(data.built.month)} ` : ""} {data.built.year !== "" ? data.built.year : ""}</p>
                                                </div>
                                            </>
                                        ) : null}

                                        {data.property_type === "lot" ? (
                                            <div className="flex items-center py-1 w-full gap-2">
                                                <p className="flex-none w-1/3 text-gray-600">{Translate("Land size")}</p>
                                                <p className=" font-medium">{(data.land.sqm || 0).toLocaleString()}m²</p>
                                            </div>
                                        ) : null}
                                    </div>
                                </Disclosure.Panel>
                            </Transition>
                        </div>
                    )}
                </Disclosure>

                {useStep.farestStep > 2 ?
                    <Disclosure defaultOpen={true}>
                        {({ open }) => (
                            <div className="flex flex-col px-4 divide-y divide-dotted">
                                <Disclosure.Button>
                                    <div className="flex items-center py-2 w-full gap-2">
                                        <div className="flex items-center gap-2">
                                            <MapPinIcon className="w-5 h-5" /><span className="font-semibold">{Translate("Address")}</span>
                                        </div>
                                        <ChevronRightIcon className={`ml-auto w-4 h-4 ${open ? "transform rotate-90" : ""}`} />
                                    </div>

                                </Disclosure.Button>

                                <Disclosure.Panel>
                                    <div className="divide-y divide-dotted">

                                        <div className="flex items-center py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("Zipcode")}</p>
                                            <p className="font-medium">{data.address.zip_code ? data.address.zip_code : "-"}</p>
                                        </div>
                                        <div className="flex items-center py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("Prefecture")}</p>
                                            <p className="font-medium">{data.address.prefecture ? data.address.prefecture : "-"}</p>
                                        </div>
                                        <div className="flex items-center py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("District")}</p>
                                            <p className="font-medium">{data.address.district ? data.address.district : "-"}</p>
                                        </div>
                                        <div className="flex items-center py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("City")}</p>
                                            <p className="font-medium">{data.address.city ? data.address.city : "-"}</p>
                                        </div>
                                        <div className="flex items-center py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("Address 1")}</p>
                                            <p className="font-medium">{data.address.address_1 ? data.address.address_1 : "-"}</p>
                                        </div>
                                        <div className="flex items-center py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("Address 2")}</p>
                                            <p className="font-medium">{data.address.address_2 ? data.address.address_2 : "-"}</p>
                                        </div>
                                    </div>
                                </Disclosure.Panel>

                            </div>
                        )}
                    </Disclosure>
                    : null
                }


                {useStep.farestStep > 2 ?
                    <Disclosure defaultOpen={true}>
                        {({ open }) => (
                            <div className="flex flex-col px-4 divide-y divide-dotted">
                                <Disclosure.Button>
                                    <div className="flex items-center py-2 w-full gap-2">
                                        <div className="flex items-center gap-2">
                                            <DocumentTextIcon className="w-5 h-5" /><span className="font-semibold">{Translate("Overview")}</span>
                                        </div>
                                        <ChevronRightIcon className={`ml-auto w-4 h-4 ${open ? "transform rotate-90" : ""}`} />
                                    </div>

                                </Disclosure.Button>

                                <Disclosure.Panel>
                                    <div className="divide-y divide-dotted">

                                        <div className="flex items-start py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("Title")}</p>
                                            <p className="font-medium line-clamp-2">{data.name}</p>
                                        </div>


                                        <div className="flex items-start py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("Overview")}</p>
                                            <p className="font-medium line-clamp-2">{data.overview ? data.overview : "-"}</p>
                                        </div>
                                        <div className="flex items-start py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("Remarks")}</p>
                                            <p className="font-medium ">{data.remarks ? data.remarks : "-"}</p>
                                        </div>
                                        <div className="flex items-start py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("Regulations")}</p>
                                            <p className="font-medium">{data.regulations ? data.regulations : "-"}</p>
                                        </div>

                                    </div>
                                </Disclosure.Panel>

                            </div>
                        )}
                    </Disclosure>
                    : null
                }



                {useStep.farestStep > 3 && data.property_type !== "lot" ?
                    <Disclosure defaultOpen={true}>
                        {({ open }) => (
                            <div className="flex flex-col px-4 divide-y divide-dotted">
                                <Disclosure.Button>
                                    <div className="flex items-center py-2 w-full gap-2">
                                        <div className="flex items-center gap-2">
                                            <RectangleGroupIcon className="w-5 h-5" /><span className="font-semibold">{Translate("Property details")}</span>
                                        </div>
                                        <ChevronRightIcon className={`ml-auto w-4 h-4 ${open ? "transform rotate-90" : ""}`} />
                                    </div>

                                </Disclosure.Button>

                                <Disclosure.Panel>
                                    <div className="divide-y divide-dotted">

                                        <div className="flex items-start py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("Layout")}</p>
                                            <p className="font-medium">{data.layout.name ? data.layout.name : "-"}</p>
                                        </div>
                                        <div className="flex items-start py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("Bedrooms")}</p>
                                            <p className="font-medium">{data.layout.bedrooms ? data.layout.bedrooms : "-"}</p>
                                        </div>
                                        <div className="flex items-start py-1 w-full gap-2">
                                            <p className="flex-none w-1/3 text-gray-600">{Translate("Bathrooms")}</p>
                                            <p className="font-medium">{data.layout.bathrooms ? data.layout.bathrooms : "-"}</p>
                                        </div>


                                    </div>
                                </Disclosure.Panel>

                            </div>
                        )}
                    </Disclosure>
                    : null
                }


                {useStep.farestStep > 3 ?
                    <Disclosure defaultOpen={true}>
                        {({ open }) => (
                            <div className="flex flex-col px-4 divide-y divide-dotted">
                                <Disclosure.Button>
                                    <div className="flex items-center py-2 w-full gap-2">
                                        <div className="flex items-center gap-2">
                                            <ListBulletIcon className="w-5 h-5" /><span className="font-semibold">{Translate("Listing Details")}</span>
                                        </div>
                                        <ChevronRightIcon className={`ml-auto w-4 h-4 ${open ? "transform rotate-90" : ""}`} />
                                    </div>

                                </Disclosure.Button>

                                <Disclosure.Panel>
                                    <div className="divide-y divide-dotted">

                                        {(data.apartments || []).map((feature, index) => (
                                            <div key={index} className="flex items-start py-1 w-full gap-2">
                                                <p className="flex-none w-1/3 text-gray-600">{Translate(feature.title)}</p>
                                                <p className="font-medium">{Translate(feature.value)}</p>
                                            </div>
                                        ))}

                                        {(data.features || []).map((feature, index) => (
                                            <div key={index} className="flex items-start py-1 w-full gap-2">
                                                <p className="flex-none w-1/3 text-gray-600">{Translate(feature.title)}</p>
                                                <span className="flex flex-col">
                                                    {
                                                        feature.type != "list_multiple_value" ? feature.value : (Array.isArray(feature.value) ? feature.value : feature.value.split(",")).map((f, i) => {
                                                            return (<p key={`${i}-${f}`} className="font-medium w-full" >{Translate(f)}</p>)
                                                        })
                                                    }
                                                </span>
                                            </div>
                                        ))}

                                    </div>




                                </Disclosure.Panel>

                            </div>
                        )}
                    </Disclosure>
                    : null
                }

                {useStep.farestStep > 3 && data.other_costs && data.other_costs.length > 0 ?
                    <Disclosure defaultOpen={true}>
                        {({ open }) => (
                            <div className="flex flex-col px-4 divide-y divide-dotted">
                                <Disclosure.Button>
                                    <div className="flex items-center py-2 w-full gap-2">
                                        <div className="flex items-center gap-2">
                                            <DocumentPlusIcon className="w-5 h-5" /><span className="font-semibold">{Translate("Other Costs")}</span>
                                        </div>
                                        <ChevronRightIcon className={`ml-auto w-4 h-4 ${open ? "transform rotate-90" : ""}`} />
                                    </div>

                                </Disclosure.Button>

                                <Disclosure.Panel>
                                    <div className="divide-y divide-dotted">

                                        {data.other_costs.map((cost, index) => (<>

                                            <div key={index} className="flex items-start py-1 w-full gap-2">
                                                <div className="flex-none w-1/3 text-gray-600">
                                                    <p>{cost.title}</p>
                                                    <p className="text-xs">{cost.description}</p>
                                                </div>
                                                <p className="ml-auto font-medium"><Price currency={cost.price.currency} amount={cost.price.amount} />({Translate(cost.type)})</p>
                                            </div>
                                        </>))}
                                    </div>
                                </Disclosure.Panel>

                            </div>
                        )}
                    </Disclosure>
                    : null
                }


                {data.document?.files.length > 0 ?
                    <Disclosure defaultOpen={true}>
                        {({ open }) => (
                            <div className="flex flex-col px-4 divide-y divide-dotted">
                                <Disclosure.Button>
                                    <div className="flex items-center py-2 w-full gap-2">
                                        <div className="flex items-center gap-2">
                                            <PaperClipIcon className="w-5 h-5" /><span className="font-semibold">{Translate("instant_material_title")}</span>
                                        </div>
                                        <ChevronRightIcon className={`ml-auto w-4 h-4 ${open ? "transform rotate-90" : ""}`} />
                                    </div>

                                </Disclosure.Button>

                                <Disclosure.Panel>
                                    <div className="divide-y divide-dotted">

                                        {data.document?.files.map((file, index) => (<>

                                            <div key={index} className="flex items-start py-1 w-full gap-2">
                                                <div className="flex-none w-full text-gray-600">
                                                    <a className="text-primary flex items-center" href={file.url} target="_blank">
                                                        {file.url.substring(file.url.lastIndexOf('/') + 1)}
                                                        <ArrowTopRightOnSquareIcon className="w-4 h-4 ml-1" />
                                                    </a>
                                                </div>
                                            </div>
                                        </>))}
                                    </div>
                                </Disclosure.Panel>

                            </div>
                        )}
                    </Disclosure>
                    : null
                }



                <div className="">
                    {updateProperty && (<>
                        <hr />

                        <div className="col-span1 py-2 bg-gray-200 border-t px-6 flex items-center">
                            <div className="py-2 ml-auto">
                                <button id="update" type="button" className="btn btn-primary" onClick={updateProperty}>{Translate("Update")}</button>
                            </div>
                        </div>
                    </>)}
                </div>

            </div>

        </div>)
}