
var _base_url = "https://viila.dev"

if (process.env.REACT_APP_BUILD === 'prod') {
    _base_url = "https://viila.co"
} else if (process.env.REACT_APP_BUILD === 'local') {
    _base_url = "http://localhost:8888"
} else {
    _base_url = "https://viila.dev"
    // _base_url = "http://localhost:8888"
}

export const BASE_URL = `${_base_url}`;
export const API_BASE_URL = `${_base_url}/api`;
export const MAPBOX_TOKEN = 'pk.eyJ1IjoiYXBpc2l0dmlpbGEiLCJhIjoiY2t3NjdlMW83MngxMjJvcGFhN2d2cmNkNyJ9.K2ko2jB7iJ8o-_6C6WeNyw';