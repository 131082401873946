import React from "react"
import { Translate } from "../v2/translate/Translate";

export default function LearnMore(props) {
    return (
        <div className="bg-primary flex flex-col  bg-radial-primary lg:p-28 ">




            <p className="text-white text-3xl font-bold mb-4">{Translate("Reimagining Real Estate")}</p>
            <p className="text-white mb-8">{Translate("The world is changing, so are buyers. New generation wants a better way to buy and sell homes. We help you do that.")}</p>

            <img className="mb-8 " src="https://uploads-ssl.webflow.com/6306f2da65bd554d1de0e39b/63129d5705c4162749116f6b_browserframe-p-1080.png" />

            <div className="flex flex-col gap-2 mb-8">
                <a className="text-white underline underline-offset-4" href="https://ja.viila.co.jp" target="_blank">{Translate("Learn more about Viila")} →</a>
                <a className="text-white underline underline-offset-4" href="https://viila.co" target="_blank">{Translate("Checkout latest listing")} →</a>
            </div>



            <p className="text-white font-medium mt-8 mb-2">{Translate("Contact us")}</p>
            <div className="flex items-center text-white gap-8">
                <a className="font-medium underline underline-offset-4" href="mailto:support@viila.co?subject=help">support@viila.co</a>
                <a className="font-medium  underline underline-offset-4" href="tel:080-3392-4276">080-3392-4276</a>
            </div>

            

        </div>)
}