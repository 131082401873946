
import React, { Fragment } from "react"
import SideNavBar from "../SideNavBar";
import TopSearchbar from "../TopSearchbar";
import { Translate, TranslateWithLang } from "../../translate/Translate";
import ComponentSelect from "../Components/Select";
import { Link } from 'react-router-dom';

import PropertyCardInList from "./PropertyCardInList"
import Select, { StylesConfig } from 'react-select';
import BackToTopButton from "../Components/BackToTopButton";
import PropertyNewStatusBadge from "../Shared/PropertyNewStatusBadge";
import PropertyTypeBadge from "../Components/PropertyTypeBadge";
import * as api from '../../services/agent'
import { PinnedProperty } from "../Shared/PinnedProperty";
import { BanIcon, ChatBubbleBottomCenterIcon, EyeSlashIcon, GlobeAltIcon, ChevronDownIcon, KeyIcon, PlusIcon, PencilIcon, MagnifyingGlassIcon, BarsArrowUpIcon, BarsArrowDownIcon } from "@heroicons/react/24/outline";
import { Popover, Transition, Menu } from '@headlessui/react'
import ScreenLoading from "../Components/ScreenLoading";
import Error from '../Shared/Error'
import ImportPropertyDialog from "../Components/ImportPropertyDialog";

export default function ListOfProperties(props) {

    let [search, setSearch] = React.useState("")
    const query = new URLSearchParams(window.location.search);
    const defaultFilter =  query.get("query") ? JSON.parse(query.get("query")) : (localStorage.getItem("properties_query_string") ? JSON.parse(localStorage.getItem("properties_query_string")) : { list_status: ["active"], property_types: ["house", "lot", "apartment"] }) //default filter here
    const [filters, setFilters] = React.useState(defaultFilter);
    const [data, setData] = React.useState({ selectedProperties: [] });
    const pinnedProperties = props.pinnedProperties.items;

    const [isLoading, setLoading] = React.useState(false)
    const [showError, setShowError] = React.useState(false);;
    const [errorMessage, setErrorMessage] = React.useState("");

    const [isOpen, setIsOpen] = React.useState(false)
    const [property, setProperty] = React.useState({});
    React.useEffect(() => {
        props.setFilters(filters)
        if (props.history) {
            props.history.replace({
                pathname: window.location.pathname,
                search: `?query=${JSON.stringify(filters)}`
            })
        }
    }, [filters]);

    React.useEffect(() => {
        localStorage.setItem("properties_query_string", query.get('query'))
    }, [query]);


    const statusOptions = [
        { value: 'active', label: Translate("active") },
        { value: 'inactive', label: Translate("inactive") },
        { value: 'business_negotiation', label: Translate("business_negotiation") },
        { value: 'sold', label: Translate("sold") },
        { value: 'draft', label: Translate("draft") },
    ]

    const propertyTypeFilterOptions = [
        { value: 'house', label: Translate("Home") },
        { value: 'lot', label: Translate("Land") },
        { value: 'apartment', label: Translate("Apartment") },
    ]

    const transactionTypeFilterOptions = [
        "seller",
        "brokerage_general",
        "brokerage_exclusive",
        "brokerage_privileged_exclusive",
        "proxy",
    ]

    const sortOptions = [
        { value: 'latest_update', label: Translate("latest_update") },
        { value: 'price_low_to_high', label: Translate("price_low_to_high") },
        { value: 'price_high_to_low', label: Translate("price_high_to_low") },
        { value: 'sqm_low_to_high', label: Translate("sqm_low_to_high") },
        { value: 'sqm_high_to_low', label: Translate("sqm_high_to_low") },

        { value: 'page_views_low_to_high', label: Translate("page_views_low_to_high") },
        { value: 'page_views_high_to_low', label: Translate("page_views_high_to_low") },
        { value: 'price_per_sqm_low_to_high', label: Translate("price_per_sqm_low_to_high") },
        { value: 'price_per_sqm_high_to_low', label: Translate("price_per_sqm_high_to_low") },

        // { value: 'from_near_to_far', label: Translate("from_near_to_far") },

        // { value: 'ref_id_low_to_high', label: Translate("ref_id_low_to_high") },
        // { value: 'ref_id_high_to_low', label: Translate("ref_id_high_to_low") },
        // { value: 'id_low_to_high', label: Translate("id_low_to_high") },
        // { value: 'id_high_to_low', label: Translate("id_high_to_low") },

        // { value: 'added_low_to_high', label: Translate("added_low_to_high") },
        // { value: 'added_high_to_low', label: Translate("added_high_to_low") },


        // { value: 'updated_low_to_high', label: Translate("updated_low_to_high") },
        // { value: 'updated_high_to_low', label: Translate("updated_high_to_low") },

        // { value: 'page_views_popular_low_to_high', label: Translate("page_views_popular_low_to_high") },
        // { value: 'page_views_popular_high_to_low', label: Translate("page_views_popular_high_to_low") },


        // { value: 'favorite_popular_low_to_high', label: Translate("favorite_popular_low_to_high") },
        // { value: 'favorite_popular_high_to_low', label: Translate("favorite_popular_high_to_low") },

        { value: 'time_on_viila_newest_to_oldest', label: Translate("time_on_viila_newest_to_oldest") },
        { value: 'time_on_viila_oldest_to_newest', label: Translate("time_on_viila_oldest_to_newest") },


    ];

    const icon = {
        inactive: <EyeSlashIcon className="flex-none w-4 h-4 mr-1 text-stone-500" />,
        active: <GlobeAltIcon className="flex-none w-4 h-4 mr-1 text-success" />,
        business_negotiation: <ChatBubbleBottomCenterIcon className="flex-none w-4 h-4 mr-1 text-orange-500" />,
        sold: <KeyIcon className="flex-none w-4 h-4 mr-1 text-red-600" />,
        draft: <PencilIcon className="flex-none w-4 h-4 mr-1 text-yellow-400" />,
    }

    const labels = {
        "draft": "Mark as Draft",
        "sold": "Mark as Sold",
        "active": "Mark as Active",
        "business_negotiation": "Mark as Negotiation",
        "inactive": "Delist this property",
    }

    const actions = [
        {
            status: "active",
            style: "bg-green-100 text-green-600",
            label: Translate(labels["active"]),
        },
        {
            status: "inactive",
            style: "bg-gray-100 text-gray-600",
            label: Translate(labels["inactive"]),
        },
        {
            status: "sold",
            style: "bg-red-100 text-red-600",
            label: Translate(labels["sold"]),
        },
        {
            status: "business_negotiation",
            style: "bg-yellow-100 text-yellow-500",
            label: Translate(labels["business_negotiation"]),
        },
    ]

    const actionOptions = [
        // { value: 'pin', label: Translate("multiple_select_action_pin_selected") },
        // { value: 'unpin', label: Translate("multiple_select_action_unpin_selected") },
        // { value: 'private', label: Translate("multiple_select_action_hide_from_viila_map_selected") },
        // { value: 'public', label: Translate("multiple_select_action_show_on_viila_map_selected") },
        { value: 'active', label: Translate("active") },
        { value: 'inactive', label: Translate("inactive") },
        { value: 'business_negotiation', label: Translate("business_negotiation") },
        { value: 'sold', label: Translate("sold") },
    ]

    const onSelectAction = (option) => {
        let action = option.value

        if (action === "pin") {
            markAsPin()
        } else if (action === "unpin") {
            markAsUnpin()
        } else if (action === "private") {
            markAsPrivate()
        } else if (action === "public") {
            markAsPublic()
        }
    }


    const handleAllChecked = (e) => {
        let items = []
        if (e.target.checked) {
            props.properties.items.forEach(k => items.push(k.id));
        }
        setData({ selectedProperties: items })
    };

    const handleChecked = (id) => {
        let items = data.selectedProperties
        const index = data.selectedProperties.indexOf(id);
        if (index > -1) {
            items.splice(index, 1);
        } else {
            items.push(id)
        }
        setData({ selectedProperties: items })
    }


    const markAsPrivate = () => {
        if (data.selectedProperties.length === 0)
            return

        setLoading(true)
        api.updatePropertyPrivate({ properties: data.selectedProperties })
            .then(response => {
                props.search()
                setData({ selectedProperties: [] })
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error && error.data ? error.data.message : error)
            }).finally(e => {
                setLoading(false)
            })
    }

    const markAsPublic = () => {
        if (data.selectedProperties.length === 0)
            return

        setLoading(true)
        api.updatePropertyPublic({ properties: data.selectedProperties })
            .then(response => {
                props.search()
                setData({ selectedProperties: [] })
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error && error.data ? error.data.message : error)
            }).finally(e => {
                setLoading(false)
            })
    }

    const markAsPin = () => {
        if (data.selectedProperties.length === 0)
            return

        setLoading(true)
        api.updatePropertyPin({ properties: data.selectedProperties })
            .then(response => {
                props.reloadPinnedProperties()
                props.search()
                setData({ selectedProperties: [] })
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error && error.data ? error.data.message : error)
            }).finally(e => {
                setLoading(false)
            })
    }

    const markAsUnpin = () => {
        if (data.selectedProperties.length === 0)
            return

        setLoading(true)
        api.updatePropertyUnpin({ properties: data.selectedProperties })
            .then(response => {
                props.reloadPinnedProperties()
                props.search()
                setData({ selectedProperties: [] })
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error && error.data ? error.data.message : error)
            }).finally(e => {
                setLoading(false)
            })
    }

    const setPin = (id) => {
        setLoading(true)
        api.updatePropertyPin({ properties: [id] })
            .then(response => {
                props.reloadPinnedProperties()
                props.search()
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error && error.data ? error.data.message : error)
            }).finally(e => {
                setLoading(false)
            })
    }

    const setUnpin = (id) => {
        setLoading(true)
        api.updatePropertyUnpin({ properties: [id] })
            .then(response => {
                props.reloadPinnedProperties()
                props.search()
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error && error.data ? error.data.message : error)
            }).finally(e => {
                setLoading(false)
            })
    }

    const setPrivate = (id) => {
        setLoading(true)
        api.updatePropertyPrivate({ properties: [id] })
            .then(response => {
                props.search()
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error && error.data ? error.data.message : error)
            }).finally(e => {
                setLoading(false)
            })
    }

    const setPublic = (id) => {
        setLoading(true)
        api.updatePropertyPublic({ properties: [id] })
            .then(response => {
                props.search()
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error && error.data ? error.data.message : error)
            }).finally(e => {
                setLoading(false)
            })
    }

    const updateSelectedStatus = function (status) {
        if (data.selectedProperties.length === 0)
            return

        setLoading(true)
        api.updatePropertyStatus({ properties: data.selectedProperties, status: status })
            .then(response => {
                props.reloadPinnedProperties()
                props.search()
                setData({ selectedProperties: [] })
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error && error.data ? error.data.message : error)
            }).finally(e => {
                setLoading(false)
            })
    }

    const setStatus = function (id, status) {
        let property = {
            id: id,
            status: status
        }

        setLoading(true)
        api.updateProperty(property)
            .then(response => {
                props.reloadPinnedProperties()
                props.search()
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error && error.data ? error.data.message : error)
            }).finally(e => {
                setLoading(false)
            })
    }

    const copyPropertyAsDraft = (id) => {
        if (id.length === 0)
            return

        setIsOpen(false)
        setLoading(true)
        api.copyPropertyAsDraft(id)
            .then(response => {
                window.location.href = `/v2/properties/wizard/${response}/summary`
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error && error.data ? error.data.message : error)
            }).finally(e => {
                setLoading(false)
            })
    }

    function closeModal() {
        setIsOpen(false)
    }

    function openModel(property) {
        setProperty(property)
        setIsOpen(true)
    }

    const onSelectOrder = (order) => {
        setFilters({ ...filters, order: order.value })
        props.handleFilterChange({ target: { name: "order", value: order.value } })
    }

    const applySearch = (e) => {
        setFilters({ ...filters, keywords: search })
        props.handleFilterChange({ target: { name: "keywords", value: search } })
    }

    // handle status filter
    const [selectedStatuses, setSelectedStatuses] = React.useState(filters && filters.list_status ? filters.list_status : null)
    const onSelectFilterStatus = (e) => {
        const items = selectedStatuses ? [...selectedStatuses] : []
        const checked = e.target.checked
        const value = e.target.value
        if (checked) {
            items.push(value)
        } else {
            const index = items.indexOf(value)
            items.splice(index, 1)
        }
        setSelectedStatuses(items)
    }
    React.useEffect(() => {
        if (selectedStatuses === null)
            return

        if (selectedStatuses.length > 0)
            setFilters({ ...filters, list_status: selectedStatuses })
        else
            setFilters(c => {
                const { list_status, ...rest } = c;
                return rest;
            });

    }, [selectedStatuses])
    // ends handle status filter

    // handle property type filter
    const [selectedPropertyTypes, setSelectedPropertyTypes] = React.useState(filters && filters.property_types ? filters.property_types : null)
    const onSelectFilterPropertyType = (e) => {
        const items = selectedPropertyTypes ? [...selectedPropertyTypes] : []
        const checked = e.target.checked
        const value = e.target.value
        if (checked) {
            items.push(value)
        } else {
            const index = items.indexOf(value)
            items.splice(index, 1)
        }
        setSelectedPropertyTypes(items)
    }

    React.useEffect(() => {

        if (selectedPropertyTypes === null)
            return

        if (selectedPropertyTypes.length > 0)
            setFilters({ ...filters, property_types: selectedPropertyTypes })
        else
            setFilters(c => {
                const { property_types, ...rest } = c;
                return rest;
            });
    }, [selectedPropertyTypes])
    //end handle property type filter

    // handle transaction type filter
    const onSelectFilterTransactionType = (e) => {
        const value = e.target.value
        setFilters({ ...filters, transaction_type: value })
    }
    //end handle property type filter

    const onSelectFilterMustbeUpdated = (e) => {
        if (e.target.checked)
            setFilters({ ...filters, must_be_updated: true,status:"active" })
        else
            setFilters({ ...filters, must_be_updated: false,status: "" })
    }
    
    return (
        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} isPresented={true} />
            <div className="flex flex-col w-full relative">

                {/* <div className="sticky top-0  z-50 bg-white">
                    <TopSearchbar {...props} />
                </div> */}

                {
                    data.selectedProperties && data.selectedProperties.length > 0 ?
                        <div className="absolute top-0 left-0 w-full bg-white p-4 z-50 shadow">
                            <div className="flex items-center">
                                <div className="flex">
                                    <span className="font-semibold mr-2">{data.selectedProperties.length}</span><span>{Translate("selected properties")}</span>
                                    <button className="border-b border-dashed font-medium text-slate-400 ml-4" onClick={_ => { setData({ selectedProperties: [] }); }}>{Translate("Clear All")}</button>
                                </div>
                                <div className="flex ml-auto items-center gap-4">

                                    <Menu as="div" >
                                        <div>
                                            <Menu.Button className="flex items-center gap-2 px-2 py-1 bg-gray-100 rounded text-sm font-medium">
                                                <PencilIcon className="w-4 h-4 text-gray-500" />
                                                {TranslateWithLang(props.language, "Change status")}
                                                <ChevronDownIcon
                                                    className={`h-4 w-4 text-gray-400`}
                                                    aria-hidden="true"
                                                />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="z-50 absolute w-56 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="px-1 py-1 ">
                                                    {actions.map((action, index) => (<Menu.Item key={index} onClick={() => { updateSelectedStatus(action.status) }}>
                                                        {({ active }) => (
                                                            <button className={`${active ? action.style : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                                                                {icon[action.status]}
                                                                {action.label}
                                                            </button>
                                                        )}
                                                    </Menu.Item>))}
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>

                                    <button onClick={e => onSelectAction({ value: "pin" })} className="flex items-center gap-2 px-2 py-1 bg-gray-100 rounded text-sm font-medium">
                                        <svg className="w-4 h-4 fill-current text-gray-600" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.326172 8.58008C0.326172 9.07812 0.660156 9.40625 1.1875 9.40625H4.35742V12.1426C4.35742 13.0391 4.73242 13.7832 4.87891 13.7832C5.01953 13.7832 5.39453 13.0391 5.39453 12.1426V9.40625H8.56445C9.0918 9.40625 9.42578 9.07812 9.42578 8.58008C9.42578 7.34375 8.43555 6.03711 6.78906 5.43945L6.5957 2.75C7.45117 2.26367 8.1543 1.71289 8.45898 1.32031C8.61133 1.12109 8.6875 0.921875 8.6875 0.746094C8.6875 0.388672 8.41211 0.125 8.00195 0.125H1.75586C1.33984 0.125 1.07031 0.388672 1.07031 0.746094C1.07031 0.921875 1.14062 1.12109 1.29297 1.32031C1.59766 1.71289 2.30078 2.26367 3.15625 2.75L2.96289 5.43945C1.31641 6.03711 0.326172 7.34375 0.326172 8.58008Z" />
                                        </svg>
                                        {Translate("multiple_select_action_pin_selected")}
                                    </button>
                                    <button onClick={e => onSelectAction({ value: "unpin" })} className="flex items-center gap-2 px-2 py-1 bg-gray-100 rounded text-sm font-medium">
                                        <svg className="text-red-600 fill-current w-4 h-4" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.01562 0.5C3.46094 0.5 3.10156 0.851562 3.10156 1.32812C3.10156 1.55469 3.1875 1.8125 3.39062 2.07812C3.78906 2.60938 4.73438 3.35156 5.88281 4L5.8125 5.02344L13.5859 12.7891C14 12.625 14.2422 12.2656 14.2422 11.7734C14.2422 10.125 12.9219 8.38281 10.7266 7.58594L10.4688 4C11.6094 3.35156 12.5469 2.625 12.9453 2.10156C13.1562 1.82812 13.2578 1.5625 13.2578 1.32812C13.2578 0.851562 12.8906 0.5 12.3438 0.5H4.01562ZM14.4922 16.7656C14.7344 17 15.1172 17 15.3438 16.7656C15.5781 16.5312 15.5859 16.1484 15.3438 15.9141L1.22656 1.80469C0.992188 1.57031 0.601562 1.5625 0.367188 1.80469C0.140625 2.03125 0.140625 2.42188 0.367188 2.65625L14.4922 16.7656ZM2.10938 11.7734C2.10938 12.4375 2.55469 12.875 3.25781 12.875H7.49219V16.5234C7.49219 17.7188 7.98438 18.7109 8.17969 18.7109C8.375 18.7109 8.86719 17.7188 8.86719 16.5234V12.9844H9.32031L4.50781 8.15625C3 9.03906 2.10938 10.4297 2.10938 11.7734Z" />
                                        </svg>
                                        {Translate("multiple_select_action_unpin_selected")}
                                    </button>


                                </div>
                            </div>
                        </div>
                        : null
                }


                <div className="bg-white">
                    <div className="w-full container mx-auto lg:px-8 px-4">

                        <div className="py-8">
                            <p className="text-3xl font-bold">{Translate("Listing")}</p>
                        </div>

                        {/* search, sort and filter area */}
                        <div className="mb-6 flex lg:flex-row flex-col gap-x-8 gap-y-4 flex-wrap">
                            <div className="flex flex-col">
                                <div className="font-medium mb-2">{Translate("Status")}</div>
                                <div className="flex items-center gap-x-4 gap-y-2 flex-wrap">
                                    {
                                        statusOptions.map((item) => (
                                            <label key={item.value} className="flex items-center">
                                                <input value={item.value} onChange={(e) => onSelectFilterStatus(e)} checked={filters?.list_status?.find(c => c === item.value) !== undefined}  defaultChecked={filters?.list_status?.find(c => c === item.value) !== undefined} type="checkbox" className="mr-2 rounded accent-primary focus:outline-none focus:ring-0 border-2 border-slate-400" />
                                                <PropertyNewStatusBadge status={item.value} />
                                            </label>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="flex flex-col">
                                <div className="font-medium mb-2">{Translate("Property Type")}</div>
                                <div className="flex items-center gap-x-4 gap-y-2 flex-wrap">
                                    {
                                        propertyTypeFilterOptions.map((item) => (
                                            <label key={item.value} className="flex items-center">
                                                <input value={item.value} onChange={(e) => onSelectFilterPropertyType(e)} defaultChecked={filters?.property_types?.find(c => c === item.value) !== undefined} type="checkbox" className="mr-2 rounded accent-primary focus:outline-none focus:ring-0 border-2 border-slate-400" />
                                                <PropertyTypeBadge type={item.value} />
                                            </label>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="flex flex-col">
                                <div className="font-medium mb-2">{Translate("Transaction Type")}</div>
                                <div className="flex items-center gap-x-4 gap-y-2 flex-wrap">
                                    <label className="flex items-center">
                                        <input value="" onChange={(e) => onSelectFilterTransactionType(e)} defaultChecked={filters?.transaction_type === ""} type="radio" name="transaction_type" className="mr-2 accent-primary focus:outline-none focus:ring-0 border-2 border-slate-400" />
                                        <span className="text-sm font-medium">{Translate("all_option")}</span>
                                    </label>
                                    {
                                        transactionTypeFilterOptions.map((item) => (
                                            <label key={item} className="flex items-center">
                                                <input value={item} onChange={(e) => onSelectFilterTransactionType(e)} defaultChecked={filters?.transaction_type === item} type="radio" name="transaction_type" className="mr-2 accent-primary focus:outline-none focus:ring-0 border-2 border-slate-400" />
                                                <span className="text-sm font-medium">{Translate(item)}</span>
                                            </label>
                                        ))
                                    }
                                </div>
                            </div>
                            {props && props.statistics && props.statistics.must_be_updated_properties ?
                            <div className="flex flex-col">
                                <div className="font-medium mb-2">&nbsp;</div>
                                <div className="flex items-center gap-x-4 gap-y-2 flex-wrap">
                                    <label className="flex items-center">
                                        <input onChange={(e) => onSelectFilterMustbeUpdated(e)} checked={filters?.must_be_updated} defaultChecked={filters?.must_be_updated} type="checkbox" name="must_be_updated" className="mr-2 rounded accent-primary focus:outline-none focus:ring-0 border-2 border-slate-400" />
                                        <span className="text-sm font-medium ">{Translate("Required update")}<div className="ml-2 px-2 inline-flex text-md leading-5 font-medium rounded-full bg-blue-100 text-blue-600">{props && props.statistics && props.statistics.must_be_updated_properties}</div></span>
                                    </label>
                                </div>
                            </div> : null}
                        </div>
                        <div className="pb-8 flex items-center gap-4 w-full">
                            <div className="flex items-center bg-slate-200 rounded h-12 overflow-hidden w-full">
                                <MagnifyingGlassIcon className="flex-none w-5 h-5 text-slate-400 ml-3" />
                                <input type="text" className="flex-1 border-none bg-transparent h-full w-40 focus:outline-none focus:ring-0" onChange={(e) => setSearch(e.target.value)} />
                                <button className="ml-auto flex-none px-3 text-sm font-semibold bg-primary text-white h-full" onClick={(e) => applySearch(e)}>{Translate("Search")}</button>
                            </div>
                        </div>
                        {/* search, sort and filter area */}
                    </div>
                </div>
                {/* number of listing and actions */}
                <div className="lg:p-8 p-4 flex items-center container mx-auto">
                    <div className="inline-flex">
                        <label className="flex items-center">
                            <input checked={props.properties.items && data.selectedProperties.length && (data.selectedProperties.length === props.properties.items.length)} type="checkbox" className="appearance-none h-6 w-6 text-primary rounded border border-gray-300 focus:ring-0 focus:outline-focus" onChange={handleAllChecked} />
                            <p className="ml-4 font-semibold">{props.properties.total_item} {Translate("Properties")} </p>
                        </label>
                    </div>
                    <div className="ml-auto ">
                        <div className="flex flex-col lg:flex-row gap-2">
                            <Link to="/v2/properties/wizard/steps#0" className="p-3 bg-primary rounded text-white flex items-center gap-2">
                                <PlusIcon className="w-5 h-5" />
                                <span className="font-medium leading-none">{Translate("New Property")}</span>
                            </Link>

                            <div className="">
                                <ComponentSelect
                                    title={props.filters?.order ? Translate(props.filters.order) : Translate(sortOptions[0].value)}
                                    icon={props.filters?.order && props.filters.order.search(/to_high/i) > 0 ? <BarsArrowUpIcon className="w-5 h-5" /> : <BarsArrowDownIcon className="w-5 h-5" />}
                                    options={sortOptions}
                                    onSelect={onSelectOrder}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                {/*ends number of listing and actions */}

                {/* Pinned properties */}
                <div className="lg:px-8 px-4 container mx-auto mb-12">
                    <div className="">
                        <div className="mb-4">
                            <p className="font-semibold mb-1">{Translate("pinned_property_title")}</p>
                            <p className="text-xs text-slate-500">{Translate("pinned_property_description")}</p>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                            {pinnedProperties === null ?
                                <div className="w-full col-span-3 rounded-lg border border-dashed text-center py-4">
                                    <p className="text-gray-600 text-xs">{TranslateWithLang(props.language, "empty_pinned_properties")}</p>
                                </div>
                                : pinnedProperties.map((property, index) => (
                                    <div key={`pin-p-${index}`} className="bg-white rounded-lg shadow flex items-center overflow-hidden">
                                        <div className="flex-1 truncate text-ellipsis ">
                                            <PinnedProperty index={index} key={property.id} data={property} />
                                        </div>
                                        <div className="ml-auto border-l">
                                            <button className="px-4 text-sm" onClick={(e) => { setUnpin(property.id) }}>
                                                <svg className="text-red-600 fill-current w-5 h-5" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.01562 0.5C3.46094 0.5 3.10156 0.851562 3.10156 1.32812C3.10156 1.55469 3.1875 1.8125 3.39062 2.07812C3.78906 2.60938 4.73438 3.35156 5.88281 4L5.8125 5.02344L13.5859 12.7891C14 12.625 14.2422 12.2656 14.2422 11.7734C14.2422 10.125 12.9219 8.38281 10.7266 7.58594L10.4688 4C11.6094 3.35156 12.5469 2.625 12.9453 2.10156C13.1562 1.82812 13.2578 1.5625 13.2578 1.32812C13.2578 0.851562 12.8906 0.5 12.3438 0.5H4.01562ZM14.4922 16.7656C14.7344 17 15.1172 17 15.3438 16.7656C15.5781 16.5312 15.5859 16.1484 15.3438 15.9141L1.22656 1.80469C0.992188 1.57031 0.601562 1.5625 0.367188 1.80469C0.140625 2.03125 0.140625 2.42188 0.367188 2.65625L14.4922 16.7656ZM2.10938 11.7734C2.10938 12.4375 2.55469 12.875 3.25781 12.875H7.49219V16.5234C7.49219 17.7188 7.98438 18.7109 8.17969 18.7109C8.375 18.7109 8.86719 17.7188 8.86719 16.5234V12.9844H9.32031L4.50781 8.15625C3 9.03906 2.10938 10.4297 2.10938 11.7734Z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                {/* Pinned properties */}
                {/* property card section */}
                <div className="lg:px-8 px-4 container mx-auto">
                    {!props.properties.items ? null : props.properties.items.map((property, index) => (
                        <PropertyCardInList openModel={openModel} setStatus={setStatus} setPrivate={setPrivate} setPublic={setPublic} setPin={setPin} setUnpin={setUnpin} checked={data.selectedProperties.findIndex((e) => e === property.id) != -1} onChange={handleChecked} property={property} key={`property-${property.property_id}`} />
                    ))}
                </div>
                {/* property card section */}

                <div className="lg:px-8 px-4 py-6 container mx-auto flex items-center">
                    <BackToTopButton />
                </div>

            </div>
            {isLoading && (<ScreenLoading {...props} />)}
            <Error isPresented={showError} errorMessage={errorMessage} dismiss={(e) => { setShowError(false) }}></Error>
            <ImportPropertyDialog isOpen={isOpen} closeModal={closeModal} copyPropertyAsDraft={copyPropertyAsDraft} property={property}></ImportPropertyDialog>
        </div >
    )
}