import React, {useEffect, useState, useMemo} from "react"
import { Link, useLocation } from 'react-router-dom';
import * as api from '../../v1/services/auth'
import { Translate, TranslateToHtml } from "../../v1/translate/Translate";

import * as Account from '../../account';
import ViilaSolid from '../../assets/logo/solid.svg'
import LearnMore from "../../Components/LearnMore";
import { ArrowRightIcon } from '@heroicons/react/24/solid';
function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Signup(props) {

    const [error, setError] = useState(null)
    const GOOGLE_OAUTH2_CLIENT_ID = '379613756082-57r2c458vvjajvgmr8ieqmbhrrs7059j.apps.googleusercontent.com'
    let query = useQuery();

    let auth;

    useEffect(() => {
        if (window.gapi?.auth2) {
            window.gapi.auth2.init({
                client_id: GOOGLE_OAUTH2_CLIENT_ID,
                scope: "profile email",
                redirect_uri: window.location.origin,
            }).then(() => {
                auth = window.gapi.auth2.getAuthInstance()

            })
        }
    })

    const handleGoogleSignIn = async (e) => {
        e.preventDefault();
        try {
            if (!auth) {
                await window.gapi.auth2.init({
                    client_id: GOOGLE_OAUTH2_CLIENT_ID,
                    scope: "profile email",
                    redirect_uri: window.location.origin,
                })
                auth = window.gapi.auth2.getAuthInstance()
            }
            const gLoginResponse = await auth.signIn()


            var id_token = gLoginResponse.getAuthResponse().id_token;
            let data = {
                "token": id_token,
                "source": window.location.href,
            }

            api.googleSignIn(data)
                .then(response => {
                    Account.updateAccount(response)
                    window.location.href = `/map?q={"list_status":["active","business_negotiation"],"property_types":["house","lot","apartment"],"transaction_type":"","page_size":1000,"price":{"min":"","max":""},"sqm":{"min":"","max":""},"lot":{"min":"","max":""},"agents":["AN001","AN017","AN013","AN013","AN019","AN012","AN010","AN009","AN008","AN005","AN011","AN068","AN025","AN016","AN014","AN069","AN053","AN078"],"has_property":true}`
                })
                .catch(errorResponse => {
                    setError(errorResponse.data)
                    setTimeout(() => {
                        setError(null)
                    }, 3000);
                })



        } catch (error) {
            setError(error)
            setTimeout(() => {
                setError(null)
            }, 3000);
        }
    }

    const [data, setData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        source: window.location.href,
    })

    useEffect(() => {
        var email = query.get("email")
        if (email) {
            setData((d) => ({...d, email: email}))
        }
    }, [query])

    const [verifyEmail, setVerifyEmail] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        api.signUp(data)
            .then(response => {
                setVerifyEmail(true)
            })
            .catch(error => {
            })
    }

    const handleDataChange = (e) => {
        var { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    const disabledButton = (e) => {
        return data.email.trim().length === 0
            || data.password.trim().length === 0
            || data.first_name.trim().length === 0
            || data.last_name.trim().length === 0
            || data.confirm_password.trim().length === 0
            || (data.password !== data.confirm_password);
    }

    function onSignIn(googleUser) {
        var id_token = googleUser.getAuthResponse().id_token;
        let data = {
            "token": id_token,
            "source": window.location.href,
        }

        api.googleSignIn(data)
            .then(response => {
                Account.updateAccount(response)
                window.location.href = `/map?q={"list_status":["active","business_negotiation"],"property_types":["house","lot","apartment"],"transaction_type":"","page_size":1000,"price":{"min":"","max":""},"sqm":{"min":"","max":""},"lot":{"min":"","max":""},"agents":["AN001","AN017","AN013","AN013","AN019","AN012","AN010","AN009","AN008","AN005","AN011","AN068","AN025","AN016","AN014","AN069","AN053","AN078"],"has_property":true}`
            })
            .catch(errorResponse => {

            })

    }

    if (verifyEmail) {
        return (
            <div className="">
                <div className="min-h-screen flex items-center justify-center  py-12 px-4 lg:px-8">
                    <div className=" w-full space-y-8">

                        <div>
                            <div className="flex items-center justify-center mb-8">
                                <img src={ViilaSolid} className="w-10 h-10 mr-3"/> 
                                <span className="text-xl font-semibold">Viila Agent Dashboard</span>
                            </div>
                            <h2 className="mt-6 text-center text-gray-900">
                                <p className="text-2xl font-medium mb-2">{Translate("Thanks for signing up, great to have you!")}</p>
                                <p className="text-lg mb-8">We've just sent an email to <span className="font-medium">{data.email}</span>, please click a link to verify your email address.</p>
                                <a href="/login" className="btn rounded bg-primary text-white inline-flex items-center">
                                    {Translate("Login to your account")}
                                    <ArrowRightIcon className="w-5 h-5 ml-2"/>
                                </a>
                            </h2>
                        </div>


                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="absolute inset-0 w-full h-full overflow-hidden flex">
           
           <div className="hidden w-full lg:flex">
                <LearnMore/>
            </div>

            <div className="flex items-center justify-center px-4 lg:px-28 w-full">

                <div className="flex flex-col w-full lg:w-96 bg-white">
                   
                    <div className="grid grid-cols-1 gap-4">

                        <div className="flex items-center mb-8">
                            <img src={ViilaSolid} className="w-10 h-10 mr-3"/> 
                            <span className="text-xl font-semibold">Viila Agent Dashboard</span>
                        </div>

                        <div className="mb-4">
                            <p className="text-lg font-medium mb-2">Get started for free</p>
                            <p>Already have an account? <Link to="/login" className="text-primary">Sign in</Link> to your account.</p>
                        </div>
                        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block">
                                        <span className="text-gray-700 text-sm font-medium">{Translate("First name")}</span>
                                        <input
                                            required
                                            name="first_name"
                                            onChange={handleDataChange}
                                            type="text"
                                            className="mt-1 rounded w-full"
                                            placeholder=""
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label className="block">
                                        <span className="text-gray-700 text-sm font-medium">{Translate("Last name")}</span>
                                        <input
                                            required
                                            name="last_name"
                                            onChange={handleDataChange}
                                            type="text"
                                            className="mt-1 rounded w-full"
                                            placeholder=""
                                        />
                                    </label>
                                </div>
                            </div>
                            <label className="block">
                                <span className="text-gray-700 text-sm font-medium">{Translate("Email address")}</span>
                                <input
                                    required
                                    name="email"
                                    value={data.email}
                                    onChange={handleDataChange}
                                    type="email"
                                    className="mt-1 rounded w-full"
                                    placeholder=""
                                />
                            </label>

                            <label className="block">
                                <span className="text-gray-700 text-sm font-medium">{Translate("Password")}</span>
                                <input
                                    required
                                    name="password"
                                    onChange={handleDataChange}
                                    type="password"
                                    className="mt-1 rounded w-full"
                                    placeholder=""
                                />
                            </label>

                            <label className="block">
                                <span className="text-gray-700 text-sm font-medium">{Translate("Confirm Password")}</span>
                                <input
                                    required
                                    name="confirm_password"
                                    onChange={handleDataChange}
                                    type="password"
                                    className="mt-1 rounded w-full"
                                    placeholder=""
                                />
                            </label>

                            <div>
                                <button disabled={disabledButton()} type="submit" className="group relative w-full btn btn-primary disabled:opacity-50">
                                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">

                                        <svg className="h-5 w-5 text-white " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    {Translate("Create account")}
                                </button>
                            </div>
                        </form>


                        <div className="mt-8">
                            <div className="mx-auto text-center text-xs">
                                {TranslateToHtml("signup_agree_title")}
                            </div>
                        </div>

                    </div>

                    
                </div>
            </div>

            
        </div>
    );
}