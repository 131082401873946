import React, {Fragment} from "react"
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { BanIcon, ChatBubbleBottomCenterIcon, EyeSlashIcon, GlobeAltIcon, HomeIcon, PencilIcon,KeyIcon } from "@heroicons/react/24/outline";
import { Translate } from "../../../translate/Translate";

export default function StatusSetting({status, disabled, onClick}) {
    const style = {
        inactive: "bg-gray-100 text-gray-600",
        active: "bg-green-100 text-green-600",
        business_negotiation: "bg-yellow-100 text-yellow-500",
        sold: "bg-red-100 text-red-600",
        draft: "bg-gray-100 text-gray-600",
    }
    const icon = {
        inactive: <EyeSlashIcon className="flex-none w-4 h-4 mr-1 text-stone-500"/>,
        active: <GlobeAltIcon className="flex-none w-4 h-4 mr-1 text-success"/>,
        business_negotiation: <ChatBubbleBottomCenterIcon className="flex-none w-4 h-4 mr-1 text-orange-500"/>,
        sold: <KeyIcon className="flex-none w-4 h-4 mr-1 text-red-600"/>,
        draft: <PencilIcon className="flex-none w-4 h-4 mr-1 text-yellow-400"/>,
    }

    if (disabled === true) {
        return (<div className="col-span-3 lg:col-span-2">
            <div>
                <div className={`inline-flex  justify-center rounded-md ${style[status]} px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}>
                    <span id={`status-badge-${status}`} className={`px-2 inline-flex text-xs leading-5 font-medium rounded-full uppercase`} >
                        {icon[status]}
                        {Translate(status)}
                    </span>
                </div>
            </div>
        </div>)
    }

    const labels = {
        "draft":"Mark as Draft",
        "sold":"Mark as Sold",
        "active":"Mark as Active",
        "business_negotiation":"Mark as Negotiation",
        "inactive":"Delist this property",
    }
    let actions = []

    switch (status) {
        case "draft": 
        case "inactive": 
            actions = [
                {
                    status: "active",
                    style: "bg-green-100 text-green-600",
                    label: Translate(labels["active"]),
                }
            ]
            break;
        case "active": 
            actions = [
                {
                    status: "inactive",
                    style: "bg-gray-100 text-gray-600",
                    label: Translate(labels["inactive"]),
                },
                {
                    status: "sold",
                    style: "bg-red-100 text-red-600",
                    label: Translate(labels["sold"]),
                },
                {
                    status: "business_negotiation",
                    style: "bg-yellow-100 text-yellow-500",
                    label: Translate(labels["business_negotiation"]),
                },
            ]
            break;
        case "sold": 
        actions = [
            {
                status: "inactive",
                style: "bg-gray-100 text-gray-600",
                label: Translate(labels["inactive"]),
            },
            {
                status: "business_negotiation",
                style: "bg-yellow-100 text-yellow-500",
                label: Translate(labels["business_negotiation"]),
            },
            {
                status: "active",
                style: "bg-green-100 text-green-600",
                label: Translate(labels["active"]),
            }
        ]
            break;
        case "business_negotiation": 
        actions = [
            {
                status: "inactive",
                style: "bg-gray-100 text-gray-600",
                label: Translate(labels["inactive"]),
            },
            {
                status: "sold",
                style: "bg-red-100 text-red-600",
                label: Translate(labels["sold"]),
            },
            {
                status: "active",
                style: "bg-green-100 text-green-600",
                label: Translate(labels["active"]),
            }
        ]
            break;
        default:
    }

    return (
    <Menu as="div" className="col-span-3 lg:col-span-2">
        <div>
            <Menu.Button className={`inline-flex  justify-center rounded-md ${style[status]} px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}>
                <span id={`status-badge-${status}`} className={`px-2 inline-flex text-xs leading-5 font-medium rounded-full uppercase`}>
                    {icon[status]}
                    {Translate(status)}
                </span>
                <ChevronDownIcon
                className={`ml-2 -mr-1 h-5 w-5 ${style[status]}`}
                aria-hidden="true"
                />
            </Menu.Button>
        </div>
        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            >
            <Menu.Items className="absolute w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1 ">
                    {actions.map((action, index) => (<Menu.Item key={index} onClick={() => { onClick(action.status) }}>
                        {({ active }) => (
                        <button className={`${active ? action.style : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                            {icon[action.status]}
                            {action.label}
                        </button>
                        )}
                    </Menu.Item>))}
                </div>
            </Menu.Items>
        </Transition>
    </Menu>
    )
}