import React from "react"
import { Translate } from "../../translate/Translate";
import { useParams, useLocation } from "react-router-dom";
import moment, { lang } from "moment";
import * as api from '../../services/agent'
import * as Constants from '../../../constants'
import { Link } from 'react-router-dom';
import AppContext from "../../../AppContext";
import { ArchiveBoxArrowDownIcon, ChevronDoubleRightIcon, FlagIcon } from "@heroicons/react/24/outline";
import FullName from "../Shared/FullName";
import DateTime from "../Shared/DateTime";
import { CustomerPhotoAndName } from "./CustomerPhotoAndName";
import SellRequest from "../Shared/SellRequest";
import { PaperClipIcon } from '@heroicons/react/24/solid'
import { useDropzone } from 'react-dropzone'

export function ThreadRow(props) {

    const {
        thread,
        isSelected
    } = props

    const messageTypeString = (data) => {
        if (data && data.type == "sell_request") return Translate("sell_request")
        if (data && data.tour) return Translate("Tour")
        return Translate("Message")
    }
    return (

        <div className={` p-4 cursor-pointer ${isSelected ? "bg-gray-100" : ""}`}>
            <div className="flex items-center mb-2">
                <div>
                    <p className="font-semibold text-sm leading-none ">{messageTypeString(thread.data)} <span className="font-normal text-gray-500 ml-2 text-xs ">{moment(thread.created_at).fromNow()}</span></p>
                </div>

                <div className="ml-auto flex-none relative">
                    {
                        thread.reply_amount ?
                            <div className="flex items-center text-gray-500 gap-1">
                                <span className="text-xs font-medium">{thread.reply_amount}</span>
                                <ChevronDoubleRightIcon className="w-3 h-3" />
                            </div>
                            : thread.sent_from == 'user' ?

                                <div className="text-primary text-xs font-medium leading-none">
                                    {Translate("New")}
                                </div>
                                : null
                    }
                </div>
            </div>
            <div className="text-gray-600 text-sm flex flex-col gap-1">
                {
                    thread.data.link ?
                        <p className="line-clamp-1">{thread.data.link}</p>
                        : null
                }
                {
                    thread.data.message ?
                        <p className="line-clamp-1">{thread.data.message}</p>
                        :
                        null
                }

                {thread.data.type == "tour" ?
                    <div className="flex flex-col items-start">
                        <p><DateTime language={"ja-JP"} date={thread.data.tour.date} /></p>
                        <p>{Translate(thread.data.tour.preferred_time)} · {Translate(thread.data.tour.type)}</p>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    )
}

export function ThreadDetail(props) {
    const account = props.account;
    const [isOpen, setIsOpen] = React.useState(false)
    const { language } = React.useContext(AppContext)
    const { data, reloadThreadDetail } = props
    const id = data.thread.id;
    const [replyingMessage, setReplyingMessage] = React.useState({
        data: {
            message: "",
            attachments: []
        }
    })

    const handleMessageChange = (e) => {
        replyingMessage.data.message = e.target.value
        setReplyingMessage({ ...replyingMessage })
    }

    const onSendMessage = (e) => {
        api.replyToThread(data.thread.id, replyingMessage)
            .then(response => {
                replyingMessage.data = {
                    message: "",
                    attachments: []
                }
                setReplyingMessage({ ...replyingMessage })
                reloadThreadDetail()
            })
            .catch(error => {
                console.log(error)
            })
    }

    const onDrop = React.useCallback((acceptedFiles, fileRejections) => {
        acceptedFiles.map((file) => {
            //add fill temp
            let image = {
                url: URL.createObjectURL(file),
            }

            var reader = new FileReader();
            reader.onload = function (event) {
                var imageData = event.target.result;
                var base64Data = imageData.split("base64,")[1];
                var fileName = file.name.replace(/\.[^/.]+$/, "")
                api.uploadImage(base64Data, fileName)
                    .then(response => {
                        let imageUrl = response.data;
                        replyingMessage.data.attachments.push(imageUrl)
                        setReplyingMessage({ ...replyingMessage })
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
            reader.readAsDataURL(file);
        });

        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === "file-too-large") {
                    alert("file size is exceed limit (10MB)")
                }
            })
        })
    }, [replyingMessage])

    const dropMessageAttachments = useDropzone({
        onDrop: onDrop,
        accept: 'image/jpeg, image/png, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        multiple: false,
        maxSize: 10 * 1024 * 1024, // 10MB
    });

    const onRemoveAttachmentAtIndex = (index) => {
        replyingMessage.data.attachments.splice(index, 1)
        setReplyingMessage({ ...replyingMessage })
    }

    return (
        <div className="flex flex-col gap-4">
            {/* this should show all the messages in this thread */}
            {data.thread.sent_from == "agent" ?
                <div key={data.thread.id} className="rounded-lg border shadow-sm w-full bg-white">
                    <div className="p-6">
                        <div className="flex mb-2">
                            <p>
                                <span className="font-semibold">
                                    <FullName firstname={data.thread.receiver.first_name} lastname={data.thread.receiver.last_name} />
                                </span>
                            </p>
                            <p className="ml-auto text-gray-500 text-sm">
                                <DateTime language={language} date={data.thread.created_at} />
                            </p>
                        </div>
                        <div className="text-gray-600 whitespace-pre">
                            {data.thread.data.message}
                        </div>
                    </div>
                </div>
                : null
            }
            {data.thread.sent_from == "user" ?
                <div key={data.id} className="rounded-lg border shadow-sm w-full bg-white">

                    <div className="p-6">
                        <div className="flex mb-4">


                            <CustomerPhotoAndName customer={data.thread.sender} />

                            <p className="ml-auto text-gray-500 text-sm">
                                <DateTime language={language} date={data.thread.created_at} />
                            </p>
                        </div>
                        <div className="text-gray-600">
                            {data.thread.data.link ?
                                <p className="mb-2"><a className="text-blue-600" href={data.thread.data.link}>{data.thread.data.link}</a></p>
                                : null
                            }

                            {
                                data.thread.data.message ?
                                    <p className=" whitespace-pre">{data.thread.data.message}</p>
                                    :
                                    null
                            }


                            {data.thread.data.type == "tour" ?
                                <div className="">
                                    <p>
                                        <DateTime language={language} date={data.thread.data.tour.date} />
                                        &nbsp;·&nbsp;{Translate(data.thread.data.tour.preferred_time)}&nbsp;·&nbsp;{Translate(data.thread.data.tour.type)}
                                    </p>
                                </div>
                                :
                                null
                            }

                            {
                                data.thread.property.id ?
                                    <div className="mt-4 border rounded overflow-hidden w-80">
                                        <a href={`${Constants.BASE_URL}/p/${data.thread.property.property_id}`} target="_blank">
                                            {data.thread.property.images.length > 0 ?
                                                <img className="w-80 h-36 object-cover" src={data.thread.property.images[0].url} />
                                                :
                                                <img className="w-80 h-36 object-cover bg-gray-400" />
                                            }
                                            <div className="p-2">
                                                <p className="font-medium text-gray-500 text-xs truncate overflow-ellipsis">{data.thread.property.name}</p>
                                                <p className="font-medium text-gray-500 text-xs truncate overflow-ellipsis">{data.thread.property.property_id}</p>
                                            </div>
                                        </a>
                                    </div>
                                    : null
                            }
                            {data.thread.data.type == "sell_request" ?
                                <>
                                    <button type="button" onClick={(e) => { setIsOpen(true) }} className="mt-8 disabled:opacity-50 bg-blue-600 text-white text-sm font-semibold px-3 py-2 rounded">{Translate("sell_request")}</button>
                                    <SellRequest isOpen={isOpen} setIsOpen={setIsOpen} data={data.thread.data.data} language={language} />
                                </> : null}

                        </div>
                    </div>
                </div> : null}

            {data.thread.replies ? data.thread.replies.map((m, i) =>
                <div key={m.id} className="rounded-lg border shadow-sm w-full bg-white">
                    <div className="p-6">
                        <div className="flex mb-2">
                            <p>
                                <span className="font-semibold">
                                    <FullName firstname={m.user.first_name} lastname={m.user.last_name} />
                                </span>
                            </p>
                            <p className="ml-auto text-gray-500 text-sm">
                                <DateTime language={language} date={m.created_at} />
                            </p>
                        </div>
                        <div className="text-gray-600 whitespace-pre">
                            {m.data.mark_as_replied ? Translate("marked_as_replied") : m.data.message}

                            {
                                m.data.attachments ? m.data.attachments.map((file, index) => (
                                    <div className="text-sm flex items-center flex-wrap gap-x-5 gap-y-2 mb-2 mt-2">
                                        <div className="flex items-center gap-2 text-gray-500">
                                            <PaperClipIcon className='w-4 h-4' />
                                            <a target="_blank" href={file}>{file.substring(file.lastIndexOf('/') + 1)}</a><span></span>
                                        </div>
                                    </div>

                                )) : null

                            }
                        </div>

                    </div>
                </div>
            )
                : null}

            <div className="rounded-lg border shadow-sm w-full bg-white m-4">
                <div className="px-4 pt-4 font-semibold">{Translate("Reply")}</div>
                <div className="px-4 pt-4 pb-3">
                    <textarea value={replyingMessage.data.message} onChange={handleMessageChange} className="w-full rounded border border-gray-300 shadow-sm disabled:bg-gray-200" placeholder={Translate("Type reply message here")} rows={3}></textarea>
                </div>

                <div className="flex items-center ml-4" {...dropMessageAttachments.getRootProps()}>
                    <input className="w-full h-full" {...dropMessageAttachments.getInputProps()} />
                    <button className="text-gray-500 font-meidum flex items-center">
                        <PaperClipIcon className="w-5 h-5 mr-1" />
                        <span className="text-sm italic">{Translate("Attach a file")}</span>
                    </button>
                    <div className="flex gap-6 m-4">
                        {
                            replyingMessage.data.attachments && replyingMessage.data.attachments.map((fileURL, index) => (
                                <div key={`file-${index}`} className="flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                    </svg>
                                    <span className="text-xs">{fileURL.substring(fileURL.lastIndexOf('/') + 1)}</span>
                                    <button type="button" onClick={(e) => onRemoveAttachmentAtIndex(index)} className="ml-2 text-red-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                    </button>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="border-t p-4">
                    <button disabled={replyingMessage.data.message.trim().length === 0 && replyingMessage.data.attachments.length === 0 } type="button" onClick={(e) => { onSendMessage() }} className="disabled:opacity-50 bg-blue-600 text-white text-sm font-semibold px-3 py-2 rounded">{Translate("Send Message")}</button>
                </div>
            </div>

        </div>
    )
}

export default function CustomerDetailMessages(props) {
    const { hash } = useLocation();
    let { customerId, id } = useParams();
    const { account, customer, query, history, loadCustomerInfo, loadCustomers } = props
    const bottomOfTheChatRef = React.useRef(null);
    const [selectedDetail, setSelectedDetail] = React.useState(null)
    const [selectedId, setSelectedId] = React.useState("")

    React.useEffect(() => {
        if (customerId)
            loadCustomerInfo(customerId)
    }, [customerId])

    React.useEffect(() => {
        if (customer === null) return;
        bottomOfTheChatRef.current.scrollIntoView({
            behavior: "smooth",
        });

        if (customer.messages && customer.messages.length > 0) {
            setSelectedId(id ? id : customer.messages[0].id)
        } else {
            setSelectedDetail(null);
            setSelectedId("")
        }

    }, [customer])

    React.useEffect(() => {
        setSelectedDetail(null);
        history.replace(`/v2/customers/${customerId}/inbox${selectedId ? `/${selectedId}` : ""}${hash ? hash : ""}${query.get("search") ? "?search=" + query.get("search") : ""}`)
        if (selectedId)
            loadThreadDetail(customerId, selectedId)
        else
            setSelectedId("")

    }, [selectedId])


    const loadThreadDetail = (customerNo, threadId) => {
        api.customerThreadInfo(customerNo, "thread", threadId)
            .then(data => {
                setSelectedDetail(data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const reloadThreadDetail = () => {
        loadCustomers()
        loadCustomerInfo(customerId)
        loadThreadDetail(customerId, selectedId)
    }

    const onArchive = () => {
        if (selectedId)
            api.archiveThread(selectedId)
                .then(data => {
                    setSelectedId("")
                    setSelectedDetail(null);
                    loadCustomerInfo(customerId)
                })
                .catch(error => {
                    console.log(error);
                })
    }

    const goReply = () => {
        bottomOfTheChatRef.current.scrollIntoView({
            behavior: "smooth",
        });
    }

    const onMarkAsReplied = (e) => {
        e.preventDefault();
        if (selectedId) {
            let markasReplied = {
                data: {
                    mark_as_replied: true,
                    message: ""
                },
                user: {
                    id: account.user.id,
                    first_name: account.user.first_name,
                    last_name: account.user.last_name
                }
            };
            api.replyToThread(selectedId, markasReplied)
                .then(response => {
                    reloadThreadDetail()
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    return (
        <div className="flex-1 flex relative  h-full overflow-hidden  overflow-y-auto">
            <div className="border-r w-72 flex flex-col h-full overflow-hidden  overflow-y-auto divide-y">
                {customer.messages ? customer.messages.map((thread, i) =>
                    <div className="w-full" key={`thread-${thread.id}`} onClick={() => { setSelectedId(thread.id) }} >
                        <ThreadRow thread={thread} isSelected={thread.id === selectedId} />
                    </div>
                )
                    : null
                }
            </div>

            <div className="flex-1 flex flex-col relative  h-full overflow-hidden  overflow-y-auto bg-gray-100">

                {selectedDetail ? <div className="border-b px-4 py-2 bg-white">
                    <div className="flex items-center gap-4">

                        <button onClick={(e) => { onMarkAsReplied(e) }} className="inline-flex items-center px-2 py-2 hover:bg-primary/10 rounded border text-primary border-primary">
                            <FlagIcon className="w-5 h-5" />
                            <span className="text-sm font-semibold ml-2">{Translate("mark_as_replied_button")}</span>
                        </button>

                        <button onClick={onArchive} className="ml-auto inline-flex items-center px-2 py-2 hover:bg-red-50 rounded border text-red-400 border-red-400">
                            <ArchiveBoxArrowDownIcon className="w-5 h-5" />
                            <span className="text-sm font-semibold ml-2">{Translate("Archive")}</span>
                        </button>
                    </div>
                </div> : null}
                <div className="flex-1 px-4 py-4 overflow-hidden overflow-y-auto">
                    <div className="flex flex-col gap-4 ">
                        {
                            selectedDetail ?
                                <ThreadDetail reloadThreadDetail={reloadThreadDetail} data={selectedDetail} account={account}></ThreadDetail>
                                : null
                        }
                        <a ref={bottomOfTheChatRef} name="bottom" className="h-0"></a>
                    </div>
                </div>

            </div>
        </div>
    )
}

