import { useMemo } from "react"

export default function PriceRange(props) {
    const { data } = props

    // const scale = useMemo(() => {
    //     if (data.min_price_per_sqm < 100000) {
    //         return 1000
    //     }
    //     if (data.min_price_per_sqm < 100000) {
    //         return 10000
    //     }
    //     if (data.min_price_per_sqm < 800000) {
    //         return 10000
    //     }
    //     if (data.min_price_per_sqm < 1000000) {
    //         return 100000
    //     }
    //     return 100000
    // }, [data])
    const scale = 100000

    if (data.counts < 2) {
        return (
            <div className="w-full text-center flex items-center justify-center">
                <div className="w-0.5 h-10 bg-green-600 absolute"></div>
            </div>
            )
    }

    return (
        <div className="w-full text-center flex items-center justify-center">
            <div className="w-0.5 h-16 bg-green-600 absolute"></div>
            <div className="flex-1 flex items-center justify-end">
                <span className="mr-4">{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(data.min_price_per_sqm))}</span>
                <div className={`bg-green-500 h-5`} style={{ width: ( data.min_price_per_sqm) / scale }}></div>
            </div>
            <div className="flex-none w-0.5 h-full relative flex items-start justify-center bg-green-600">
                <div className="w-0.5 h-16 bg-green-600 absolute bottom-0"></div>
                <div className="w-0.5 h-16 bg-green-600 absolute top-0"></div>
            </div>
            <div className="flex-1 flex items-center justify-start">
            <div className={`bg-green-500 h-5`} style={{ width: (data.max_price_per_sqm) / scale }}></div>
                <span>{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(data.max_price_per_sqm))}</span>
            </div>

            
            {/* <div className={`bg-green-500 h-3`} style={{ width: (data.max_price_per_sqm - data.min_price_per_sqm) / scale }}>
                <div className="w-0.5 h-full bg-green-600" style={{ marginLeft: ((data.avg_price_per_sqm - data.min_price_per_sqm) / scale) }}></div>
            </div> */}
            
        </div>
    )
}