import * as Constants from '../../../constants'
import { Translate } from "../../translate/Translate";


export default function PropertyFeedRow(props) {
    const {property} = props

    const slug = (text = '') => {
        return text.trim().toLowerCase().replaceAll(" ", "-")
    }
    
    const FloorAreaRatio = (data) => {
        const f = data.features.find((f) => f.title == "Floor Area Ratio")
        if (f) {
            return f.value
        }

        return ""
    }
    const BuildingCoverageRatio = (data) => {
        const f = data.features.find((f) => f.title == "Building Coverage Ratio")
        if (f) {
            return f.value
        }

        return ""
    }

    const HasSingleFAR = (data) => {
        return FloorAreaRatio(data).split("").filter((str) => str == "%").length == 1
    }

    const BulidingSizeExceedRatio = (data) => {
        if (data.property_type == 'apartment') {
            return false
        }
        if (FloorAreaRatio(data) == "" || HasSingleFAR(data) == false) {
            return false
        }
        return data.sqm > data.land.sqm
    }

    const FloorAreaSQM = (data) => {
        const f = data.features.find((f) => f.title === "Floor Area Ratio")
        if (f) {
            return (+f.value) * data.land.sqm
        }
        return 0
    }

    const HasBCRandFAR = (data) => {
        return FloorAreaRatio(data) !== "" && BuildingCoverageRatio(data) !== ""
    }

    const HasSingleBCR = (data) => {
        return BuildingCoverageRatio(data).split("").filter((str) => str == "%").length == 1
    }

    const BuildingCoverageSQM = (data) => {
        return +BuildingCoverageRatio(data) * data.land.sqm / 100
    }

    const PricePerSQM = (data) => {
        let size = data.sqm
        if (data.property_type === 'lot'){
            size = data.land.sqm
        }

        if (size <= 0) {
            size = 1
        }

        let perSQM = data.price.amount / size
        return perSQM
    }

    return (
        <div className="bg-white lg:border shadow-sm hover:shadow-lg overflow-hidden relative transition duration-500 ease-in-out transform">
            <div className="flex items-center p-4">
                <a className="inline-flex items-center" href={`${Constants.BASE_URL}/agents/${property.listed_by.agent_id}/${slug(property.listed_by.company.name)}`} rel="canonical">
                    <div className="flex flex-col ml-2 truncate text-ellipsis">
                        <div className="text-xs mt-0.5 text-gray-500 truncate text-ellipsis">
                            <div className="flex items-center">
                                <p className="capitalize text-xs ">{property.area.name}&nbsp;·&nbsp;{property.property_type}&nbsp;·&nbsp;{property.property_id}&nbsp;·&nbsp;</p>
                                {/* <div className="inline-flex items-center " title="ページビュー数">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                                        </svg>
            
                                    <span className="text-xs">0</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            
            <div className="w-full flex-none relative">
                <a href={`${Constants.BASE_URL}/${slug(property.area.id)}/${slug(property.name)}/${property.property_id}`} rel="canonical"> 
                    <div className="absolute p-2 z-20">
                        <div className="flex flex-wrap">
                            <div className="mr-2 mb-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs uppercase focus:outline-none bg-black bg-opacity-50">
                                <svg className="fill-current text-white w-3 h-3" viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.44531 13.9531H4.89062V15.2969C4.89062 16.9062 5.71094 17.7188 7.34375 17.7188H19.1953C20.8203 17.7188 21.6484 16.9062 21.6484 15.2969V6.99219C21.6484 5.39062 20.8203 4.57812 19.1953 4.57812H17.7422V3.3125C17.7422 1.70312 16.9219 0.890625 15.2891 0.890625H3.44531C1.80469 0.890625 0.992188 1.70312 0.992188 3.3125V11.5391C0.992188 13.1484 1.80469 13.9531 3.44531 13.9531ZM3.46094 12.6953C2.67969 12.6953 2.25 12.2812 2.25 11.4688V3.38281C2.25 2.57031 2.67969 2.14844 3.46094 2.14844H15.2734C16.0391 2.14844 16.4844 2.57031 16.4844 3.38281V4.57812H7.34375C5.71094 4.57812 4.89062 5.38281 4.89062 6.99219V12.6953H3.46094ZM6.14844 7.0625C6.14844 6.25 6.58594 5.83594 7.36719 5.83594H19.1719C19.9453 5.83594 20.3906 6.25 20.3906 7.0625V13.5859L17.5234 10.8828C17.1797 10.5625 16.7734 10.4062 16.3438 10.4062C15.9141 10.4062 15.5391 10.5547 15.1719 10.875L11.6641 13.9844L10.2656 12.7188C9.92969 12.4297 9.57812 12.2734 9.20312 12.2734C8.85938 12.2734 8.53125 12.4141 8.20312 12.7109L6.14844 14.4922V7.0625ZM10.3906 11.0781C11.3984 11.0781 12.2266 10.25 12.2266 9.22656C12.2266 8.22656 11.3984 7.38281 10.3906 7.38281C9.375 7.38281 8.54688 8.22656 8.54688 9.22656C8.54688 10.25 9.375 11.0781 10.3906 11.0781Z"></path>
                                </svg>
                                <span className="ml-1 font-semibold text-white text-xs">{property.images.length}</span>
                            </div>
                            {property.hide_location ? (<div className="mr-2 mb-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs uppercase focus:outline-none bg-black bg-opacity-50">
                                <span className="ml-1 font-semibold text-white text-xs">{Translate("no_location_title")}</span>
                            </div>): null}
                            
                            <div className="bg-red-600 font-semibold text-white px-2 py-0.5 rounded-full text-xs uppercase mr-2 mb-2 truncate">{Translate("tag_new")}</div>
                        </div>
                    </div>
                    
                    {property.images.length === 0 ? (<div className="z-0 h-64 w-full object-cover bg-gray-100 relative">
                    </div>): (
                    <div className="w-full relative h-64 z-10">
                        <img id="main-image" alt="" decoding="async" className={`absolute top-0 left-0 ${property.images.length > 1 ? "hover:opacity-0": ""} lazyload z-0 w-full h-64 object-cover`}
                            src={property.images[0].url} data-src={property.images[0].url} />
                            {property.images.length > 1 ? (<img alt="" decoding="async" className="lazyload z-0 w-full h-64 object-cover "
                                src={property.images[1].url} data-src={property.images[1].url} />): null}
                    </div>)}
                </a>
            </div>

            <div className="w-full p-4">
                <a href={`${Constants.BASE_URL}/${slug(property.area.id)}/${slug(property.name)}/${property.property_id}`} rel="canonical"> 
                    <div className=" "> 
                        <p className="text-sm lg:truncate">{property.name}</p>
                        
                        <div className="flex items-baseline">
                            <p className="font-bold text-lg leading-8">{new Intl.NumberFormat('ja-JP',{ style: 'currency', currency: property.price.currency ?? "JPY" }).format(Number(property.price.amount)) }</p>
                        </div>
                        

                        <div className="flex mt-1">
                            <div className="flex space-x-6 lg:space-x-4 truncate overflow-ellipsis">

                                {property.property_type === "house" ? (<>
                                    <div>
                                        <p className="text-xs text-gray-600">{Translate("Floor plan")}</p>
                                        <p className="text-sm font-medium truncate overflow-ellipsis">{property.layout.name}</p>
                                    </div>

                                    <div className="tooltip">
                                        <p className="text-xs text-gray-600">{Translate("Building size")}</p>
                                        <p className={`text-sm font-medium ${BulidingSizeExceedRatio(property) ? "border-b border-dashed border-red-600": ""}`}>{property.sqm.toLocaleString()}m²</p>
                                        {BulidingSizeExceedRatio(property) ? (
                                        <div className="tooltip-text -mt-24 bg-gray-600 text-white text-xs shadow rounded">
                                            {Translate("Building size exceeds Floor Area Ratio")}<br /> 
                                            {FloorAreaRatio(property)} · {FloorAreaSQM(property)}m²
                                        </div>): null}
                                    </div>
                                    <div>
                                        <p className="text-xs text-gray-600">{Translate("Land size")}</p>
                                        <p className="text-sm font-medium">{property.land.sqm.toLocaleString()}m²</p>
                                    </div>

                                    {HasBCRandFAR(property) ? (
                                    <div className="tooltip">
                                        <p className="text-xs text-gray-600">{Translate("BCR")}/{Translate("FAR")}</p>
                                        <p className="text-sm font-medium">{BuildingCoverageRatio(property)}/{FloorAreaRatio(property)}</p>
                                        {HasSingleBCR(property) && HasSingleFAR(property)? (<div className="tooltip-text -mt-32 -ml-12 bg-gray-600 text-white text-xs shadow rounded">
                                            <p>{Translate("Building Coverage Ratio")}</p>
                                            <p className="mb-1">{BuildingCoverageSQM(property)}m²</p>
                                            <p>{Translate("Floor Area Ratio")}</p>
                                            <p>{FloorAreaSQM(property)}m²</p>
                                        </div>): null}
                                    </div>):null}
                                </>): null}


                                {property.property_type === "apartment" ? (<>

                                    {property.floor !== "" ? (<div>
                                        <p className="text-xs text-gray-600">{Translate("room_location_floor_title")}/{Translate("total_floors_title")}</p>
                                        <p className="text-sm font-medium">{property.floor}/{property.amount_of_floors}</p>
                                    </div>):null}

                                    <div>
                                        <p className="text-xs text-gray-600">{Translate("Floor plan")}</p>
                                        <p className="text-sm font-medium">{property.layout.name}</p>
                                    </div>
                                    <div>
                                        <p className="text-xs text-gray-600">{Translate("Building size")}</p>
                                        <p className="text-sm font-medium">{property.sqm.toLocaleString()}m²</p>
                                    </div>
                                    <div>
                                        <p className="text-xs text-gray-600">{Translate("Price per m²")}</p>
                                        <p className="text-sm font-medium">{PricePerSQM(property)}/m²</p>
                                    </div>
                                </>): null}

                                {property.property_type === "lot" ? (<>
                                    <div>
                                        <p className="text-xs text-gray-600">{Translate("Land size")}</p>
                                        <p className="text-sm font-medium">{property.land.sqm}}m²</p>
                                    </div>
                                    <div>
                                        <p className="text-xs text-gray-600">{Translate("Price per m²")}</p>
                                        <p className="text-sm font-medium">{PricePerSQM(property)}/m²</p>
                                    </div>
                                    {HasBCRandFAR(property) ? (<div className="tooltip">
                                        <p className="text-xs text-gray-600">{Translate("BCR")}/{Translate("FAR")}</p>
                                        <p className="text-sm font-medium">{BuildingCoverageRatio(property)}/{FloorAreaRatio(property)}</p>
                                        {HasSingleBCR(property) && HasSingleFAR(property) ? (
                                            <div className="tooltip-text -mt-32 -ml-12 bg-gray-600 text-white text-xs shadow rounded">
                                                <p>{Translate("Building Coverage Ratio")}</p>
                                                <p className="mb-1">{BuildingCoverageSQM(property)}m²</p>
                                                <p>{Translate("Floor Area Ratio")}</p>
                                                <p>{FloorAreaSQM(property)}m²</p>
                                            </div>
                                        ):null}
                                    </div>):null}
                                </>): null}
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}