import { HomeIcon, MapPinIcon } from "@heroicons/react/24/outline"
import DateTime from "../../Shared/DateTime"

export default function SellerRequestDetailBox(props) {
    const {Translate,data} = props
    const propertyDetail = [
        "floor_plan",
        "lot_sqm",
        "apartment_name",
        "room_sqm",
        "landshape",
        "structure"
    ]
    return (
    <div className="bg-white rounded overflow-hidden ring-1 ring-slate-200">
        <div className="flex flex-col ">
            <div className="p-5 border-b flex">
                <div>
                    <p className="text-lg font-medium">{data.sell_request_no}</p>
                    <p className="text-xs text-gray-500">
                    {Translate("Submitted at")}: <DateTime date={data.created_at} />
                    </p>
                </div>
                <div className="ml-auto">
                </div>
            </div>
            <div className="p-5 bg-white flex flex-col gap-5">
                
                <div className="flex items-start flex-col">
                    <div className="w-full">
                        <p className="font-medium flex items-center gap-1">
                            <MapPinIcon className="w-5 h-5"/>
                            <span>{Translate("Address")}</span>
                        </p>
                    </div>
                    <div className="flex flex-col w-full">
                        <div className="flex flex-col items-start w-full py-2">
                            <p className="text-gray-500 text-sm font-medium">
                                {Translate("Address")}
                            </p>
                            <p className="font-medium">{data.data.full_address}</p>
                        </div>
                        {data.viila_data && data.viila_data.lot_number_url ? <iframe src={data.viila_data.lot_number_url+"/embed"}></iframe>  : ""}
                    </div>
                </div>

                <div className="flex items-start flex-col">
                    <div className="w-full">
                        <p className=" flex items-center gap-1">
                            <HomeIcon className="w-5 h-5"/>
                            <span className="font-medium">{Translate("Property Info")}</span>
                        </p>
                    </div>

                    <div className="flex flex-col w-full divide-y">
                        <div className="flex flex-col items-start w-full py-2">
                            <p className="text-gray-500 text-sm">{Translate("property_type")}</p>
                            <div className=" flex flex-col">
                                <p className="font-medium">{Translate(data.property_type)}</p>
                            </div>
                        </div>
                        {propertyDetail.filter((key) => data.data[key] !== undefined).map((key) => <div key={key} className="flex flex-col items-start w-full py-2">
                            <p className="text-gray-500 text-sm">{Translate(key)}</p>
                            <div className=" flex flex-col">
                                <p className="font-medium">{Translate(data.data[key])}{["room_sqm", "lot_sqm"].includes(key) ? ` ${Translate("m²")}` : null}</p>
                            </div>
                        </div>)}
                        {data.property_type === "house" && data.data["construction_year"] && data.data["construction_year"] !== "" && (<div className="flex flex-col items-start w-full py-2">
                            <p className="text-gray-500 text-sm">{Translate("Built")}</p>
                            <div className=" flex flex-col">
                                <p className="font-medium">{data.data["construction_year"]}</p>
                            </div>
                        </div>)}
                    </div>
                </div>

                <div className="flex flex-col gap-2">
                    <div className="w-full">
                        <p className="font-semibold flex items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                            </svg>
                            <span>{Translate("Sell Info")}</span>
                        </p>
                    </div>
                    <div className="flex flex-col w-full divide-y">
                        <div className="flex flex-col items-start w-full py-2">
                            <p className="text-gray-500 text-sm">{Translate("property_condition")}</p>
                            <div className=" flex flex-col">
                                <p className="font-medium">{Translate(data.data.property_condition)}</p>
                            </div>
                        </div>
                        <div className="flex flex-col items-start w-full py-2">
                            <p className="text-gray-500 text-sm">{Translate("reason_to_sell")}</p>
                            <div className=" flex flex-col">
                                {data.data.reason_to_sell.map((item, index) => (<p key={index} className="font-semibold">{item == "others" ? Translate(item) + " (" + data.data["reason_to_sell-others"] + ") " : Translate(item)}</p>))}
                            </div>
                        </div>
                        <div className="flex flex-col items-start w-full py-2">
                            <p className="text-gray-500 text-sm">{Translate("request_to_agents")}</p>
                            <div className=" flex flex-col">
                                {data.data.request_to_agents && data.data.request_to_agents.map((item, index) => (<p key={index} className="font-semibold">{item == "others" ? Translate(item) + " (" + data.data["request_to_agents-others"] + ") " : Translate(item)}</p>))}
                            </div>
                        </div>
                        <div className="flex flex-col items-start w-full py-2">
                            <p className="text-gray-500 text-sm">{Translate("property_owner")}</p>
                            <div className=" flex flex-col">
                                <p className="font-medium">{Translate(data.data.property_owner)}</p>
                            </div>
                        </div>
                        <div className="flex flex-col items-start w-full py-2">
                            <p className="text-gray-500 text-sm">{Translate("time_to_sell")}</p>
                            <div className=" flex flex-col">
                                <p className="font-medium">{Translate(data.data.time_to_sell)}</p>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    </div>)
}