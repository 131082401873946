import React from "react"
import { Link } from 'react-router-dom';

export default function Term(props) {
    const { Translate } = props

    return (
        <div>

            <nav className="w-full  border-b bg-white sticky top-0 z-50">

                <div className="flex container mx-auto py-2  px-5">
                    <Link to="/" className="flex items-center text-gray-800">
                        <svg className="h-8 w-8" width="86" height="81" viewBox="0 0 86 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 41.9772C0 43.7729 1.40638 45.3269 3.65658 45.3269C4.74652 45.3269 5.73099 44.7398 6.60998 44.0492L10.6182 40.734V72.8501C10.6182 77.9611 13.7473 81 19.1267 81H66.6975C72.0417 81 75.206 77.9611 75.206 72.8501V40.5613L79.4252 44.0492C80.269 44.7398 81.2535 45.3269 82.3434 45.3269C84.4178 45.3269 86 44.0492 86 42.0462C86 40.8721 85.5429 39.9397 84.6288 39.18L75.206 31.3754V16.6641C75.206 15.1101 74.1864 14.1432 72.6042 14.1432H67.7522C66.2052 14.1432 65.1505 15.1101 65.1505 16.6641V23.0874L48.0278 8.96316C44.9689 6.44222 41.1014 6.44222 38.0425 8.96316L1.40638 39.18C0.457073 39.9397 0 40.9757 0 41.9772Z" fill="black" />
                            <path d="M75.6069 5.30434C75.6069 8.23385 73.189 10.6087 70.2064 10.6087C67.2238 10.6087 64.8059 8.23385 64.8059 5.30434C64.8059 2.37483 67.2238 0 70.2064 0C73.189 0 75.6069 2.37483 75.6069 5.30434Z" fill="black" />
                        </svg>
                        <span className="text-lg font-bold ml-2">Agent Dashboard</span>
                    </Link>
                    <div className="ml-auto">
                        <Link to="/login" className="flex items-center btn btn-primary">
                            <span className="mr-1">{Translate("Sign in")}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                            </svg>
                        </Link>
                    </div>
                </div>
            </nav>


            <div className="w-full relative overflow-hidden bg-slate-50">

                <div className="container mx-auto px-8 my-12">
                    <h1><strong>利用規約</strong></h1>
                    <p><span>この利用規約（以下，「本規約」といいます。）は，Viila Technologies株式会社（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。</span></p>
                    <h2 className="text-xl mb-4 mt-8"><strong>第1条（適用）</strong></h2>
                    <ol>
                        <li><span>本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。</span></li>
                        <li><span>当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。</span></li>
                        <li><span>本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。</span></li>
                    </ol>
                    <h2 className="text-xl mb-4 mt-8"><strong>第2条（利用登録）</strong></h2>
                    <ol>
                        <li><span>本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。</span></li>
                        <li><span>当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。</span></li>
                        <ol>
                            <li><span>利用登録の申請に際して虚偽の事項を届け出た場合</span></li>
                            <li><span>本規約に違反したことがある者からの申請である場合</span></li>
                            <li><span>その他，当社が利用登録を相当でないと判断した場合</span></li>
                        </ol>
                    </ol>
                    <h2 className="text-xl mb-4 mt-8"><strong>第3条（ユーザーIDおよびパスワードの管理）</strong></h2>
                    <ol>
                        <li><span>ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを適切に管理するものとします。</span></li>
                        <li><span>ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。当社は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。</span></li>
                        <li><span>ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は，当社に故意又は重大な過失がある場合を除き，当社は一切の責任を負わないものとします。</span></li>
                    </ol>
                    <h2 className="text-xl mb-4 mt-8"><strong>第4条（禁止事項）</strong></h2>
                    <p><span>ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。</span></p>
                    <ol>
                        <li><span>法令または公序良俗に違反する行為</span></li>
                        <li><span>犯罪行為に関連する行為</span></li>
                        <li><span>本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為</span></li>
                        <li><span>当社，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為</span></li>
                        <li><span>本サービスによって得られた情報を商業的に利用する行為</span></li>
                        <li><span>当社のサービスの運営を妨害するおそれのある行為</span></li>
                        <li><span>不正アクセスをし，またはこれを試みる行為</span></li>
                        <li><span>他のユーザーに関する個人情報等を収集または蓄積する行為</span></li>
                        <li><span>不正な目的を持って本サービスを利用する行為</span></li>
                        <li><span>本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為</span></li>
                        <li><span>他のユーザーに成りすます行為</span></li>
                        <li><span>当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為</span></li>
                        <li><span>面識のない異性との出会いを目的とした行為</span></li>
                        <li><span>当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為</span></li>
                        <li><span>その他，当社が不適切と判断する行為</span></li>
                    </ol>
                    <h2 className="text-xl mb-4 mt-8"><strong>第5条（本サービスの提供の停止等）</strong></h2>
                    <ol>
                        <li><span>当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</span></li>
                        <ol>
                            <li><span>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</span></li>
                            <li><span>地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合</span></li>
                            <li><span>コンピュータまたは通信回線等が事故により停止した場合</span></li>
                            <li><span>その他，当社が本サービスの提供が困難と判断した場合</span></li>
                        </ol>
                        <li><span>当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。</span></li>
                    </ol>
                    <h2 className="text-xl mb-4 mt-8"><strong>第6条（利用制限および登録抹消）</strong></h2>
                    <ol>
                        <li><span>当社は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。</span></li>
                        <ol>
                            <li><span>本規約のいずれかの条項に違反した場合</span></li>
                            <li><span>登録事項に虚偽の事実があることが判明した場合</span></li>
                            <li><span>料金等の支払債務の不履行があった場合</span></li>
                            <li><span>当社からの連絡に対し，一定期間返答がない場合</span></li>
                            <li><span>本サービスについて，最終の利用から一定期間利用がない場合</span></li>
                            <li><span>その他，当社が本サービスの利用を適当でないと判断した場合</span></li>
                        </ol>
                        <li><span>当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。</span></li>
                    </ol>
                    <h2 className="text-xl mb-4 mt-8"><strong>第7条（退会）</strong></h2>
                    <p><span>ユーザーは，当社の定める退会手続により，本サービスから退会できるものとします。</span></p>
                    <h2 className="text-xl mb-4 mt-8"><strong>第8条（保証の否認および免責事項）</strong></h2>
                    <ol>
                        <li><span>当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。</span></li>
                        <li><span>当社は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。</span></li>
                        <li><span>前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。</span></li>
                        <li><span>当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。</span></li>
                    </ol>
                    <h2 className="text-xl mb-4 mt-8"><strong>第9条（サービス内容の変更等）</strong></h2>
                    <p><span>当社は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。</span></p>
                    <h2 className="text-xl mb-4 mt-8"><strong>第10条（利用規約の変更）</strong></h2>
                    <p><span>当社は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。</span></p>
                    <h2 className="text-xl mb-4 mt-8"><strong>第11条（個人情報の取扱い）</strong></h2>
                    <p><span>当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。</span></p>
                    <h2 className="text-xl mb-4 mt-8"><strong>第12条（通知または連絡）</strong></h2>
                    <p><span>ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。当社は,ユーザーから,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。</span></p>
                    <h2 className="text-xl mb-4 mt-8"><strong>第13条（権利義務の譲渡の禁止）</strong></h2>
                    <p><span>ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。</span></p>
                    <h2 className="text-xl mb-4 mt-8"><strong>第14条（準拠法・裁判管轄）</strong></h2>
                    <ol>
                        <li><span>本規約の解釈にあたっては，日本法を準拠法とします。</span></li>
                        <li><span>本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。</span></li>
                    </ol>
                    <p>
                        <br />
                        <br />
                    </p>
                </div>
            </div>
            <div id="footer" className="py-10 bg-gray-900">
                <div className="container mx-auto p-4">
                    <p className="text-sm text-gray-500">©Viila Technologies株式会社. All rights reserved.</p>
                </div>
            </div>

        </div>
    )
}