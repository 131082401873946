import SelectControl from "./SelectControl";
import InputField from "./InputField";
import MoneyInputField from "./MoneyInputField";
import { Translate } from "../../../translate/Translate";

export default function CostField(props) {
    const {index, useFormReturn, removeOtherCost} =props
    const {control, formState: { errors }, clearErrors } = useFormReturn


    return (<div className="grid grid-cols-3 gap-2 items-center mb-6">
    <div className="col-span-3 lg:col-span-1">
        <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Cost title")}</label>
        <InputField control={control} errors={errors}
            clearErrors={clearErrors}
            required={true}
            name={`other_costs.${index}.title`}
            id={`other_costs-${index}-title`}
            placeholder={Translate("Management fee")} 
        />
    </div>

    <div className="col-span-2 lg:col-span-1">
        <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Amount")}</label>
        <MoneyInputField control={control} errors={errors} clearErrors={clearErrors}
            required={true}
            name={`other_costs.${index}.price.amount`}
            id={`other_costs-${index}-value`}
            className=""
            placeholder="50,000"
        />
    </div>

    <div className="col-span-1 lg:col-span-1">
        <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Pay type")}</label>
        <SelectControl  control={control} errors={errors} clearErrors={clearErrors}
            name={`other_costs.${index}.type`}
            id={`other_costs-${index}-type`}
            options={[
                {label: Translate("Monthly"), value: "monthly"},
                {label: Translate("Yearly"), value: "yearly"},
                {label: Translate("One time"), value: "one-time"},
            ]}
            />
    </div>

    <div className="col-span-3 lg:col-span-3">
        <div className="flex items-center">
            <div className="w-full">
                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Remark")}</label>
                <InputField control={control} errors={errors}
                    clearErrors={clearErrors}
                    name={`other_costs.${index}.description`}
                    id={`other_costs-${index}-description`}
                    placeholder={Translate("Mitsui Villa management")} 
                />
            </div>
            <div className="ml-auto">
                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">&nbsp;</label>
                <button type="button" id={`remove-cost-${index}`} onClick={(e) => removeOtherCost(index)} className="focus:outline-none text-xs font-medium text-red-500 ml-2">
                    <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                </button>
            </div>
        </div>
    </div>

</div>)
}