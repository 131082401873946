import React, { useState, useCallback, useRef, useEffect } from "react"
import * as api from '../../../../v2/services/agent'
import clsx from "clsx";
import MoneyInputField from "./MoneyInputField"
import InputField from "./InputField"

export default function ChangeStatusForm(props) {
    const {originalStatus, data, Translate, useFormReturn} = props
    const {formState: { errors }, control, setValue, getValues, clearErrors} = useFormReturn
    const labels = {
        "draft":"Mark as Draft",
        "sold":"Mark as Sold",
        "active":"Mark as Active",
        "business_negotiation":"Mark as Negotiation",
        "inactive":"Delist this property",
        "delete":"Delete this property",
    }

    return (<>
        <div className="">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                            {Translate(labels[data.status])} · {data.property_id}
                        </h3>
                        <div className="">
                            <p className="text-sm text-gray-500">
                                {Translate("mark_property_to_sold_dialog_description")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            
            {data.status !== "sold" ? null :
                <div className="col-span-1 py-4 lg:py-4 px-6">
                    <div className="grid grid-cols-3 items-center">
                        <div className="col-span-3 lg:col-span-1">
                            <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("update_property_status_actual_sold_price")}</p>
                        </div>
                        <div className="col-span-3 lg:col-span-2">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="font-medium text-gray-600">
                                        ¥
                                    </span>
                                </div>
                                <MoneyInputField
                                    control={control}
                                    errors={errors} clearErrors={clearErrors}
                                    required={true}
                                    placeholder={data.price?.amount}
                                    defaultValue={data.price?.amount}
                                    name="status_info.sold_price"
                                    id="sold_price"
                                    className=""
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="col-span-1 py-4 lg:py-4 px-6">
                <div className="grid grid-cols-3 items-center">
                    <div className="col-span-3 lg:col-span-1">
                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("update_property_status_remark")}</p>
                    </div>
                    <div className="col-span-3 lg:col-span-2">
                        <InputField
                            control={control}
                            errors={errors} clearErrors={clearErrors}
                            required={true}
                            name="status_info.remark"
                            />
                    </div>
                </div>
            </div>
        </div>
    </>)
}